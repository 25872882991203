import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

import * as firebaseServiceWorker from '../firebaseServiceWorker';
import * as serviceWorker from '../serviceWorker';
import outerTheme from '../theme/theme.json';
import http from '../utils/http';
import gateway from '../utils/gateway';
import showToast from '../utils/showToast';
import getTenantEyepassImage from '../utils/getTenantEyepassImage';
import getLocaleNamespaces from '../utils/getLocaleNamespaces';
import TenantEyepassLoader from '../components/TenantEyepassLoader';
import ErrorComponent from '../components/Error';
import PushNotificationContainer from '../components/PushNotificationContainer';
import { Creators as tenantEyepassActions } from '../store/modules/tenantEyepass';
import { Creators as AuthCreators } from '../store/modules/auth';
import { Creators as UserCreators } from '../store/modules/user';
import * as productOrderActions from '../store/modules/productOrder';
import { Creators as tagActionCreators } from '../store/modules/tag';
import Analytics from '../utils/Analytics';
import manifestBuilder from '../utils/manifestBuilder';
import LocalStorageManager from '../utils/LocalStorageManager';
import getManifestS3Url from '../utils/getManifestS3Url';

import Routes from './Routes';

const mapStateToProps = ({ tenantEyepass }) => ({
  tenantEyepass,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setTenantEyepass: tenantEyepassActions.setTenantEyepass,
      clearTenantEyepass: tenantEyepassActions.clearTenantEyepass,
      logout: AuthCreators.logout,
      setUser: UserCreators.setUser,
      removeUser: UserCreators.removeUser,
      clearProductOrder: productOrderActions.clearProductOrder,
      removeTag: tagActionCreators.removeTag,
    },
    dispatch,
  );

const TenantRouter = ({
  tenantEyepass,
  setTenantEyepass,
  clearTenantEyepass,
  setUser,
  removeUser,
  clearProductOrder,
  removeTag,
}) => {
  const location = useLocation();
  const { t, i18n } = useTranslation();

  const {
    domain,
    theme,
    customLocale,
    title,
    logo,
    favicon,
    eyepassDescription,
    integrations,
    tenantId,
    manifest,
  } = tenantEyepass;

  const [isLoading, setIsLoading] = useState(false);
  const [manifestUrl, setManifestUrl] = useState(null);
  const [startingManifest] = useState(manifest);
  const [tenantEyepassNotFound, setTenantEyepassNotFound] = useState(false);
  const [eyepassNotPublished, setEyepassNotPublished] = useState(false);
  const hasUrlDomain = !!location.pathname && !!location.pathname.split('/')[1];

  const setCustomLocale = (newCustomLocale) => {
    const customLocaleLngs = Object.keys(newCustomLocale);

    if (customLocaleLngs.length > 0) {
      customLocaleLngs.forEach((customLocaleLng) => {
        if (newCustomLocale[customLocaleLng]) {
          const customLocaleNamespaces = Object.keys(
            newCustomLocale[customLocaleLng],
          );

          customLocaleNamespaces.forEach((customLocaleNamespace) => {
            const namespaceLocale =
              newCustomLocale[customLocaleLng][customLocaleNamespace];

            i18n.addResourceBundle(
              `${customLocaleLng}-CUSTOM`,
              customLocaleNamespace,
              namespaceLocale,
            );
          });
        }
      });
    }
  };

  const setNewManifest = (urlDomain) => {
    setManifestUrl(getManifestS3Url(urlDomain));
  };

  const clearTenantEyepassConfig = () => {
    clearTenantEyepass();

    const namespaces = getLocaleNamespaces();

    namespaces.forEach((namespace) => {
      i18n.removeResourceBundle('en-CUSTOM', namespace);
      i18n.removeResourceBundle('es-CUSTOM', namespace);
      i18n.removeResourceBundle('pt-CUSTOM', namespace);
    });

    setManifestUrl();
  };

  const handleUserAndLocalStorage = async (newTenantId) => {
    if (LocalStorageManager.getToken()) {
      LocalStorageManager.setDomainTenantId(newTenantId);

      const profile = await gateway
        .get('/profile')
        .then(({ data }) => data)
        .catch(() => null);

      if (profile) {
        setUser(profile.customer);
        Analytics.setCustomer(profile.customer.id);
      } else {
        removeUser();
      }
    } else {
      LocalStorageManager.removeToken();
      LocalStorageManager.removeRefreshToken();
      removeUser();
    }
  };

  const loadTenantEyepass = async (urlDomain) => {
    try {
      setIsLoading(true);
      setNewManifest(urlDomain);
      const response = await http
        .get(`tenant-eyepass-domain/${urlDomain}`)
        .then(({ data }) => data);

      if (!response?.data?.tenantEyepass) {
        throw new Error(t('errors:tenantEyepassNotFound'));
      }

      const {
        tenantId: newTenantId,
        domain: newDomain,
        theme: themeText,
        customLocale: customLocaleText,
        logo: newLogo,
        favicon: newFavicon,
        tosUrl,
        tutorialEnabled,
        minCartValue,
        deliveryDisabled,
        socialLoginDisabled,
        qrReaderDisabled,
        qrCodeOnly,
        paymentOnDeliveryEnabled,
        paymentOnDeliveryOnly,
        title: newTitle,
        eyepassShortName: newEyepassShortName,
        eyepassDescription: newEyepassDescription,
        integrations: newEyepassIntegrations,
        maxInstallments,
        minInstallmentValue,
        published,
        providerId,
      } = response.data.tenantEyepass;

      if (!published) {
        setEyepassNotPublished(true);
        return;
      }

      const newCustomLocale = customLocaleText
        ? JSON.parse(customLocaleText)
        : {};
      let customTheme = themeText ? JSON.parse(themeText) : outerTheme;
      customTheme = customTheme?.colors?.primary
        ? {
            ...customTheme,
            colors: {
              ...customTheme.colors,
              loginBackground:
                customTheme.colors?.loginBackground ||
                customTheme.colors.primary,
              loadingScreen:
                customTheme.colors?.loadingScreen || customTheme.colors.primary,
            },
          }
        : outerTheme;

      const newManifest = manifestBuilder(
        newDomain,
        newTitle,
        newEyepassShortName,
        newEyepassDescription,
        customTheme?.colors?.primary || '#7D1DD1',
        !!newFavicon && getTenantEyepassImage('favicon.ico', newTenantId),
        !!newLogo && getTenantEyepassImage('logo192.png', newTenantId),
        !!newLogo && getTenantEyepassImage('logo512.png', newTenantId),
      );

      setTenantEyepass({
        tenantId: newTenantId,
        domain: newDomain,
        title: newTitle,
        eyepassShortName: newEyepassShortName,
        eyepassDescription: newEyepassDescription,
        theme: customTheme,
        customLocale: newCustomLocale,
        logo: newLogo || null,
        favicon: newFavicon || null,
        tosUrl: tosUrl || null,
        tutorialEnabled: !!tutorialEnabled,
        minCartValue,
        deliveryDisabled: !!deliveryDisabled,
        socialLoginDisabled: !!socialLoginDisabled,
        qrReaderDisabled: !!qrReaderDisabled,
        qrCodeOnly: !!qrCodeOnly,
        paymentOnDeliveryEnabled: !!paymentOnDeliveryEnabled,
        paymentOnDeliveryOnly: !!paymentOnDeliveryOnly,
        maxInstallments: maxInstallments || 1,
        minInstallmentValue: minInstallmentValue || 0,
        integrations: newEyepassIntegrations
          ? JSON.parse(newEyepassIntegrations)
          : null,
        manifest: newManifest,
        providerId,
      });

      setCustomLocale(newCustomLocale);

      serviceWorker.register({ domain: newDomain });
      LocalStorageManager.setDomainTenantId(newTenantId);
      await handleUserAndLocalStorage(newTenantId);
    } catch (err) {
      console.error(err);
      showToast(err?.response?.data?.message || err.message, 'error');
      setTenantEyepassNotFound(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const { pathname } = location;
    const urlDomain = pathname.split('/')[1];

    if (!!urlDomain && urlDomain !== domain) {
      clearTenantEyepassConfig();
      loadTenantEyepass(urlDomain);
    } else if (!!urlDomain && urlDomain === domain) {
      LocalStorageManager.setDomainTenantId(tenantId);
      setCustomLocale(customLocale);
      setNewManifest(domain);
      serviceWorker.register({ domain });
    } else {
      clearTenantEyepassConfig();
      clearProductOrder();
      removeUser();
      removeTag();
    }
  }, []);

  const reregisterSW = async () => {
    if (location.pathname.split('/')[1] === domain) {
      firebaseServiceWorker.register();
    }
  };

  useEffect(() => {
    if (manifest && manifestUrl && startingManifest !== manifest) {
      reregisterSW();
    }
  }, [manifestUrl, manifest, startingManifest]);

  if (eyepassNotPublished) {
    return <ErrorComponent errorMessage={t('errors:eyepassNotPublished')} />;
  }

  if (tenantEyepassNotFound || !hasUrlDomain) {
    return <ErrorComponent errorMessage={t('errors:notFound')} />;
  }

  if (isLoading || !domain) {
    return <TenantEyepassLoader />;
  }

  const googleAnalytics = integrations?.googleAnalytics;
  const facebookPixel = integrations?.facebookPixel;
  const appsFlyer = integrations?.appsFlyer;

  return (
    <>
      <Helmet>
        {!!appsFlyer && (
          <script>
            {`!(function (t, e, n, s, a, c, i, o, p) {
              (t.AppsFlyerSdkObject = a),
                (t.AF =
                  t.AF ||
                  function () {
                    (t.AF.q = t.AF.q || []).push(
                      [Date.now()].concat(Array.prototype.slice.call(arguments)),
                    );
                  }),
                (t.AF.id = t.AF.id || i),
                (t.AF.plugins = {}),
                (o = e.createElement(n)),
                (p = e.getElementsByTagName(n)[0]),
                (o.async = 1),
                (o.src =
                  'https://websdk.appsflyer.com?' +
                  (c.length > 0 ? 'st=' + c.split(',').sort().join(',') + '&' : '') +
                  (i.length > 0 ? 'af_id=' + i : '')),
                p.parentNode.insertBefore(o, p);
            })(window, document, 'script', 0, 'AF', 'pba', {
              pba: { webAppId: "${appsFlyer.token}" },
            });`}
          </script>
        )}
        {!!googleAnalytics && (
          <>
            <script
              async
              src={`https://www.googletagmanager.com/gtag/js?id=${googleAnalytics.token}`}
            />
            <script>
              {`window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());

              gtag('config', '${googleAnalytics.token}');`}
            </script>
          </>
        )}
        {!!facebookPixel && (
          <>
            <script>
              {`!function(f,b,e,v,n,t,s)
                {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                n.queue=[];t=b.createElement(e);t.async=!0;
                t.src=v;s=b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t,s)}(window, document,'script',
                'https://connect.facebook.net/en_US/fbevents.js');
                fbq('init', '${facebookPixel.token}');
                fbq('track', 'PageView');`}
            </script>
            <noscript>
              <img
                height="1"
                width="1"
                style={{ display: 'none' }}
                src={`https://www.facebook.com/tr?id=${facebookPixel.token}&ev=PageView&noscript=1`}
                alt=""
              />
            </noscript>
          </>
        )}
        {!!theme?.colors?.primary && (
          <meta
            name="theme-color"
            content={theme?.colors?.primary || '#7D1DD1'}
          />
        )}
        {!!theme?.colors?.primary && (
          <meta
            name="msapplication-navbutton-color"
            content={theme?.colors?.primary || '#7D1DD1'}
          />
        )}
        {!!theme?.colors?.primary && (
          <meta
            name="apple-mobile-web-app-status-bar-style"
            content={theme?.colors?.primary || '#7D1DD1'}
          />
        )}
        {!!eyepassDescription && (
          <meta name="description" content={eyepassDescription} />
        )}
        {!!logo && <link rel="apple-touch-icon" href={logo} />}
        {!!favicon && (
          <link
            rel="icon"
            href={getTenantEyepassImage('favicon.ico', tenantId)}
          />
        )}
        <link rel="manifest" href={manifestUrl} />
        <title>{title}</title>
      </Helmet>
      <PushNotificationContainer>
        <Routes domain={domain} />
      </PushNotificationContainer>
    </>
  );
};

TenantRouter.propTypes = {
  tenantEyepass: PropTypes.object.isRequired,
  setTenantEyepass: PropTypes.func.isRequired,
  clearTenantEyepass: PropTypes.func.isRequired,
  setUser: PropTypes.func.isRequired,
  removeUser: PropTypes.func.isRequired,
  clearProductOrder: PropTypes.func.isRequired,
  removeTag: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(TenantRouter);
