import React from 'react';
import PropTypes from 'prop-types';

import Loading from '../Loading';
import OrderAddressSelectItem from '../OrderAddressSelectItem';

const DeliveryAddressSelect = ({
  addressesList,
  onSelect,
  selectedAddressId,
  disabled,
  isLoading,
}) => {
  const renderAddressItem = (addressItem) => {
    const {
      id,
      label,
      address,
      number,
      address2,
      district,
      state,
      city,
      main,
    } = addressItem;

    const addressText = address ? `${address}, ${number}` : '';
    const cityState = city ? `${city}, ${state}` : '-';

    return (
      <OrderAddressSelectItem
        key={id}
        selected={selectedAddressId === id}
        main={main}
        label={label}
        address={addressText}
        cityState={cityState}
        district={district}
        complement={address2}
        onSelect={() => onSelect(addressItem)}
        disabled={disabled}
      />
    );
  };

  return (
    <>
      {isLoading && addressesList.length === 0 && <Loading />}
      {addressesList.map(renderAddressItem)}
    </>
  );
};

DeliveryAddressSelect.defaultProps = {
  selectedAddressId: null,
  disabled: false,
  isLoading: false,
};

DeliveryAddressSelect.propTypes = {
  addressesList: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
  selectedAddressId: PropTypes.string,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
};

export default DeliveryAddressSelect;
