const validCreditCardFlags = [
  'Visa',
  'Mastercard',
  'American Express',
  'Elo',
  'Hipercard',
  'Aura',
  'JCB',
  'Diners Club',
  'Discover',
];

export default validCreditCardFlags;
