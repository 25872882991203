import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';

import {
  fetchLimit,
  analyticsEventActions,
  analyticsEventCategory,
} from '../../utils/constants';
import Analytics from '../../utils/Analytics';
import * as productOrderActions from '../../store/modules/productOrder';
import ProductService from '../../services/ecommerce';
import groupOrderHistoryByDay from '../../utils/groupOrderHistoryByDay';
import gateway from '../../utils/gateway';
import showToast from '../../utils/showToast';
import showCustomToast from '../../utils/showCustomToast';
import mapOrderHistory from '../../utils/mapOrderHistory';
import mapStore from '../../utils/mapStore';

import CancelOrderModal from './CancelOrderModal';
import OrderHistoryScreen from './OrderHistoryScreen';
import OrderHistoryDetailContainer from './OrderHistoryDetailContainer';

const fetchHistoryToastId = 'FETCH_HISTORY_TOAST_ID';
const fetchMoreToastId = 'FETCH_MORE_TOAST_ID';

const mapStateToProps = ({ tenantEyepass: { domain, tenantId } }) => ({
  domain,
  tenantId,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setStore: productOrderActions.setStore,
      clearOrder: productOrderActions.clearOrder,
      setOrder: productOrderActions.setOrder,
    },
    dispatch,
  );

const OrderHistoryContainer = ({
  history,
  setStore,
  setOrder,
  domain,
  tenantId,
}) => {
  const { t } = useTranslation();

  const parsedQuery = queryString.parse(history.location.search);
  const initialOrderItem = parsedQuery.orderId
    ? { id: parsedQuery.orderId }
    : null;

  const [orderList, setOrderList] = useState([]);
  const [groupedOrders, setGroupedOrders] = useState([]);
  const [orderItem, setOrderItem] = useState(initialOrderItem);
  const [isLoading, setIsLoading] = useState(true);
  const [isFetchingMore, setIsFetchingMore] = useState(false);
  const [fetchOffset, setFetchOffset] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [cancelOrder, setCancelOrder] = useState(null);

  useEffect(() => {
    if (orderList && orderList.length > 0) {
      setGroupedOrders(groupOrderHistoryByDay(orderList));
    }
  }, [orderList]);

  const fetchOrderHistory = async () => {
    try {
      setIsLoading(true);

      const response = await gateway
        .get(ProductService.history(0, fetchLimit))
        .then(({ data }) => data);

      const { offset: newOffset, has_more: newHasMore, data } = response;
      setOrderList(data.map(mapOrderHistory));
      setFetchOffset(newOffset + data.length);
      setHasMore(newHasMore);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      showCustomToast(e.message, 'error', {
        toastId: fetchHistoryToastId,
      });
    }
  };

  const attemptCancelOrder = async (orderId) => {
    try {
      setIsLoading(true);

      await gateway
        .post(ProductService.cancelOrder(), {
          orderId,
        })
        .then(({ data }) => data);

      showToast(t('orderHistory:orderCancelSuccess'), 'success');
      fetchOrderHistory();
    } catch (error) {
      const message = error?.response?.data?.message || error.message;

      showToast(message, 'error');
    }
    fetchOrderHistory();
  };

  const fetchMoreOrderHistory = async (offset = 0) => {
    try {
      setIsFetchingMore(true);

      const response = await gateway
        .get(ProductService.history(offset, fetchLimit))
        .then(({ data }) => data);

      const { offset: newOffset, has_more: newHasMore, data } = response;

      setOrderList((prevOrderList) =>
        prevOrderList.concat(data.map(mapOrderHistory)),
      );
      setFetchOffset(newOffset + data.length);
      setHasMore(newHasMore);
      setIsFetchingMore(false);
    } catch (e) {
      setIsFetchingMore(false);
      showCustomToast(e.message, 'error', {
        toastId: fetchMoreToastId,
      });
    }
  };

  const handleFetchMore = () => {
    if (!isLoading && !isFetchingMore) {
      fetchMoreOrderHistory(fetchOffset);
    }
  };

  const handleSelectHistoryItem = (newOrderItem) => {
    Analytics.event({
      category: analyticsEventCategory.ORDER,
      action: analyticsEventActions.TRACKING_ORDER,
    });
    setOrderItem(newOrderItem);
  };

  const handleRemoveHistoryItem = (mustRefetch = false) => {
    setOrderItem(null);

    if (mustRefetch) {
      fetchOrderHistory();
    }
  };

  useEffect(() => {
    fetchOrderHistory();
  }, []);

  const handleOpenOrderCancelModal = (order) => {
    setCancelOrder(order);
  };

  const handleCloseOrderCancelModal = () => {
    setCancelOrder(null);
  };

  const handleCancelOrder = () => {
    attemptCancelOrder(cancelOrder.orderId);
    setCancelOrder(null);
    setOrderItem(null);
  };

  const validateStore = async (eventPointId) => {
    try {
      setIsLoading(true);
      const response = await gateway
        .get(ProductService.getEventPoint(eventPointId, tenantId))
        .then(({ data }) => data);

      const { event_point: eventPoint } = response;

      const store = (eventPoint && mapStore(eventPoint)) || null;

      if (store) {
        return store;
      }

      throw new Error(t('errors:orderNotFound'));
    } catch (error) {
      return Promise.reject(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleOrderRedo = async (store, products) => {
    try {
      const validatedStore = await validateStore(store.id);

      if (validatedStore.isOpen) {
        setStore({
          store: validatedStore,
        });
        setOrder({ products });

        history.push(`/${domain}/pedido`);
      } else {
        throw new Error(t('errors:storeIsNotOpen'));
      }
    } catch (e) {
      showToast(e.message, 'error');
    }
  };

  const navigateToHome = () => history.push(`/${domain}/carrinho`);

  const renderContent = () => {
    if (orderItem) {
      return (
        <OrderHistoryDetailContainer
          outerLoading={isLoading}
          order={orderItem}
          onReturn={handleRemoveHistoryItem}
          onCancelOrder={handleOpenOrderCancelModal}
          onRedoOrder={handleOrderRedo}
        />
      );
    }

    return (
      <OrderHistoryScreen
        orders={groupedOrders}
        onFetchMore={handleFetchMore}
        onCancelOrder={handleOpenOrderCancelModal}
        isLoading={isLoading}
        isFetchingMore={isFetchingMore}
        hasMore={hasMore}
        onHistoryItemSelect={handleSelectHistoryItem}
        onOrderRedo={handleOrderRedo}
        onNavigate={navigateToHome}
      />
    );
  };

  return (
    <>
      <CancelOrderModal
        visible={!!cancelOrder}
        onCloseModal={handleCloseOrderCancelModal}
        onConfirm={handleCancelOrder}
        order={cancelOrder}
      />
      {renderContent()}
    </>
  );
};

OrderHistoryContainer.defaultProps = {
  domain: null,
};

OrderHistoryContainer.propTypes = {
  history: PropTypes.object.isRequired,
  setStore: PropTypes.func.isRequired,
  setOrder: PropTypes.func.isRequired,
  domain: PropTypes.string,
  tenantId: PropTypes.number.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OrderHistoryContainer);
