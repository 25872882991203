import { css } from '@emotion/core';

export const title = (theme) => css`
  margin-bottom: ${theme && theme.getSpacing(3, 'px')};
  text-align: left;
  font-size: ${theme && theme.getFontSize(1, 'px')};
  font-family: ${theme && theme.getFont()};
  color: ${theme && theme.getColor('text')};
  font-weight: bold;
  text-transform: uppercase;
`;
