import { measureTypes } from './constants';
import getProductImageSrc from './getProductImageSrc';

const mapComplementItem = (complementItem) => {
  const {
    id,
    name,
    description,
    price,
    product: complementProductData,
  } = complementItem;

  const complementGroupId =
    complementItem.complement_group_id ||
    complementItem.product_complement_group_id;

  return {
    id,
    name: complementProductData?.name || name || '',
    description,
    price,
    complementGroupId,
    quantity: 0,
    complementProductData,
  };
};

const mapProductComplementGroups = (complementGroup) => {
  const {
    id,
    min,
    max,
    name,
    description,
    product_complements: complementItems,
  } = complementGroup;

  return {
    id,
    min,
    max,
    name,
    description,
    complementItems: complementItems.map(mapComplementItem),
  };
};

const mapProductComplementGroupAssociations = (
  productComplementGroupAssociation,
) => {
  const { complement_group: complementGroup } =
    productComplementGroupAssociation;

  return {
    id: complementGroup.id,
    min: complementGroup.min,
    max: complementGroup.max,
    name: complementGroup.name,
    description: '',
    complementItems: complementGroup.complements
      .sort((a, b) => a.position - b.position)
      .map(mapComplementItem),
  };
};

const mapComboProductItem = (menuProductCombo) => {
  const { id, price, quantity, product } = menuProductCombo;
  const {
    id: productId,
    image,
    name,
    rigid_stock_enabled: rigidStockEnabled,
    extra,
    description,
    type,
    measure,
  } = product;

  const balance = (extra && extra.balance) ?? undefined;

  return {
    id: productId,
    menuProductComboId: id,
    itemName: name,
    itemPrice: price,
    itemImage: image,
    itemQuantity: quantity,
    itemMeasure: measureTypes[measure] || 'UNIT',
    itemDescription: description,
    itemRigidStockEnabled: rigidStockEnabled,
    itemStockBalance: balance,
    itemType: type,
  };
};

const adaptComplementGroups = (
  productComplementGroupAssociations,
  productComplementGroups,
) => {
  if (
    productComplementGroupAssociations &&
    productComplementGroupAssociations.length > 0
  ) {
    return productComplementGroupAssociations
      .sort((a, b) => a.position - b.position)
      .map(mapProductComplementGroupAssociations);
  }

  if (productComplementGroups && productComplementGroups.length > 0) {
    return productComplementGroups
      .sort((a, b) => a.position - b.position)
      .map(mapProductComplementGroups);
  }

  return [];
};

const mapMenuContent = (menuContent, menuProductGroupId) => {
  const {
    id,
    price,
    product,
    favorite,
    nickname,
    product_complement_groups: productComplementGroups,
    menu_product_combos: menuProductCombos,
    checkout_journey_enabled: checkoutJourneyEnabled,
    delivery_journey_enabled: deliveryJourneyEnabled,
    takeout_journey_enabled: takeoutJourneyEnabled,
    product_group_id: productGroupId,
  } = menuContent;

  const {
    id: productId,
    image,
    name,
    rigid_stock_enabled: rigidStockEnabled,
    extra,
    product_images: productImages,
    description,
    type,
    measure,
    sku,
    tracking_enabled: trackingEnabled,
    product_complement_group_associations: productComplementGroupAssociations,
  } = product;

  const balance = (extra && extra.balance) ?? undefined;

  const mainImage = getProductImageSrc(image);

  return {
    id,
    productId,
    menuProductGroupId,
    price,
    productGroupId,
    name: nickname || name,
    image: mainImage,
    mainImageKey: image,
    rigidStockEnabled,
    favorite,
    stockBalance: balance,
    sku,
    measure: measureTypes[measure] || 'UNIT',
    checkoutJourneyEnabled,
    deliveryJourneyEnabled,
    takeoutJourneyEnabled,
    productImages: productImages.map(({ image: altImage }) => altImage),
    description,
    complementGroups: adaptComplementGroups(
      productComplementGroupAssociations,
      productComplementGroups,
    ),
    complementsTotal: 0,
    type,
    comboProductItems: menuProductCombos.map(mapComboProductItem),
    trackingEnabled,
  };
};

const mapProductGroups = (menuProductGroup) => {
  const { id, color } = menuProductGroup;

  const productGroup = menuProductGroup.product_group;

  const { id: productGroupId, name } = productGroup;

  const menuContents = productGroup.menu_contents;

  const products = menuContents
    .sort((a, b) => a.order_product - b.order_product)
    .map((menuContent) => {
      return mapMenuContent(menuContent, id);
    });

  return {
    id,
    productGroupId,
    color,
    name,
    products,
  };
};

export default mapProductGroups;
