import { css } from '@emotion/core';
import styled from '@emotion/styled';

export const HeaderContainer = styled.div`
  width: 100%;
  height: 80px;
  padding-top: ${({ theme }) => theme && theme.getSpacing(1, 'px')};
  padding-bottom: ${({ theme }) => theme && theme.getSpacing(1, 'px')};
  padding-left: ${({ theme }) => theme && theme.getSpacing(2, 'px')};
  padding-right: ${({ theme }) => theme && theme.getSpacing(2, 'px')};
  background-color: ${({ theme }) => theme && theme.getColor('foreground')};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const headerNameWrapper = (theme) => css`
  width: 100%;
  padding: ${theme && theme.getSpacing(1, 'px')};
`;

export const HeaderName = styled.span`
  text-align: left;
  font-size: ${({ theme }) => theme && theme.getFontSize(1, 'px')};
  font-family: ${({ theme }) => theme && theme.getFont('heading')};
  color: ${({ theme }) => theme && theme.getColor('uiText')};
  font-weight: bold;
  width: 100%;
`;
