import * as Yup from 'yup';
import { documentSchema } from '../../utils/schemas';
import dayjs from '../../utils/dayjs';

const requiredFormSchema = Yup.object().shape({
  fullName: Yup.string().required('formValidation:fieldRequired'),
  email: Yup.string()
    .email('formValidation:invalidEmail')
    .required('formValidation:fieldRequired'),
  phone: Yup.string()
    .matches(
      /^\([1-9]{2}\) (?:[2-8]|9[1-9])[0-9]{3}-[0-9]{4}$/,
      'formValidation:invalidPhone',
    )
    .required('formValidation:fieldRequired'),
  birthdate: Yup.string()
    .required('formValidation:fieldRequired')
    .test(
      'validate-birthdate',
      'formValidation:invalidBirthDate',
      function validateBirthDate(value) {
        if (!value) {
          return true;
        }
        return dayjs(value, 'DD/MM/YYYY').format('DD/MM/YYYY') === value;
      },
    ),
  document: documentSchema,
  password: Yup.string()
    .test(
      'password-has-six-chars',
      'formValidation:invalidPassword',
      function validatePasswordLength(value) {
        return value && value.length >= 6;
      },
    )
    .test(
      'password-has-one-numeral',
      'formValidation:invalidPassword',
      function validatePasswordHasNumeral(value) {
        return value && /\d/.test(value);
      },
    )
    .required('formValidation:fieldRequired'),
  passwordConfirmation: Yup.string()
    .test(
      'password-match',
      'formValidation:passwordsDontMatch',
      function validatePasswords(value) {
        const { password } = this.parent;

        return password === value;
      },
    )
    .required('formValidation:fieldRequired'),
  acceptedTOS: Yup.bool().oneOf([true], 'formValidation:mustAcceptTOS'),
  acceptedMarketing: Yup.bool(),
});

export default requiredFormSchema;
