import React from 'react';
import PropTypes from 'prop-types';
import ComboItem from '../ComboItem';
import localizePrice from '../../utils/localizePrice';
import { smallMeasureTypesMap } from '../../utils/constants';
import {
  groupContainer,
  GroupTitleWrapper,
  GroupTitle,
} from './ProductGroup.styles';

const ProductGroup = ({ productGroup, onAddItem, ignoreRigidStock }) => {
  const handleAddItem = (productItem) => () => {
    onAddItem(productItem);
  };

  const renderGroupProductItem = (productItem) => {
    const maxComboProduct = (productItem.comboProductItems || []).reduce(
      (acc, comboProductItem) => {
        const { itemRigidStockEnabled, itemStockBalance, itemQuantity } =
          comboProductItem;
        if (!itemRigidStockEnabled) {
          return acc;
        }
        const maxStock = itemStockBalance === undefined ? 99 : itemStockBalance;
        return Math.min(acc, Math.floor(maxStock / itemQuantity));
      },
      99,
    );
    const disabled =
      !ignoreRigidStock &&
      ((productItem.rigidStockEnabled &&
        productItem.stockBalance !== undefined &&
        productItem.stockBalance <= 0) ||
        maxComboProduct === 0);

    const { measure } = productItem;

    const productValue =
      !!productItem.price && measure !== 'UNIT'
        ? `R$ ${localizePrice(productItem.price / 10)} / 100${
            smallMeasureTypesMap[measure]
          }`
        : `R$ ${localizePrice(productItem.price)}`;

    const productAmount =
      measure === 'UNIT'
        ? `${productItem.sumAmount}`
        : `${productItem.sumAmount * 100} ${smallMeasureTypesMap[measure]}`;

    return (
      <ComboItem
        key={`combo-item-${productItem.id}`}
        comboImgSrc={productItem.image}
        comboName={productItem.name}
        comboValue={productValue}
        onClick={handleAddItem(productItem)}
        disabled={disabled}
        comboDescription={productItem.description}
        amountText={productItem.sumAmount > 0 ? productAmount : null}
      />
    );
  };

  const { name, products } = productGroup;

  return (
    <div css={groupContainer}>
      <GroupTitleWrapper>
        <GroupTitle>{name}</GroupTitle>
      </GroupTitleWrapper>
      {products.map(renderGroupProductItem)}
    </div>
  );
};

ProductGroup.propTypes = {
  productGroup: PropTypes.object.isRequired,
  onAddItem: PropTypes.func.isRequired,
  ignoreRigidStock: PropTypes.bool,
};

ProductGroup.defaultProps = {
  ignoreRigidStock: false,
};

export default ProductGroup;
