import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import LoadingScreen from '../../components/LoadingScreen';
import Header from '../../components/Header';
import InfiniteScroll from '../../components/InfiniteScroll';
import HistoryItem from '../../components/HistoryItem';
import localizePrice from '../../utils/localizePrice';
import dayjs from '../../utils/dayjs';
import { historyGroupTitle, DescriptionText } from './OrderHistoryPage.styles';
import { screenWrapper, screenInner } from '../Page.styles';

const OrderHistoryScreen = ({
  orders,
  onFetchMore,
  hasMore,
  onHistoryItemSelect,
  isLoading,
  isFetchingMore,
  onNavigate,
}) => {
  const { t } = useTranslation();

  const onSeeDetails = (order) => () => onHistoryItemSelect(order);

  const renderHistoryItem = (order) => {
    const {
      id,
      priceTotal,
      store: { name },
      status,
      time,
      deliveryPackage,
      orderId,
    } = order;

    const priceText = `R$ ${localizePrice(priceTotal)}`;

    return (
      <HistoryItem
        key={id}
        orderId={orderId}
        storeName={name}
        priceText={priceText}
        timeText={dayjs(time).format('HH:mm')}
        orderStatus={status}
        onSeeDetails={onSeeDetails(order)}
        deliveryStatus={deliveryPackage && deliveryPackage.status}
      />
    );
  };

  const renderHistoryGroup = (groupedHistory) => {
    return (
      <Fragment key={groupedHistory.day}>
        <div css={historyGroupTitle}>
          <DescriptionText fontFamily="futura">
            {dayjs(groupedHistory.day, 'DD-MM-YYYY')
              .locale('pt-br')
              .format('D MMMM YYYY')}
          </DescriptionText>
        </div>
        {groupedHistory.orders.map(renderHistoryItem)}
      </Fragment>
    );
  };

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <div css={screenWrapper}>
      <Header title={t('navigation:orderHistory')} onPrev={onNavigate} />
      <InfiniteScroll
        id="history"
        hideScroll
        hasHeader
        items={orders}
        renderItem={renderHistoryGroup}
        onFetchMore={onFetchMore}
        hasMore={hasMore}
        isLoading={isFetchingMore}
        endListMessage={t('orderHistory:endOrdersList')}
        emptyListMessage={t('orderHistory:emptyOrdersList')}
        divCss={screenInner}
      />
    </div>
  );
};

OrderHistoryScreen.defaultProps = {
  isLoading: false,
  isFetchingMore: false,
};

OrderHistoryScreen.propTypes = {
  orders: PropTypes.array.isRequired,
  onFetchMore: PropTypes.func.isRequired,
  hasMore: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  isFetchingMore: PropTypes.bool,
  onHistoryItemSelect: PropTypes.func.isRequired,
  onNavigate: PropTypes.func.isRequired,
};

export default OrderHistoryScreen;
