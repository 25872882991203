import React from 'react';
import PropTypes from 'prop-types';

import HeaderCornerButton from '../HeaderCornerButton';
import { ReactComponent as ChevronLeft } from '../../assets/chevron_left.svg';
import { ReactComponent as ChevronRight } from '../../assets/chevron_right.svg';

import {
  headerContainerCss,
  headerTextCss,
  headerCornerButtonDivCss,
} from './Header.styles';

const Header = ({
  title,
  onPrev,
  leftIcon: LeftIcon,
  rightActionHandler,
  rightIcon: RightIcon,
}) => (
  <div css={headerContainerCss}>
    {onPrev ? (
      <div css={headerCornerButtonDivCss}>
        <HeaderCornerButton
          icon={(LeftIcon && <LeftIcon />) || <ChevronLeft />}
          onClick={onPrev}
        />
      </div>
    ) : (
      <div css={headerCornerButtonDivCss} />
    )}
    <div>
      <span css={headerTextCss}>{title}</span>
    </div>
    {rightActionHandler ? (
      <div css={headerCornerButtonDivCss}>
        <HeaderCornerButton
          icon={(RightIcon && <RightIcon />) || <ChevronRight />}
          onClick={rightActionHandler}
        />
      </div>
    ) : (
      <div css={headerCornerButtonDivCss} />
    )}
  </div>
);

Header.defaultProps = {
  onPrev: null,
  leftIcon: null,
  rightActionHandler: null,
  rightIcon: null,
};

Header.propTypes = {
  title: PropTypes.string.isRequired,
  onPrev: PropTypes.func,
  rightActionHandler: PropTypes.func,
  rightIcon: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.func,
    PropTypes.element,
    PropTypes.object,
  ]),
  leftIcon: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.func,
    PropTypes.element,
    PropTypes.object,
  ]),
};

export default Header;
