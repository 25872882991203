import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

import ScrollableView from '../../components/ScrollableView';
import Header from '../../components/Header';
import OptionalDataForm from '../../components/OptionalDataForm';
import { formWrapper } from './OptionalDataPage.styles';

const mapStateToProps = ({ tenantEyepass: { domain } }) => ({
  domain,
});

const OptionalDataScreen = ({ formInitialValues, onFormSubmit, domain }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleOnPrev = () => {
    history.push(`/${domain}/registro`);
  };

  return (
    <>
      <Header onPrev={handleOnPrev} title={t('navigation:myAddress')} />
      <ScrollableView hasHeader>
        <div css={formWrapper}>
          <OptionalDataForm
            onFormSubmit={onFormSubmit}
            initialValues={formInitialValues}
          />
        </div>
      </ScrollableView>
    </>
  );
};

OptionalDataScreen.defaultProps = {
  domain: null,
};

OptionalDataScreen.propTypes = {
  formInitialValues: PropTypes.object.isRequired,
  onFormSubmit: PropTypes.func.isRequired,
  domain: PropTypes.string,
};

export default connect(mapStateToProps)(OptionalDataScreen);
