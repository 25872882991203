import React from 'react';
import PropTypes from 'prop-types';
import Logo from '../../components/Logo';
import checked from '../../assets/checked.svg';
import ScrollableView from '../../components/ScrollableView';
import {
  backgroundCss,
  wrapperCss,
  checkCss,
  flavourTextCss,
  nameTextCss,
} from './DataSentPage.styles';

const DataSentPage = ({ userName, flavourText }) => {
  return (
    <div css={backgroundCss}>
      <ScrollableView>
        <div css={wrapperCss}>
          <Logo />
          <img src={checked} alt="checked" css={checkCss} />
          {userName && <span css={nameTextCss}>{userName}</span>}
          <div css={flavourTextCss}>{flavourText}</div>
        </div>
      </ScrollableView>
    </div>
  );
};

DataSentPage.defaultProps = {
  userName: '',
  flavourText: '',
};

DataSentPage.propTypes = {
  userName: PropTypes.string,
  flavourText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.func,
    PropTypes.node,
  ]),
};

export default DataSentPage;
