import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import { useTranslation } from 'react-i18next';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import PopMenu from '../PopMenu';

import {
  ItemContainer,
  mainTag,
  labelText,
  descText,
} from './CustomerAddressItem.styles';

const CustomerAddressItem = ({
  label,
  main,
  address,
  cityState,
  district,
  complement,
  onRemove,
  onTurnMain,
  onEdit,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [anchorMenu, setAnchorMenu] = useState(null);
  const open = !!anchorMenu;

  const handleClick = (event) => {
    setAnchorMenu(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorMenu(null);
  };

  const handleRemoveAddress = () => {
    handleClose();
    onRemove();
  };

  const handleTurnAddressMain = () => {
    handleClose();
    onTurnMain();
  };

  const handleEditAddress = () => {
    handleClose();
    onEdit();
  };

  return (
    <ItemContainer main={main}>
      <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
        <span css={labelText}>{label}</span>
        <span css={descText}>{address}</span>
        <span css={descText}>{cityState}</span>
        <span css={descText}>{district}</span>
        <span css={descText}>{complement}</span>
      </div>
      <div
        style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-end',
          height: '100%',
        }}
      >
        {main && <span css={mainTag}>{t('addressesListPage:main')}</span>}
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>
        <PopMenu
          id="long-menu"
          anchorEl={anchorMenu}
          open={open}
          onClose={handleClose}
        >
          <MenuItem
            key="edit"
            onClick={handleEditAddress}
            style={{
              color: theme.getColor('background'),
            }}
          >
            {t('addressesListPage:edit')}
          </MenuItem>
          {!main && (
            <MenuItem
              key="turnMain"
              onClick={handleTurnAddressMain}
              style={{
                color: theme.getColor('background'),
              }}
            >
              {t('addressesListPage:turnMain')}
            </MenuItem>
          )}
          <MenuItem
            key="remove"
            onClick={handleRemoveAddress}
            style={{
              color: theme.getColor('background'),
            }}
          >
            {t('addressesListPage:remove')}
          </MenuItem>
        </PopMenu>
      </div>
    </ItemContainer>
  );
};

CustomerAddressItem.defaultProps = {
  main: false,
};

CustomerAddressItem.propTypes = {
  label: PropTypes.string.isRequired,
  main: PropTypes.bool,
  address: PropTypes.string.isRequired,
  cityState: PropTypes.string.isRequired,
  district: PropTypes.string.isRequired,
  complement: PropTypes.string.isRequired,
  onRemove: PropTypes.func.isRequired,
  onTurnMain: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
};

export default CustomerAddressItem;
