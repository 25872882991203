import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

import Analytics from '../utils/Analytics';

const AppRoute = ({
  template: Template,
  component,
  componentProps,
  ...rest
}) => {
  const renderChildren = (routeProps) => {
    Analytics.pageview(routeProps.location.pathname.substring(1));

    const Component = component;
    if (Template) {
      return (
        <Template
          location={routeProps.location}
          history={routeProps.history}
          match={routeProps.match}
        >
          <Component {...rest} {...componentProps} />
        </Template>
      );
    }

    return (
      <Component
        location={routeProps.location}
        history={routeProps.history}
        match={routeProps.match}
        {...rest}
        {...componentProps}
      />
    );
  };

  return <Route {...rest} render={renderChildren} />;
};

AppRoute.defaultProps = {
  template: undefined,
  componentProps: undefined,
  component: undefined,
};

AppRoute.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.element,
    PropTypes.object,
  ]),
  template: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.element,
    PropTypes.object,
  ]),
  componentProps: PropTypes.object,
};

export default AppRoute;
