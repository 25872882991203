import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SessionStorageManager from '../../utils/SessionStorageManager';
import * as registerFormActions from '../../store/modules/registerForm';
import RequiredDataScreen from './RequiredDataScreen';

const mapStateToProps = ({
  registerForm: { registerForm },
  tenantEyepass: { domain },
}) => ({
  formData: {
    autoFetchData: registerForm.autoFetchData,
    fullName: registerForm.fullName,
    email: registerForm.email,
    phone: registerForm.phone,
    birthdate: registerForm.birthdate,
    document: registerForm.document,
    acceptedMarketing: registerForm.acceptedMarketing,
    acceptedTOS: registerForm.acceptedTOS,
  },
  domain,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setRegisterForm: registerFormActions.setRegisterForm,
    },
    dispatch,
  );

const RequiredDataContainer = ({
  history,
  formData,
  setRegisterForm,
  domain,
}) => {
  const handleFormSubmit = (submittedFormData) => {
    setRegisterForm(submittedFormData);
    history.push(`/${domain}/registro/endereco`);
  };

  const handleUserData = (user) => {
    setRegisterForm({
      id: user.id,
      fullName: user.fullName || '',
      email: user.email || '',
      phone: user.phone,
      birthdate: user.birthdate || '',
      document: user.document,
      addressZipcode: user?.address?.zipcode || '',
      addressStreet: user?.address?.address || '',
      addressNumber: user?.address?.number || '',
      addressComplement: user?.address?.complement || '',
      addressDistrict: user?.address?.district || '',
      addressCity: user?.address?.city || '',
      addressState: user?.address?.state || '',
    });
  };

  const handleRemoveUserId = () => {
    setRegisterForm({
      id: null,
    });
  };

  const hasToken = !!SessionStorageManager.getRegisterToken();

  return (
    <RequiredDataScreen
      formInitialValues={formData}
      onFormSubmit={handleFormSubmit}
      isPreRegistered={hasToken}
      onUserDataFound={handleUserData}
      onRemoveUserData={handleRemoveUserId}
      noAutoFetchCustomer={!formData.autoFetchData}
      domain={domain}
    />
  );
};

RequiredDataContainer.defaultProps = {
  domain: null,
};

RequiredDataContainer.propTypes = {
  history: PropTypes.object.isRequired,
  formData: PropTypes.object.isRequired,
  setRegisterForm: PropTypes.func.isRequired,
  domain: PropTypes.string,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RequiredDataContainer);
