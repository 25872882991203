import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Modal from '@bit/eyemobile.core.modal';

import {
  productContent,
  productInnerWrapper,
  productOverlay,
} from '../../components/ProductAdjustModal/ProductAdjustModal.styles';
import showToast from '../../utils/showToast';
import StoreSelect from '../../components/StoreSelect';
import withModal from '../../hocs/withModal';
import StoreSelectConfirmation from '../../components/StoreSelectConfirmation';
import gateway from '../../utils/gateway';
import EcommerceService from '../../services/ecommerce';
import { shippingTypes, currentEnv } from '../../utils/constants';
import fetchGeolocation from '../../utils/fetchGeolocation';
import mapStore from '../../utils/mapStore';
import debounce from '../../utils/debounce';
import clean from '../../utils/clean';

const mapStateToProps = ({
  auth,
  productOrder: {
    productOrder: { shippingType },
  },
  tenantEyepass: { tenantId },
}) => ({
  isLogged: auth.isLogged,
  shippingType,
  tenantId,
});

const StoreSelectModal = ({
  visible,
  currentStore,
  onStoreChange,
  onCloseModal,
  isLogged,
  hasProducts,
  tenantId,
  deliveryAddress,
  shippingType,
}) => {
  const { t } = useTranslation();
  const [storeList, setStoreList] = useState([]);
  const [selectedStore, setSelectedStore] = useState(null);
  const [filterText, setFilterText] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [userHasDeniedGeolocation, setUserHasDeniedGeolocation] =
    useState(false);
  const [storeChangeConfirmationMode, setStoreChangeConfirmationMode] =
    useState(false);

  const searchStores = async (name) => {
    setIsLoading(true);

    let position = null;

    if (
      deliveryAddress &&
      deliveryAddress.latitude &&
      deliveryAddress.longitude
    ) {
      position = {
        coords: {
          latitude: deliveryAddress.latitude,
          longitude: deliveryAddress.longitude,
        },
      };
    } else {
      try {
        position = await fetchGeolocation();
      } catch (e) {
        if (!userHasDeniedGeolocation) {
          setUserHasDeniedGeolocation(true);
          showToast(t('warnings:geoLocationDisabled'), 'warning');
        }
      }
    }

    try {
      let pointParams = { name };
      if (position && position.coords) {
        pointParams = {
          ...pointParams,
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
          shippingType,
        };
      }
      if (!isLogged) {
        pointParams = {
          ...pointParams,
          tenant_id: tenantId,
        };
      }

      const response = await gateway
        .get(EcommerceService.getEventPoints(), { params: clean(pointParams) })
        .then(({ data }) => data);

      const filteredStores = response.event_points
        .filter(({ point }) => {
          if (currentEnv !== 'production') {
            return true;
          }

          if (point.point_eyepass && point.point_eyepass.menu_only) {
            return true;
          }

          return (
            shippingType !== shippingTypes.ADDRESS ||
            (shippingType === shippingTypes.ADDRESS &&
              point.extra.routeDistance)
          );
        })
        .map(mapStore);

      setStoreList(filteredStores);
    } catch (e) {
      console.error(e);
      showToast(t('errors:fetchPointsFailure'), 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const handleStoreSearch = debounce(searchStores, 500);

  useEffect(() => {
    handleStoreSearch('');
  }, []);

  const handleFilterTextChange = (e) => {
    setFilterText(e.target.value);
    handleStoreSearch(e.target.value);
  };

  const handleStoreChange = (newStore) => {
    if (currentStore && newStore.id !== currentStore.id && hasProducts) {
      setSelectedStore(newStore);
      setStoreChangeConfirmationMode(true);
    } else {
      onStoreChange(newStore);
    }
  };

  const handleStoreChangeConfirmation = () => {
    onStoreChange(selectedStore);
  };

  return (
    <Modal
      visible={visible}
      styles={{
        innerWrapper: productInnerWrapper,
        content: productContent,
        overlay: productOverlay,
      }}
      keepMounted
    >
      {storeChangeConfirmationMode ? (
        <StoreSelectConfirmation
          onCancel={onCloseModal}
          onConfirm={handleStoreChangeConfirmation}
        />
      ) : (
        <StoreSelect
          onClose={onCloseModal}
          storeList={storeList}
          filterText={filterText}
          onFilterTextChange={handleFilterTextChange}
          onStoreChange={handleStoreChange}
          shippingType={shippingType}
          deliveryAddress={deliveryAddress}
          isLoading={isLoading}
        />
      )}
    </Modal>
  );
};

StoreSelectModal.defaultProps = {
  visible: false,
  shippingType: null,
  currentStore: null,
  deliveryAddress: null,
};

StoreSelectModal.propTypes = {
  visible: PropTypes.bool,
  currentStore: PropTypes.object,
  onStoreChange: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  isLogged: PropTypes.bool.isRequired,
  hasProducts: PropTypes.bool.isRequired,
  tenantId: PropTypes.number.isRequired,
  deliveryAddress: PropTypes.object,
  shippingType: PropTypes.string,
};

const enhance = compose(
  withModal((props) => ({
    visible: props.visible,
  })),
  connect(mapStateToProps),
);

export default enhance(StoreSelectModal);
