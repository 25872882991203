import { toast } from 'react-toastify';

const showToast = (message, type) => {
  if (type && toast[type]) {
    toast[type](message);
  } else {
    toast(message);
  }
};

export default showToast;
