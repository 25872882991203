import React from 'react';
import {
  ToastContainer as ToastifyToastContainer,
  toast,
} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ToastContainer = () => (
  <ToastifyToastContainer
    position={toast.POSITION.TOP_CENTER}
    autoClose={5000}
    hideProgressBar
    newestOnTop
    closeOnClick
    rtl={false}
    pauseOnVisibilityChange
    pauseOnHover
    draggable
    style={{
      width: '100%',
      top: 0,
      left: 0,
      padding: 0,
      marginLeft: 0,
      textAlign: 'center',
    }}
  />
);

ToastContainer.propTypes = {};

export default ToastContainer;
