import { css } from '@emotion/core';
import styled from '@emotion/styled';
import facepaint from 'facepaint';

const mobileMq = facepaint(['@media screen and (min-width: 375px)']);

const footerBps = ({ theme }) =>
  mobileMq({
    height: [85, 100],
    paddingLeft: [theme.getSpacing(2, 'px'), theme.getSpacing(4, 'px')],
    paddingRight: [theme.getSpacing(2, 'px'), theme.getSpacing(4, 'px')],
    paddingBottom: [theme.getSpacing(1, 'px'), theme.getSpacing(2, 'px')],
  });

export const FooterContainer = styled.div`
  ${footerBps};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const contentWrapper = (theme) => css`
  width: 100%;
  display: block;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  overflow-x: auto;
  cursor: grab;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }
  &::-webkit-scrollbar:vertical {
    width: 8px;
  }
  &::-webkit-scrollbar:horizontal {
    height: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    border: 2px solid #ffffff;
  }
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #ffffff;
  }
`;

export const tosContent = (theme) => css`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100% !important;
  height: auto !important;
  padding: 0 ${theme.getSpacing(4, 'px')};
`;

export const footerText = (theme) => css`
  font-family: ${theme.getFont()};
  font-size: ${theme.getFontSize(1.15, 'px')};
  color: ${theme.getColor('text')};
`;
