import React from 'react';
import { Router, Switch, Route } from 'react-router-dom';

import UserRemoval from '../pages/UserRemovalPage';
import PaymentLinkPage from '../pages/PaymentLinkPage';
import BottomNavigator from '../components/BottomNavigator';

// import Routes from './Routes';
import TenantRouter from './TenantRouter';
import history from './history';

const AppRouter = () => (
  <Router history={history}>
    <Switch>
      <Route path="/user-removal" exact component={UserRemoval} />
      <Route path="/p/:uuid" exact component={PaymentLinkPage} />
      <Route>
        <TenantRouter />
        <BottomNavigator />
      </Route>
    </Switch>
  </Router>
);

export default AppRouter;
