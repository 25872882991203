import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import Menu from '@material-ui/core/Menu';

const PopMenu = ({ id, children, anchorEl, open, onClose }) => {
  const theme = useTheme();

  return (
    <Menu
      id={id}
      anchorEl={anchorEl}
      keepMounted
      open={open}
      onClose={onClose}
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      PaperProps={{
        style: {
          backgroundColor: theme.getColor('primary'),
        },
      }}
      style={{
        zIndex: 9999,
      }}
    >
      {children}
    </Menu>
  );
};

PopMenu.defaultProps = {
  anchorEl: null,
};

PopMenu.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
    PropTypes.array,
  ]).isRequired,
  anchorEl: PropTypes.object,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default PopMenu;
