import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as ArrowDown } from '../../assets/arrow_down.svg';
import { Wrapper, Button, Content } from './Accordion.styles';

const Accordion = ({ id, text, children, onToggle, onOpen, open }) => {
  const [isOpen, setIsOpen] = useState(false);
  const currentOpen = open !== undefined ? open : isOpen;

  const handleClick = () => {
    if (onToggle) {
      onToggle(id);
    } else {
      setIsOpen((prevState) => !prevState);
    }
  };

  useEffect(() => {
    if (currentOpen && onOpen) onOpen(id);
  }, [currentOpen]);

  return (
    <Wrapper id={id}>
      <Button type="button" onClick={handleClick}>
        {text}
        <ArrowDown
          style={{
            marginLeft: 'auto',
            transform: currentOpen ? 'rotate(180deg)' : 'rotate(0deg)',
          }}
        />
      </Button>
      <Content isOpen={currentOpen}>{children}</Content>
    </Wrapper>
  );
};

Accordion.defaultProps = {
  onToggle: undefined,
  onOpen: undefined,
  open: undefined,
  id: undefined,
};

Accordion.propTypes = {
  text: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  onToggle: PropTypes.func,
  onOpen: PropTypes.func,
  open: PropTypes.bool,
  id: PropTypes.string,
};

export default Accordion;
