import React, { useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import Modal from '@bit/eyemobile.core.modal';
import Slide from '@material-ui/core/Slide';
import { useTranslation } from 'react-i18next';

import localizePrice from '../../utils/localizePrice';
import useComplementGroups from '../../utils/useComplementGroups';
import useGiftCard from '../../utils/useGiftCard';
import withModal from '../../hocs/withModal';
import ProductAdjustMenu from '../ProductAdjustMenu';
import { productTypes } from '../../utils/constants';
import showCustomToast from '../../utils/showCustomToast';

import {
  productContent,
  productInnerWrapper,
  productInnerCss,
  productOverlay,
} from './ProductAdjustModal.styles';

const ProductAdjustModal = ({
  visible,
  product,
  onCloseModal,
  onAddToCart,
  onRemove,
  selectedShippingType,
  ignoreRigidStock,
}) => {
  const { measure, price, type, observations, amount, giftCardFields, sku } =
    product;
  const { t } = useTranslation();

  const rigidStockEnabled = !ignoreRigidStock && product.rigidStockEnabled;
  const stockBalance = ignoreRigidStock ? undefined : product.stockBalance;

  const [observationText, setObservationText] = useState(observations || '');
  const [productAmount, setProductAmount] = useState(
    amount || (onRemove ? 0 : 1),
  );
  const [toTakeout, setToTakeout] = useState(false);
  const [complementGroupState, complementGroupsActions] = useComplementGroups({
    complementGroups: product.complementGroups,
    complementsTotal: product.complementsTotal,
  });
  const {
    giftCard,
    errors: giftCardErrors,
    touched: giftCardTouched,
    helpers,
  } = useGiftCard(giftCardFields && { ...giftCardFields });

  const { complementGroups, complementsTotal } = complementGroupState;

  const handleAddProduct = useCallback(() => {
    const maxItems = type === productTypes.GIFT_CARD ? 1 : 99;
    const maxStock = stockBalance === undefined ? 99 : stockBalance;
    setProductAmount((prevAmount) => {
      if (!rigidStockEnabled || prevAmount < Math.min(maxItems, maxStock)) {
        return prevAmount + 1;
      }

      showCustomToast(t('productOrder:maxStockBalance'), 'error', {
        toastId: 'MAX_STOCK_TOAST',
      });
      return prevAmount;
    });
  }, [type, ignoreRigidStock, stockBalance, setProductAmount, t]);

  const handleSubtractProduct = () =>
    setProductAmount((prevAmount) =>
      Math.max(onRemove ? 0 : 1, prevAmount - 1),
    );

  const handleObservationChange = (e) => setObservationText(e.target.value);

  const handleTakeoutChange = () => {
    setToTakeout((prevToTakeout) => !prevToTakeout);
  };

  const handleProductRemoval = () => {
    onRemove(product);
  };

  const handleAddToCart = () => {
    let cartProduct = {
      ...product,
      amount: productAmount,
      observations: observationText,
      takeout: toTakeout,
      complementGroups,
      complementsTotal,
    };

    if (type === productTypes.GIFT_CARD) {
      cartProduct = { ...cartProduct, giftCardFields: giftCard };
    }

    onAddToCart(cartProduct);
  };

  const measurePriceText =
    measure !== 'UNIT' ? localizePrice(price / 10) : localizePrice(price);

  const priceText = `R$ ${price && measurePriceText}`;

  const finalProductPrice =
    measure !== 'UNIT'
      ? (productAmount * price) / 10
      : (price + complementsTotal) * productAmount;

  const finalProductPriceText = `R$ ${localizePrice(finalProductPrice)}`;

  const complementsConfirmDisabled = useMemo(
    () =>
      complementGroups.length > 0 &&
      complementGroups.some(
        ({ min, complementItems }) =>
          min !== 0 &&
          min >
            complementItems.reduce((acc, { quantity }) => acc + quantity, 0),
      ),
    [complementGroups],
  );

  const giftCardDisabled = useMemo(() => {
    if (type === productTypes.GIFT_CARD) {
      return (
        !giftCard.name ||
        !giftCard.email ||
        !giftCard.message ||
        giftCardErrors.email
      );
    }

    return false;
  }, [giftCard]);

  const isDisabled = () => {
    const conditionals = [complementsConfirmDisabled, giftCardDisabled];

    return conditionals.some((conditional) => conditional === true);
  };

  return (
    <Slide direction="up" in={visible}>
      <Modal
        visible={visible}
        styles={{
          innerWrapper: productInnerWrapper,
          content: productContent,
          overlay: productOverlay,
          inner: productInnerCss,
        }}
        keepMounted
      >
        <ProductAdjustMenu
          productId={product.productId}
          productImgSrc={product.mainImageKey}
          productImages={product.productImages}
          productName={product.name}
          productType={product.type}
          priceText={priceText}
          finalProductPriceText={finalProductPriceText}
          productAmount={productAmount}
          productMeasure={measure}
          onAdd={handleAddProduct}
          onSubtract={handleSubtractProduct}
          observationText={observationText}
          onObservationChange={handleObservationChange}
          takeoutEnabled={product.takeoutEnabled}
          toTakeout={toTakeout}
          onTakeoutChange={handleTakeoutChange}
          onCancel={onCloseModal}
          onAddToCart={handleAddToCart}
          onRemove={handleProductRemoval}
          disabled={isDisabled()}
          productDescription={product.description}
          complementGroups={complementGroups}
          onComplementIncrement={complementGroupsActions.incrementItem}
          onComplementDecrement={complementGroupsActions.decrementItem}
          giftCard={giftCard}
          setGiftCardName={helpers.setName}
          setGiftCardEmail={helpers.setEmail}
          setGiftCardMessage={helpers.setMessage}
          productSku={sku}
          giftCardErrors={giftCardErrors}
          giftCardTouched={giftCardTouched}
          trackingEnabled={product.trackingEnabled}
          selectedShippingType={selectedShippingType}
        />
      </Modal>
    </Slide>
  );
};

ProductAdjustModal.defaultProps = {
  visible: false,
  onRemove: undefined,
  ignoreRigidStock: false,
};

ProductAdjustModal.propTypes = {
  visible: PropTypes.bool,
  product: PropTypes.object.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  onAddToCart: PropTypes.func.isRequired,
  onRemove: PropTypes.func,
  selectedShippingType: PropTypes.string.isRequired,
  ignoreRigidStock: PropTypes.bool,
};

export default withModal((props) => ({
  visible: props.visible && props.product,
}))(ProductAdjustModal);
