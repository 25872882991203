import React from 'react';
import PropTypes from 'prop-types';

import { Wrapper, iconDefaultStyle } from './Icon.styles';

const Icon = ({ icon, size, wrapperStyle, iconStyle, color }) => {
  const styledIcon = React.cloneElement(icon, {
    style: { ...iconDefaultStyle, ...iconStyle },
  });

  return (
    <div style={wrapperStyle}>
      <Wrapper style={{ height: size, width: size }} iconColor={color}>
        {styledIcon}
      </Wrapper>
    </div>
  );
};

Icon.defaultProps = {
  wrapperStyle: undefined,
  iconStyle: undefined,
  size: '24px',
  color: 'primary',
};

Icon.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.func, PropTypes.node, PropTypes.element])
    .isRequired,
  size: PropTypes.string,
  wrapperStyle: PropTypes.object,
  iconStyle: PropTypes.object,
  color: PropTypes.string,
};

export default Icon;
