import { css } from '@emotion/core';
import styled from '@emotion/styled';
import facepaint from 'facepaint';

const mobileMq = facepaint([
  '@media screen and (min-width: 375px)',
  '@media screen and (min-width: 720px)',
]);

export const ItemContainer = styled.div`
  width: 100%;
  background-color: #f5f5f5;
  border-radius: 17px;
  border: 1px solid
    ${({ theme, selected }) =>
      selected ? theme.getColor('primary') : 'transparent'};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  ${({ theme }) =>
    mobileMq({
      paddingRight: [theme.getSpacing(1, 'px'), theme.getSpacing(2, 'px')],
    })}

  &:not(:first-of-type) {
    margin-top: ${({ theme }) => theme.getSpacing(2, 'px')};
  }
`;

export const itemContentWrapper = (theme) => css`
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  cursor: pointer;
  margin-right: 5px;
  ${mobileMq({
    paddingLeft: [theme.getSpacing(1, 'px'), theme.getSpacing(2, 'px')],
    paddingTop: [theme.getSpacing(1, 'px'), theme.getSpacing(2, 'px')],
    paddingBottom: [theme.getSpacing(1, 'px'), theme.getSpacing(2, 'px')],
  })};
`;

export const cardNumberText = (theme) => css`
  color: ${theme && theme.getColor('text')};
  font-size: ${theme && theme.getFontSize(1, 'px')};
  font-family: ${theme && theme.getFont('futura')};
  text-align: left;
  margin-left: ${theme && theme.getSpacing(1, 'px')};
  margin-right: ${theme && theme.getSpacing(1, 'px')};
`;
