import React from 'react';

import Loading from '../Loading';

import { wrapper } from './TenantEyepassLoader.styles';

const TenantEyepassLoader = () => {
  return (
    <div css={wrapper}>
      <Loading />
    </div>
  );
};

TenantEyepassLoader.propTypes = {};

export default TenantEyepassLoader;
