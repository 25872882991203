const mapCreditCard = (creditCard) => {
  const { id, brand, main } = creditCard;

  const lastDigits = creditCard.last_digits;

  return {
    id,
    number: `**** **** **** ${lastDigits}`,
    brand,
    main,
  };
};

export default mapCreditCard;
