import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import pt from './pt.json';
import en from './en.json';
import es from './es.json';
import fr from './fr.json';

i18n.use(initReactI18next).init({
  lng: 'pt-CUSTOM',
  fallbackLng: [
    'pt-CUSTOM',
    'pt',
    'en-CUSTOM',
    'en',
    'es-CUSTOM',
    'es',
    'fr-CUSTOM',
    'fr',
  ],
  debug: process.env.NODE_ENV === 'development',
  wait: true,
  resources: {
    pt,
    en,
    es,
    fr,
  },
  react: {
    wait: true,
    defaultTransParent: 'span',
    transSupportBasicHtmlNodes: true,
    transKeepBasicHtmlNodesFor: ['strong'],
  },
  interpolation: {
    escapeValue: false,
  },
});

if (process.env.NODE_ENV === 'development') {
  i18n.on('missingKey', (lngs, namespace, key) => {
    console.warn(`Missing translation: ${lngs} - [${namespace}]`, key);
  });
}

export default i18n;
