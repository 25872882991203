import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import localizePrice from '../../utils/localizePrice';

import {
  AddressItemContainer,
  leftDescriptionWrapper,
  rightDescriptionWrapper,
  descriptionTextCss,
  closedBadge,
  addressTextCss,
  addressDistanceCss,
  deliveryTextCss,
} from './Addressitem.styles';

const AddressItem = ({
  distanceText,
  durationText,
  storeName,
  storeAddress,
  addressCityState,
  storeDeliveryPrice,
  isDisabled,
  onClick,
}) => {
  const { t } = useTranslation();

  const deliveryDescripion = useMemo(() => {
    if (storeDeliveryPrice === 0) {
      return t('productOrder:freeDelivery');
    }

    if (storeDeliveryPrice === null) {
      return '';
    }

    return `R$ ${localizePrice(storeDeliveryPrice)}`;
  }, [storeDeliveryPrice]);

  return (
    <AddressItemContainer
      onClick={onClick}
      onKeyDown={onClick}
      role="presentation"
      disabled={isDisabled}
    >
      <div css={leftDescriptionWrapper}>
        <span css={descriptionTextCss}>{storeName}</span>
        <span css={addressTextCss}>{storeAddress}</span>
        <span css={addressTextCss}>{addressCityState}</span>
      </div>
      <div css={rightDescriptionWrapper}>
        {isDisabled ? (
          <div css={closedBadge}>{t('productOrder:closed')}</div>
        ) : (
          <>
            <span css={addressDistanceCss}>{distanceText}</span>
            <span css={addressDistanceCss}>{durationText}</span>
            <span css={deliveryTextCss}>{deliveryDescripion}</span>
          </>
        )}
      </div>
    </AddressItemContainer>
  );
};

AddressItem.defaultProps = {
  isDisabled: false,
  storeDeliveryPrice: null,
};

AddressItem.propTypes = {
  distanceText: PropTypes.string.isRequired,
  durationText: PropTypes.string.isRequired,
  storeName: PropTypes.string.isRequired,
  storeAddress: PropTypes.string.isRequired,
  addressCityState: PropTypes.string.isRequired,
  storeDeliveryPrice: PropTypes.number,
  isDisabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

export default AddressItem;
