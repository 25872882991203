import React from 'react';
import PropTypes from 'prop-types';
import ScrollDiv from './ScrollableView.styles';

const ScrollableView = ({
  children,
  hasHeader,
  hasFooter,
  hasBottomTabs,
  hideScroll,
  id,
  flex,
  divCss,
}) => (
  <ScrollDiv
    id={id}
    hideScroll={hideScroll}
    hasHeader={hasHeader}
    hasFooter={hasFooter}
    hasBottomTabs={hasBottomTabs}
    flex={flex}
    css={divCss}
  >
    {children}
  </ScrollDiv>
);

ScrollableView.defaultProps = {
  hasHeader: false,
  hasFooter: false,
  hasBottomTabs: true,
  hideScroll: false,
  id: undefined,
  flex: true,
  divCss: undefined,
};

ScrollableView.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
    PropTypes.array,
  ]).isRequired,
  hasHeader: PropTypes.bool,
  hasFooter: PropTypes.bool,
  hasBottomTabs: PropTypes.bool,
  hideScroll: PropTypes.bool,
  id: PropTypes.string,
  flex: PropTypes.bool,
  divCss: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
};

export default ScrollableView;
