import React from 'react';
import PropTypes from 'prop-types';
import Button from '@bit/eyemobile.core.button';
import { useTranslation } from 'react-i18next';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import honestMarketImg from '../../assets/honest-market.svg';
import giftImg from '../../assets/gift.svg';
import deliveryTakeoutImg from '../../assets/delivery-takeout.svg';
import selfServiceImg from '../../assets/self-service.svg';

import {
  carouselCss,
  tourFrameCss,
  wrapperCss,
  tourText,
  tourCenterImg,
  cornerSkipButtonWrapper,
} from './AppTour.styles';

const AppTour = ({ onQuit }) => {
  const { t } = useTranslation();

  return (
    <div css={wrapperCss}>
      <div
        css={cornerSkipButtonWrapper}
        onClick={onQuit}
        onKeyDown={onQuit}
        role="presentation"
      >
        {t('navigation:skip')}
      </div>
      <Carousel
        showArrows
        showStatus={false}
        showIndicators
        showThumbs={false}
        swipeable
        stopOnHover
        emulateTouch
        css={carouselCss}
      >
        <div css={tourFrameCss}>
          <div style={{ position: 'relative', padding: '30px' }}>
            <img
              alt="honest-market"
              src={honestMarketImg}
              css={tourCenterImg}
            />
          </div>
          <span css={tourText} style={{ fontWeight: 'bold' }}>
            {t('wizard:honestMarket')}
          </span>
          <span css={tourText}>{t('wizard:honestMarketDesc')}</span>
        </div>
        <div css={tourFrameCss}>
          <div style={{ position: 'relative', padding: '30px' }}>
            <img alt="self-service" src={selfServiceImg} css={tourCenterImg} />
          </div>
          <span css={tourText} style={{ fontWeight: 'bold' }}>
            {t('wizard:selfService')}
          </span>
          <span css={tourText}>{t('wizard:selfServiceDesc')}</span>
        </div>
        <div css={tourFrameCss}>
          <div style={{ position: 'relative', padding: '30px' }}>
            <img
              alt="delivery-takeout"
              src={deliveryTakeoutImg}
              css={tourCenterImg}
            />
          </div>
          <span css={tourText} style={{ fontWeight: 'bold' }}>
            {t('wizard:deliveryTakeout')}
          </span>
          <span css={tourText}>{t('wizard:deliveryTakeoutDesc')}</span>
        </div>
        <div css={tourFrameCss}>
          <div style={{ padding: '30px' }}>
            <img alt="gift" src={giftImg} css={tourCenterImg} />
          </div>
          <span css={tourText} style={{ fontWeight: 'bold' }}>
            {t('wizard:fidelityGiftCard')}
          </span>
          <span css={tourText}>{t('wizard:fidelityGiftCardDesc1')}</span>
          <span css={tourText}>{t('wizard:fidelityGiftCardDesc2')}</span>
          <div style={{ margin: '30px' }}>
            <Button color="primary" onClick={onQuit}>
              {t('navigation:next')}
            </Button>
          </div>
        </div>
      </Carousel>
    </div>
  );
};

AppTour.propTypes = {
  onQuit: PropTypes.func.isRequired,
};

export default AppTour;
