import React, { useState } from 'react';
import PropTypes from 'prop-types';

import shoppingBasket from '../../assets/shopping_basket.svg';

import { productImageCss } from './ProductImage.styles';

const ProductImage = ({ size, imgSrc, alt }) => {
  const [fallbackImg, setFallbackImg] = useState(null);

  const onImgError = () => setFallbackImg(shoppingBasket);

  return (
    <img
      css={productImageCss}
      style={{ height: size, width: size }}
      src={fallbackImg || imgSrc || shoppingBasket}
      alt={alt}
      onError={onImgError}
      resizemode="contain"
    />
  );
};

ProductImage.defaultProps = {
  size: '30px',
};

ProductImage.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  imgSrc: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
};

export default ProductImage;
