import { useState, cloneElement, Children } from 'react';
import PropTypes from 'prop-types';

const getId = (id) => `accordion-${id}`;

const AccordionGroup = ({ children, initial, toggle }) => {
  const [opened, setOpened] = useState(
    initial !== undefined ? getId(initial) : undefined,
  );

  const handleToggle = (id) =>
    toggle
      ? setOpened((prevId) => (prevId === id ? undefined : id))
      : setOpened(id);

  const mapChildren = (child, index) => {
    const id = getId(index);
    return (
      child &&
      cloneElement(child, {
        id,
        onToggle: handleToggle,
        open: id === opened,
      })
    );
  };

  return Children.map(children, mapChildren);
};

AccordionGroup.defaultProps = {
  initial: undefined,
  toggle: false,
};

AccordionGroup.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  initial: PropTypes.number,
  toggle: PropTypes.bool,
};

export default AccordionGroup;
