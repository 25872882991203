import { createReducer, createAction } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';

export const Types = {
  SET_REGISTER_FORM: 'formSave/SET_REGISTRATION_FORM',
  CLEAR_FORM: 'formSave/CLEAR_FORM',
};

export const setRegisterForm = createAction(Types.SET_REGISTER_FORM);
export const clearRegisterForm = createAction(Types.CLEAR_FORM);

const initialState = {
  id: null,
  autoFetchData: true,
  fullName: '',
  email: '',
  phone: '',
  birthdate: '',
  document: '',
  addressZipcode: '',
  addressStreet: '',
  addressNumber: '',
  addressComplement: '',
  addressDistrict: '',
  addressCity: '',
  addressState: '',
};

const registerForm = createReducer(initialState, {
  [setRegisterForm]: (state, action) => ({
    ...state,
    ...action.payload,
  }),
  [clearRegisterForm]: (state, action) => initialState,
});

export const reducers = combineReducers({
  registerForm,
});
