import { css } from '@emotion/core';
import styled from '@emotion/styled';

export const addressItemContainer = (theme) => css`
  width: 100%;
  padding: ${theme && theme.getSpacing(1, 'px')};
  display: flex;
  flex-direction: row;
  cursor: pointer;
`;

export const AddressItemContainer = styled.div`
  width: 100%;
  padding: ${({ theme }) => theme && theme.getSpacing(1, 'px')};
  display: flex;
  flex-direction: row;
  cursor: pointer;
  background-color: #f5f5f5;
  border-radius: 16px;
  margin-top: ${({ theme }) => theme && theme.getSpacing(1, 'px')};
  margin-top: ${({ theme }) => theme && theme.getSpacing(1, 'px')};
  ${({ disabled }) =>
    disabled && 'opacity: 0.5; cursor: default; pointer-events: none;'}
`;

export const leftDescriptionWrapper = (theme) => css`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
`;

export const rightDescriptionWrapper = (theme) => css`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: ${theme && theme.getSpacing(1, 'px')};
`;

export const descriptionTextCss = (theme) => css`
  text-align: left;
  font-family: ${theme && theme.getFont('futura')};
  font-size: ${theme && theme.getFontSize(1, 'px')};
  color: ${theme && theme.getColor('text')};
  margin-bottom: ${theme && theme.getSpacing(1, 'px')};
`;

export const closedBadge = (theme) => css`
  font-family: ${theme && theme.getFont()};
  font-size: ${theme && theme.getFontSize(0.6, 'px')};
  color: ${theme && theme.getColor('lightText')};
  padding-left: ${theme && theme.getSpacing(1, 'px')};
  padding-right: ${theme && theme.getSpacing(1, 'px')};
  padding-top: ${theme && theme.getSpacing(0.5, 'px')};
  padding-bottom: ${theme && theme.getSpacing(0.5, 'px')};
  border-radius: 20px;
  background-color: ${theme && theme.getColor('default')};
  text-transform: uppercase;
`;

export const addressTextCss = (theme) => css`
  text-align: left;
  font-family: ${theme && theme.getFont()};
  font-size: ${theme && theme.getFontSize(0.8, 'px')};
  color: ${theme && theme.getColor('text')};
  opacity: 0.7;
  margin-top: 4px;
`;

export const deliveryTextCss = (theme) => css`
  font-family: ${theme && theme.getFont()};
  font-size: ${theme && theme.getFontSize(1, 'px')};
  color: ${theme && theme.getColor('text')};
  white-space: nowrap;
`;

export const addressDistanceCss = (theme) => css`
  font-family: ${theme && theme.getFont()};
  font-size: ${theme && theme.getFontSize(1, 'px')};
  color: ${theme && theme.getColor('text')};
  font-weight: bold;
  white-space: nowrap;
  margin-bottom: 4px;
`;
