import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import CircleButton from '@bit/eyemobile.core.circle-button';
import Input from '@bit/eyemobile.core.input';

import { ReactComponent as KeyboardArrowDown } from '../../assets/keyboard_arrow_down.svg';
import { productTypes, smallMeasureTypesMap } from '../../utils/constants';
import RowRadioButton from '../RowRadioButton';
import ComplementGroup from '../ComplementGroup';
import Carousel from '../Carousel';
import RhizomProductTrack from '../RhizomProductTrack';

import CarouselImage from './CarouselImage';
import ProductAdjustFooter from './ProductAdjustFooter';
import {
  productAdjustWrapper,
  productAdjustInput,
  optionalsWrapper,
  productContent,
  descriptionText,
  productInfo,
  ProductInfoText,
  productPriceText,
  contentScroll,
  radioLabelCss,
  fieldSpacing,
  textareaCss,
} from './ProductAdjustMenu.styles';

const mapStateToProps = ({
  tenantEyepass: { integrations },
  productOrder: {
    productOrder: { store },
  },
}) => ({
  menuOnly: Boolean(store) && store.isMenuOnly,
  hasRhizomIntegration: !!integrations && !!integrations.rhizom,
});

const ProductAdjustMenu = ({
  productId,
  productImgSrc,
  productImages,
  productName,
  productType,
  priceText,
  finalProductPriceText,
  productAmount,
  productMeasure,
  takeoutEnabled,
  complementGroups,
  onComplementIncrement,
  onComplementDecrement,
  onTakeoutChange,
  toTakeout,
  onAdd,
  onSubtract,
  observationText,
  onObservationChange,
  onCancel,
  onAddToCart,
  onRemove,
  productDescription,
  disabled,
  giftCard,
  setGiftCardName,
  setGiftCardEmail,
  setGiftCardMessage,
  giftCardErrors,
  giftCardTouched,
  trackingEnabled,
  menuOnly,
  hasRhizomIntegration,
  selectedShippingType,
}) => {
  const { t } = useTranslation();

  const shouldRemove = onRemove && productAmount === 0;

  const mapImageSrc = (imageKey) => (
    <div key={imageKey}>
      <CarouselImage productName={productName} imageKey={imageKey} />
    </div>
  );

  const mapProductImage = (productImage) => {
    return productImage?.image || productImage;
  };

  const sortByPosition = (images) => {
    const unsortedArray = [...images];

    const sortPosition = (imageA, imageB) => imageA.position - imageB.position;

    return unsortedArray.sort(sortPosition);
  };

  const getSecondaryImages = () => {
    return sortByPosition(productImages).map(mapProductImage).map(mapImageSrc);
  };

  const renderImages = useMemo(() => {
    const secondaryImages = getSecondaryImages();
    return [mapImageSrc(productImgSrc)].concat(secondaryImages);
  }, [productImgSrc, productImages]);

  const renderComplementGroup = (complementGroup) => {
    return (
      <ComplementGroup
        key={`complement-group-${complementGroup.id}`}
        name={complementGroup.name}
        min={complementGroup.min}
        max={complementGroup.max}
        description={complementGroup.description}
        complementItems={complementGroup.complementItems}
        onIncrement={onComplementIncrement}
        onDecrement={onComplementDecrement}
      />
    );
  };

  const handleConfirmation = () => {
    const confirmationFunc = shouldRemove ? onRemove : onAddToCart;
    confirmationFunc();
  };

  const renderContent = () => {
    if (productType === productTypes.GIFT_CARD) {
      return (
        <>
          <div css={fieldSpacing}>
            <Input
              name="name"
              placeholder={t('forms:name')}
              hideIcons
              hideErrors
              guide={false}
              value={giftCard.name}
              onChange={setGiftCardName}
            />
          </div>
          <div css={fieldSpacing}>
            <Input
              name="email"
              type="email"
              placeholder={t('forms:email')}
              error={giftCardErrors.email}
              touched={giftCardTouched.email}
              hideIcons
              guide={false}
              value={giftCard.email}
              onChange={setGiftCardEmail}
            />
          </div>
          <div css={fieldSpacing}>
            <Input
              name="message"
              placeholder={t('forms:message')}
              hideIcons
              hideErrors
              guide={false}
              isMultiline
              animateLabel={false}
              onChange={setGiftCardMessage}
              value={giftCard.message}
              inputStyle={{
                minHeight: 150,
              }}
              inputCss={textareaCss}
            />
          </div>
        </>
      );
    }

    return (
      <>
        {complementGroups.map(renderComplementGroup)}
        {takeoutEnabled && (
          <div css={optionalsWrapper}>
            <RowRadioButton
              checked={toTakeout}
              onChange={onTakeoutChange}
              label={t('productOrder:toTakeout')}
              labelCss={radioLabelCss}
              style={{
                padding: 0,
              }}
            />
          </div>
        )}
        {!menuOnly && (
          <textarea
            css={productAdjustInput}
            value={observationText}
            onChange={onObservationChange}
            placeholder={t('productOrder:observationPlaceholder')}
          />
        )}
      </>
    );
  };

  const adjustedPriceText =
    productMeasure === 'UNIT'
      ? priceText
      : `${priceText} / 100${smallMeasureTypesMap[productMeasure]}`;

  const adjustedAmount =
    productMeasure === 'UNIT'
      ? productAmount
      : `${productAmount * 100} ${smallMeasureTypesMap[productMeasure]}`;

  const hasExtraImages = !!productImages && productImages.length > 0;

  return (
    <div css={productAdjustWrapper}>
      <div
        style={{
          width: '100%',
          paddingRight: '16px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
        }}
      >
        <CircleButton
          icon={<KeyboardArrowDown />}
          size="30px"
          onClick={onCancel}
          backgroundColor="primary"
          color="background"
          padding="3px"
        />
      </div>
      <div css={contentScroll}>
        <Carousel
          showArrows={false}
          showStatus={false}
          showIndicators={hasExtraImages}
          infiniteLoop
          showThumbs={false}
          swipeable={hasExtraImages}
          stopOnHover
          emulateTouch={hasExtraImages}
        >
          {renderImages}
        </Carousel>
        <div css={productContent}>
          <div css={productInfo}>
            <ProductInfoText isTitle>{productName}</ProductInfoText>
            {productDescription && (
              <p css={descriptionText}>{productDescription}</p>
            )}
            <span css={productPriceText}>{adjustedPriceText}</span>
          </div>
          {renderContent()}
          {hasRhizomIntegration && trackingEnabled && (
            <RhizomProductTrack productId={productId} />
          )}
        </div>
        <ProductAdjustFooter
          shouldRemove={shouldRemove}
          disabled={disabled}
          onConfirm={handleConfirmation}
          onCancel={onCancel}
          priceText={finalProductPriceText}
          amountText={adjustedAmount}
          isMenuOnly={menuOnly}
          onSubtract={onSubtract}
          onAdd={onAdd}
        />
      </div>
    </div>
  );
};

ProductAdjustMenu.defaultProps = {
  onRemove: null,
  productMeasure: 'UNIT',
  takeoutEnabled: false,
  toTakeout: false,
  onTakeoutChange: null,
  productDescription: '',
  productImages: [],
  complementGroups: [],
  disabled: false,
  giftCard: undefined,
};

ProductAdjustMenu.propTypes = {
  productId: PropTypes.string.isRequired,
  productImgSrc: PropTypes.string.isRequired,
  productName: PropTypes.string.isRequired,
  priceText: PropTypes.string.isRequired,
  finalProductPriceText: PropTypes.string.isRequired,
  productAmount: PropTypes.number.isRequired,
  productMeasure: PropTypes.string,
  complementGroups: PropTypes.array,
  onComplementIncrement: PropTypes.func.isRequired,
  onComplementDecrement: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  onSubtract: PropTypes.func.isRequired,
  observationText: PropTypes.string.isRequired,
  onObservationChange: PropTypes.func.isRequired,
  takeoutEnabled: PropTypes.bool,
  toTakeout: PropTypes.bool,
  onTakeoutChange: PropTypes.func,
  onCancel: PropTypes.func.isRequired,
  onAddToCart: PropTypes.func.isRequired,
  onRemove: PropTypes.func,
  productDescription: PropTypes.string,
  productImages: PropTypes.array,
  disabled: PropTypes.bool,
  productType: PropTypes.number.isRequired,
  giftCard: PropTypes.object,
  setGiftCardName: PropTypes.func.isRequired,
  setGiftCardEmail: PropTypes.func.isRequired,
  setGiftCardMessage: PropTypes.func.isRequired,
  giftCardErrors: PropTypes.object.isRequired,
  giftCardTouched: PropTypes.object.isRequired,
  trackingEnabled: PropTypes.bool.isRequired,
  menuOnly: PropTypes.bool.isRequired,
  hasRhizomIntegration: PropTypes.bool.isRequired,
  selectedShippingType: PropTypes.string.isRequired,
};

export default connect(mapStateToProps)(ProductAdjustMenu);
