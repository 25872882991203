import styled from '@emotion/styled';
import facepaint from 'facepaint';

const mq = facepaint(['@media screen and (min-width: 375px)']);

export const ButtonWrapper = styled.div`
  min-height: 28px;
  padding: 5px 14px 3px 14px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 20px;
  cursor: pointer;
  background-color: ${({ theme, buttonColor }) =>
    theme && theme.getColor(buttonColor || 'background')};
  ${({ disabled }) =>
    disabled && 'cursor: default; pointer-events: none; opacity: 0.7;'}
  max-width: 100%;
  overflow: hidden;
  -webkit-tap-highlight-color: transparent;
  user-select: none;
`;

export const ButtonText = styled.span`
  ${({ theme }) =>
    mq({
      fontSize: [theme.getFontSize(0.8, 'px'), theme.getFontSize(1, 'px')],
      lineHeight: [theme.getFontSize(1, 'px'), theme.getFontSize(1.2, 'px')],
    })}
  font-family: ${({ theme }) => theme && theme.getFont('futura')};
  color: ${({ theme, buttonTextColor }) =>
    theme && theme.getColor(buttonTextColor || 'text')};
  margin: 4px 0px;
`;
