import { imagesUrl } from './constants';

const fullResPath = `${imagesUrl}/eyepass/highlights`;

const getHighlightImage = (imageKey) => {
  if (!imageKey) {
    return undefined;
  }

  return `${fullResPath}/${imageKey}`;
};

export default getHighlightImage;
