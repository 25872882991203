import { css } from '@emotion/core';

export const backgroundCss = (theme) => css`
  width: 100%;
  height: 100%;
  background-color: ${(theme && theme.getColor('loginBackground')) ||
  theme.getColor('foreground')};
`;

export const wrapperCss = (theme) => css`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: ${theme && theme.getSpacing(1, 'px')};
  padding-right: ${theme && theme.getSpacing(1, 'px')};
`;

export const logoCss = (theme) => css`
  width: 180px;
  height: 180px;
  object-fit: contain;
  margin: ${theme && theme.getSpacing(2, 'px')};
`;

export const checkCss = (theme) => css`
  width: 120px;
  height: 120px;
  object-fit: contain;
  margin: ${theme && theme.getSpacing(2, 'px')};
`;

export const flavourTextCss = (theme) => css`
  p {
    font-family: ${theme && theme.getFont('heading')};
    font-size: ${theme && theme.getFontSize(1, 'px')};
    color: ${theme && theme.getColor('uiText')};
    text-align: center;

    &:not(:last-child) {
      margin-bottom: ${theme && theme.getSpacing(2, 'px')};
    }
  }
`;

export const nameTextCss = (theme) => css`
  font-family: ${theme && theme.getFont('heading')};
  font-size: ${theme && theme.getFontSize(1, 'px')};
  color: ${theme && theme.getColor('uiText')};
  text-align: center;
  margin-bottom: ${theme && theme.getSpacing(2, 'px')};
  font-weight: 700;
`;
