import { css } from '@emotion/core';

export const carouselCss = (theme) => css`
  .carousel-root {
    height: 100%;
  }

  height: 100%;

  .carousel {
    height: 100%;

    .slide {
      background: transparent;
      height: 100%;
    }
    .slider {
      height: 100%;
    }
    .slider-wrapper {
      height: 100%;
    }
    .control-arrow {
      opacity: 0;
      background: ${theme && theme.getColor('primary')};

      &:hover {
        opacity: 1;
        background: ${theme && theme.getColor('primary')};
      }
    }

    .control-dots {
      margin-bottom: 40px;

      .dot {
        opacity: 1;
        box-shadow: none;
        outline: none;
        border: 1px solid ${theme && theme.getColor('primary')};
        transition: transform 200ms, background-color 200ms;
        background-color: transparent;

        &.selected {
          transform: scale(1.4);
          background-color: ${theme && theme.getColor('primary')};
        }
      }
    }
  }
`;

export const tourFrameCss = (theme) => css`
  height: 100%;
  width: 100%;
  padding: ${theme && theme.getSpacing(1, 'px')};
  background-color: ${theme && theme.getColor('background')};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const wrapperCss = css`
  width: 100%;
  height: 100%;
`;

export const tourText = (theme) => css`
  color: ${theme && theme.getColor('text')};
  font-size: ${theme && theme.getFontSize(1, 'px')};
  margin-bottom: 10px;
`;

export const tourCenterImg = css`
  width: 350px;
  height: 350px;
  object-fit: contain;
`;

export const cornerSkipButtonWrapper = (theme) => css`
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 10px 20px;
  cursor: pointer;
  color: ${theme && theme.getColor('primary')};
  font-weight: bold;
  z-index: 1000;
`;

export const drawerExampleWrapper = css`
  height: 126px;
  width: 126px;
  border-radius: 63px;
  flex: 0;
  object-fit: contain;
  position: absolute;
  top: -40px;
  left: -50px;
`;
