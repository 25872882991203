import { css } from '@emotion/core';

export const menuWrapper = css`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const buttonsRow = (theme) => css`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding-top: ${theme && theme.getSpacing(2, 'px')};
`;

export const labelText = (theme) => css`
  font-family: ${theme && theme.getFont()};
  font-size: ${theme && theme.getFontSize(1, 'px')};
  color: ${theme && theme.getColor('text')};
`;
