import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { getToken } from '../../utils/firebase';
import gateway from '../../utils/gateway';
import * as registerFormActions from '../../store/modules/registerForm';
import { Creators as AuthCreators } from '../../store/modules/auth';
import { Creators as UserCreators } from '../../store/modules/user';
import RegisterErrorScreen from '../../components/Error';
import { stripMask } from '../../utils/adaptField';
import DataSentPageScreen from './DataSentPageScreen';
import LocalStorageManager from '../../utils/LocalStorageManager';

const mapStateToProps = ({ tenantEyepass: { domain, tenantId } }) => ({
  domain,
  tenantId,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      clearRegisterForm: registerFormActions.clearRegisterForm,
      loginSuccessful: AuthCreators.loginSuccessful,
      setUser: UserCreators.setUser,
    },
    dispatch,
  );

const DataSentPageContainer = ({
  history,
  location,
  setUser,
  loginSuccessful,
  domain,
  tenantId,
}) => {
  const { t } = useTranslation();
  const state = location.state || {};

  const errorMessage = t('registrationError:cantRecoverRegistration');

  const handleReturn = () => {
    history.push(`/${domain}/login`);
  };

  const registerNotificationToken = async (customerInfoId) => {
    try {
      const token = await getToken();

      if (token) {
        await gateway.post('/customer-notification', { customerInfoId, token });
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleLogin = async () => {
    try {
      const response = await gateway.post('/customers/login', {
        username: stripMask(state.document),
        password: state.password,
        tenant_id: `${tenantId}`,
      });

      LocalStorageManager.setToken(response.headers['x-access-token']);
      LocalStorageManager.setRefreshToken(response.headers['x-refresh-token']);

      const profile = await gateway.get('/profile').then(({ data }) => data);

      setUser(profile.customer);
      registerNotificationToken(profile.customer.id);
      loginSuccessful();

      handleReturn();
    } catch (e) {
      console.error(e);
    }
  };

  const flavourText = (
    <>
      <p>{t('success:registration')}</p>
      <p>{t('info:redirectToCart')}</p>
    </>
  );

  useEffect(() => {
    if (state.hasRegistered && state.shouldLogin) {
      setTimeout(handleLogin, 1500);
    }
  }, []);

  if (!state.hasRegistered) {
    return (
      <RegisterErrorScreen
        onReturn={handleReturn}
        errorMessage={errorMessage}
      />
    );
  }

  return (
    <DataSentPageScreen userName={state?.name} flavourText={flavourText} />
  );
};

DataSentPageContainer.defaultProps = {
  domain: null,
};

DataSentPageContainer.propTypes = {
  history: PropTypes.object.isRequired,
  setUser: PropTypes.func.isRequired,
  loginSuccessful: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  domain: PropTypes.string,
  tenantId: PropTypes.number.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DataSentPageContainer);
