import React from 'react';
import PropTypes from 'prop-types';

import { Step, Progress } from './StepProgressBar.styles';

const StepProgressBar = ({ progress, total, separators, style, id }) => {
  const renderStep = (item, index) => (
    <Step
      key={`${id}-step-${index}`}
      style={{ backgroundColor: item ? undefined : 'transparent' }}
      width={100 / total}
    />
  );

  const normalizeProgress = progress > total ? total : progress;
  const steps = new Array(total).fill(true).fill(false, normalizeProgress);

  return (
    <Progress style={style} separators={separators}>
      {steps.map(renderStep)}
    </Progress>
  );
};

StepProgressBar.defaultProps = {
  progress: 0,
  total: 1,
  separators: true,
  style: undefined,
};

StepProgressBar.propTypes = {
  progress: PropTypes.number,
  total: PropTypes.number,
  separators: PropTypes.bool,
  style: PropTypes.object,
  id: PropTypes.string.isRequired,
};

export default StepProgressBar;
