import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Loading from '@bit/eyemobile.core.loading';
import Button from '@bit/eyemobile.core.button';

import localizePrice from '../../utils/localizePrice';
import FormikRadioBox from '../../components/FormikRadioBox';
import OrderReviewGiftCardModal from './OrderReviewGiftCardModal';
import { ReactComponent as AddIcon } from '../../assets/add_icon_custom.svg';

import {
  tabWrapper,
  tabTitle,
  tabTitleWrapper,
  loyaltiesLoadingWrapper,
  PriceRelationText,
  radioBoxContent,
  radioBoxDescription,
  radioBoxTitle,
  radioBoxBordered,
  radioBoxButton,
} from './OrderReviewPage.styles';

const OrderReviewDiscounts = ({
  isFetching,
  values,
  setFieldValue,
  loyaltyCashback,
  hasGiftCardProduct,
  maxPercentCashback,
  subtotal,
  deliveryPrice,
}) => {
  const { t } = useTranslation();

  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleVisibilityChange = (state) => () => setIsModalVisible(state);

  const handleAddVoucher = (voucher) => {
    setFieldValue('voucher', voucher);
  };

  const renderCashback = () => {
    if (isFetching) {
      return (
        <div css={loyaltiesLoadingWrapper}>
          <PriceRelationText>Carregando suas recompensas...</PriceRelationText>
          <Loading isSmall show />
        </div>
      );
    }

    if (!loyaltyCashback) {
      return null;
    }

    return (
      <FormikRadioBox
        name="useCashback"
        components={{
          Label: (
            <>
              <div css={radioBoxContent}>
                <span css={radioBoxTitle}>{t('orderReview:cashback')}</span>
                <span css={radioBoxDescription}>
                  {loyaltyCashback.availableCashback > 0
                    ? t('orderReview:youHaveLoyaltyAvailable')
                    : t('orderReview:noLoyaltyAvailable')}
                </span>
                <span css={radioBoxDescription} style={{ textAlign: 'right' }}>
                  R$ {localizePrice(loyaltyCashback.availableCashback)}
                </span>
                {maxPercentCashback < 100 && (
                  <PriceRelationText color="error">
                    {'* '}
                    {t('orderReview:cashbackPercentWarning', {
                      percent: maxPercentCashback,
                    })}
                  </PriceRelationText>
                )}
              </div>
            </>
          ),
        }}
        disabled={!loyaltyCashback.availableCashback}
        boxCss={radioBoxBordered}
      />
    );
  };

  const renderVoucher = () => {
    if (values.voucher) {
      return (
        <FormikRadioBox
          name="voucher"
          components={{
            Label: (
              <>
                <div css={radioBoxContent}>
                  <span css={radioBoxTitle}>{t('orderReview:giftCard')}</span>
                  <span css={radioBoxDescription}>{values.voucher.code}</span>
                  <span
                    css={radioBoxDescription}
                    style={{ textAlign: 'right' }}
                  >
                    R$ {localizePrice(values.voucher.total)}
                  </span>
                </div>
              </>
            ),
          }}
          style={{ marginTop: 16 }}
          boxCss={radioBoxBordered}
        />
      );
    }

    return null;
  };

  return (
    <div css={tabWrapper}>
      <div css={tabTitleWrapper}>
        <span css={tabTitle}>{t('orderReview:discounts')}</span>
      </div>
      {renderCashback()}
      {renderVoucher()}
      {!values.voucher && !hasGiftCardProduct && (
        <Button
          type="button"
          onClick={handleVisibilityChange(true)}
          onKeyDown={handleVisibilityChange(true)}
          color="primary"
          styles={radioBoxButton}
          block
          endIcon={<AddIcon />}
        >
          {t('orderReview:addGiftCard')}
        </Button>
      )}
      <OrderReviewGiftCardModal
        onClose={handleVisibilityChange(false)}
        visible={isModalVisible}
        onConfirm={handleAddVoucher}
        subtotal={subtotal}
        deliveryPrice={deliveryPrice}
      />
    </div>
  );
};

OrderReviewDiscounts.defaultProps = {
  hasGiftCardProduct: false,
  loyaltyCashback: null,
  maxPercentCashback: 100,
};

OrderReviewDiscounts.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  loyaltyCashback: PropTypes.object,
  values: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  maxPercentCashback: PropTypes.number,
  hasGiftCardProduct: PropTypes.bool,
  subtotal: PropTypes.number.isRequired,
  deliveryPrice: PropTypes.number.isRequired,
};

export default OrderReviewDiscounts;
