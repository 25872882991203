import React from 'react';

const withModal = (injectedProps) => (Component) => {
  const modalWrapper = (props) => {
    let innerProps = injectedProps;

    if (typeof injectedProps === 'function') {
      innerProps = injectedProps(props);
    }

    if (!innerProps.visible) {
      return true;
    }

    return <Component {...props} />;
  };

  return modalWrapper;
};

export default withModal;
