import React from 'react';
import { useTranslation } from 'react-i18next';

import ScrollableView from '../../components/ScrollableView';
import SuccessIcon from '../../assets/checked.svg';

import {
  backgroundCss,
  wrapperCss,
  iconCss,
  messageCss,
} from './PaymentLinkPage.styles';

const PaymentLinkSuccessScreen = () => {
  const { t } = useTranslation();

  return (
    <div css={backgroundCss}>
      <ScrollableView>
        <div css={wrapperCss}>
          <img src={SuccessIcon} alt="checked" css={iconCss} />
          <div css={messageCss}>{t('paymentLink:completedTransaction')}</div>
        </div>
      </ScrollableView>
    </div>
  );
};

export default PaymentLinkSuccessScreen;
