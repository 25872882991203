import React from 'react';
import PropTypes from 'prop-types';
import { withFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import Input from '@bit/eyemobile.core.input';
import Button from '@bit/eyemobile.core.button';

import FormikInput from '../../components/FormikInput';
import ScrollableView from '../../components/ScrollableView';
import Header from '../../components/Header';
import dayjs from '../../utils/dayjs';
import { adaptPhone } from '../../utils/adaptField';
import RowRadioButton from '../../components/RowRadioButton';
import { phoneMask, birthDateMask } from '../../utils/masks';
import {
  mainForm,
  fieldPadding,
  formAlignment,
  ButtonPadding,
} from '../../components/Form/Form.styles';
import { screenWrapper, screenInner } from '../Page.styles';

import { buttonsWrapper } from './UserBasicDataPage.styles';

const UserDataScreen = ({
  userData,
  onBackClick,
  onReceiveNotificationChange,
  onUserDataDelete,
  values,
  setFieldValue,
  handleBlur,
  isValid,
  isSubmitting,
  handleSubmit,
}) => {
  const { t } = useTranslation();

  const handleEmailBlurTrim = async (e) => {
    const { email } = values;
    setFieldValue('email', email ? email.trim() : '');
    handleBlur(e);
  };

  const handleEmailChange = async (e) => {
    const { value } = e.target;
    setFieldValue('email', value ? value.trim() : '');
  };

  return (
    <div css={screenWrapper}>
      <Header title={t('navigation:myData')} onPrev={onBackClick} />
      <ScrollableView hasHeader divCss={screenInner}>
        <div css={mainForm}>
          <div css={formAlignment}>
            <div css={fieldPadding}>
              <Input
                value={userData.document}
                placeholder={t('forms:document')}
                animateLabel
                hideIcons={false}
                disabled
                variant="bordered"
              />
            </div>
            <div css={fieldPadding}>
              <FormikInput
                name="fullName"
                placeholder={t('forms:fullname')}
                animateLabel
                hideIcons={false}
                disabled={isSubmitting}
                borderColor="primary"
                labelColor="primary"
                variant="bordered"
              />
            </div>
            <div css={fieldPadding}>
              <FormikInput
                name="email"
                placeholder={t('forms:email')}
                animateLabel
                hideIcons={false}
                disabled={isSubmitting}
                borderColor="primary"
                labelColor="primary"
                variant="bordered"
                onChange={handleEmailChange}
                onBlur={handleEmailBlurTrim}
              />
            </div>
            <div css={fieldPadding}>
              <FormikInput
                name="phone"
                placeholder={t('forms:phone')}
                animateLabel
                mask={phoneMask}
                hideIcons={false}
                disabled={isSubmitting}
                inputMode="numeric"
                pattern="[0-9]*"
                variant="bordered"
                borderColor="primary"
                labelColor="primary"
              />
            </div>
            {userData.birthday && (
              <div css={fieldPadding}>
                <FormikInput
                  name="birthday"
                  placeholder={t('forms:birthday')}
                  animateLabel
                  mask={birthDateMask}
                  hideIcons={false}
                  disabled={isSubmitting}
                  inputMode="numeric"
                  pattern="[0-9]*"
                  borderColor="primary"
                  labelColor="primary"
                  variant="bordered"
                />
              </div>
            )}
            <div css={fieldPadding}>
              <RowRadioButton
                checked={userData.pushNotificationEnabled}
                onChange={onReceiveNotificationChange}
                label={t('forms:pushNotificationAccept')}
              />
            </div>
            <div css={buttonsWrapper}>
              <ButtonPadding>
                <Button
                  isLoading={isSubmitting}
                  disabled={!isValid || isSubmitting}
                  onClick={handleSubmit}
                  color="primary"
                  block
                >
                  {t('forms:confirm')}
                </Button>
              </ButtonPadding>
              <ButtonPadding style={{ marginBottom: '32px' }}>
                <Button
                  variant="outlined"
                  onClick={onUserDataDelete}
                  color="error"
                  block
                >
                  {t('navigation:deleteUser')}
                </Button>
              </ButtonPadding>
            </div>
          </div>
        </div>
      </ScrollableView>
    </div>
  );
};

UserDataScreen.propTypes = {
  userData: PropTypes.object.isRequired,
  onBackClick: PropTypes.func.isRequired,
  onReceiveNotificationChange: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  onUserDataDelete: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

const formikEnhance = withFormik({
  mapPropsToValues: ({ initialValues }) => ({
    fullName: initialValues?.fullName || '',
    birthday: initialValues?.birthday || '',
    email: initialValues?.email || '',
    phone: initialValues?.phone
      ? adaptPhone(initialValues.phone.replace('+55', ''))
      : '',
  }),
  validationSchema: Yup.object().shape({
    fullName: Yup.string().required('formValidation:fieldRequired'),
    birthday: Yup.string()
      .optional()
      .test(
        'validate-birthdate',
        'formValidation:invalidBirthDate',
        function validateBirthDate(value) {
          if (!value) {
            return true;
          }
          return dayjs(value, 'DD/MM/YYYY').format('DD/MM/YYYY') === value;
        },
      ),
    email: Yup.string()
      .email('formValidation:invalidEmail')
      .required('formValidation:fieldRequired'),
    phone: Yup.string()
      .matches(
        /^\([1-9]{2}\) (?:[2-8]|9[1-9])[0-9]{3}-[0-9]{4}$/,
        'formValidation:invalidPhone',
      )
      .required('formValidation:fieldRequired'),
  }),
  handleSubmit: (values, { props, dirty, ...formikBag }) => {
    const { onFormSubmit } = props;
    onFormSubmit(values, formikBag);
  },
});

export default formikEnhance(UserDataScreen);
