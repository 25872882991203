import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'emotion-theming';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import PopMenu from '../PopMenu';
import CreditCardFlag from '../CreditCardFlag';

import {
  CardContainer,
  cardDigits,
  cardMainTag,
  cardSection,
} from './CustomerCardItem.styles';

const CustomerCardItem = ({
  brand,
  lastDigits,
  main,
  onRemove,
  onTurnMain,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [anchorMenu, setAnchorMenu] = useState(null);
  const open = !!anchorMenu;

  const handleClick = (event) => {
    setAnchorMenu(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorMenu(null);
  };

  const handleRemoveCard = () => {
    handleClose();
    onRemove();
  };

  const handleTurnCardMain = () => {
    handleClose();
    onTurnMain();
  };

  return (
    <CardContainer selected={main}>
      <div css={cardSection}>
        <CreditCardFlag brand={brand} size={38} />
        <span css={cardDigits}>{`**** ${lastDigits}`}</span>
      </div>
      <div css={cardSection}>
        {!!main && <span css={cardMainTag}>{t('cardsPage:main')}</span>}
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>
        <PopMenu
          id="long-menu"
          anchorEl={anchorMenu}
          open={open}
          onClose={handleClose}
        >
          {!main && (
            <MenuItem
              key="turnMain"
              onClick={handleTurnCardMain}
              style={{
                color: theme.getColor('background'),
              }}
            >
              {t('cardsPage:turnMain')}
            </MenuItem>
          )}
          <MenuItem
            key="remove"
            onClick={handleRemoveCard}
            style={{
              color: theme.getColor('background'),
            }}
          >
            {t('cardsPage:remove')}
          </MenuItem>
        </PopMenu>
      </div>
    </CardContainer>
  );
};

CustomerCardItem.defaultProps = {
  brand: null,
};

CustomerCardItem.propTypes = {
  brand: PropTypes.string,
  lastDigits: PropTypes.string.isRequired,
  main: PropTypes.bool.isRequired,
  onRemove: PropTypes.func.isRequired,
  onTurnMain: PropTypes.func.isRequired,
};

export default CustomerCardItem;
