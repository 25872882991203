import isObject from 'isobject';
import isNil from './isNil';
import isEmpty from './isEmpty';

const isValid = (value) =>
  !isNil(value) && !isEmpty(value) && JSON.stringify(value) !== '{}';

const clean = (object, recursive = true) => {
  const filterInvalid = (key) => isValid(object[key]);

  return Object.keys(object)
    .filter(filterInvalid)
    .reduce((acc, curr) => {
      let normalizedValue;

      if (recursive && isObject(object[curr])) {
        normalizedValue = clean(object[curr]);
      } else {
        normalizedValue = object[curr];
      }

      if (isValid(normalizedValue)) {
        return {
          ...acc,
          [curr]: normalizedValue,
        };
      }

      return acc;
    }, {});
};

export default clean;
