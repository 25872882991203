export default class LocalStorageManager {
  static prefix = 'EYEPASS';

  static getDomain() {
    return window.location.pathname.split('/')[1].toUpperCase();
  }

  static setUser(user) {
    const domain = this.getDomain();
    localStorage.setItem(`${this.prefix}_USER_${domain}`, JSON.stringify(user));
  }

  static getUser() {
    const domain = this.getDomain();
    if (
      typeof localStorage[`${this.prefix}_USER_${domain}`] !== 'undefined' &&
      localStorage[`${this.prefix}_USER_${domain}`] !== 'undefined'
    ) {
      return JSON.parse(localStorage.getItem(`${this.prefix}_USER_${domain}`));
    }
  }

  static removeUser() {
    const domain = this.getDomain();
    if (typeof localStorage[`${this.prefix}_USER_${domain}`] !== 'undefined') {
      localStorage.removeItem(`${this.prefix}_USER_${domain}`);
    }
  }

  static setRegisterToken(token) {
    const domain = this.getDomain();
    localStorage.setItem(`${this.prefix}_REGISTER_TOKEN_${domain}`, token);
  }

  static getRegisterToken() {
    const domain = this.getDomain();
    if (
      typeof localStorage[`${this.prefix}_REGISTER_TOKEN_${domain}`] !==
        'undefined' &&
      localStorage[`${this.prefix}_REGISTER_TOKEN_${domain}`] !== 'undefined'
    ) {
      return localStorage.getItem(`${this.prefix}_REGISTER_TOKEN_${domain}`);
    }
  }

  static removeRegisterToken() {
    const domain = this.getDomain();
    if (
      typeof localStorage[`${this.prefix}_REGISTER_TOKEN_${domain}`] !==
      'undefined'
    ) {
      localStorage.removeItem(`${this.prefix}_REGISTER_TOKEN_${domain}`);
    }
  }

  static setToken(token) {
    const domain = this.getDomain();
    localStorage.setItem(`${this.prefix}_TOKEN_${domain}`, token);
  }

  static getToken() {
    const domain = this.getDomain();
    if (
      typeof localStorage[`${this.prefix}_TOKEN_${domain}`] !== 'undefined' &&
      localStorage[`${this.prefix}_TOKEN_${domain}`] !== 'undefined'
    ) {
      return localStorage.getItem(`${this.prefix}_TOKEN_${domain}`);
    }
  }

  static removeToken() {
    const domain = this.getDomain();
    if (typeof localStorage[`${this.prefix}_TOKEN_${domain}`] !== 'undefined') {
      localStorage.removeItem(`${this.prefix}_TOKEN_${domain}`);
    }
  }

  static setRefreshToken(token) {
    const domain = this.getDomain();
    localStorage.setItem(`${this.prefix}_REFRESH_TOKEN_${domain}`, token);
  }

  static getRefreshToken() {
    const domain = this.getDomain();
    if (
      typeof localStorage[`${this.prefix}_REFRESH_TOKEN_${domain}`] !==
        'undefined' &&
      localStorage[`${this.prefix}_REFRESH_TOKEN_${domain}`] !== 'undefined'
    ) {
      return localStorage.getItem(`${this.prefix}_REFRESH_TOKEN_${domain}`);
    }
  }

  static removeRefreshToken() {
    const domain = this.getDomain();
    if (
      typeof localStorage[`${this.prefix}_REFRESH_TOKEN_${domain}`] !==
      'undefined'
    ) {
      localStorage.removeItem(`${this.prefix}_REFRESH_TOKEN_${domain}`);
    }
  }

  static setIsNotFirstAccess() {
    const domain = this.getDomain();
    localStorage.setItem(`${this.prefix}_FIRST_ACCESS_${domain}`, true);
  }

  static getIsNotFirstAccess() {
    const domain = this.getDomain();
    if (
      typeof localStorage[`${this.prefix}_FIRST_ACCESS_${domain}`] !==
        'undefined' &&
      localStorage[`${this.prefix}_FIRST_ACCESS_${domain}`] !== 'undefined'
    ) {
      return localStorage.getItem(`${this.prefix}_FIRST_ACCESS_${domain}`);
    }
  }

  static removeIsNotFirstAccess() {
    const domain = this.getDomain();
    if (
      typeof localStorage[`${this.prefix}_FIRST_ACCESS_${domain}`] !==
      'undefined'
    ) {
      localStorage.removeItem(`${this.prefix}_FIRST_ACCESS_${domain}`);
    }
  }

  static setDomainTenantId(tenantId) {
    const domain = this.getDomain();
    localStorage.setItem(
      `${this.prefix}_TENANT_ID_${domain}`,
      String(tenantId),
    );
  }

  static getDomainTenantId() {
    const domain = this.getDomain();
    if (
      typeof localStorage[`${this.prefix}_TENANT_ID_${domain}`] !==
        'undefined' &&
      localStorage[`${this.prefix}_TENANT_ID_${domain}`] !== 'undefined'
    ) {
      return localStorage.getItem(`${this.prefix}_TENANT_ID_${domain}`);
    }
  }

  static removeDomainTenantId() {
    const domain = this.getDomain();
    if (
      typeof localStorage[`${this.prefix}_TENANT_ID_${domain}`] !== 'undefined'
    ) {
      localStorage.removeItem(`${this.prefix}_TENANT_ID_${domain}`);
    }
  }

  static clear() {
    localStorage.clear();
  }
}
