import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Button from '@bit/eyemobile.core.button';
import { useTheme } from 'emotion-theming';

import { ReactComponent as SideMenuIcon } from '../../assets/chevron_left.svg';
import Header from '../../components/Header';
import ScrollableView from '../../components/ScrollableView';
import HistoryProductItem from '../../components/HistoryProductItem';
import HistoryComboItem from '../../components/HistoryComboItem';
import StepProgressBar from '../../components/StepProgressBar';
import { smallMeasureTypesMap } from '../../utils/constants';
import localizePrice from '../../utils/localizePrice';
import dayjs from '../../utils/dayjs';

import OrderDescriptionRow from './OrderDescriptionRow';
import OrderHistoryDetailPayment from './OrderHistoryDetailPayment';
import OrderHistoryDetail from './OrderHistoryDetail';

import {
  innerBoxCss,
  outerBoxCss,
  fieldText,
  DescriptionText,
  divisorCss,
  descriptionRow,
  deliveryAddressWapper,
  statusTextWrapper,
  StatusText,
  blockButtonWrapper,
  storeAddressCol,
  storeAddressText,
  trackingLink,
} from './OrderHistoryPage.styles';
import { screenWrapper, screenInner } from '../Page.styles';

const packageStatusColorsMap = {
  PACKAGE_REQUESTED: 'default',
  PACKAGE_CONFIRMED: 'success',
  PACKAGE_WITHDRAWN: 'success',
  PACKAGE_DELIVERED: 'success',
  PACKAGE_SCHEDULED: 'success',
  PACKAGE_OTHER: 'default',
};

const progressStatusMap = {
  REGISTERED: 2,
  PREPARING: 3,
  READY: 4,
  DELIVERED: 4,
  WAITING_PAYMENT: 1,
  REJECTED: 0,
  CANCELED: 0,
};

const progressTotalStatusMap = {
  REGISTERED: 4,
  PREPARING: 4,
  READY: 4,
  DELIVERED: 4,
  WAITING_PAYMENT: 4,
  REJECTED: 1,
  CANCELED: 1,
};

const followableStatus = ['READY', 'COLLECTED'];

const etaStatus = ['REGISTERED', 'PREPARING', 'READY', 'COLLECTED'];

const OrderHistoryDetailScreen = ({
  order,
  onReturn,
  onRedoOrder,
  onCancelOrder,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const unitQuantityAdapt = (quantity) =>
    quantity > 1 ? `${quantity} ${t('orderHistory:units')}` : '';

  const renderProducts = (product) => {
    const {
      id: productId,
      name,
      amount,
      measure,
      totalPrice,
      image,
      complementGroups,
      comboProductItems,
    } = product;

    const quantityText =
      measure === 'UNIT'
        ? unitQuantityAdapt(amount)
        : `${amount * 100} ${smallMeasureTypesMap[measure]}`;

    const productTotalPriceText = `R$ ${localizePrice(totalPrice)}`;

    if (comboProductItems && comboProductItems.length > 0) {
      return (
        <HistoryComboItem
          key={productId}
          comboImgSrc={image}
          comboName={name}
          comboQuantity={amount}
          comboQuantityText={quantityText}
          comboItems={comboProductItems}
          totalPrice={productTotalPriceText}
        />
      );
    }

    return (
      <HistoryProductItem
        key={productId}
        productName={name}
        productImgSrc={image}
        productQuantity={quantityText}
        totalPrice={productTotalPriceText}
        complementGroups={complementGroups}
      />
    );
  };

  const getScheduleText = (schedule) => {
    if (!schedule) {
      return '';
    }

    const { start, end } = schedule;

    return `${dayjs(start).format('HH:mm')} - ${dayjs(end).format(
      'HH:mm, DD/MM/YYYY',
    )}`;
  };

  const renderDeliveryAddress = (address) => {
    const { state, city, addressStreetNumber, district, complement } = address;

    const complementText = complement ? `, ${complement}` : '';

    const addressText = `${addressStreetNumber}${complementText}`;
    const stateText = `${city} - ${state}`;

    return (
      <div css={deliveryAddressWapper}>
        <DescriptionText bold>
          {t('orderHistory:deliveryAddress')}
        </DescriptionText>
        <DescriptionText>{addressText}</DescriptionText>
        <DescriptionText>{district}</DescriptionText>
        <DescriptionText>{stateText}</DescriptionText>
      </div>
    );
  };

  const {
    orderId,
    time,
    store,
    status: orderStatus,
    transactionProducts,
    transactionCombos,
    deliveryType,
    deliveryAddress,
    deliveryPackage,
    schedule,
    eta,
    comment,
    deliveryDetail,
  } = order;

  const adaptedOrderStatus =
    deliveryPackage?.deliveryStatus === 'PACKAGE_DELIVERED' &&
    orderStatus === 'READY'
      ? 'DELIVERED'
      : orderStatus;

  const timeText = dayjs(time).format('DD/MM/YYYY, HH:mm');
  const statusText = t(`orderHistory:FULL_${adaptedOrderStatus}`);
  const deliveryText =
    deliveryType === 'TAG'
      ? `${t(`orderHistory:${deliveryType}`)} ${deliveryDetail}`
      : t(`orderHistory:${deliveryType}`);
  const scheduleText = getScheduleText(schedule);

  const statusProgress = progressStatusMap[adaptedOrderStatus];
  const statusTotalProgress = progressTotalStatusMap[adaptedOrderStatus];
  const mustShowDeliveryStatus =
    !!deliveryPackage ||
    (deliveryType === 'ADDRESS' && orderStatus === 'READY');

  const renderDeliveryData = () => {
    if (!deliveryPackage) {
      return (
        <>
          <span css={fieldText}>{t('orderHistory:delivery')}</span>
          <div css={statusTextWrapper}>
            <StatusText statusColor="default">
              {t('orderHistory:notTrackable')}
            </StatusText>
          </div>
          <div css={divisorCss} />
        </>
      );
    }

    const {
      status: packageStatus,
      statusMessage,
      trackingUrl,
    } = deliveryPackage;

    const packageStatusText = t(`orderHistory:FULL_${packageStatus}`);
    const packageStatusColor = packageStatusColorsMap[packageStatus];
    const showTrackingButton = followableStatus.some(
      (flwbStatus) => orderStatus === flwbStatus,
    );
    return (
      <>
        <span css={fieldText}>{t('orderHistory:delivery')}</span>
        <div css={statusTextWrapper}>
          <StatusText statusColor={packageStatusColor}>
            {packageStatusText}
          </StatusText>
        </div>
        <div css={descriptionRow}>
          <DescriptionText>{statusMessage}</DescriptionText>
        </div>
        {showTrackingButton && (
          <div css={statusTextWrapper}>
            <a
              href={
                trackingUrl.startsWith('https://')
                  ? trackingUrl
                  : `//${trackingUrl}`
              }
              css={trackingLink}
              rel="noreferrer"
              target="_blank"
            >
              {t('orderHistory:trackDelivery')}
            </a>
          </div>
        )}
        <div css={divisorCss} />
      </>
    );
  };

  const renderStoreData = () => {
    const { name, address, addressNumber, city, state } = store;

    const storeAddress = address
      ? `${address}, ${addressNumber}`
      : t('productOrder:storeNoAddress');

    const cityState = city ? `${city}, ${state}` : '';

    return (
      <>
        <span css={fieldText}>{t('orderHistory:storeData')}</span>
        <DescriptionText>{name}</DescriptionText>
        <div css={storeAddressCol}>
          <span css={storeAddressText}>{storeAddress}</span>
          <span css={storeAddressText}>{cityState}</span>
        </div>
      </>
    );
  };

  return (
    <div css={screenWrapper}>
      <Header
        onPrev={onReturn}
        leftIcon={SideMenuIcon}
        title={`${t('orderHistory:order')} #${orderId}`}
      />
      <ScrollableView hasHeader divCss={screenInner}>
        <div css={outerBoxCss}>
          <div css={innerBoxCss}>
            <span css={fieldText}>{t('orderHistory:itemsList')}</span>
            {transactionCombos.map(renderProducts)}
            {transactionProducts.map(renderProducts)}
            <div css={divisorCss} />
            <div css={statusTextWrapper}>
              <StatusText>{statusText}</StatusText>
            </div>
            <StepProgressBar
              id="order-history"
              progress={statusProgress}
              total={statusTotalProgress}
              separators={false}
              style={{
                height: (theme && theme.getSpacing(1, 'px')) || 7,
              }}
            />
            <div css={divisorCss} />
            <OrderHistoryDetail order={order} />
            <div css={divisorCss} />
            <OrderHistoryDetailPayment order={order} />
            <div css={divisorCss} />
            {mustShowDeliveryStatus && renderDeliveryData()}
            {renderStoreData()}
            <div css={divisorCss} />
            <span css={fieldText}>{t('orderHistory:orderData')}</span>
            <OrderDescriptionRow label={t('orderHistory:orderDoneAt')}>
              {timeText}
            </OrderDescriptionRow>
            {eta && etaStatus.some((status) => orderStatus === status) && (
              <OrderDescriptionRow
                label={t('orderHistory:expectedDeliveryDuration')}
              >
                {`${dayjs(time)
                  .add(Math.max(eta - 10, 10), 'm')
                  .format('HH:mm')} - ${dayjs(time)
                  .add(eta + 10, 'm')
                  .format('HH:mm')}`}
              </OrderDescriptionRow>
            )}
            <OrderDescriptionRow label={t('orderHistory:deliveryType')}>
              {deliveryText}
            </OrderDescriptionRow>
            {comment && comment.length > 0 && (
              <OrderDescriptionRow label={t('orderHistory:comment')}>
                {comment}
              </OrderDescriptionRow>
            )}
            {schedule && (
              <OrderDescriptionRow label={t('orderHistory:scheduledTo')}>
                {scheduleText}
              </OrderDescriptionRow>
            )}
            {!!deliveryAddress && renderDeliveryAddress(deliveryAddress)}

            <div css={divisorCss} />
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
              }}
            >
              {(adaptedOrderStatus === 'REGISTERED' ||
                adaptedOrderStatus === 'PREPARING') && (
                <div css={blockButtonWrapper}>
                  <Button color="error" onClick={onCancelOrder}>
                    {t('orderHistory:cancelOrder')}
                  </Button>
                </div>
              )}
              <div css={blockButtonWrapper}>
                <Button color="primary" onClick={onRedoOrder}>
                  {t('orderHistory:redoOrder')}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </ScrollableView>
    </div>
  );
};
OrderHistoryDetailScreen.propTypes = {
  order: PropTypes.object.isRequired,
  onReturn: PropTypes.func.isRequired,
  onRedoOrder: PropTypes.func.isRequired,
  onCancelOrder: PropTypes.func.isRequired,
};

export default OrderHistoryDetailScreen;
