import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Button from '@bit/eyemobile.core.button';

import {
  confirmationWrapper,
  warningText,
  buttonsRow,
} from './ClearCartConfirmation.styles';

const ClearCartConfirmation = ({ onConfirm, onCancel }) => {
  const { t } = useTranslation();

  return (
    <div css={confirmationWrapper}>
      <span css={warningText}>{t('productOrder:confirmCardClear')}</span>
      <div css={buttonsRow}>
        <Button variant="outlined" color="error" onClick={onCancel}>
          {t('formValidation:cancel')}
        </Button>
        <Button color="error" onClick={onConfirm}>
          {t('formValidation:confirm')}
        </Button>
      </div>
    </div>
  );
};

ClearCartConfirmation.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default ClearCartConfirmation;
