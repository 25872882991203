import React from 'react';
import PropTypes from 'prop-types';
import CircleButton from '@bit/eyemobile.core.circle-button';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'emotion-theming';

import { ReactComponent as KeyboardArrowDown } from '../../assets/keyboard_arrow_down.svg';

import {
  languageSelectWrapper,
  contentScroll,
  LanguageItem,
  LanguageItemText,
  languageListTitle,
} from './LanguageSelector.styles';

const LanguageSelector = ({
  languageOptions,
  selectedLanguage,
  onLanguageSelect,
  onCancel,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const renderLanguageOption = (languageOption) => (
    <LanguageItem
      key={languageOption}
      onClick={() => onLanguageSelect(languageOption)}
      onKeyDown={() => onLanguageSelect(languageOption)}
      role="presentation"
      aria-label={languageOption}
      active={selectedLanguage === languageOption}
    >
      <LanguageItemText active={selectedLanguage === languageOption}>
        {t(`languages:${languageOption}`)}
      </LanguageItemText>
    </LanguageItem>
  );

  return (
    <div css={languageSelectWrapper}>
      <div
        style={{
          width: '100%',
          paddingRight: '8px',
          paddingBottom: theme.getSpacing(2, 'px'),
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
        }}
      >
        <CircleButton
          icon={<KeyboardArrowDown />}
          size="30px"
          onClick={onCancel}
          backgroundColor="primary"
          color="background"
          padding="3px"
        />
      </div>
      <span css={languageListTitle}>{t('languages:availableLanguages')}</span>
      {languageOptions.length > 0 ? (
        <div css={contentScroll}>
          {languageOptions.map(renderLanguageOption)}
        </div>
      ) : (
        <span>{t('productOrder:noShippingTypeAvailable')}</span>
      )}
    </div>
  );
};

LanguageSelector.propTypes = {
  languageOptions: PropTypes.array.isRequired,
  selectedLanguage: PropTypes.string.isRequired,
  onLanguageSelect: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default LanguageSelector;
