import crypto from 'crypto-browserify';

import dayjs from './dayjs';
import gateway from './gateway';
import ecommerceService from '../services/ecommerce';

const adaptCard = (cardNumber, holderName, expirationDate, cvv) =>
  `card_number=${cardNumber}&card_holder_name=${holderName}`.concat(
    `&card_expiration_date=${expirationDate}&card_cvv=${cvv}`,
  );

const fetchPublicKeyAndId = async (pointId) => {
  const response = await gateway
    .get(ecommerceService.cardKey(pointId))
    .then(({ data }) => data);

  return response;
};

const hashCreditCard = async (
  pointId,
  cardNumber,
  holderName,
  expirationDate,
  cvv,
) => {
  try {
    const adaptedExpirationDate = dayjs(expirationDate, 'MM/YYYY').format(
      'MMYY',
    );
    const card = adaptCard(cardNumber, holderName, adaptedExpirationDate, cvv);

    const { id, public_key: publicKey } = await fetchPublicKeyAndId(pointId);

    const buffer = Buffer.from(card, 'utf8');
    const encrypted = crypto.publicEncrypt(
      { key: publicKey, padding: crypto.constants.RSA_PKCS1_PADDING },
      buffer,
    );

    const base64Encrypted = encrypted.toString('base64');

    return `${id}_${base64Encrypted}`;
  } catch (error) {
    return Promise.reject(error);
  }
};

export default hashCreditCard;
