import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Modal from '@bit/eyemobile.core.modal';

import localizePrice from '../../utils/localizePrice';
import withModal from '../../hocs/withModal';
import {
  productContent,
  productInnerWrapper,
  productOverlay,
} from '../ProductAdjustModal/ProductAdjustModal.styles';
import InstallmentSelect from '../InstallmentSelect';

const mapStateToProps = ({
  tenantEyepass: { maxInstallments, minInstallmentValue },
}) => ({
  maxInstallments,
  minInstallmentValue,
});

const InstallmentsModal = ({
  visible,
  onClose,
  paymentValue,
  initialInstallment,
  onInstallmentSelect,
  maxInstallments,
  minInstallmentValue,
}) => {
  const { t } = useTranslation();

  const createInstallment = (installmentsNumber) => ({
    value: installmentsNumber,
    message: t('forms:installmentsPay', {
      installments: installmentsNumber,
      installmentValue: localizePrice(paymentValue / installmentsNumber),
    }),
  });

  const installmentOptions = useMemo(() => {
    const effectiveMaxInstallments =
      minInstallmentValue === 0
        ? maxInstallments
        : Math.min(
            Math.floor(paymentValue / minInstallmentValue),
            maxInstallments,
          );
    const extraInstallments = new Array(effectiveMaxInstallments - 1).fill(1);
    const filledInstallments = extraInstallments.map((_, index) => index + 2);

    return [
      {
        value: 1,
        message: t('forms:singleInstallment', {
          installmentValue: localizePrice(paymentValue),
        }),
      },
    ].concat(filledInstallments.map(createInstallment));
  }, []);

  const handleInstallmentSelect = (newInstallment) =>
    onInstallmentSelect(newInstallment);

  return (
    <Modal
      visible={visible}
      styles={{
        innerWrapper: productInnerWrapper,
        content: productContent,
        overlay: productOverlay,
      }}
      keepMounted
    >
      <InstallmentSelect
        installmentOptions={installmentOptions}
        selectedInstallment={initialInstallment}
        onSelect={handleInstallmentSelect}
        onClose={onClose}
      />
    </Modal>
  );
};

InstallmentsModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  paymentValue: PropTypes.number.isRequired,
  initialInstallment: PropTypes.object.isRequired,
  onInstallmentSelect: PropTypes.func.isRequired,
  maxInstallments: PropTypes.number.isRequired,
  minInstallmentValue: PropTypes.number.isRequired,
};

export default compose(
  withModal((props) => ({
    visible: props.visible,
  })),
  connect(mapStateToProps),
)(InstallmentsModal);
