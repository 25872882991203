import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import ComplementItem from '../ComplementItem';

import {
  groupContainer,
  descriptionText,
  minMaxDescriptionText,
  groupDescription,
  titleCss,
} from './ComplementGroup.styles';

const mapStateToProps = ({
  productOrder: {
    productOrder: { store },
  },
}) => ({
  menuOnly: Boolean(store) && store.isMenuOnly,
});

const ComplementGroup = ({
  name,
  min,
  max,
  description,
  complementItems,
  onIncrement,
  onDecrement,
  menuOnly,
}) => {
  const { t } = useTranslation();

  const handleIncrement = (complementGroupId, complementItemId) => () =>
    onIncrement(complementGroupId, complementItemId);

  const handleDecrement = (complementGroupId, complementItemId) => () =>
    onDecrement(complementGroupId, complementItemId);

  const renderComplementItem = (complementItem) => {
    const { id: complementItemId, complementGroupId } = complementItem;

    return (
      <ComplementItem
        key={`complement-item-${complementItemId}`}
        name={complementItem.name}
        description={complementItem.description}
        price={complementItem.price}
        quantity={complementItem.quantity}
        groupMax={max}
        onIncrement={handleIncrement(complementGroupId, complementItemId)}
        onDecrement={handleDecrement(complementGroupId, complementItemId)}
      />
    );
  };

  const getMinMaxSelectText = () => {
    if (menuOnly) {
      return '';
    }

    if (max === 1) {
      return t('productOrder:select1Complement');
    }

    if (min === max) {
      return t('productOrder:selectMaxComplements', { max });
    }

    if (min === 0) {
      return t('productOrder:selectUpToMaxComplements', { max });
    }

    return t('productOrder:selectFromMinToMaxComplements', { min, max });
  };

  return (
    <div css={groupContainer}>
      <div css={groupDescription}>
        <div>
          <span css={titleCss}>
            {name}
            {min > 0 && ' *'}
          </span>
          {description && (
            <span css={descriptionText} style={{ marginTop: 8 }}>
              {description}
            </span>
          )}
        </div>
        <span
          css={minMaxDescriptionText}
          style={{
            marginLeft: 8,
          }}
        >
          {getMinMaxSelectText()}
        </span>
      </div>
      {complementItems.map(renderComplementItem)}
    </div>
  );
};

ComplementGroup.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  complementItems: PropTypes.array.isRequired,
  onIncrement: PropTypes.func.isRequired,
  onDecrement: PropTypes.func.isRequired,
  menuOnly: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(ComplementGroup);
