import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Button from '@bit/eyemobile.core.button';

import {
  helpWrapper,
  contactText,
  contactLink,
  buttonsRow,
  contentRow,
  divisorCss,
} from './TenantContact.styles';

const TenantContact = ({ onClose, phone, email }) => {
  const { t } = useTranslation();

  const whatsAppHref = `https://api.whatsapp.com/send?phone=${phone}`;

  const emailHref = `emailto:${email}`;

  return (
    <div css={helpWrapper}>
      <span css={contactText}>{t('navigation:contactUsThrough')}</span>
      <div css={divisorCss} />
      {!!phone && (
        <div css={contentRow}>
          <span css={contactText}>WhatsApp</span>
          <a href={whatsAppHref} css={contactLink}>
            {phone}
          </a>
        </div>
      )}
      {!!email && (
        <div css={contentRow}>
          <span css={contactText}>{t('navigation:email')}</span>
          <a href={emailHref} css={contactLink}>
            {email}
          </a>
        </div>
      )}
      <div css={buttonsRow}>
        <Button color="error" onClick={onClose} block>
          {t('navigation:closeModal')}
        </Button>
      </div>
    </div>
  );
};

TenantContact.propTypes = {
  onClose: PropTypes.func.isRequired,
  phone: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
};

export default TenantContact;
