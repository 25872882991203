export const adaptDocument = (cpf) => {
  if (typeof cpf === 'string' && cpf.length === 11) {
    return `${cpf.substring(0, 3)}.${cpf.substring(3, 6)}.${cpf.substring(
      6,
      9,
    )}-${cpf.substring(9, 11)}`;
  }

  return cpf;
};

export const adaptPhone = (phone) => {
  if (typeof phone === 'string') {
    if (phone.length === 13) {
      return `(${phone.substring(2, 4)}) ${phone.substring(
        4,
        9,
      )}-${phone.substring(9, 13)}`;
    }

    if (phone.length === 11) {
      return `(${phone.substring(0, 2)}) ${phone.substring(
        2,
        7,
      )}-${phone.substring(7, 11)}`;
    }
  }

  return phone;
};

export const stripMask = (maskedValue) => maskedValue.replace(/\D/g, '').trim();
