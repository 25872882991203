const fetchCepData = async (cep) =>
  new Promise((resolve, reject) => {
    try {
      const trimmedCep = cep.replace(/\D/g, '').trim();

      if (trimmedCep.length === 8) {
        fetch(`https://viacep.com.br/ws/${trimmedCep}/json/`)
          .then((results) => results.json())
          .then((data) => {
            if (data.erro) {
              reject(new Error('CEP inválido'));
            } else {
              resolve({
                district: data.bairro,
                city: data.localidade,
                state: data.uf,
                street: data.logradouro,
              });
            }
          });
      } else {
        resolve(null);
      }
    } catch (error) {
      reject(error);
    }
  });

export default fetchCepData;
