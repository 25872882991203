import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'emotion-theming';
import CircleButton from '@bit/eyemobile.core.circle-button';

import { ReactComponent as KeyboardArrowDown } from '../../assets/keyboard_arrow_down.svg';

import {
  installmentSelectWrapper,
  modalTop,
  contentScroll,
  InstallmentItem,
  InstallmentItemText,
  installmentListTitle,
} from './InstallmentSelect.styles';

const InstallmentSelect = ({
  selectedInstallment,
  installmentOptions,
  onSelect,
  onClose,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const renderInstallment = (installment) => (
    <InstallmentItem
      key={`installments-${installment.value}`}
      onClick={() => onSelect(installment.value)}
      onKeyDown={() => onSelect(installment.value)}
      role="presentation"
      aria-label={`installment-${installment.value}`}
      active={selectedInstallment === installment.value}
    >
      <InstallmentItemText active={selectedInstallment === installment.value}>
        {installment.message}
      </InstallmentItemText>
    </InstallmentItem>
  );

  const availableInstallments = useMemo(() => {
    if (installmentOptions.length > 0) {
      return (
        <div
          style={{
            marginLeft: theme.getSpacing(1, 'px'),
            marginRight: theme.getSpacing(1, 'px'),
          }}
        >
          {installmentOptions.map(renderInstallment)}
        </div>
      );
    }

    return <span>{t('forms:noInstallmentsAvailable')}</span>;
  }, [installmentOptions, selectedInstallment]);

  return (
    <div css={installmentSelectWrapper}>
      <div css={modalTop}>
        <CircleButton
          icon={<KeyboardArrowDown />}
          size="30px"
          onClick={onClose}
          backgroundColor="primary"
          color="background"
          padding="3px"
        />
      </div>
      <span css={installmentListTitle}>{t('forms:selectInstallments')}</span>
      {installmentOptions.length > 0 ? (
        <div css={contentScroll}>{availableInstallments}</div>
      ) : (
        <span>{t('forms:noInstallmentsAvailable')}</span>
      )}
    </div>
  );
};

InstallmentSelect.defaultProps = {
  selectedInstallment: null,
};

InstallmentSelect.propTypes = {
  installmentOptions: PropTypes.array.isRequired,
  selectedInstallment: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default InstallmentSelect;
