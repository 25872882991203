import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'emotion-theming';
import { useHistory } from 'react-router-dom';
import Button from '@bit/eyemobile.core.button';

import ScrollableView from '../../components/ScrollableView';
import MenuIconButton from '../../components/MenuIconButton';
import Header from '../../components/Header';
import {
  mainForm,
  fieldPadding,
  formAlignment,
} from '../../components/Form/Form.styles';
import { ReactComponent as ArrowRight } from '../../assets/chevron_right.svg';
import { screenWrapper, screenInner } from '../Page.styles';

import { linkCss } from './UserBasicDataPage.styles';

const mapStateToProps = ({ tenantEyepass: { tutorialEnabled } }) => ({
  tutorialEnabled,
});

const UserBasicDataScreen = ({
  onLogout,
  onShowUserData,
  tenantContact,
  onOpenTosModal,
  onOpenLanguagesModal,
  onOpenContactModal,
  domain,
  tutorialEnabled,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const history = useHistory();

  const navigateTo = (path) => () => history.push(`/${domain}${path}`);

  const navigateToHome = () => history.push(`/${domain}/carrinho`);

  return (
    <div css={screenWrapper}>
      <Header title={t('navigation:myAccount')} onPrev={navigateToHome} />
      <ScrollableView hasHeader divCss={screenInner}>
        <div css={mainForm} style={{ paddingBottom: '20px' }}>
          <div css={formAlignment}>
            <div css={fieldPadding} style={{ maxWidth: '480px' }}>
              <div
                style={{
                  width: '100%',
                  marginBottom: theme.getSpacing(2, 'px'),
                }}
              >
                <MenuIconButton
                  onClick={onShowUserData}
                  label={t('navigation:myData')}
                  icon={<ArrowRight />}
                />
              </div>
              <div
                style={{
                  width: '100%',
                  marginBottom: theme.getSpacing(2, 'px'),
                }}
              >
                <MenuIconButton
                  onClick={navigateTo('/fidelidade')}
                  label={t('navigation:myLoyalties')}
                  icon={<ArrowRight />}
                />
              </div>
              <div
                style={{
                  width: '100%',
                  marginBottom: theme.getSpacing(2, 'px'),
                }}
              >
                <MenuIconButton
                  onClick={navigateTo('/enderecos')}
                  label={t('navigation:addressesList')}
                  icon={<ArrowRight />}
                />
              </div>
              <div
                style={{
                  width: '100%',
                  marginBottom: theme.getSpacing(4, 'px'),
                }}
              >
                <MenuIconButton
                  onClick={navigateTo('/cartoes')}
                  label={t('navigation:paymentMethods')}
                  icon={<ArrowRight />}
                />
              </div>
              {tutorialEnabled && (
                <div
                  style={{
                    width: '100%',
                    marginBottom: theme.getSpacing(2, 'px'),
                  }}
                >
                  <MenuIconButton
                    onClick={navigateTo('/app-wizard')}
                    label={t('navigation:appWizard')}
                    icon={<ArrowRight />}
                    textStyle={{ color: theme.getColor('placeholder') }}
                  />
                </div>
              )}
              <div
                style={{
                  width: '100%',
                  marginBottom: theme.getSpacing(2, 'px'),
                }}
              >
                <MenuIconButton
                  onClick={onOpenLanguagesModal}
                  label={t('languages:changeLanguage')}
                  icon={<ArrowRight />}
                  textStyle={{ color: theme.getColor('placeholder') }}
                />
              </div>
              <div
                style={{
                  width: '100%',
                  marginBottom: theme.getSpacing(4, 'px'),
                }}
              >
                <MenuIconButton
                  onClick={navigateTo('/alterar-senha')}
                  label={t('navigation:changePassword')}
                  icon={<ArrowRight />}
                  textStyle={{ color: theme.getColor('placeholder') }}
                />
              </div>
              <div
                style={{
                  width: '100%',
                  marginBottom: theme.getSpacing(2, 'px'),
                }}
              >
                <Button block onClick={onLogout} color="error">
                  {t('navigation:logout')}
                </Button>
              </div>
            </div>
            <div
              style={{
                width: '100%',
                marginTop: '20px',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              {!!tenantContact && (tenantContact.email || tenantContact.phone) && (
                <div
                  css={linkCss}
                  role="presentation"
                  onClick={onOpenContactModal}
                  onKeyDown={onOpenContactModal}
                >
                  {t('navigation:contactUs')}
                </div>
              )}
              <span
                css={linkCss}
                onClick={onOpenTosModal}
                onKeyDown={onOpenTosModal}
                role="presentation"
              >
                {t('forms:TOSShort')}
              </span>
            </div>
          </div>
        </div>
      </ScrollableView>
    </div>
  );
};

UserBasicDataScreen.defaultProps = {
  tenantContact: null,
  domain: null,
};

UserBasicDataScreen.propTypes = {
  onLogout: PropTypes.func.isRequired,
  onShowUserData: PropTypes.func.isRequired,
  tenantContact: PropTypes.object,
  onOpenContactModal: PropTypes.func.isRequired,
  onOpenLanguagesModal: PropTypes.func.isRequired,
  onOpenTosModal: PropTypes.func.isRequired,
  domain: PropTypes.string,
  tutorialEnabled: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(UserBasicDataScreen);
