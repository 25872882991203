export default class SessionStorageManager {
  static prefix = 'EYEPASS_SESSION';

  static getDomain() {
    const pathname = window.location?.pathname || '/';

    const domain = pathname.split('/')[1].toUpperCase();

    return domain;
  }

  static setOrderReviewData(orderReviewData) {
    sessionStorage.setItem(
      `${this.prefix}_ORDER_REVIEW_DATA`,
      JSON.stringify(orderReviewData),
    );
  }

  static removeOrderReviewData() {
    if (
      typeof sessionStorage[`${this.prefix}_ORDER_REVIEW_DATA`] !== 'undefined'
    ) {
      sessionStorage.removeItem(`${this.prefix}_ORDER_REVIEW_DATA`);
    }
  }

  static getOrderReviewData() {
    if (
      typeof sessionStorage[`${this.prefix}_ORDER_REVIEW_DATA`] !==
        'undefined' &&
      sessionStorage[`${this.prefix}_ORDER_REVIEW_DATA`] !== 'undefined'
    ) {
      return sessionStorage.getItem(`${this.prefix}_ORDER_REVIEW_DATA`);
    }
  }

  static setOrderAddress(orderAddress) {
    sessionStorage.setItem(
      `${this.prefix}_ORDER_ADDRESS`,
      JSON.stringify(orderAddress),
    );
  }

  static removeOrderAddress() {
    if (typeof sessionStorage[`${this.prefix}_ORDER_ADDRESS`] !== 'undefined') {
      sessionStorage.removeItem(`${this.prefix}_ORDER_ADDRESS`);
    }
  }

  static getOrderAddress() {
    if (
      typeof sessionStorage[`${this.prefix}_ORDER_ADDRESS`] !== 'undefined' &&
      sessionStorage[`${this.prefix}_ORDER_ADDRESS`] !== 'undefined'
    ) {
      return sessionStorage.getItem(`${this.prefix}_ORDER_ADDRESS`);
    }
  }

  static setOrderDeliverySchedule(orderAddress) {
    sessionStorage.setItem(
      `${this.prefix}_ORDER_SCHEDULE`,
      JSON.stringify(orderAddress),
    );
  }

  static removeOrderDeliverySchedule() {
    if (
      typeof sessionStorage[`${this.prefix}_ORDER_SCHEDULE`] !== 'undefined'
    ) {
      sessionStorage.removeItem(`${this.prefix}_ORDER_SCHEDULE`);
    }
  }

  static getOrderDeliverySchedule() {
    if (
      typeof sessionStorage[`${this.prefix}_ORDER_SCHEDULE`] !== 'undefined' &&
      sessionStorage[`${this.prefix}_ORDER_SCHEDULE`] !== 'undefined'
    ) {
      return sessionStorage.getItem(`${this.prefix}_ORDER_SCHEDULE`);
    }
  }

  static setRegisterToken(token) {
    sessionStorage.setItem(`${this.prefix}_REGISTER_TOKEN`, token);
  }

  static getRegisterToken() {
    if (
      typeof sessionStorage[`${this.prefix}_REGISTER_TOKEN`] !== 'undefined' &&
      sessionStorage[`${this.prefix}_REGISTER_TOKEN`] !== 'undefined'
    ) {
      return sessionStorage.getItem(`${this.prefix}_REGISTER_TOKEN`);
    }
  }

  static removeRegisterToken() {
    if (
      typeof sessionStorage[`${this.prefix}_REGISTER_TOKEN`] !== 'undefined'
    ) {
      sessionStorage.removeItem(`${this.prefix}_REGISTER_TOKEN`);
    }
  }

  static setDeliveryType(deliveryType) {
    sessionStorage.setItem(`${this.prefix}_DELIVERY_TYPE`, deliveryType);
  }

  static getDeliveryType() {
    if (
      typeof sessionStorage[`${this.prefix}_DELIVERY_TYPE`] !== 'undefined' &&
      sessionStorage[`${this.prefix}_DELIVERY_TYPE`] !== 'undefined'
    ) {
      return sessionStorage.getItem(`${this.prefix}_DELIVERY_TYPE`);
    }
  }

  static removeDeliveryType() {
    if (typeof sessionStorage[`${this.prefix}_DELIVERY_TYPE`] !== 'undefined') {
      sessionStorage.removeItem(`${this.prefix}_DELIVERY_TYPE`);
    }
  }

  static setTag(tag) {
    const domain = this.getDomain();
    sessionStorage.setItem(`${this.prefix}_${domain}_TAG`, JSON.stringify(tag));
  }

  static getTag() {
    const domain = this.getDomain();
    const key = `${this.prefix}_${domain}_TAG`;

    if (
      typeof sessionStorage[key] !== 'undefined' &&
      sessionStorage[key] !== 'undefined'
    ) {
      return JSON.parse(sessionStorage.getItem(key));
    }
  }

  static removeTag() {
    const domain = this.getDomain();
    const key = `${this.prefix}_${domain}_TAG`;

    if (typeof sessionStorage[key] !== 'undefined') {
      sessionStorage.removeItem(key);
    }
  }

  static clear() {
    sessionStorage.clear();
  }
}
