import styled from '@emotion/styled';

export const Wrapper = styled.div`
  color: ${({ iconColor, theme }) =>
    theme && theme.getColor(iconColor || 'primary')};

  svg {
    width: 100%;
    height: 100%;
    object-fit: 'contain';
  }
`;

export const iconDefaultStyle = {
  width: '100%',
  height: '100%',
  objectFit: 'contain',
};
