import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Input from '@bit/eyemobile.core.input';
import { useTheme } from 'emotion-theming';

import { ReactComponent as SearchIcon } from '../../assets/search.svg';
import { ReactComponent as CloseIcon } from '../../assets/close.svg';
import { shippingTypes } from '../../utils/constants';
import SmallIconButton from '../../components/SmallIconButton';
import { ReactComponent as ArrowDown } from '../../assets/keyboard_arrow_down.svg';
import localizePrice from '../../utils/localizePrice';
import timeTextFromMinutes from '../../utils/timeTextFromMinutes';

import {
  orderDeliveryWrapper,
  orderDeliveryItem,
  searchBar,
  openFiltersButton,
  openFiltersButtonText,
  selectedFiltersList,
  selectedFilterItem,
  selectedFilterItemName,
  extraDescriptions,
} from './OrderCartPage.styles';

const mapStateToProps = ({
  productOrder: {
    productOrder: { expected },
  },
  tenantEyepass: { qrReaderDisabled },
  tag,
  user,
}) => ({
  expectedDistance: expected.distance,
  expectedDuration: expected.duration,
  tag,
  qrReaderDisabled,
  user,
});

const OrderCartOptions = ({
  deliveryAddress,
  selectedStore,
  selectedShippingType,
  currentTag,
  onShippingTypeChange,
  onOrderStoreChange,
  disabled,
  onSearchTextChange,
  appliedFilters,
  onFiltersChange,
  onRemoveFilter,
  expectedDuration,
  expectedDistance,
  tag,
  qrReaderDisabled,
  user,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [displayText, setDisplayText] = useState('');

  const durationText =
    selectedShippingType === shippingTypes.ADDRESS && expectedDuration
      ? timeTextFromMinutes(expectedDuration)
      : '';
  const distanceText =
    expectedDistance > 0 ? `${Math.round(expectedDistance)} Km` : '';
  const deliveryDescripion =
    selectedStore?.deliveryPrice > 0
      ? `R$ ${localizePrice(selectedStore.deliveryPrice)}`
      : t('productOrder:freeDelivery');
  const shippingText =
    selectedShippingType === shippingTypes.ADDRESS ? deliveryDescripion : '';

  const deliveryShippingUnable = useMemo(() => {
    if (!selectedStore) {
      return false;
    }

    return (
      (selectedShippingType === shippingTypes.ADDRESS &&
        !selectedStore?.deliveryEnabled) ||
      (selectedShippingType === shippingTypes.TAKEOUT &&
        !selectedStore?.takeoutEnabled) ||
      (selectedShippingType === shippingTypes.TAG && !selectedStore?.tagEnabled)
    );
  }, [selectedShippingType, selectedStore]);

  const shippingTypeText = useMemo(() => {
    if (selectedShippingType === shippingTypes.ADDRESS) {
      return t('productOrder:houseDelivery');
    }

    if (selectedShippingType === shippingTypes.TAG) {
      if (currentTag && currentTag.startsWith('null')) {
        const remainingTag = currentTag.split('null')[1];
        return `${t('orderReview:deliverTo')} ${t(
          'productOrder:receipt',
        )} ${remainingTag}`;
      }

      return `${t('orderReview:deliverTo')} ${currentTag}`;
    }

    if (selectedShippingType === shippingTypes.TAKEOUT) {
      return t('productOrder:pickupAt');
    }

    return t('productOrder:selectShippingType');
  }, [selectedShippingType, selectedStore, currentTag, deliveryAddress, t]);

  const renderFilter = (filter) => (
    <div
      css={selectedFilterItem}
      key={filter.menuProductGroupId}
      role="presentation"
      onClick={() => onRemoveFilter(filter)}
      onKeyDown={() => onRemoveFilter(filter)}
    >
      <span css={selectedFilterItemName}>{filter.name}</span>
      <CloseIcon
        color={theme.getColor('background')}
        style={{ width: '20px', height: '20px', marginLeft: '4px' }}
      />
    </div>
  );

  const storeText = useMemo(() => {
    if (selectedStore?.name) {
      return selectedStore.name;
    }

    return t('productOrder:selectStore');
  }, [selectedStore]);

  const topPosition = useMemo(() => {
    const headerHeight = qrReaderDisabled && !user?.id ? -50 : 0;

    const freeDeliveryHeight = selectedStore?.totalToFreeDelivery ? 81 : 50;

    return `${headerHeight + freeDeliveryHeight}px`;
  }, [selectedStore?.totalToFreeDelivery, qrReaderDisabled]);

  const handleSearchTextChange = (value) => {
    onSearchTextChange(value);
  };

  const handleDisplayTextChange = (e) => {
    const { value } = e.target;
    setDisplayText(value);
    setTimeout(() => {
      handleSearchTextChange(value);
    }, 500);
  };

  return (
    <div css={orderDeliveryWrapper}>
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          zIndex: 1001,
          position: 'fixed',
          top: topPosition,
          background: '#FFFFFF',
        }}
      >
        <div
          css={orderDeliveryItem}
          style={{
            mar: '5px',
            justifyContent: 'flex-start',
            width: '50%',
          }}
        >
          <SmallIconButton
            onClick={onShippingTypeChange}
            label={shippingTypeText}
            icon={<ArrowDown />}
            disabled={disabled}
          />
          <span css={extraDescriptions}>{shippingText}</span>
          {deliveryShippingUnable && (
            <span
              css={extraDescriptions}
              style={{ color: theme.getColor('error') }}
            >
              {t('errors:invalidShippingType')}
            </span>
          )}
        </div>
        <div
          css={orderDeliveryItem}
          style={{ width: '50%', alignItems: 'flex-end' }}
        >
          <SmallIconButton
            onClick={onOrderStoreChange}
            label={storeText}
            icon={<ArrowDown />}
            disabled={!onOrderStoreChange}
          />
          <span css={extraDescriptions}>{durationText}</span>
          <span css={extraDescriptions}>{distanceText}</span>
        </div>
      </div>
      <div css={searchBar}>
        <Input
          placeholder={t('productSearch:searchByName')}
          value={displayText}
          onChange={handleDisplayTextChange}
          variant="bordered"
          color="primary"
          inputIcon={<SearchIcon />}
          inputStyle={{ borderColor: '#B4B4B4', fontSize: '16px' }}
        />
        {!!onFiltersChange && (
          <div
            onClick={onFiltersChange}
            onKeyDown={onFiltersChange}
            role="presentation"
            css={openFiltersButton}
          >
            <span css={openFiltersButtonText}>{t('productSearch:filter')}</span>
          </div>
        )}
      </div>
      <div css={selectedFiltersList}>{appliedFilters.map(renderFilter)}</div>
    </div>
  );
};

OrderCartOptions.defaultProps = {
  currentTag: '',
  selectedShippingType: null,
  selectedStore: null,
  disabled: false,
  onFiltersChange: null,
  deliveryAddress: null,
};

OrderCartOptions.propTypes = {
  deliveryAddress: PropTypes.object,
  selectedStore: PropTypes.object,
  selectedShippingType: PropTypes.string,
  currentTag: PropTypes.string,
  onShippingTypeChange: PropTypes.func.isRequired,
  onOrderStoreChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  onSearchTextChange: PropTypes.func.isRequired,
  appliedFilters: PropTypes.array.isRequired,
  onFiltersChange: PropTypes.func,
  onRemoveFilter: PropTypes.func.isRequired,
  expectedDuration: PropTypes.number.isRequired,
  expectedDistance: PropTypes.number.isRequired,
  tag: PropTypes.object.isRequired,
  qrReaderDisabled: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(OrderCartOptions);
