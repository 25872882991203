const manifestBuilder = (
  domain,
  name,
  shortName,
  description,
  color,
  favIconSrc,
  logo192Src,
  logo512Src,
) => ({
  short_name: shortName || name,
  name,
  description: description || '',
  icons: [
    {
      src: favIconSrc || 'favicon.ico',
      sizes: '16x16',
      type: 'image/x-icon',
    },
    {
      src: logo192Src || 'logo192.png',
      type: 'image/png',
      sizes: '192x192',
    },
    {
      src: logo512Src || 'logo512.png',
      type: 'image/png',
      sizes: '512x512',
    },
  ],
  start_url: `${window.location.origin}/${domain}`,
  display: 'standalone',
  theme_color: color,
  background_color: color,
});

export default manifestBuilder;
