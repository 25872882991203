import { css } from '@emotion/core';

export const backgroundCss = (theme) => css`
  width: 100%;
  height: 100%;
  background-color: #7d1dd1;
`;

export const wrapperCss = (theme) => css`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: ${theme.getSpacing(4, 'px')};
  padding-right: ${theme.getSpacing(4, 'px')};
  padding-top: ${theme.getSpacing(6, 'px')};
  padding-bottom: ${theme.getSpacing(6, 'px')};
`;

export const roundBoxCss = css`
  width: 310px;
  min-height: 200px;
  padding: 16px;
  border: 1px solid rgb(0, 0, 0);
  background-color: #ffffff;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const titleTextCss = (theme) => css`
  font-size: ${theme.getFontSize(1.2, 'px')};
  font-family: ${theme.getFont('futura')};
  color: #000000;
  margin-bottom: ${theme.getSpacing(2, 'px')};
`;

export const commonTextCss = (theme) => css`
  font-size: ${theme.getFontSize(1, 'px')};
  font-family: ${theme.getFont('futura')};
  color: #000000;
`;

export const logoCss = (theme) => css`
  width: 140px;
  height: 140px;
  object-fit: contain;
  margin: ${theme.getSpacing(1, 'px')};
`;

export const contactContainer = (theme) => css`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: ${theme.getSpacing(1, 'px')};
`;
