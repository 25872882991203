import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import LoadingScreen from '../../components/LoadingScreen';
import Error from '../../components/Error';
import { stripMask } from '../../utils/adaptField';
import clean from '../../utils/clean';
import gateway from '../../utils/gateway';
import EcommerceService from '../../services/ecommerce';
import { TransactionError } from '../../utils/errors';
import dayjs from '../../utils/dayjs';
import OrderPixScreen from '../OrderPaymentStatusPage/OrderPixScreen';

import PaymentLinkScreen from './PaymentLinkScreen';
import PaymentLinkFailScreen from './PaymentLinkFailScreen';
import PaymentLinkSuccessScreen from './PaymentLinkSuccessScreen';
import PaymentLinkLoadingScreen from './PaymentLinkLoadingScreen';

const mapStateToProps = null;

const mapDispatchToProps = null;

const PaymentLinkContainer = ({ match }) => {
  const { uuid } = match.params;
  const { t } = useTranslation();

  const [isCheckingToken, setIsCheckingToken] = useState(true);
  const [error, setError] = useState(false);
  const [transaction, setTransaction] = useState(null);
  const [pixQRcode, setPixQRcode] = useState(null);
  const [retryTransaction, setRetryTransaction] = useState(false);

  const handleTransactionRetry = () => {
    setError(false);
    setRetryTransaction(false);
  };

  const fetchPaymentLink = async () => {
    try {
      const response = await gateway
        .get(EcommerceService.payments(uuid))
        .then(({ data }) => data);

      if (!response.transaction) {
        setError(t('paymentLink:invalidLink'));
      } else {
        setTransaction(response.transaction);
        if (response.extraData.pix_qrcode) {
          setPixQRcode({
            pixQrCode: response.extraData.pix_qrcode,
            expirationDate: response.extraData.pix_expiration_date,
          });
        }
      }
    } catch (e) {
      if (e.response) {
        if (e.response.data && e.response.data.message) {
          setError(e.response.data.message);
        } else {
          setError(
            t('paymentLink:requestFailedWithStatus', {
              status: e.response.status,
            }),
          );
        }
      } else {
        setError(t('paymentLink:requestFailed'));
      }
    }
    setIsCheckingToken(false);
  };

  const handleGetPaymentLink = async () => {
    try {
      const response = await gateway
        .get(EcommerceService.payments(uuid))
        .then(({ data }) => data);

      if (!response.transaction) {
        throw new TransactionError(t('paymentLink:invalidLink'));
      }

      const {
        transaction_pays: transactionPays,
        cancelled,
        completed,
      } = response.transaction;

      if (transactionPays.length > 0) {
        const { tef_transaction: tefTransaction } = transactionPays[0];
        const parsedTefTransaction = tefTransaction
          ? JSON.parse(tefTransaction)
          : undefined;
        if (
          !cancelled &&
          !completed &&
          parsedTefTransaction &&
          parsedTefTransaction.status === 4
        ) {
          setRetryTransaction(true);
          throw new TransactionError(t('paymentLink:failedTransaction'));
        }
      }

      setTransaction(response.transaction);
      setIsCheckingToken(false);
    } catch (e) {
      if (e.response) {
        if (e.response.data && e.response.data.message) {
          setError(e.response.data.message);
        } else {
          setError(
            t('paymentLink:requestFailedWithStatus', {
              status: e.response.status,
            }),
          );
        }
      } else if (e instanceof TransactionError) {
        setError(e.message);
      } else {
        setError(t('paymentLink:requestFailed'));
      }
      setIsCheckingToken(false);
    }
  };

  const handleFormSubmit = async (formData, formikBag) => {
    const {
      document,
      email,
      creditCardNumber,
      creditCardHolderName,
      creditCardExpiration,
      creditCardCvv,
      payLinkType,
      installments,
    } = formData;

    try {
      formikBag.setSubmitting(true);

      const fullFormData = clean({
        document: stripMask(document),
        email,
        creditCardNumber: stripMask(creditCardNumber),
        creditCardHolderName,
        creditCardExpiration: dayjs(creditCardExpiration, 'MM/YYYY').format(
          'MMYY',
        ),
        creditCardCvv: stripMask(creditCardCvv),
        type: payLinkType,
        installments: Number(installments),
      });

      const payload = {
        payment: {
          card_number: fullFormData.creditCardNumber,
          card_holder_name: fullFormData.creditCardHolderName,
          card_expiration_date: fullFormData.creditCardExpiration,
          card_cvv: fullFormData.creditCardCvv,
          type: fullFormData.type,
          installments: fullFormData.installments,
        },
        customer: {
          document: fullFormData.document,
          email: fullFormData.email,
        },
      };

      const response = await gateway
        .post(EcommerceService.payments(uuid), payload)
        .then(({ data }) => data);

      if (!response.transaction) {
        throw new TransactionError(t('paymentLink:invalidLink'));
      }

      const {
        transaction_pays: transactionPays,
        cancelled,
        completed,
      } = response.transaction;

      if (transactionPays.length > 0) {
        const { tef_transaction: tefTransaction } = transactionPays[0];
        const parsedTefTransaction = tefTransaction
          ? JSON.parse(tefTransaction)
          : undefined;
        if (
          !cancelled &&
          !completed &&
          parsedTefTransaction &&
          parsedTefTransaction.status === 4
        ) {
          setRetryTransaction(true);
          throw new TransactionError(t('paymentLink:failedTransaction'));
        }
      }

      if (response.extraData.pix_qrcode) {
        setPixQRcode({
          pixQrCode: response.extraData.pix_qrcode,
          expirationDate: response.extraData.pix_expiration_date,
        });
      }

      setTransaction(response.transaction);
    } catch (e) {
      if (e.response && e.response.data && e.response.data.message) {
        setError(e.response.data.message);
      } else if (e instanceof TransactionError) {
        setError(e.message);
      } else {
        setError(t('paymentLink:failedTransaction'));
      }
    } finally {
      formikBag.setSubmitting(false);
    }
  };

  const handlePixRefresh = async () => {
    try {
      const response = await gateway
        .get(EcommerceService.payments(uuid))
        .then(({ data }) => data);

      setTransaction(response.transaction);
    } catch (err) {
      console.error();
      setError(err?.response?.data?.message || err.message);
    }
  };

  useEffect(() => {
    if (uuid) {
      fetchPaymentLink();
    } else {
      setIsCheckingToken(false);
      setError(t('paymentLink:invalidLink'));
    }
  }, []);

  if (isCheckingToken) {
    return <LoadingScreen />;
  }

  if (error) {
    return (
      <Error
        onReturn={retryTransaction && handleTransactionRetry}
        buttonText={t('navigation:tryAgain')}
        errorMessage={error}
      />
    );
  }

  if (transaction.cancelled) {
    return <PaymentLinkFailScreen message="paymentLink:cancelledTransaction" />;
  }

  if (transaction.completed) {
    return <PaymentLinkSuccessScreen />;
  }

  if (pixQRcode) {
    const pixExpirationDate = dayjs(pixQRcode.expirationDate).format(
      'DD/MM, HH:mm',
    );

    return (
      <OrderPixScreen
        pixKey={pixQRcode.pixQrCode}
        transactionValue={transaction.total}
        pixExpirationDate={pixExpirationDate}
        onRefresh={handlePixRefresh}
      />
    );
  }

  if (transaction.in_transaction) {
    return <PaymentLinkLoadingScreen onRefresh={handleGetPaymentLink} />;
  }

  return (
    <PaymentLinkScreen
      transaction={transaction}
      initialValues={{
        document: transaction.customer_doc || '',
        email: transaction.customer_email || '',
      }}
      onFormSubmit={handleFormSubmit}
      onRefresh={handleGetPaymentLink}
    />
  );
};

PaymentLinkContainer.propTypes = {
  match: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PaymentLinkContainer);
