import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';

import { ButtonWrapper, ButtonText } from './SmallIconButton.styles';

const SmallIconButton = ({ onClick, label, disabled, icon }) => {
  const theme = useTheme();

  const styledIcon = useMemo(
    () =>
      icon
        ? React.cloneElement(icon, {
            style: {
              width: '100%',
              height: '100%',
              objectFit: 'contain',
              color: theme.getColor('text'),
            },
          })
        : false,
    [theme, icon],
  );

  return (
    <ButtonWrapper
      onClick={onClick}
      onKeyDown={onClick}
      role="presentation"
      disabled={disabled}
    >
      <ButtonText>{label}</ButtonText>
      {styledIcon && (
        <div
          style={{
            width: '21px',
            height: '21px',
            marginLeft: theme.getSpacing(1, 'px'),
          }}
        >
          {styledIcon}
        </div>
      )}
    </ButtonWrapper>
  );
};

SmallIconButton.defaultProps = {
  label: '',
  disabled: false,
  icon: null,
};

SmallIconButton.propTypes = {
  icon: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.node,
    PropTypes.element,
  ]),
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string,
  disabled: PropTypes.bool,
};

export default SmallIconButton;
