import { shippingTypes } from './constants';

const getInitialShippingType = (
  shippingType,
  currentTag,
  selectedStore,
  isTagOnlyDelivery,
  deliveryDisabled,
  paymentOnDeliveryOnly,
) => {
  const { takeoutEnabled, tagEnabled, deliveryEnabled } = selectedStore;

  if (paymentOnDeliveryOnly) {
    return shippingTypes.TAKEOUT;
  }

  if (
    (takeoutEnabled &&
      !isTagOnlyDelivery &&
      shippingType === shippingTypes.TAKEOUT) ||
    (tagEnabled && shippingType === shippingTypes.TAG) ||
    (deliveryEnabled &&
      !deliveryDisabled &&
      !isTagOnlyDelivery &&
      shippingType === shippingTypes.ADDRESS)
  ) {
    return shippingType || shippingTypes.ADDRESS;
  }

  if ((!!currentTag || isTagOnlyDelivery) && tagEnabled) {
    return shippingTypes.TAG;
  }

  if (deliveryEnabled && !deliveryDisabled) {
    return shippingTypes.ADDRESS;
  }

  if (takeoutEnabled) {
    return shippingTypes.TAKEOUT;
  }

  return '';
};

export default getInitialShippingType;
