import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useTranslation } from 'react-i18next';

import * as productOrderActions from '../../store/modules/productOrder';
import { Creators as tagActionCreators } from '../../store/modules/tag';
import { Creators as UserCreators } from '../../store/modules/user';
import ecommerceService from '../../services/ecommerce';
import LoyaltyService from '../../services/loyalty';
import {
  paymentTypes,
  shippingTypes,
  analyticsEventActions,
  analyticsEventCategory,
} from '../../utils/constants';
import Analytics from '../../utils/Analytics';
import gateway from '../../utils/gateway';
import showToast from '../../utils/showToast';
import mapCreditCard from '../../utils/mapCreditCard';
import { TransactionError } from '../../utils/errors';
import buildProductOrder from '../../utils/buildProductOrder';
import mapCashbackLoyalty from '../../utils/mapCashbackLoyalty';
import useModalController from '../../utils/useModalController';
import SessionStorageManager from '../../utils/SessionStorageManager';
import addressValidation from '../../utils/addressValidation';
import LoadingScreen from '../../components/LoadingScreen';
import ProductAdjustModal from '../../components/ProductAdjustModal';
import ComboAdjustModal from '../../components/ComboAdjustModal';
import DeliveryAddressModal from '../../components/DeliveryAddressModal';
import ClearCartModal from '../OrderCartPage/ClearCartModal';

import DeliveryScheduleModal from './DeliveryScheduleModal';
import OrderTagChangeModal from './OrderTagChangeModal';
import ScheduleClearModal from './ScheduleClearModal';
import OrderReviewScreen from './OrderReviewScreen';

const mapStateToProps = ({
  productOrder: {
    productOrder: {
      products,
      promotionalProducts,
      store,
      shippingType,
      deliveryAddress,
    },
  },
  tag: {
    tag,
    isTagLocked,
    isTagOnlyDelivery,
    allowAnonymous,
    accountId,
    checkoutId,
    isPostPaid,
    requiredFields,
  },
  tenantEyepass: { domain, tenantId },
  user,
}) => ({
  savedProducts: products,
  savedPromotionalProducts: promotionalProducts,
  selectedStore: store,
  selectedShippingType: shippingType,
  deliveryAddress,
  currentTag: tag,
  isTagLocked,
  isTagOnlyDelivery,
  user,
  domain,
  allowAnonymous,
  tenantId,
  accountId,
  checkoutId,
  isPostPaid,
  requiredFields,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      alterProductInOrder: productOrderActions.alterProductInOrder,
      removeProductInOrder: productOrderActions.removeProductInOrder,
      removePromotionalProductInOrder:
        productOrderActions.removePromotionalProductInOrder,
      clearOrder: productOrderActions.clearOrder,
      setDeliveryAddress: productOrderActions.setDeliveryAddress,
      setExpectedDistanceDuration:
        productOrderActions.setExpectedDistanceDuration,
      setTagName: tagActionCreators.setTagName,
      removeTag: tagActionCreators.removeTag,
      setUser: UserCreators.setUser,
    },
    dispatch,
  );

const getTotalPriceFromProducts = (products) =>
  products.reduce(
    (productAcc, { price, amount, measure, complementsTotal }) => {
      if (measure === 'UNIT') {
        const adaptedComplementsTotal = complementsTotal || 0;
        return productAcc + (price + adaptedComplementsTotal) * amount;
      }

      return productAcc + (price * amount * 1000 * 0.1) / 1000;
    },
    0,
  );

const OrderReviewContainer = ({
  history,
  savedProducts,
  savedPromotionalProducts,
  selectedStore,
  alterProductInOrder,
  removeProductInOrder,
  removePromotionalProductInOrder,
  user,
  currentTag,
  isTagLocked,
  setTagName,
  isTagOnlyDelivery,
  clearOrder,
  selectedShippingType,
  deliveryAddress,
  setDeliveryAddress,
  setExpectedDistanceDuration,
  domain,
  allowAnonymous,
  tenantId,
  accountId,
  checkoutId,
  isPostPaid,
  requiredFields,
}) => {
  const { t } = useTranslation();

  const [isFetchingLoyalties, setIsFetchingLoyalties] = useState(false);
  const [currentProduct, setCurrentProduct] = useState(null);
  const [currentCombo, setCurrentCombo] = useState(null);
  const [loyaltyCashback, setLoyaltyCashback] = useState(null);
  const captchaRef = useRef(null);
  const [modals, { toggleModal, closeModals }] = useModalController([
    'addressChangeModal',
    'productAdjustModal',
    'tagFormModal',
    'clearCartModal',
    'orderScheduleModal',
    'clearScheduleModal',
  ]);

  const totalPrice = getTotalPriceFromProducts(
    savedProducts.concat(savedPromotionalProducts),
  );

  const ignoreRigidStock = Boolean(selectedStore?.ignoreRigidStock);

  useEffect(() => {
    if (!selectedStore) {
      history.push(`/${domain}/carrinho`);
    }

    if (selectedShippingType === 'ADDRESS' && !deliveryAddress) {
      history.push(`/${domain}/carrinho`);
    }
  }, []);

  const handleSideMenuClick = () => history.push(`/${domain}/carrinho`);

  const handlePostOrder = async (productOrder) => {
    try {
      const token = captchaRef?.current?.getValue();
      if (!token) {
        throw new TransactionError(t('paymentLink:needRecaptchaToken'));
      }
      const productOrderData = { ...productOrder, token };
      const response = await gateway
        .post(ecommerceService.finishOrder(), productOrderData)
        .then(({ data }) => data);

      if (response.transaction) {
        Analytics.event({
          category: analyticsEventCategory.ORDER,
          action: analyticsEventActions.FINISH_ORDER,
          label: 'Pedido finalizado',
          value: totalPrice,
        });
        SessionStorageManager.setDeliveryType(productOrder.shipping.type);
        history.push(`/${domain}/pedidos/${response.transaction.id}`);
      } else {
        throw new TransactionError(t('paymentLink:invalidLink'));
      }
    } catch (error) {
      const responseMessage = error?.response?.data?.message;
      showToast(responseMessage || error.message, 'error');
    }
  };

  const handlePostAnonymousOrder = async (postPaidProductOrder) => {
    try {
      const token = captchaRef?.current?.getValue();
      if (!token) {
        throw new TransactionError(t('paymentLink:needRecaptchaToken'));
      }
      const postPaidProductOrderData = { ...postPaidProductOrder, token };
      const response = await gateway
        .post(ecommerceService.finishPostPaidOrder(), postPaidProductOrderData)
        .then(({ data }) => data);

      if (response.order[0]?.id) {
        Analytics.event({
          category: analyticsEventCategory.ORDER,
          action: analyticsEventActions.FINISH_ORDER,
          label: 'Pedido finalizado',
          value: totalPrice,
        });
        history.push(`/${domain}/pedidos/${response.order[0].id}`);
      } else {
        throw new TransactionError(t('paymentLink:invalidLink'));
      }
    } catch (error) {
      const responseMessage = error?.response?.data?.message;

      showToast(responseMessage || error.message, 'error');
    }
  };

  const getCurrentOrderDeliverySchedule = () => {
    const sessionsDeliverySchedule =
      SessionStorageManager.getOrderDeliverySchedule();

    return sessionsDeliverySchedule
      ? JSON.parse(sessionsDeliverySchedule)
      : null;
  };

  const getCashBackLoyalty = async () => {
    try {
      setIsFetchingLoyalties(true);

      const response = await gateway
        .get(LoyaltyService.getCashbackLoyalty())
        .then(({ data }) => data);

      if (response.loyalties.length === 1) {
        const cashbackLoyalty = mapCashbackLoyalty(response.loyalties[0]);

        setLoyaltyCashback(cashbackLoyalty);
      }
    } catch (error) {
      showToast(error.message);
    } finally {
      setIsFetchingLoyalties(false);
    }
  };

  useEffect(() => {
    if (selectedStore && !allowAnonymous) {
      getCashBackLoyalty();
    }

    SessionStorageManager.setOrderAddress(deliveryAddress);
  }, []);

  const handlePaymentExecution = async (values, formikBag) => {
    try {
      const {
        creditCardOption,
        shippingType,
        useCashback,
        paymentType,
        voucher,
        installments,
        comment,
        name,
        phone,
      } = values;

      const sessionOrderSchedule = getCurrentOrderDeliverySchedule();

      if (shippingType === shippingTypes.ADDRESS && !allowAnonymous) {
        const primaryAddress =
          !!deliveryAddress.latitude && !!deliveryAddress.longitude
            ? {
                latitude: deliveryAddress.latitude,
                longitude: deliveryAddress.longitude,
              }
            : {
                zipcode: deliveryAddress.zipcode,
                street: deliveryAddress.address,
                complement: deliveryAddress.address2,
                number: deliveryAddress.number,
                district: deliveryAddress.district,
                city: deliveryAddress.city,
                state: deliveryAddress.state,
              };

        await handleAddressValidation(primaryAddress);
      }

      let shipping = {
        type: shippingType,
        address: deliveryAddress || null,
        tag: currentTag || '',
      };

      if (deliveryAddress && shippingType === shippingTypes.ADDRESS) {
        shipping = { ...shipping, address_id: deliveryAddress.id };
      }

      let paymentDeliveryPrice = 0;

      let isFreeDelivery = totalPrice >= selectedStore?.totalToFreeDelivery;

      if (selectedStore?.totalToFreeDelivery === 0) {
        isFreeDelivery = false;
      }

      if (shippingType === shippingTypes.ADDRESS && !isFreeDelivery) {
        paymentDeliveryPrice = selectedStore.deliveryPrice;
      }

      const transactionTotal =
        getTotalPriceFromProducts(
          savedProducts.concat(savedPromotionalProducts),
        ) + paymentDeliveryPrice;

      let payment = {
        total: transactionTotal,
        type: paymentType,
        installments,
      };

      const cashback = {
        total: transactionTotal,
        useCashback,
        loyaltyCashback,
      };

      const giftCard = voucher || null;

      if (paymentType === paymentTypes.CREDIT_CARD_ONLINE) {
        payment = {
          ...payment,
          cardId: creditCardOption,
        };
      }

      if (paymentType === paymentTypes.CASH) {
        payment = { ...payment, change: values.change };
      }

      const productOrder = buildProductOrder(
        selectedStore,
        savedProducts,
        savedPromotionalProducts,
        payment,
        cashback,
        shipping,
        sessionOrderSchedule,
        giftCard,
        comment,
      );

      let customer = {};

      if (name.length) {
        customer = {
          ...customer,
          name,
        };
      }

      if (phone.length) {
        customer = {
          ...customer,
          phone,
        };
      }

      const postPaidProductOrder = {
        accountId,
        checkoutId,
        eventPointId: productOrder.event_point_id,
        tenantId: tenantId.toString(),
        products: productOrder.products,
        customer,
        comment,
      };

      if (isPostPaid) {
        await handlePostAnonymousOrder(postPaidProductOrder);
      } else {
        await handlePostOrder(productOrder);
      }

      formikBag.setSubmitting(false);
    } catch (e) {
      console.error(e);
      formikBag.setSubmitting(false);
    }
  };

  const handleProductAdjust = (product) => {
    Analytics.modalview(
      'Ajuste de produto do carrinho',
      '/pedido/ajuste-produto',
    );
    toggleModal('productAdjustModal');

    if (product.comboProductItems.length > 0) {
      setCurrentCombo(product);
    } else {
      setCurrentProduct(product);
    }
  };

  const handleProductAdjustFinish = (newProduct) => {
    closeModals();
    setCurrentProduct(null);
    const { amount, measure, complementsTotal, price } = newProduct;
    Analytics.event({
      category: analyticsEventCategory.ORDER,
      action: analyticsEventActions.EDIT_PRODUCT,
      label: newProduct.name,
      value:
        measure !== 'UNIT'
          ? (amount * price) / 10
          : (price + complementsTotal) * amount,
    });
    alterProductInOrder({ product: newProduct });
  };

  const handleComboAdjustFinish = (newCombo) => {
    closeModals();
    setCurrentProduct(null);
    const { amount, price } = newCombo;
    Analytics.event({
      category: analyticsEventCategory.ORDER,
      action: analyticsEventActions.EDIT_PRODUCT,
      label: newCombo.name,
      value: amount * price,
    });
    alterProductInOrder({ product: newCombo });
  };

  const handleRemoveProduct = (product) => {
    closeModals();
    setCurrentProduct(null);
    setCurrentCombo(null);
    if (product.source !== 'menu') {
      removePromotionalProductInOrder({
        productOrderId: product.productOrderId,
      });
    } else {
      removeProductInOrder({ productOrderId: product.productOrderId });
    }
  };

  const handleCloseProductAdjustModal = () => {
    closeModals();
    setCurrentProduct(null);
    setCurrentCombo(null);
  };

  const handleBackupForm = (values) =>
    SessionStorageManager.setOrderReviewData(values);

  const handleOpenAddressChangeModal = () => {
    Analytics.modalview('Troca de endereço de entrega', '/pedido/endereco');
    toggleModal('addressChangeModal');
  };

  const handleAddressValidation = async (newAddress) => {
    try {
      await addressValidation(selectedStore.id, newAddress, t);
    } catch (error) {
      showToast(error?.response?.data?.message || error.message, 'error');
      return Promise.reject(error);
    }
  };

  const handleOpenTagChangeModal = () => toggleModal('tagFormModal');

  const handleEmptyCart = () => {
    closeModals();
    clearOrder();
    Analytics.event({
      category: analyticsEventCategory.ORDER,
      action: analyticsEventActions.CLEAR_PRODUCTS,
      label: 'Limpeza de produtos do carrinho',
    });
  };

  const handleClearCartModalOpen = () => toggleModal('clearCartModal');

  const handleOpenOrderScheduleModal = () => {
    Analytics.modalview('Agendar pedido', '/pedido/agendamento');
    toggleModal('orderScheduleModal');
  };

  const handleCloseOrderScheduleModal = () => closeModals();

  const handleOpenScheduleModal = () => toggleModal('clearScheduleModal');

  const handleClearSchedule = () => {
    SessionStorageManager.removeOrderDeliverySchedule();
    closeModals();
  };

  const handleOrderScheduleModalConfirm = (newSchudule) => {
    SessionStorageManager.setOrderDeliverySchedule(newSchudule);
    Analytics.event({
      category: analyticsEventCategory.ORDER,
      action: analyticsEventActions.SCHEDULE_ORDER,
      label: 'Pedido Agendado',
    });
    closeModals();
  };

  const handleTagChangeSubmit = (newTagValues, formikBag) => {
    setTagName({ tag: newTagValues.tag });
    closeModals();
  };

  const handleCurrentAddressChange = (newAddress) => {
    SessionStorageManager.setOrderAddress(newAddress);

    setDeliveryAddress({ deliveryAddress: newAddress });
    setExpectedDistanceDuration({
      expected: {
        duration: newAddress.duration,
        distance: newAddress.distance,
      },
    });
    closeModals();
  };

  const getFormInitialValues = () => {
    const sessionInitialValues = SessionStorageManager.getOrderReviewData();

    if (sessionInitialValues) {
      return {
        ...JSON.parse(sessionInitialValues),
        shippingType: selectedShippingType,
      };
    }

    return {
      shippingType: selectedShippingType,
    };
  };

  if (!selectedStore) {
    return <LoadingScreen />;
  }

  const formInitialValues = getFormInitialValues();

  const currentDeliverySchedule = getCurrentOrderDeliverySchedule();

  const availableCreditCards = ((user && user.customer_cards) || []).map(
    mapCreditCard,
  );

  return (
    <>
      <DeliveryAddressModal
        visible={modals.addressChangeModal}
        onClose={closeModals}
        initialAddress={deliveryAddress}
        storeId={selectedStore?.id}
        addressesList={(user && user.customer_addresses) || []}
        onFinish={handleCurrentAddressChange}
      />
      <ProductAdjustModal
        visible={modals.productAdjustModal}
        product={currentProduct}
        onCloseModal={handleCloseProductAdjustModal}
        onAddToCart={handleProductAdjustFinish}
        onRemove={handleRemoveProduct}
        ignoreRigidStock={ignoreRigidStock}
      />
      <ComboAdjustModal
        visible={modals.productAdjustModal}
        productCombo={currentCombo}
        onCloseModal={handleCloseProductAdjustModal}
        onAddToCart={handleComboAdjustFinish}
        onRemove={handleRemoveProduct}
      />
      <OrderTagChangeModal
        visible={modals.tagFormModal}
        initialTagValue={currentTag}
        onFormSubmit={handleTagChangeSubmit}
        onCloseModal={closeModals}
      />
      <ClearCartModal
        visible={modals.clearCartModal}
        onCloseModal={closeModals}
        onConfirm={handleEmptyCart}
      />
      <ScheduleClearModal
        visible={modals.clearScheduleModal}
        onCloseModal={closeModals}
        onConfirm={handleClearSchedule}
      />
      <DeliveryScheduleModal
        visible={modals.orderScheduleModal}
        onCloseModal={handleCloseOrderScheduleModal}
        onScheduleDayConfirm={handleOrderScheduleModalConfirm}
        storeSchedule={selectedStore.schedule}
        eventPointId={selectedStore.id}
        initialSelectedSchedule={currentDeliverySchedule}
      />
      <OrderReviewScreen
        selectedStore={selectedStore}
        onSidemenuClick={handleSideMenuClick}
        onFormSave={handleBackupForm}
        orderProducts={savedPromotionalProducts.concat(savedProducts)}
        totalPrice={totalPrice}
        initialValues={formInitialValues}
        onAddressChange={handleOpenAddressChangeModal}
        currentTag={currentTag}
        currentAddress={deliveryAddress}
        onTagChange={handleOpenTagChangeModal}
        onOrderSchedule={handleOpenOrderScheduleModal}
        onFormSubmit={handlePaymentExecution}
        onProductAdjust={handleProductAdjust}
        onProductRemove={handleRemoveProduct}
        availableCreditCards={availableCreditCards}
        isTagLocked={isTagLocked}
        deliveryPrice={selectedStore.deliveryPrice}
        isFetchingLoyalties={isFetchingLoyalties}
        isTagOnlyDelivery={isTagOnlyDelivery}
        loyaltyCashback={loyaltyCashback}
        onEmptyCart={handleClearCartModalOpen}
        storeSchedule={selectedStore.schedule}
        deliverySchedule={currentDeliverySchedule}
        onClearSchedule={handleOpenScheduleModal}
        allowAnonymous={allowAnonymous}
        isPostPaid={isPostPaid}
        requiredFields={requiredFields}
        captchaRef={captchaRef}
      />
    </>
  );
};

OrderReviewContainer.defaultProps = {
  selectedStore: null,
  isTagOnlyDelivery: false,
  selectedShippingType: null,
  deliveryAddress: null,
  domain: null,
};

OrderReviewContainer.propTypes = {
  history: PropTypes.object.isRequired,
  savedProducts: PropTypes.array.isRequired,
  savedPromotionalProducts: PropTypes.array.isRequired,
  selectedStore: PropTypes.object,
  user: PropTypes.object.isRequired,
  alterProductInOrder: PropTypes.func.isRequired,
  removeProductInOrder: PropTypes.func.isRequired,
  removePromotionalProductInOrder: PropTypes.func.isRequired,
  currentTag: PropTypes.string.isRequired,
  isTagLocked: PropTypes.bool.isRequired,
  setTagName: PropTypes.func.isRequired,
  isTagOnlyDelivery: PropTypes.bool,
  clearOrder: PropTypes.func.isRequired,
  selectedShippingType: PropTypes.string,
  deliveryAddress: PropTypes.object,
  setDeliveryAddress: PropTypes.func.isRequired,
  setExpectedDistanceDuration: PropTypes.func.isRequired,
  domain: PropTypes.string,
  allowAnonymous: PropTypes.string.isRequired,
  tenantId: PropTypes.number.isRequired,
  accountId: PropTypes.number.isRequired,
  checkoutId: PropTypes.number.isRequired,
  isPostPaid: PropTypes.bool.isRequired,
  requiredFields: PropTypes.array.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OrderReviewContainer);
