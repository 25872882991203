import facepaint from 'facepaint';

export const mq = facepaint([
  '@media screen and (min-width: 320px)',
  '@media screen and (min-width: 480px)',
  '@media screen and (min-width: 720px)',
]);

export const mobileMq = facepaint([
  '@media screen and (min-width: 375px)',
  '@media screen and (min-width: 720px)',
]);
