const getTotalPriceFromProductMenus = (products) =>
  products.reduce(
    (productAcc, { price, amount, measure, complementsTotal }) => {
      if (measure === 'UNIT') {
        return productAcc + (price + complementsTotal) * amount;
      }

      return productAcc + (price * amount * 1000 * 0.1) / 1000;
    },
    0,
  );

export default getTotalPriceFromProductMenus;
