import { createReducer, createAction } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';

export const Types = {
  SET_ORDER_ITEM: 'orderHistory/SET_ORDER_ITEM',
  CLEAR_ORDER_ITEM: 'orderHistory/CLEAR_ORDER_ITEM',
};

export const setOrderItem = createAction(Types.SET_ORDER_ITEM);
export const clearOrderItem = createAction(Types.CLEAR_ORDER_ITEM);

const initialState = {
  order: null,
};

const orderHistory = createReducer(initialState, {
  [setOrderItem]: (state, action) => ({
    ...state,
    order: action.payload.order,
  }),
  [clearOrderItem]: (state, action) => initialState,
});

export const reducers = combineReducers({
  orderHistory,
});
