import React from 'react';
import PropTypes from 'prop-types';
import Modal from '@bit/eyemobile.core.modal';

import withModal from '../../hocs/withModal';
import TenantContact from '../../components/TenantContact';

const ContactUsModal = ({ visible, tenantContact, onClose }) => {
  const phone = tenantContact?.phone || '';
  const email = tenantContact?.email || '';

  return (
    <Modal visible={visible}>
      <TenantContact onClose={onClose} phone={phone} email={email} />
    </Modal>
  );
};

ContactUsModal.defaultProps = {
  tenantContact: null,
};

ContactUsModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  tenantContact: PropTypes.object,
  onClose: PropTypes.func.isRequired,
};

export default withModal((props) => ({
  visible: props.visible && props.tenantContact,
}))(ContactUsModal);
