import { css } from '@emotion/core';

export const comboItemProductCss = css`
  width: 100%;
  padding-left: 3px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const comboItemProductImageCss = css`
  height: 30px;
  width: 30px;
  border-radius: 15px;
  margin-right: 5px;
`;

export const expandComboWrapper = (theme) => css`
  position: absolute;
  bottom: 0;
  left: 0;
  height: 24px;
  width: 24px;
  padding-left: 6px;
  padding-right: 6px;
  background-color: ${theme.getColor('primary')};
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const comboItemDescription = (theme) => css`
  font-family: ${theme.getFont()};
  font-size: ${theme.getFontSize(0.85, 'px')};
  color: ${theme.getColor('text')};
  text-align: left;
  margin-bottom: 4px;
`;
