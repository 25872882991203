class CustomerService {
  static base = '/customers';

  static create = this.base;

  static find = (id) => `${this.base}/${id}`;

  static update = (id) => `${this.base}/${id}`;

  static complete = (id) => `${this.base}/complete/${id}`;

  static validate = (id) => `${this.base}/${id}/valid`;
}

export default CustomerService;
