import React, { useState } from 'react';
import PropTypes from 'prop-types';

import shoppingBasket from '../../assets/shopping_basket.svg';

import {
  itemContainer,
  complementWrapperCss,
  itemWrapperCss,
  descriptionText,
  productImageCss,
} from './HistoryProductItem.styles';

const HistoryProductItem = ({
  productImgSrc,
  productName,
  productQuantity,
  complementGroups,
  totalPrice,
}) => {
  const [fallbackImg, setFallbackImg] = useState(null);

  const onImgError = () => setFallbackImg(shoppingBasket);

  const renderComplementGroup = (complementGroup) => {
    return (
      <div
        css={complementWrapperCss}
        key={`complement-group-${complementGroup.id}`}
      >
        <span css={descriptionText} style={{ fontStyle: 'italic' }}>
          {complementGroup.name}
        </span>
        <div css={complementWrapperCss}>
          {complementGroup.complementItems.map((complementItem) => (
            <span
              css={descriptionText}
              key={`complement-item-${complementItem.id}`}
              style={{ fontStyle: 'italic' }}
            >
              {`- ${
                complementItem.quantity !== 1
                  ? `x${complementItem.quantity}`
                  : ''
              } ${complementItem.name}`}
            </span>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div css={itemContainer}>
      <div>
        <img
          src={fallbackImg || productImgSrc || shoppingBasket}
          alt={`img-${productName}`}
          onError={onImgError}
          resizemode="contain"
          css={productImageCss}
        />
      </div>
      <div css={itemWrapperCss}>
        <span css={descriptionText}>{productName}</span>
        {!!productQuantity && (
          <span css={descriptionText}>{productQuantity}</span>
        )}
        {complementGroups.map(renderComplementGroup)}
        <span css={descriptionText} style={{ fontWeight: 'bold' }}>
          {totalPrice}
        </span>
      </div>
    </div>
  );
};

HistoryProductItem.defaultProps = {
  productQuantity: null,
  productImgSrc: null,
  complementGroups: [],
};

HistoryProductItem.propTypes = {
  productImgSrc: PropTypes.string,
  productName: PropTypes.string.isRequired,
  productQuantity: PropTypes.string,
  totalPrice: PropTypes.string.isRequired,
  complementGroups: PropTypes.array,
};

export default HistoryProductItem;
