import { css } from '@emotion/core';

export const comboItemCss = (theme) => css`
  width: 100%;
  padding: 5px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const comboItemImageCss = (theme) => css`
  height: 40px;
  width: 40px;
  border-radius: 20px;
  margin-right: 5px;
`;

export const comboItemQuantityCss = (theme) => css`
  font-family: ${theme.getFont()};
  font-size: ${theme.getFontSize(1, 'px')};
  color: ${theme.getColor('text')};
  font-weight: bold;
`;

export const comboItemNameCss = (theme) => css`
  font-family: ${theme.getFont()};
  font-size: ${theme.getFontSize(0.85, 'px')};
  color: ${theme.getColor('text')};
`;
