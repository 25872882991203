import { css } from '@emotion/core';
import facepaint from 'facepaint';

const mq = facepaint([
  '@media screen and (min-width: 320px)',
  '@media screen and (min-width: 480px)',
  '@media screen and (min-width: 720px)',
]);

export const footerContainer = (theme) => css`
  ${mq({
    width: ['320px', '100%', '480px', '100%'],
  })}
  background-color: #f5f5f5;
  ${mq({
    paddingLeft: [
      theme.getSpacing(1, 'px'),
      theme.getSpacing(2, 'px'),
      theme.getSpacing(2, 'px'),
      theme.getSpacing(4, 'px'),
    ],
    paddingRight: [
      theme.getSpacing(1, 'px'),
      theme.getSpacing(2, 'px'),
      theme.getSpacing(2, 'px'),
      theme.getSpacing(4, 'px'),
    ],
  })}
  position: fixed;
  bottom: 50px;
  padding-bottom: 20px;
  height: 74px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const priceText = (theme) => css`
  font-size: ${theme.getFontSize(1.4, 'px')};
  font-family: ${theme.getFont('futura')};
  color: ${theme.getColor('text')};
`;
