import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import ScrollableView from '../../components/ScrollableView';
import Header from '../../components/Header';
import RequiredDataForm from '../../components/RequiredDataForm';
import PdfViewerModal from '../../components/PdfViewerModal';

import { formWrapper } from './RequiredDataPage.styles';

const RequiredDataScreen = ({
  formInitialValues,
  onFormSubmit,
  isPreRegistered,
  onUserDataFound,
  onRemoveUserData,
  noAutoFetchCustomer,
  domain,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [tosModalOpen, setTosModalOpen] = useState(false);

  const toggleTosModal = () => setTosModalOpen((prev) => !prev);

  const handleOnPrev = () => {
    history.push(`/${domain}/login`);
  };

  return (
    <>
      <PdfViewerModal visible={tosModalOpen} onCloseModal={toggleTosModal} />
      <Header onPrev={handleOnPrev} title={t('navigation:myProfile')} />
      <ScrollableView hasHeader>
        <div css={formWrapper}>
          <RequiredDataForm
            onFormSubmit={onFormSubmit}
            initialValues={formInitialValues}
            isRegistered={isPreRegistered}
            onIncompleteDataFound={onUserDataFound}
            onNoUserDataFound={onRemoveUserData}
            noAutoFetchCustomer={noAutoFetchCustomer}
            onOpenTosModal={toggleTosModal}
          />
        </div>
      </ScrollableView>
    </>
  );
};

RequiredDataScreen.defaultProps = {
  isPreRegistered: false,
  noAutoFetchCustomer: false,
  domain: null,
};

RequiredDataScreen.propTypes = {
  formInitialValues: PropTypes.object.isRequired,
  onFormSubmit: PropTypes.func.isRequired,
  isPreRegistered: PropTypes.bool,
  onUserDataFound: PropTypes.func.isRequired,
  onRemoveUserData: PropTypes.func.isRequired,
  noAutoFetchCustomer: PropTypes.bool,
  domain: PropTypes.string,
};

export default RequiredDataScreen;
