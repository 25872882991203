import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import localizePrice from '../../utils/localizePrice';

import OrderDescriptionRow from './OrderDescriptionRow';

import { fieldText, paymentCol } from './OrderHistoryPage.styles';

const OrderHistoryDetailPayment = ({ order }) => {
  const { t } = useTranslation();

  const { payments, priceTotal, priceSubtotal, deliveryFee, discount } = order;

  const subtotalText = `R$ ${localizePrice(priceSubtotal)}`;
  const deliveryFeeText =
    deliveryFee && deliveryFee > 0
      ? `R$ ${localizePrice(deliveryFee)}`
      : t('orderReview:forFree');

  const giftCardPayment = payments.find(({ tef }) => tef === 5);

  let totalWithoutGiftCard = priceTotal;
  if (giftCardPayment) {
    totalWithoutGiftCard -= giftCardPayment.subtotal;
  }
  const priceText = `R$ ${localizePrice(totalWithoutGiftCard)}`;

  const renderGiftCardPayment = () => {
    if (!giftCardPayment) {
      return null;
    }

    return (
      <OrderDescriptionRow label={t('orderReview:giftCard')}>
        {`- R$ ${localizePrice(giftCardPayment.subtotal)}`}
      </OrderDescriptionRow>
    );
  };

  return (
    <div css={paymentCol}>
      <span css={fieldText}>{t('orderHistory:order')}</span>
      <OrderDescriptionRow label={t('orderHistory:subtotal')}>
        {subtotalText}
      </OrderDescriptionRow>
      <OrderDescriptionRow label={t('orderHistory:deliveryFee')}>
        {deliveryFeeText}
      </OrderDescriptionRow>
      {!!discount && (
        <OrderDescriptionRow label={t('orderHistory:discount')}>
          {`- R$ ${localizePrice(discount)}`}
        </OrderDescriptionRow>
      )}
      {renderGiftCardPayment()}
      <OrderDescriptionRow label={t('orderHistory:total')} bold>
        {priceText}
      </OrderDescriptionRow>
    </div>
  );
};

OrderHistoryDetailPayment.propTypes = {
  order: PropTypes.object.isRequired,
};

export default OrderHistoryDetailPayment;
