import React from 'react';
import PropTypes from 'prop-types';

import { DescriptionText, descriptionRow } from './OrderHistoryPage.styles';

const OrderDescriptionRow = ({ label, children, bold }) => {
  return (
    <div css={descriptionRow}>
      <DescriptionText style={{ marginRight: '20px' }} fontFamily="futura">
        {label}
      </DescriptionText>
      <DescriptionText bold={bold} style={{ textAlign: 'right' }}>
        {children}
      </DescriptionText>
    </div>
  );
};

OrderDescriptionRow.defaultProps = {
  bold: false,
};

OrderDescriptionRow.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  bold: PropTypes.bool,
};

export default OrderDescriptionRow;
