import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { withFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import Button from '@bit/eyemobile.core.button';

import Input from '../FormikInput';
import { mainForm, fieldPadding, ButtonPadding } from '../Form/Form.styles';

import { warningText } from './UserDeletePasswordForm.styles';

const schema = Yup.object().shape({
  password: Yup.string()
    .min(6, 'formValidation:invalidPassword')
    .required('formValidation:fieldRequired'),
});

const UserDeletePasswordForm = ({
  onCancel,
  handleSubmit,
  isSubmitting,
  isValid,
}) => {
  const { t } = useTranslation();

  return (
    <form onSubmit={handleSubmit} css={mainForm}>
      <span css={warningText}>{t('warnings:userDataDeleteFinalWarning')}</span>
      <div css={fieldPadding}>
        <Input
          name="password"
          placeholder={t('forms:password')}
          animateLabel
          type="password"
          hideIcons={false}
          inputStyle={{
            letterSpacing: 4,
          }}
        />
      </div>
      <ButtonPadding hasMultiButtons>
        <Button
          variant="outlined"
          disabled={isSubmitting}
          onClick={onCancel}
          color="error"
        >
          {t('formValidation:cancel')}
        </Button>
        <Button
          isLoading={isSubmitting}
          disabled={!isValid || isSubmitting}
          onClick={handleSubmit}
          color="error"
        >
          {t('forms:confirm')}
        </Button>
      </ButtonPadding>
    </form>
  );
};

UserDeletePasswordForm.propTypes = {
  onCancel: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  isValid: PropTypes.bool.isRequired,
};

const formikEnhance = withFormik({
  mapPropsToValues: ({ initialValues }) => ({
    password: initialValues?.password || '',
  }),
  validationSchema: schema,
  handleSubmit: (values, { props, dirty, ...formikBag }) => {
    const { onFormSubmit } = props;
    onFormSubmit(values, formikBag);
  },
});

export default formikEnhance(UserDeletePasswordForm);
