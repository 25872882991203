import { css } from '@emotion/core';
import styled from '@emotion/styled';
import facepaint from 'facepaint';

const mq = facepaint(['@media screen and (min-width: 375px)']);

export const itemContainer = (theme) => css`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: ${theme && theme.getSpacing(0.5, 'px')};
  padding-bottom: ${theme && theme.getSpacing(0.5, 'px')};
`;

export const radioButtonItemContainer = (theme) => css`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: ${theme && theme.getSpacing(1, 'px')};
  padding-bottom: ${theme && theme.getSpacing(1, 'px')};
  border: none;
  outline: none;
  cursor: pointer;
`;

export const itemDescription = (theme) => css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-right: ${theme && theme.getSpacing(1, 'px')};
`;

const descriptionTextBps = (theme) =>
  mq({
    fontSize: [theme.getFontSize(0.85, 'px'), theme.getFontSize(1, 'px')],
  });

export const DescriptionText = styled.span`
  ${({ theme }) => theme && descriptionTextBps(theme)};
  font-family: ${({ theme }) => theme && theme.getFont()};
  color: ${({ theme, textColor }) =>
    theme && theme.getColor(textColor || 'text')};

  ${({ bold }) => bold && 'font-weight: bold;'}
`;

export const valueText = (theme) => css`
  font-family: ${theme && theme.getFont('futura')};
  ${mq({
    fontSize: [theme.getFontSize(0.85, 'px'), theme.getFontSize(1, 'px')],
  })}
  color: ${theme && theme.getColor('success')};
`;

export const buttonRow = (theme) => css`
  height: 100%;
  min-width: 100px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding-left: ${theme && theme.getSpacing(1, 'px')};
`;

export const quantityWrapper = (theme) => css`
  margin-left: ${theme && theme.getSpacing(1, 'px')};
  margin-right: ${theme && theme.getSpacing(1, 'px')};
`;

export const RadioButtonInput = styled.div`
  border: none;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: transparent;
  border: 1px solid ${({ theme }) => theme && theme.getColor('primary')};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  outline: none;
`;

export const radioButtonCheck = (theme) => css`
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background-color: ${theme && theme.getColor('primary')};
  flex-shrink: 0;
`;
