import { css } from '@emotion/core';
import facepaint from 'facepaint';

const mq = facepaint(['@media screen and (min-width: 375px)']);

export const groupContainer = (theme) => css`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: ${theme && theme.getSpacing(2, 'px')};
  padding-bottom: ${theme && theme.getSpacing(2, 'px')};
`;

export const groupDescription = (theme) => css`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: ${theme && theme.getSpacing(1, 'px')};
  position: relative;
  margin-bottom: ${theme && theme.getSpacing(4, 'px')};
`;

const titleBps = (theme) =>
  mq({
    fontSize: [theme.getFontSize(1, 'px'), theme.getFontSize(1.15, 'px')],
  });

export const titleCss = (theme) => css`
  ${theme && titleBps(theme)};
  font-family: ${theme && theme.getFont('futura')};
  color: ${theme && theme.getColor('text')};
  font-size: ${theme && theme.getFontSize(1.1, 'px')};
  display: block;
  margin-bottom: ${theme && theme.getSpacing(0.5, 'px')};
`;

const descriptionTextBps = (theme) =>
  mq({
    fontSize: [theme.getFontSize(0.8, 'px'), theme.getFontSize(1, 'px')],
  });

export const descriptionText = (theme) => css`
  ${theme && descriptionTextBps(theme)};
  font-family: ${theme && theme.getFont()};
  color: ${theme && theme.getColor('default')};
`;

export const minMaxDescriptionText = (theme) => css`
  ${descriptionText(theme)};
  display: block;
  position: absolute;
  right: 0;
  top: calc(100% + 8px);
`;
