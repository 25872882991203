import styled from '@emotion/styled';

const progressBorder = '#E5E5E5';

export const Step = styled.span`
  display: block;
  width: ${({ width }) => width}%;
  height: 100%;
  background-color: ${({ theme }) => theme.getColor('foreground')};
  position: relative;
  z-index: 5;
`;

export const Progress = styled.div`
  width: 100%;
  height: 24px;
  display: flex;
  border: 1px solid ${progressBorder};
  background-color: ${progressBorder};
  border-radius: 8px;
  position: relative;
  z-index: 10;

  span:first-of-type {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  span:last-of-type {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  span:not(:last-of-type) {
    ${({ separators }) =>
      separators && `border-right: 1px solid ${progressBorder}`};
  }
`;
