import React, { cloneElement } from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';

import RowRadioButton from '../RowRadioButton';

import { Box, LeftWrapper, labelCss } from './FormikRadioBox.styles';

const FormikRadioBox = ({
  name,
  label,
  value,
  components,
  disabled,
  style,
  boxCss,
}) => {
  const [field, , helpers] = useField(name);

  const handleChange = () => {
    helpers.setTouched(true);
    if (typeof field.value === 'boolean') {
      helpers.setValue(!field.value);
    } else {
      helpers.setValue(field.value === value ? '' : value);
    }
  };

  const renderLabel = () => {
    if (components.Label) {
      const { Label } = components;

      return cloneElement(Label, { label });
    }

    if (label) {
      return label;
    }

    return null;
  };

  const checkByType = {
    string: field.value === value,
    boolean: field.value,
    object: !!field.value,
  };

  const checked = checkByType[typeof field.value];

  return (
    <Box css={boxCss} style={style}>
      <LeftWrapper>
        <RowRadioButton
          name={name}
          checked={checked}
          onChange={handleChange}
          disabled={disabled}
          labelCss={labelCss}
        />
      </LeftWrapper>
      {renderLabel()}
    </Box>
  );
};

FormikRadioBox.defaultProps = {
  disabled: false,
  components: {},
  label: '',
  style: undefined,
  value: false,
  boxCss: undefined,
};

FormikRadioBox.propTypes = {
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  components: PropTypes.shape({
    Label: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.func,
      PropTypes.element,
    ]),
  }),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  label: PropTypes.string,
  style: PropTypes.object,
  boxCss: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
};

export default FormikRadioBox;
