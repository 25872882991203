export default class ContextManager {
  static prefix = 'EYEPASS_CONTEXT';

  static getDomain() {
    const pathname = window.location?.pathname || '/';

    const domain = pathname.split('/')[1].toUpperCase();

    return domain;
  }

  static setTag(tag) {
    const domain = this.getDomain();
    sessionStorage.setItem(`${this.prefix}_${domain}_TAG`, JSON.stringify(tag));
  }

  static getTag() {
    const domain = this.getDomain();
    const key = `${this.prefix}_${domain}_TAG`;

    if (
      typeof sessionStorage[key] !== 'undefined' &&
      sessionStorage[key] !== 'undefined'
    ) {
      return JSON.parse(sessionStorage.getItem(key));
    }
  }

  static removeTag() {
    const domain = this.getDomain();
    const key = `${this.prefix}_${domain}_TAG`;

    if (typeof sessionStorage[key] !== 'undefined') {
      sessionStorage.removeItem(key);
    }
  }
}
