import { css } from '@emotion/core';
import styled from '@emotion/styled';

import { mq } from '../../utils/commonMqs';

export const mainForm = css`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const formAlignment = css`
  max-width: 480px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const formPadding = (theme) => css`
  max-width: 100%;
  padding-top: ${theme && theme.getSpacing(4, 'px')};
  padding-bottom: ${theme && theme.getSpacing(4, 'px')};
  ${mq({
    paddingLeft: [
      theme.getSpacing(2, 'px'),
      theme.getSpacing(4, 'px'),
      theme.getSpacing(8, 'px'),
    ],
    paddingRight: [
      theme.getSpacing(2, 'px'),
      theme.getSpacing(4, 'px'),
      theme.getSpacing(8, 'px'),
    ],
  })}
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const formMiniPadding = (theme) => css`
  width: 100%;
  padding-top: ${theme && theme.getSpacing(2, 'px')};
  padding-bottom: ${theme && theme.getSpacing(2, 'px')};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const fieldPadding = (theme) => css`
  width: 100%;
  padding-top: ${theme && theme.getSpacing(2, 'px')};
  padding-bottom: ${theme && theme.getSpacing(2, 'px')};
`;

export const ButtonPadding = styled.div`
  width: 100%;
  padding-top: ${({ theme }) => theme && theme.getSpacing(2, 'px')};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  ${({ hasMultiButtons }) =>
    hasMultiButtons && 'justify-content: space-between;'}
`;

export const rowFields = css`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const rowFieldPadding = (theme) => css`
  width: 100%;
  padding-top: ${theme && theme.getSpacing(2, 'px')};
  padding-bottom: ${theme && theme.getSpacing(2, 'px')};

  &:not(:first-of-type) {
    padding-left: ${theme && theme.getSpacing(2, 'px')};
  }
`;

export const prompTextWrapper = (theme) => css`
  width: 100%;
  padding-top: ${theme && theme.getSpacing(1, 'px')};
  padding-bottom: ${theme && theme.getSpacing(2, 'px')};
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const promptText = (theme) => css`
  color: ${theme && theme.getColor('text')};
  font-family: ${theme && theme.getFont()};
  font-size: ${theme && theme.getFontSize(0.75, 'px')};
`;
