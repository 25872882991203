import getUsedPercentValue from './getUsedPercentValue';

const getCashbackValue = (
  finalPrice,
  cashbackValue,
  giftCardValue,
  maxPercent = 100,
) => {
  const usedCashbackValue = getUsedPercentValue(
    finalPrice,
    maxPercent,
    cashbackValue,
  );

  if (giftCardValue > 0) {
    const discountedFinalValue = Math.max(finalPrice - giftCardValue, 0);

    if (discountedFinalValue < 3.5) {
      return usedCashbackValue > 3.5 ? discountedFinalValue : 0;
    }

    const cashbackDiscountedFinalValue = Math.max(
      discountedFinalValue - usedCashbackValue,
      0,
    );

    if (cashbackDiscountedFinalValue < 5) {
      return discountedFinalValue - 5;
    }

    return cashbackDiscountedFinalValue;
  }

  const cashBackPriceDif = finalPrice - usedCashbackValue;
  const cashbackNotFull = cashBackPriceDif > 0 && cashBackPriceDif < 3.5;
  const moreCashbackThanTotal = cashBackPriceDif <= 0;

  if (moreCashbackThanTotal) {
    return Math.round(finalPrice * 100) / 100;
  }

  if (cashbackNotFull) {
    return Math.round(Math.max(finalPrice - 3.5, 0) * 100) / 100;
  }

  return Math.round(usedCashbackValue * 100) / 100;
};

export default getCashbackValue;
