import React from 'react';
import { useTranslation } from 'react-i18next';

import { logo } from '../../assets';
import Icon from '../../components/Icon';

import { ReactComponent as Mail } from '../../assets/mail_outline.svg';
import { ReactComponent as Phone } from '../../assets/phone.svg';

import {
  backgroundCss,
  wrapperCss,
  roundBoxCss,
  titleTextCss,
  commonTextCss,
  logoCss,
  contactContainer,
} from './UserRemoval.styles';

const UserRemovalScreen = () => {
  const { t } = useTranslation();

  return (
    <div css={backgroundCss}>
      <div css={wrapperCss}>
        <img css={logoCss} src={logo} alt="logo" />
        <div css={roundBoxCss}>
          <span css={titleTextCss}>{t('userRemoval:aboutUserRemoval')}</span>
          <span css={commonTextCss}>
            {t('userRemoval:userRemovalInstructions')}
          </span>
          <div css={contactContainer}>
            <Icon icon={<Phone />} wrapperStyle={{ marginRight: '5px' }} />
            <a css={commonTextCss} href="tel:(48)3211-3131">
              (48) 3211-3131
            </a>
          </div>
          <div css={contactContainer}>
            <Icon icon={<Mail />} wrapperStyle={{ marginRight: '5px' }} />
            <a href="emailto:contato@eyemobile.com.br" css={commonTextCss}>
              contato@eyemobile.com.br
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserRemovalScreen;
