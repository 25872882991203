import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/es/integration/react';
import { Global } from '@emotion/core';
import { StylesProvider, MuiThemeProvider } from '@material-ui/core/styles';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import 'core-js/stable';
import 'cross-fetch/polyfill';

import Main from './theme/Main';
import reduxStore from './store/configureStore';
import gateway from './utils/gateway';
import globalStyles from './index.styles';
import applyInterceptors from './utils/gatewayInterceptors';
import { initializeApp } from './utils/firebase';
import './locales/i18n';
import AppTenant from './AppTenant';
import { currentEnv } from './utils/constants';

const { persistor, store } = reduxStore;

applyInterceptors(gateway);

if (currentEnv === 'production') {
  Sentry.init({
    dsn: 'https://1f98bac4c64947e8b26e8dc8cb082a85@o468574.ingest.sentry.io/5496594',
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

ReactDOM.render(
  <React.StrictMode>
    <Global styles={globalStyles} />
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <MuiThemeProvider theme={Main}>
          <StylesProvider injectFirst>
            <AppTenant />
          </StylesProvider>
        </MuiThemeProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

initializeApp();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register();
