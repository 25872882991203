import React from 'react';
import PropTypes from 'prop-types';

import Loading from '../Loading';
import { WrapperDiv, messageCss } from './LoadingScreen.styles';
import Logo from '../Logo';

const LoadingScreen = ({ message }) => {
  return (
    <WrapperDiv>
      <Logo />
      <Loading />
      {message && <span css={messageCss}>{message}</span>}
    </WrapperDiv>
  );
};

LoadingScreen.defaultProps = {
  message: null,
};

LoadingScreen.propTypes = {
  message: PropTypes.string,
};

export default LoadingScreen;
