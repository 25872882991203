import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import ScrollableView from '../../components/ScrollableView';
import ErrorIcon from '../../assets/error.svg';

import {
  backgroundCss,
  wrapperCss,
  iconCss,
  messageCss,
} from './PaymentLinkPage.styles';

const PaymentLinkFailScreen = ({ message }) => {
  const { t } = useTranslation();

  return (
    <div css={backgroundCss}>
      <ScrollableView>
        <div css={wrapperCss}>
          <img src={ErrorIcon} alt="error" css={iconCss} />
          <div css={messageCss}>{t(message)}</div>
        </div>
      </ScrollableView>
    </div>
  );
};

PaymentLinkFailScreen.defaultProps = {
  message: 'paymentLink:failedTransaction',
};

PaymentLinkFailScreen.propTypes = {
  message: PropTypes.string,
};

export default PaymentLinkFailScreen;
