import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { useTranslation } from 'react-i18next';

import * as productOrderActions from '../../store/modules/productOrder';
import EcommerceService from '../../services/ecommerce';
import {
  analyticsEventActions,
  analyticsEventCategory,
  shippingTypes,
} from '../../utils/constants';
import clean from '../../utils/clean';
import gateway from '../../utils/gateway';
import debounce from '../../utils/debounce';
import mapStore from '../../utils/mapStore';
import showToast from '../../utils/showToast';
import Analytics from '../../utils/Analytics';
import mapProductGroups from '../../utils/mapProductGroups';
import fetchGeolocation from '../../utils/fetchGeolocation';
import addressValidation from '../../utils/addressValidation';
import getDeliveryAddress from '../../utils/getDeliveryAddress';
import useModalController from '../../utils/useModalController';
import adaptOrderToNewStore from '../../utils/adaptOrderToNewStore';
import SessionStorageManager from '../../utils/SessionStorageManager';
import ContextManager from '../../utils/ContextManager';
import getInitialShippingType from '../../utils/getInitialShippingType';
import filterOrderProductMenus from '../../utils/filterOrderProductMenus';
import extractProductMenuGroups from '../../utils/extractProductMenuGroups';
import getTotalPriceFromProductMenus from '../../utils/getTotalPriceFromProductMenus';
import Error from '../../components/Error';
import FiltersModal from '../../components/FiltersModal';
import LoadingScreen from '../../components/LoadingScreen';
import ComboAdjustModal from '../../components/ComboAdjustModal';
import ProductAdjustModal from '../../components/ProductAdjustModal';
import DeliveryAddressModal from '../../components/DeliveryAddressModal';
import withContext from '../../hocs/withContext';

import ClearCartModal from './ClearCartModal';
import OrderCartScreen from './OrderCartScreen';
import StoreSelectModal from './StoreSelectModal';
import QrCodeReaderModal from './QrCodeReaderModal';
import OrderCartListModal from './OrderCartListModal';
import OrderShippingTypeSelectModal from './OrderShippingTypeSelectModal';

const mapStateToProps = ({
  productOrder: {
    productOrder: {
      products,
      promotionalProducts,
      store,
      isEventPointLocked,
      shippingType,
      deliveryAddress,
    },
  },
  tenantEyepass: {
    domain,
    tenantId,
    qrCodeOnly,
    minCartValue,
    deliveryDisabled,
    paymentOnDeliveryOnly,
  },
  auth,
  user,
}) => ({
  savedProducts: products,
  savedPromotionalProducts: promotionalProducts,
  selectedStore: store,
  selectedShippingType: shippingType,
  deliveryAddress,
  isEventPointLocked,
  user,
  isLogged: auth.isLogged,
  domain,
  tenantId,
  qrCodeOnly,
  minCartValue,
  deliveryDisabled,
  paymentOnDeliveryOnly,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      alterProductInOrder: productOrderActions.alterProductInOrder,
      removeProductInOrder: productOrderActions.removeProductInOrder,
      addProductToOrder: productOrderActions.addProductToOrder,
      clearOrder: productOrderActions.clearOrder,
      setStore: productOrderActions.setStore,
      setOrder: productOrderActions.setOrder,
      setShippingType: productOrderActions.setShippingType,
      clearShippingType: productOrderActions.clearShippingType,
      setDeliveryAddress: productOrderActions.setDeliveryAddress,
      setExpectedDistanceDuration:
        productOrderActions.setExpectedDistanceDuration,
    },
    dispatch,
  );

const shippingTypeMap = {
  ADDRESS: 'endereço',
  TAG: 'tag',
  TAKEOUT: 'takeout',
};

const OrderCartContainer = ({
  history,
  savedProducts,
  savedPromotionalProducts,
  selectedStore,
  selectedShippingType,
  setShippingType,
  clearShippingType,
  setStore,
  setOrder,
  setTag,
  deliveryAddress,
  setDeliveryAddress,
  addProductToOrder,
  alterProductInOrder,
  removeProductInOrder,
  clearOrder,
  isLogged,
  isEventPointLocked,
  user,
  tag,
  domain,
  tenantId,
  qrCodeOnly,
  minCartValue,
  deliveryDisabled,
  paymentOnDeliveryOnly,
  removeTag,
  setExpectedDistanceDuration,
}) => {
  const [modals, { toggleModal, closeModals }] = useModalController([
    'storeSelectModal',
    'productAdjustModal',
    'clearCartModal',
    'skuReaderModal',
    'shippingTypeModal',
    'cartListModal',
    'addressChangeModal',
    'filtersModal',
  ]);
  const [currentProduct, setCurrentProduct] = useState(null);
  const [currentCombo, setCurrentCombo] = useState(null);
  const [editProduct, setEditProduct] = useState(null);
  const [editCombo, setEditCombo] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [productMenus, setProductMenus] = useState([]);
  const [availableFilters, setAvailableFilters] = useState([]);
  const [appliedFilters, setAppliedFilters] = useState([]);
  const [filteredProductMenus, setFilteredProductMenus] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [userHasDeniedGeolocation, setUserHasDeniedGeolocation] =
    useState(false);
  const [disableOrder, setDisableOrder] = useState(true);
  const { t } = useTranslation();

  const ignoreRigidStock = Boolean(selectedStore?.ignoreRigidStock);

  const countedProductMenus = useMemo(
    () =>
      filteredProductMenus.map((productGroup) => {
        if (
          savedProducts.some(
            (savedProduct) =>
              savedProduct.menuProductGroupId === productGroup.id,
          )
        ) {
          return {
            ...productGroup,
            products: productGroup.products.map((product) => {
              const sumAmount = savedProducts.reduce((acc, sProduct) => {
                if (
                  sProduct.id === product.id &&
                  sProduct.menuProductGroupId === product.menuProductGroupId
                ) {
                  return acc + sProduct.amount;
                }

                return acc;
              }, 0);

              return {
                ...product,
                sumAmount,
              };
            }),
          };
        }

        return productGroup;
      }),
    [filteredProductMenus, savedProducts],
  );

  const fetchStores = async (newDeliveryAddress) => {
    setIsFetching(true);

    let position = null;

    if (
      newDeliveryAddress &&
      newDeliveryAddress.latitude &&
      newDeliveryAddress.longitude
    ) {
      position = {
        coords: {
          latitude: newDeliveryAddress.latitude,
          longitude: newDeliveryAddress.longitude,
        },
      };
    } else {
      try {
        position = await fetchGeolocation();
      } catch (e) {
        if (!userHasDeniedGeolocation) {
          setUserHasDeniedGeolocation(true);
        }
      }
    }

    try {
      let pointParams = {};
      if (position && position.coords) {
        pointParams = {
          ...pointParams,
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        };
      }
      if (!isLogged) {
        pointParams = {
          ...pointParams,
          tenant_id: tenantId,
        };
      }

      const response = await gateway
        .get(EcommerceService.getEventPoints(), { params: clean(pointParams) })
        .then(({ data }) => data);

      let initialStore = null;

      if (response.event_points) {
        const storeList = response.event_points
          .filter(({ open }) => !!open)
          .map(mapStore);

        if (storeList.length > 0) {
          initialStore = storeList && storeList[0];
          setStore({ store: initialStore });
          const initialShippingType = getInitialShippingType(
            selectedShippingType,
            tag?.tag,
            storeList[0],
            tag?.isTagOnlyDelivery,
            deliveryDisabled,
            paymentOnDeliveryOnly,
          );
          setShippingType({ shippingType: initialShippingType });
          setExpectedDistanceDuration({
            expected: {
              duration: storeList[0].duration,
              distance: storeList[0].distance,
            },
          });
          SessionStorageManager.removeOrderDeliverySchedule();
        }
        setIsFetching(false);
        return initialStore;
      }
      setIsFetching(false);
      return initialStore;
    } catch (e) {
      console.error(e);
      showToast(t('errors:fetchPointsFailure'), 'error');
      setIsFetching(false);
    }
  };

  const fetchEventPoint = async (eventPointId, isTagEventPointLocked) => {
    setIsFetching(true);

    try {
      const response = await gateway
        .get(
          EcommerceService.getEventPoint(
            eventPointId,
            isLogged ? null : tenantId,
          ),
        )
        .then(({ data }) => data);

      const { event_point: eventPoint } = response;

      const store = (eventPoint && mapStore(eventPoint)) || null;

      setStore({
        store,
        isEventPointLocked: isTagEventPointLocked || false,
      });

      setShippingType({ shippingType: shippingTypes.TAG });
      setIsFetching(false);
    } catch (e) {
      console.error(e);
      showToast(t('errors:fetchPointsFailure'), 'error');
      setIsFetching(false);
    }
  };

  const fetchShippingPrice = async (newDeliveryAddress, initialStore) => {
    try {
      let query = null;

      if (
        newDeliveryAddress &&
        !!newDeliveryAddress.latitude &&
        !!newDeliveryAddress.longitude
      ) {
        query = EcommerceService.checkAddressCoordinatesValidity(
          initialStore.id,
          newDeliveryAddress.latitude,
          newDeliveryAddress.longitude,
        );
      } else {
        query = EcommerceService.checkAddressValidity(
          initialStore.id,
          newDeliveryAddress,
        );
      }

      const response = await gateway.get(query).then(({ data }) => data);

      const { extra } = response.event_point.point;

      if (extra && extra.shipping_price) {
        const newDistance =
          (extra && (extra.routeDistance || extra.distance)) || 0;
        const newDuration = (extra && extra.duration) || 0;
        const newDeliveryPrice =
          (extra.shipping_price && extra.shipping_price?.value) || 0;

        setExpectedDistanceDuration({
          expected: {
            duration: newDuration,
            distance: newDistance,
          },
        });
        setStore({
          store: {
            ...initialStore,
            deliveryPrice: newDeliveryPrice,
            duration: newDuration,
            distance: newDistance,
          },
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleGetProductMenus = async () => {
    try {
      setIsLoading(true);

      let params = { event_point_id: selectedStore && selectedStore.id };

      if (selectedShippingType === shippingTypes.TAKEOUT) {
        params = { ...params, takeout_journey_enabled: true };
      }

      if (selectedShippingType === shippingTypes.TAG) {
        params = { ...params, honest_market_journey_enabled: true };
      }

      if (selectedShippingType === shippingTypes.ADDRESS) {
        params = { ...params, delivery_journey_enabled: true };
      }

      const response = await gateway
        .get(EcommerceService.menus(), {
          params,
        })
        .then(({ data }) => data);

      const mappedProductMenus =
        response.menu_product_groups.map(mapProductGroups);

      const newFavoriteProducts = mappedProductMenus.reduce(
        (acc, productMenu) => {
          const favorites = productMenu.products.filter(
            ({ favorite }) => favorite,
          );

          return acc.concat(favorites);
        },
        [],
      );

      if (newFavoriteProducts.length === 0) {
        setProductMenus(mappedProductMenus);
      } else {
        setProductMenus(
          [
            {
              id: 0,
              productGroupId: 0,
              name: t('productOrder:highlights'),
              products: newFavoriteProducts,
            },
          ].concat(mappedProductMenus),
        );
      }

      const newFilteredProductMenus = filterOrderProductMenus(
        mappedProductMenus,
        searchText,
        appliedFilters,
      );
      setFilteredProductMenus(newFilteredProductMenus);

      setAvailableFilters(
        extractProductMenuGroups(response.menu_product_groups),
      );

      setIsLoading(false);
    } catch (e) {
      console.error(e);
      showToast(t('errors:fetchProductsFailure'), 'error');
      setProductMenus([]);
      setIsLoading(false);
    }
  };

  const handleNewContext = (contextData, newStore = null) => {
    const { type } = contextData;
    const {
      isEventPointLocked: newIsEventPointLocked,
      tag: currentTag,
      isTagLocked,
      eventPointId,
      isAnon,
      accountId,
      checkoutId,
      isPostpaid,
      requiredFields,
    } = contextData.data;

    toggleModal('skuReaderModal');

    const tagInfo = {
      tag: currentTag,
      isTagLocked,
      isTagOnlyDelivery: type === 'TAG',
      eventPointId,
      isEventPointLocked: newIsEventPointLocked,
      isAnon,
      accountId,
      checkoutId,
      isPostpaid,
      requiredFields,
    };

    setTag(tagInfo);

    ContextManager.setTag(tagInfo);
    const currentStore = newStore || selectedStore;
    setStore({
      store: currentStore,
      isEventPointLocked: newIsEventPointLocked || false,
    });
    setShippingType({ shippingType: shippingTypes.TAG });
    SessionStorageManager.removeOrderDeliverySchedule();
  };

  const handleRemoveFilter = (filterItem) =>
    setAppliedFilters((prev) =>
      prev.filter(
        (filter) => filter.menuProductGroupId !== filterItem.menuProductGroupId,
      ),
    );

  useEffect(() => {
    if (selectedStore) {
      handleGetProductMenus();
    }
  }, [selectedStore, selectedShippingType]);

  const handleFilters = debounce(
    (currentAvailableProductMenus, currentSearchText, currentFilters) => {
      const newFilteredProductMenus = filterOrderProductMenus(
        currentAvailableProductMenus,
        currentSearchText,
        currentFilters,
      );
      setFilteredProductMenus(newFilteredProductMenus);
    },
    500,
  );

  useEffect(() => {
    if (productMenus.length > 0) {
      handleFilters(productMenus, searchText, appliedFilters);
    }
  }, [searchText, availableFilters, appliedFilters, productMenus]);

  useEffect(() => {
    if (
      savedProducts &&
      savedProducts.length > 0 &&
      productMenus &&
      productMenus.length > 0
    ) {
      setOrder({ products: adaptOrderToNewStore(savedProducts, productMenus) });
    }
  }, [productMenus]);

  const startUp = async () => {
    let newDeliveryAddress = null;

    if (!deliveryAddress || !deliveryAddress?.zipcode) {
      newDeliveryAddress = getDeliveryAddress(
        user.customer_addresses || [],
        user,
      );

      setDeliveryAddress({ deliveryAddress: newDeliveryAddress });
    }

    let initialStore = selectedStore;

    if (!initialStore) {
      if (tag.tag) {
        const { eventPointId, isEventPointLocked: isTagEventPointLocked } = tag;

        initialStore = await fetchEventPoint(
          eventPointId,
          isTagEventPointLocked,
        );
      } else {
        initialStore = await fetchStores(deliveryAddress || newDeliveryAddress);
      }
    }

    if (
      selectedShippingType === shippingTypes.ADDRESS &&
      initialStore &&
      newDeliveryAddress
    ) {
      await fetchShippingPrice(newDeliveryAddress, initialStore);
    }
  };

  useEffect(() => {
    startUp();
  }, []);

  const handleLogin = () => {
    history.push(`/${domain}/login`, { redirectTo: `/${domain}/pedido` });
  };

  const closeAllModals = () => {
    closeModals();
    setEditProduct(null);
    setEditCombo(null);
    setCurrentProduct(null);
    setCurrentCombo(null);
    setDisableOrder(true);
  };

  const handleSearchTextChange = (newText) => setSearchText(newText);

  const openFiltersModal = () => toggleModal('filtersModal');

  const { allowAnonymous = false } = tag;

  const handleOrderFinish = async () => {
    if (allowAnonymous) {
      setDisableOrder(false);
      setIsSubmitting(true);
      toggleModal('cartListModal');
    } else {
      const invalidShippingType =
        (selectedShippingType === shippingTypes.ADDRESS &&
          !selectedStore?.deliveryEnabled) ||
        (selectedShippingType === shippingTypes.TAKEOUT &&
          !selectedStore?.takeoutEnabled) ||
        (selectedShippingType === shippingTypes.TAG &&
          !selectedStore?.tagEnabled);

      if (invalidShippingType) {
        showToast(t('errors:invalidShippingTypeDesc'), 'error');
      } else if (!deliveryAddress || !deliveryAddress.zipcode) {
        showToast(t('errors:noAddressSelected'), 'error');
      } else if (selectedShippingType !== shippingTypes.ADDRESS) {
        setDisableOrder(false);
        toggleModal('cartListModal');
      } else {
        try {
          setIsSubmitting(true);

          toggleModal('cartListModal');

          const isAddressValid = await addressValidation(
            selectedStore.id,
            deliveryAddress,
            t,
          );

          setDisableOrder(!isAddressValid);
        } catch (err) {
          showToast(err?.response?.data?.message || err.message, 'error');
        } finally {
          setIsSubmitting(false);
        }
      }
    }
  };

  const openDeliveryAddressModal = () => {
    toggleModal('addressChangeModal');
  };

  const handleChangeStore = () => {
    toggleModal('storeSelectModal');
  };

  const handleStoreSelected = (newStore) => {
    if ((newStore && newStore.id) !== (selectedStore && selectedStore.id)) {
      Analytics.event({
        category: analyticsEventCategory.ORDER,
        action: analyticsEventActions.SELECT_STORE,
        label: newStore.name,
      });

      setStore({ store: newStore });

      const { isTagLocked } = tag;

      if (!isTagLocked) {
        removeTag();
        if (tag?.tag) {
          clearShippingType();
        }
      }
      setAppliedFilters([]);
      SessionStorageManager.removeOrderDeliverySchedule();
      setProductMenus([]);
    }

    setExpectedDistanceDuration({
      expected: { distance: newStore.distance, duration: newStore.duration },
    });
    closeModals();
  };

  const handleCloseStoreSelectModal = () => {
    closeModals();
  };

  const handleAddItem = (productItem) => {
    if (productItem.comboProductItems.length > 0) {
      setCurrentCombo(productItem);
    } else {
      setCurrentProduct(productItem);
    }

    toggleModal('productAdjustModal');
  };

  const handleAddToCart = (adjustedProduct) => {
    const { amount, measure, complementsTotal, price } = adjustedProduct;
    addProductToOrder({ product: adjustedProduct });
    Analytics.event({
      category: analyticsEventCategory.ORDER,
      action: analyticsEventActions.ADD_PRODUCT,
      label: adjustedProduct.name,
      value:
        measure !== 'UNIT'
          ? (amount * price) / 10
          : (price + complementsTotal) * amount,
    });
    closeAllModals();
  };

  const handleAddComboToCart = (adjustedCombo) => {
    const { amount, price } = adjustedCombo;
    addProductToOrder({ product: adjustedCombo });
    Analytics.event({
      category: analyticsEventCategory.ORDER,
      action: analyticsEventActions.ADD_PRODUCT,
      label: adjustedCombo.name,
      value: price * amount,
    });
    closeAllModals();
  };

  const handleEmptyCart = () => {
    toggleModal('clearCartModal');
    Analytics.event({
      category: analyticsEventCategory.ORDER,
      action: analyticsEventActions.CLEAR_PRODUCTS,
      label: 'Limpeza de produtos no carrinho',
    });
    clearOrder();
  };

  const handleOpenSkuReaderModal = () => {
    if (selectedStore) toggleModal('skuReaderModal');
  };

  const handleCloseSkuReaderModal = () => {
    toggleModal('skuReaderModal');
  };

  const handleSkuReaderSuccess = (newProduct) => {
    setCurrentProduct(newProduct);
    toggleModal('productAdjustModal');
    toggleModal('skuReaderModal');
  };

  const handleOpenShippingTypeModal = () => {
    toggleModal('shippingTypeModal');
  };

  const handleShippingTypeSelect = (newShippingType) => {
    Analytics.event({
      category: analyticsEventCategory.ORDER,
      action: analyticsEventActions.SELECT_SHIPPING,
      label: shippingTypeMap[newShippingType],
    });
    setShippingType({ shippingType: newShippingType });
    SessionStorageManager.removeOrderDeliverySchedule();
    toggleModal('shippingTypeModal');
  };

  const handleProductEditStart = (product) => {
    toggleModal('cartListModal');

    if (product.comboProductItems && product.comboProductItems.length > 0) {
      setEditCombo(product);
    } else {
      setEditProduct(product);
    }
  };

  const handleProductEditFinish = (newProduct) => {
    setEditProduct(null);
    toggleModal('cartListModal');
    alterProductInOrder({ product: newProduct });

    const { measure, amount, price, complementsTotal } = newProduct;
    Analytics.event({
      category: analyticsEventCategory.ORDER,
      action: analyticsEventActions.ADD_PRODUCT,
      label: newProduct.name,
      value:
        measure !== 'UNIT'
          ? (amount * price) / 10
          : (price + complementsTotal) * amount,
    });
  };

  const handleComboEditFinish = (newCombo) => {
    setEditCombo(null);
    toggleModal('cartListModal');
    alterProductInOrder({ product: newCombo });

    const { amount, price } = newCombo;
    Analytics.event({
      category: analyticsEventCategory.ORDER,
      action: analyticsEventActions.ADD_PRODUCT,
      label: newCombo.name,
      value: amount * price,
    });
  };

  const handleRemoveProductEdit = (product) => {
    setEditProduct(null);
    toggleModal('cartListModal');
    removeProductInOrder({ productOrderId: product.productOrderId });
  };

  const handleRemoveComboEdit = (combo) => {
    setEditCombo(null);
    toggleModal('cartListModal');
    removeProductInOrder({ productOrderId: combo.productOrderId });
  };

  const handleDeliveryAddressChange = (newAddress) => {
    SessionStorageManager.setOrderAddress(newAddress);

    setDeliveryAddress({ deliveryAddress: newAddress });
    setExpectedDistanceDuration({
      expected: {
        duration: newAddress.duration,
        distance: newAddress.distance,
      },
    });
    setStore({
      store: {
        ...selectedStore,
        deliveryPrice: newAddress.deliveryPrice,
        duration: newAddress.duration,
        distance: newAddress.duration,
      },
    });
    closeModals();
  };

  const handleFiltersChange = (filterItems) => {
    Analytics.event({
      category: analyticsEventCategory.ORDER,
      action: analyticsEventActions.FILTER_PRODUCTS,
      label: 'Mudança de filtros',
    });
    setAppliedFilters(filterItems);
    closeModals();
  };

  const totalPrice = useMemo(
    () =>
      getTotalPriceFromProductMenus(
        savedProducts.concat(savedPromotionalProducts),
      ),
    [savedProducts, savedPromotionalProducts],
  );

  const reviewOrder = () => {
    Analytics.event({
      category: analyticsEventCategory.ORDER,
      action: analyticsEventActions.REVIEW_ORDER,
      label: 'Review de pedido',
      value: totalPrice,
    });

    if (allowAnonymous) {
      history.push(`/${domain}/comanda`);
    } else {
      history.push(`/${domain}/pedido`);
    }
  };

  const submitDisabled =
    isFetching ||
    isLoading ||
    !selectedStore ||
    savedProducts.concat(savedPromotionalProducts).length === 0 ||
    totalPrice < minCartValue ||
    !selectedShippingType ||
    isSubmitting;

  const hasProducts =
    (savedProducts && savedProducts.length > 0) ||
    (savedPromotionalProducts && savedPromotionalProducts.length > 0);

  if (isFetching) {
    return <LoadingScreen />;
  }

  if (qrCodeOnly && !tag.tag) {
    return <Error errorMessage={t('errors:qrCodeOnly')} />;
  }

  return (
    <>
      <FiltersModal
        visible={modals.filtersModal}
        onCloseModal={closeAllModals}
        onFinish={handleFiltersChange}
        availableFilters={availableFilters}
        appliedFilters={appliedFilters}
      />
      <DeliveryAddressModal
        visible={modals.addressChangeModal}
        onClose={closeModals}
        initialAddress={deliveryAddress}
        storeId={selectedStore?.id}
        onFinish={handleDeliveryAddressChange}
      />
      <QrCodeReaderModal
        visible={modals.skuReaderModal}
        onCloseModal={handleCloseSkuReaderModal}
        onNewProductScanned={handleSkuReaderSuccess}
        onNewContext={handleNewContext}
        storeId={selectedStore && selectedStore.id}
        isLogged={isLogged}
        hasProducts={hasProducts}
      />
      <ClearCartModal
        visible={modals.clearCartModal}
        onCloseModal={closeAllModals}
        onConfirm={handleEmptyCart}
      />
      <StoreSelectModal
        visible={modals.storeSelectModal}
        onStoreChange={handleStoreSelected}
        onCloseModal={handleCloseStoreSelectModal}
        currentStore={selectedStore}
        hasProducts={hasProducts}
        deliveryAddress={deliveryAddress}
      />
      <ProductAdjustModal
        visible={modals.productAdjustModal}
        product={currentProduct}
        onCloseModal={closeAllModals}
        onAddToCart={handleAddToCart}
        selectedShippingType={selectedShippingType}
        ignoreRigidStock={ignoreRigidStock}
      />
      <ComboAdjustModal
        visible={modals.productAdjustModal}
        productCombo={currentCombo}
        onCloseModal={closeAllModals}
        onAddToCart={handleAddComboToCart}
      />
      <ProductAdjustModal
        visible={!!editProduct}
        product={editProduct}
        onCloseModal={closeAllModals}
        onAddToCart={handleProductEditFinish}
        onRemove={handleRemoveProductEdit}
        selectedShippingType={selectedShippingType}
        ignoreRigidStock={ignoreRigidStock}
      />
      <ComboAdjustModal
        visible={!!editCombo}
        productCombo={editCombo}
        onCloseModal={closeAllModals}
        onAddToCart={handleComboEditFinish}
        onRemove={handleRemoveComboEdit}
      />
      <OrderShippingTypeSelectModal
        visible={modals.shippingTypeModal}
        selectedStore={selectedStore}
        currentTag={tag?.tag}
        selectedShippingType={selectedShippingType}
        onCloseModal={closeAllModals}
        onShippingTypeSelect={handleShippingTypeSelect}
        isTagOnlyDelivery={tag?.isTagOnlyDelivery}
      />
      <OrderCartListModal
        visible={modals.cartListModal}
        onCloseModal={closeAllModals}
        onFinishOrder={reviewOrder}
        onEditProduct={handleProductEditStart}
        disableOrder={disableOrder}
      />
      <OrderCartScreen
        currentAddress={deliveryAddress}
        selectedStore={selectedStore}
        selectedShippingType={selectedShippingType}
        onShippingTypeChange={handleOpenShippingTypeModal}
        onChangeStore={handleChangeStore}
        orderProducts={savedProducts.concat(savedPromotionalProducts)}
        productGroups={countedProductMenus}
        onAddItem={handleAddItem}
        onQrCodeScan={handleOpenSkuReaderModal}
        totalPrice={totalPrice}
        isLogged={isLogged}
        onLogin={handleLogin}
        onOrderFinish={handleOrderFinish}
        isLoading={isLoading}
        submitDisabled={submitDisabled}
        hasProducts={hasProducts}
        isStoreLocked={isEventPointLocked}
        currentTag={tag?.tag}
        onDeliveryAddressChange={openDeliveryAddressModal}
        searchText={searchText}
        appliedFilters={appliedFilters}
        onSearchTextChange={handleSearchTextChange}
        onFiltersChange={openFiltersModal}
        onRemoveFilter={handleRemoveFilter}
        tagInfo={tag}
      />
    </>
  );
};

OrderCartContainer.defaultProps = {
  isEventPointLocked: false,
  selectedStore: undefined,
  tag: undefined,
  user: null,
  selectedShippingType: undefined,
  deliveryAddress: null,
  domain: null,
};

OrderCartContainer.propTypes = {
  history: PropTypes.object.isRequired,
  savedProducts: PropTypes.array.isRequired,
  savedPromotionalProducts: PropTypes.array.isRequired,
  addProductToOrder: PropTypes.func.isRequired,
  alterProductInOrder: PropTypes.func.isRequired,
  removeProductInOrder: PropTypes.func.isRequired,
  setOrder: PropTypes.func.isRequired,
  clearOrder: PropTypes.func.isRequired,
  selectedStore: PropTypes.object,
  selectedShippingType: PropTypes.string,
  setShippingType: PropTypes.func.isRequired,
  clearShippingType: PropTypes.func.isRequired,
  setStore: PropTypes.func.isRequired,
  setTag: PropTypes.func.isRequired,
  user: PropTypes.object,
  isEventPointLocked: PropTypes.bool,
  isLogged: PropTypes.bool.isRequired,
  tag: PropTypes.object,
  deliveryAddress: PropTypes.object,
  setDeliveryAddress: PropTypes.func.isRequired,
  domain: PropTypes.string,
  tenantId: PropTypes.number.isRequired,
  qrCodeOnly: PropTypes.bool.isRequired,
  minCartValue: PropTypes.number.isRequired,
  deliveryDisabled: PropTypes.bool.isRequired,
  paymentOnDeliveryOnly: PropTypes.bool.isRequired,
  removeTag: PropTypes.func.isRequired,
  setExpectedDistanceDuration: PropTypes.func.isRequired,
};

const enhance = compose(
  withContext,
  connect(mapStateToProps, mapDispatchToProps),
);

export default enhance(OrderCartContainer);
