import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'emotion-theming';
import CircleButton from '@bit/eyemobile.core.circle-button';

import { ReactComponent as KeyboardArrowDown } from '../../assets/keyboard_arrow_down.svg';

import {
  shippingTypeSelectWrapper,
  contentScroll,
  ShippingTypeItem,
  ShippingTypeItemText,
  shippingTypeListTitle,
} from './OrderShippingTypeSelect.styles';

const OrderShippingTypeSelect = ({
  disabled,
  selectedShippingType,
  shippingTypeOptions,
  onSelect,
  onClose,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const renderShippingTypes = (shippingType) => (
    <ShippingTypeItem
      key={shippingType}
      onClick={() => onSelect(shippingType)}
      onKeyDown={() => onSelect(shippingType)}
      role="presentation"
      aria-label={shippingType}
      active={selectedShippingType === shippingType}
    >
      <ShippingTypeItemText active={selectedShippingType === shippingType}>
        {t(`productOrder:${shippingType}`)}
      </ShippingTypeItemText>
    </ShippingTypeItem>
  );

  const availableShippingTypes = useMemo(() => {
    if (shippingTypeOptions.length > 0) {
      return (
        <div
          style={{
            marginLeft: theme.getSpacing(1, 'px'),
            marginRight: theme.getSpacing(1, 'px'),
          }}
        >
          {shippingTypeOptions.map(renderShippingTypes)}
        </div>
      );
    }

    return <span>{t('productOrder:noShippingTypeAvailable')}</span>;
  }, [shippingTypeOptions, disabled, selectedShippingType]);

  return (
    <div css={shippingTypeSelectWrapper}>
      <div
        style={{
          width: '100%',
          paddingRight: '8px',
          paddingBottom: theme.getSpacing(2, 'px'),
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
        }}
      >
        <CircleButton
          icon={<KeyboardArrowDown />}
          size="30px"
          onClick={onClose}
          backgroundColor="primary"
          color="background"
          padding="3px"
        />
      </div>
      <span css={shippingTypeListTitle}>
        {t('productOrder:selectShippingType')}
      </span>
      {shippingTypeOptions.length > 0 ? (
        <div css={contentScroll}>{availableShippingTypes}</div>
      ) : (
        <span>{t('productOrder:noShippingTypeAvailable')}</span>
      )}
    </div>
  );
};

OrderShippingTypeSelect.defaultProps = {
  disabled: false,
  selectedShippingType: null,
};

OrderShippingTypeSelect.propTypes = {
  disabled: PropTypes.bool,
  shippingTypeOptions: PropTypes.array.isRequired,
  selectedShippingType: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default OrderShippingTypeSelect;
