import { css } from '@emotion/core';
import styled from '@emotion/styled';

export const confirmationWrapper = css`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const headerText = (theme) => css`
  width: 100%;
  text-align: center;
  color: ${theme && theme.getColor('text')};
  font-family: ${theme && theme.getFont('heading')};
  font-size: ${theme && theme.getFontSize(1.2, 'px')};
  font-weight: bold;
  padding-bottom: ${theme && theme.getSpacing(1, 'px')};
`;

export const ModalText = styled.span`
  text-align: center;
  color: ${({ theme }) => theme && theme.getColor('text')};
  font-family: ${({ theme }) => theme && theme.getFont()};
  font-size: ${({ theme }) => theme && theme.getFontSize(1, 'px')};
  margin-top: ${({ theme }) => theme && theme.getSpacing(1, 'px')};
  margin-bottom: ${({ theme }) => theme && theme.getSpacing(1, 'px')};

  ${({ bold }) => bold && 'font-weight: bold;'}
`;

export const spacedRow = css`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const buttonRow = (theme) => css`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: ${theme && theme.getSpacing(2, 'px')};
`;

export const buttonWrapper = (theme) => css`
  margin-right: ${theme && theme.getSpacing(0.5, 'px')};
  margin-left: ${theme && theme.getSpacing(0.5, 'px')};
  width: 100%;
`;
