import { useEffect } from 'react';
import PropTypes from 'prop-types';

const CacheBusterOverlay = ({ isLatestVersion, reloadApp }) => {
  useEffect(() => {
    if (!isLatestVersion) {
      console.warn('Clearing cache');
      reloadApp();
    }
  }, [isLatestVersion, reloadApp]);

  return true;
};

CacheBusterOverlay.propTypes = {
  isLatestVersion: PropTypes.bool.isRequired,
  reloadApp: PropTypes.func.isRequired,
};

export default CacheBusterOverlay;
