import { css } from '@emotion/core';

export const linkCss = (theme) => css`
  color: ${theme.getColor('text')};
  font-size: ${theme.getFontSize(0.85, 'px')};
  font-family: ${theme.getFont('futura')};
  padding: ${theme.getSpacing(1, 'px')};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
`;

export const buttonsWrapper = css`
  max-width: 100%;
  display: flex;
  flex-direction: column;
`;
