import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import useQuery from '../../utils/useQuery';
import * as registerFormActions from '../../store/modules/registerForm';
import RegisterErrorScreen from '../../components/Error';

const mapStateToProps = ({ tenantEyepass: { domain } }) => ({
  domain,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      clearRegisterForm: registerFormActions.clearRegisterForm,
    },
    dispatch,
  );

const RegisterErrorContainer = ({ history, clearRegisterForm, domain }) => {
  const { t } = useTranslation();
  const query = useQuery();

  const errorMessage =
    query.get('e') || t('registrationError:registrationNotPossible');

  const handleReturn = () => {
    clearRegisterForm();
    history.push(`/${domain}/login`);
  };

  return (
    <RegisterErrorScreen onReturn={handleReturn} errorMessage={errorMessage} />
  );
};

RegisterErrorContainer.defaultProps = {
  domain: null,
};

RegisterErrorContainer.propTypes = {
  history: PropTypes.object.isRequired,
  clearRegisterForm: PropTypes.func.isRequired,
  domain: PropTypes.string,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RegisterErrorContainer);
