import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { withFormik } from 'formik';
import Button from '@bit/eyemobile.core.button';

import { ButtonPadding } from '../Form/Form.styles';
import RowRadioButton from '../RowRadioButton';
import PaymentDataForm from '../PaymentDataForm';
import creditCardFormSchema from './creditCardFormSchema';

const CreditCardForm = ({
  values,
  touched,
  errors,
  handleBlur,
  handleChange,
  handleSubmit,
  setFieldValue,
  isSubmitting,
  isValid,
  onCancel,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <PaymentDataForm
        values={values}
        touched={touched}
        errors={errors}
        handleBlur={handleBlur}
        handleChange={handleChange}
      />
      <RowRadioButton
        name="turn-card-main"
        checked={values.isMain}
        onChange={() => setFieldValue('isMain', !values.isMain)}
        label={t('cardsPage:turnMain')}
        setFieldValue={setFieldValue}
        style={{ marginBottom: '16px' }}
      />
      <ButtonPadding hasMultiButtons={!!onCancel}>
        {!!onCancel && (
          <Button onClick={onCancel} color="error" disabled={isSubmitting}>
            {t('forms:cancel')}
          </Button>
        )}
        <Button
          isLoading={isSubmitting}
          onClick={handleSubmit}
          color="primary"
          disabled={isSubmitting || !isValid}
        >
          {t('forms:confirm')}
        </Button>
      </ButtonPadding>
    </>
  );
};

CreditCardForm.propTypes = {
  values: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
  onCancel: PropTypes.func,
};

CreditCardForm.defaultProps = {
  onCancel: null,
};

const formikEnhance = withFormik({
  mapPropsToValues: ({ initialValues }) => ({
    creditCardNumber: initialValues?.creditCardNumber || '',
    creditCardHolderName: initialValues?.creditCardHolderName || '',
    creditCardExpiration: initialValues?.creditCardExpiration || '',
    creditCardCvv: initialValues?.creditCardCvv || '',
    creditCardTokenize: initialValues?.creditCardTokenize || '',
    isMain: initialValues?.isMain || '',
  }),
  validationSchema: creditCardFormSchema,
  handleSubmit: (values, { props, dirty, ...formikBag }) => {
    const { onFormSubmit } = props;
    onFormSubmit(values, formikBag);
  },
});

const enhanced = formikEnhance(CreditCardForm);

enhanced.defaultProps = {
  onCancel: null,
};

enhanced.propTypes = {
  initialValues: PropTypes.object.isRequired,
  onFormSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
};

export default enhanced;
