import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import LoadingScreen from '../../components/LoadingScreen';
import gateway from '../../utils/gateway';
import arrayMove from '../../utils/arrayMove';
import LoyaltyService from '../../services/loyalty';

import LoyaltyPageScreen from './LoyaltyPageScreen';

const mapStateToProps = ({ tenantEyepass: { domain } }) => ({
  domain,
});

const LoyaltyPageContainer = ({ domain }) => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [loyalties, setLoyalties] = useState([]);
  const [hasError, setHasError] = useState(false);

  const navigateToHome = () => history.push(`/${domain}/carrinho`);

  const fetchCustomerLoyalties = async () => {
    try {
      setIsLoading(true);

      const response = await gateway
        .get(LoyaltyService.getLoyalties())
        .then(({ data }) => data);

      const newLoyalties = response.loyalties;

      const indexCashback = newLoyalties.findIndex(({ cashback }) => cashback);
      if (indexCashback > -1) {
        setLoyalties(arrayMove(newLoyalties, indexCashback, 0));
      } else {
        setLoyalties(newLoyalties);
      }
    } catch (e) {
      console.error(e);
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchCustomerLoyalties();
  }, []);

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <LoyaltyPageScreen
      loyalties={loyalties}
      hasError={hasError}
      onNavigate={navigateToHome}
    />
  );
};

LoyaltyPageContainer.defaultProps = {};

LoyaltyPageContainer.propTypes = {
  domain: PropTypes.string.isRequired,
};

export default connect(mapStateToProps)(LoyaltyPageContainer);
