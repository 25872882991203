const { REACT_APP_SERVER_ENV } = process.env;

export const shippingTypes = {
  ADDRESS: 'ADDRESS',
  TAG: 'TAG',
  TAKEOUT: 'TAKEOUT',
};

export const paymentTypes = {
  CASH: 'CASH',
  CREDIT_CARD: 'CREDIT_CARD',
  DEBIT_CARD: 'DEBIT_CARD',
  CREDIT_CARD_ONLINE: 'CREDIT_CARD_ONLINE',
  PIX: 'PIX',
};

export const measureTypes = {
  1: 'UNIT',
  2: 'kG',
  3: 'L',
};

export const smallMeasureTypesMap = {
  UNIT: 'un',
  kG: 'g',
  L: 'mL',
};

export const orderStatusTypes = {
  0: 'REGISTERED',
  1: 'PREPARING',
  2: 'READY',
  3: 'DELIVERED',
  4: 'WAITING_PAYMENT',
  5: 'REJECTED',
  6: 'CANCELED',
  7: 'PAYMENT_CANCELED',
  8: 'CANCELLED_BY_OPERATOR',
  9: 'COLLECTED',
};

export const deliveryTypes = {
  0: shippingTypes.TAG,
  1: shippingTypes.TAKEOUT,
  2: shippingTypes.ADDRESS,
};

export const paymentProviders = {
  PAGARME: 1,
  GETNET: 4,
};

export const packageDeliveryStatus = {
  0: 'PACKAGE_REQUESTED',
  1: 'PACKAGE_CONFIRMED',
  2: 'PACKAGE_WITHDRAWN',
  3: 'PACKAGE_DELIVERED',
  4: 'PACKAGE_SCHEDULED',
  5: 'PACKAGE_OTHER',
};

export const productTypes = {
  DEFAULT: 0,
  GIFT_CARD: 1,
};

export const fetchLimit = 20;

export const tenantAttachmentsFolder =
  'https://s3-sa-east-1.amazonaws.com/eyemobile-cloud/files/tenants';

export const imagesUrl =
  REACT_APP_SERVER_ENV === 'production'
    ? 'https://images.eyemobile.com.br'
    : 'https://eyemobile-public-staging.s3.amazonaws.com';

export const avatarBucketRegion =
  REACT_APP_SERVER_ENV === 'production' ? 'sa-east-1' : 'us-east-1';

export const analyticsEventActions = {
  CLICK: 'CLICK',
  GOOGLE_LOGIN: 'GOOGLE_LOGIN',
  FACEBOOK_LOGIN: 'FACEBOOK_LOGIN',
  REGISTRATION_START: 'CADASTRO_INICIO',
  REGISTRATION_END: 'CADASTRO_FIM',
  LOGIN: 'LOGIN',
  LOGOUT: 'LOGOUT',
  TRACKING_ORDER: 'ACOMPANHANDO_PEDIDO',
  NEW_PASSWORD_REQUEST: 'REQUISICAO_NOVA_SENHA',
  NEW_PASSWORD_SET: 'NOVA_SENHA_DEFINIDA',
  FILTER_PRODUCTS: 'PRODUTOS_FILTRADOS',
  SELECT_STORE: 'LOJA_SELECIONADA',
  SELECT_SHIPPING: 'FORMA_DE_ENTREGA_SELECIONADA',
  PRODUCT_SCANNED: 'PRODUTO_ESCANEADO',
  ADD_PRODUCT: 'PRODUTO_ADICIONADO',
  EDIT_PRODUCT: 'PRODUTO_EDITADO',
  REMOVE_PRODUCT: 'PRODUTO_REMOVIDO',
  CLEAR_PRODUCTS: 'CARRINHO_ESVAZIADO',
  REVIEW_ORDER: 'REVIEW_PEDIDO',
  FINISH_ORDER: 'PEDIDO_FINALIZADO',
  SCHEDULE_ORDER: 'PEDIDO_AGENDADO',
  ADD_CREDIT_CARD: 'CARTAO_ADICIONADO',
  REMOVE_CREDIT_CARD: 'CARTAO_REMOVIDO',
  ADD_ADDRESS: 'ENDERECO_ADICIONADO',
  EDIT_ADDRESS: 'ENDERECO_EDITADO',
  REMOVE_ADDRESS: 'ENDERECO_REMOVIDO',
  DELETE_USER_DATA: 'DADOS_USUARIO_REMOVIDO',
};

export const analyticsEventCategory = {
  META: 'META',
  ORDER: 'ORDER',
  CUSTOMER: 'CUSTOMER',
};

export const languageFlagMap = {
  pt: 'BR',
  en: 'US',
  es: 'ES',
  fr: 'FR',
};

export const appleWebsitePushIdentifier = 'web.com.eyepass.app';

export const currentEnv = REACT_APP_SERVER_ENV;
