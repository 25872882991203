import { combineReducers } from 'redux';
import { all } from 'redux-saga/effects';

import {
  reducers as exampleReducers,
  sagas as exampleSagas,
} from './modules/example';

import { reducers as userReducers } from './modules/user';
import { reducers as authReducers, sagas as authSagas } from './modules/auth';
import { Types as AppTypes } from './modules/app';
import { reducers as registerFormReducers } from './modules/registerForm';
import { reducers as productOrderReducers } from './modules/productOrder';
import { reducers as tagReducers } from './modules/tag';
import { reducers as orderHistoryReducers } from './modules/orderHistory';
import { reducers as tenantEyepassReducers } from './modules/tenantEyepass';
import { reducers as languageReducers } from './modules/language';

export function* rootSaga() {
  yield all([exampleSagas(), authSagas()]);
}

export const appReducer = combineReducers({
  example: exampleReducers,
  user: userReducers,
  auth: authReducers,
  registerForm: registerFormReducers,
  productOrder: productOrderReducers,
  tag: tagReducers,
  orderHistory: orderHistoryReducers,
  tenantEyepass: tenantEyepassReducers,
  language: languageReducers,
});

const rootReducer = (state, action) => {
  if (action.type === AppTypes.RESET_APP) {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default rootReducer;
