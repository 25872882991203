import { css } from '@emotion/core';

import { mq } from '../../utils/commonMqs';

export const section = (theme) => css`
  padding: ${theme && theme.getSpacing(2, 'px')};
  margin-bottom: 40px;
`;

export const title = (theme) => css`
  padding-top: ${theme && theme.getSpacing(2, 'px')};
  padding-bottom: ${theme && theme.getSpacing(2, 'px')};
  text-align: left;
  font-size: ${theme && theme.getFontSize(1, 'px')};
  font-family: ${theme && theme.getFont()};
  color: ${theme && theme.getColor('text')};
  font-weight: bold;
  text-transform: uppercase;
`;

export const backgroundCss = (theme) => css`
  width: 100%;
  height: 100%;
  background-color: ${theme && theme.getColor('foreground')};
`;

export const wrapperCss = (theme) => css`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: ${theme.getSpacing(1, 'px')};
  padding-right: ${theme.getSpacing(1, 'px')};
`;

export const iconCss = (theme) => css`
  width: 120px;
  height: 120px;
  object-fit: contain;
  margin: ${theme.getSpacing(2, 'px')};
`;

export const messageCss = (theme) => css`
  font-family: ${theme && theme.getFont('heading')};
  font-size: ${theme && theme.getFontSize(1, 'px')};
  color: ${theme && theme.getColor('uiText')};
  text-align: center;
`;

export const buttonWrapperCss = (theme) => css`
  ${mq({
    paddingTop: [
      theme.getSpacing(1, 'px'),
      theme.getSpacing(2, 'px'),
      theme.getSpacing(4, 'px'),
    ],
  })}
`;
