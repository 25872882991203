import styled from '@emotion/styled';
import facepaint from 'facepaint';

const mq = facepaint(['@media screen and (min-width: 375px)']);

const getSize = () => {
  return mq({
    width: [16, 24],
    height: [16, 24],
  });
};

const getColor = ({ theme }) => {
  if (theme) {
    return theme.getColor('secondary');
  }

  return false;
};

export const CornerButton = styled.button`
  ${getSize};
  outline: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
  margin: ${({ theme }) => theme && theme.getSpacing(1, 'px')};
  color: ${getColor};
  padding: 0;
  flex-shrink: 0;

  ${({ disabled }) =>
    disabled && 'opacity: 0.5; cursor: default; pointer-events: none;'}

  svg {
    width: 100%;
    height: 100%;
  }
`;

export const iconCss = {
  width: '100%',
  height: '100%',
  objectFit: 'contain',
};
