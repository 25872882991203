import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getToken, onMessageListener } from '../../utils/firebase';
import showToast from '../../utils/showToast';

const mapStateToProps = ({ tenantEyepass: { tenantId } }) => ({
  tenantId,
});

const PushNotificationContainer = ({ children, tenantId }) => {
  const handleNotificationConfig = async () => {
    try {
      await getToken();
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    handleNotificationConfig();
  }, []);

  onMessageListener()
    .then((payload) => {
      console.log({ payload }); // eslint-disable-line
      const { notification } = payload;
      const { body, title } = notification;

      showToast(`${title}: ${body}`, 'info');
    })
    .catch((err) => {
      console.log(err); // eslint-disable-line
    });

  return <>{children}</>;
};

PushNotificationContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
    PropTypes.array,
  ]).isRequired,
  tenantId: PropTypes.number.isRequired,
};

export default connect(mapStateToProps)(PushNotificationContainer);
