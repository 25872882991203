import { css } from '@emotion/core';
import styled from '@emotion/styled';
import facepaint from 'facepaint';

const tabletMq = facepaint(['@media screen and (min-width: 720px)']);

export const contentWrapper = (theme) => css`
  padding-top: ${theme && theme.getSpacing(3, 'px')};
  padding-bottom: 50px;
  ${tabletMq({
    paddingLeft: ['0px', theme && theme.getSpacing(3, 'px')],
    paddingRight: ['0px', theme && theme.getSpacing(3, 'px')],
  })}
`;

export const tabWrapper = (theme) => css`
  width: 100%;
  padding: ${theme && theme.getSpacing(2, 'px')};
`;

export const tabTitleWrapper = (theme) => css`
  width: 100%;
  margin-bottom: ${theme && theme.getSpacing(3, 'px')};
`;

export const orderButtonWrapper = (theme) => css``;

export const tabTitle = (theme) => css`
  text-align: left;
  font-size: ${theme && theme.getFontSize(1, 'px')};
  font-family: ${theme && theme.getFont('futura')};
  color: ${theme && theme.getColor('text')};
  text-transform: uppercase;
`;

export const filterOrderButtonsWrapper = css`
  flex: 0;
  display: flex;
  flex-direction: row;
`;

export const FilterOrderButton = styled.button`
  min-height: 40px;
  padding: ${({ theme }) => theme && theme.getSpacing(1, 'px')};
  margin: ${({ theme }) => theme && theme.getSpacing(1, 'px')};
  outline: none;
  border: none;
  cursor: pointer;
  border-radius: 20px;
  background-color: ${({ theme }) => theme && theme.getColor('primary')};
  color: ${({ theme }) => theme && theme.getColor('lightText')};
  font-size: ${({ theme }) => theme && theme.getFontSize()};
  font-family: ${({ theme }) => theme && theme.getFont()};

  ${({ inactive }) => inactive && 'opacity: 0.5;'}
`;

export const addressModalWrapper = (theme) => css`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding-left: ${theme && theme.getSpacing(1, 'px')};
  padding-right: ${theme && theme.getSpacing(1, 'px')};
  padding-bottom: ${theme && theme.getSpacing(1, 'px')};
`;

export const radioBoxContent = css`
  width: 100%;
`;

export const radioBoxTitle = (theme) => css`
  color: ${theme && theme.getColor()};
  font-family: ${theme && theme.getFont('futura')};
  font-size: ${theme && theme.getFontSize(1, 'px')};
  display: block;
  margin-bottom: ${theme && theme.getSpacing(1, 'px')};
`;

export const radioBoxDescription = (theme) => css`
  color: ${theme && theme.getColor()};
  font-family: ${theme && theme.getFont()};
  font-size: ${theme && theme.getFontSize(0.8, 'px')};
  display: block;
  line-height: 1.4;

  & + button {
    margin-top: ${theme && theme.getSpacing(1, 'px')};
  }
`;

export const radioBoxBordered = (theme) => css`
  padding-top: ${theme && theme.getSpacing(2, 'px')};
  padding-bottom: ${theme && theme.getSpacing(2, 'px')};
  padding-left: ${theme && theme.getSpacing(2, 'px')};
  padding-right: ${theme && theme.getSpacing(2, 'px')};
  border: 1px solid #cccccc;
  border-radius: 10px;

  &:not(:last-child) {
    margin-bottom: ${theme && theme.getSpacing(3, 'px')};
  }
`;

export const radioBoxButton = {
  root: (theme) => ({
    fontSize: theme.getFontSize(0.75, 'px'),
    padding: '8px 16px',
    minHeight: 40,
    justifyContent: 'space-between',
  }),
  icon: () => ({
    width: 24,
    height: 24,
  }),
};

export const radioBoxPrice = (theme) => css`
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
`;

export const shippingTypeErrorWrapper = (theme) => css`
  width: 100%;
  padding-top: ${theme && theme.getSpacing(1, 'px')};
`;

export const shippingTypeErrorText = (theme) => css`
  color: ${theme && theme.getColor('error')};
  font-family: ${theme && theme.getFont()};
  font-size: ${theme && theme.getFontSize(1, 'px')};
  width: 100%;
`;

export const priceRelationWrapper = (theme) => css`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: ${theme && theme.getSpacing(1, 'px')};
`;

export const priceRelationRow = (theme) => css`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: ${theme && theme.getSpacing(1, 'px')};
`;

export const PriceRelationText = styled.span`
  color: ${({ theme, color }) => theme && theme.getColor(color || 'text')};
  font-family: ${({ theme }) => theme && theme.getFont('futura')};
  font-size: ${({ theme, big }) =>
    theme && theme.getFontSize(big ? 1.2 : 1, 'px')};

  ${({ bold }) => bold && 'font-weight: bold;'}
`;

export const Divisor = styled.div`
  width: 100%;
  height: 2px;
  opacity: 0.25;
  border-bottom: 2px dashed ${({ theme }) => theme && theme.getColor('text')};
  margin-top: ${({ theme }) => theme && theme.getSpacing(1, 'px')};
  margin-bottom: ${({ theme }) => theme && theme.getSpacing(1, 'px')};
`;

export const clearOrderText = (theme) => css`
  color: ${theme && theme.getColor('error')};
  font-size: ${theme && theme.getFontSize(1, 'px')};
  padding: ${theme && theme.getSpacing(1, 'px')};
  text-align: center;
  margin-top: ${theme && theme.getSpacing(2, 'px')};
  cursor: pointer;
  text-decoration: underline;
  outline: none;
`;

export const loyaltiesLoadingWrapper = (theme) => css`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${theme && theme.getSpacing(1, 'px')};
`;

const overlay = {
  padding: 0,
  top: 0,
  height: '100vh',
  position: 'fixed',
};

export const addressModalStyles = {
  innerWrapper: {
    top: 20,
    maxWidth: 480,
    transform: 'translateX(-50%)',
    height: 'calc(100% - 20px)',
    width: '100%',
    paddingLeft: '13px',
    paddingRight: '13px',
  },
  content: {
    borderTopLeftRadius: '22px',
    borderTopRightRadius: '22px',
    borderBottomLeftRadius: '0px',
    borderBottomRightRadius: '0px',
    boxShadow: 'none',
    padding: 0,
    paddingTop: '20px',
    height: '100%',
  },
  overlay,
};
export const deliveryScheduleModalStyles = {
  innerWrapper: {
    top: 20,
    maxWidth: 480,
    transform: 'translateX(-50%)',
    height: 'calc(100% - 20px)',
    width: '100%',
    paddingLeft: '13px',
    paddingRight: '13px',
  },
  content: {
    borderTopLeftRadius: '22px',
    borderTopRightRadius: '22px',
    borderBottomLeftRadius: '0px',
    borderBottomRightRadius: '0px',
    boxShadow: 'none',
    padding: 0,
    paddingTop: '20px',
    height: '100%',
  },
  overlay,
};

export const changeExplanation = (theme) => css`
  color: ${theme && theme.getColor('error')};
  font-size: ${theme && theme.getFontSize(1, 'px')};
  font-family: ${theme && theme.getFont()};
  margin-top: 4px;
  width: 100%;
  display: block;
  text-align: center;
`;

export const scheduleButtonsRow = (theme) => css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: ${theme && theme.getSpacing(2, 'px')};
`;

export const modalTitle = (theme) => css`
  font-size: ${theme && theme.getFontSize(1.12, 'px')};
  text-transform: uppercase;
  display: block;
  margin: 0 auto;
  width: 235px;
  text-align: center;
`;

export const discountInputGroup = (theme) => css`
  display: flex;
  margin-top: ${theme && theme.getSpacing(4, 'px')};
  margin-bottom: ${theme && theme.getSpacing(2, 'px')};

  & > div:not(:last-child) {
    margin-right: ${theme && theme.getSpacing(2, 'px')};
  }
`;

export const contentScroll = (theme) => css`
  width: 100%;
  display: block;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  padding-right: ${theme && theme.getSpacing(1, 'px')};
  padding-left: ${theme && theme.getSpacing(1, 'px')};

  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }
  &::-webkit-scrollbar:vertical {
    width: 8px;
  }
  &::-webkit-scrollbar:horizontal {
    height: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    border: 2px solid #ffffff;
  }
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #ffffff;
  }
`;

export const giftCardInput = {
  borderBottom: 'none',
  border: '1px solid #000000',
  borderRadius: 20,
  textAlign: 'center',
};

export const orderClearButtonWrapper = (theme) => css`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: ${theme.getSpacing(1, 'px')};
`;

export const fieldTitle = (theme) => css`
  font-size: ${theme.getFontSize(1, 'px')};
  line-height: ${theme.getFontSize(1.3, 'px')};
  font-family: ${theme.getFont('futura')};
  color: ${theme.getColor('text')};
  display: flex;
  align-items: center;
  border: none;
  width: 100%;
  margin: 20px 0 8px 0;
  text-transform: uppercase;
  outline: 0;
`;

export const orderCommentInput = (theme) => css`
  width: 100%;
  outline: none;
  height: 100px;
  border: 1px solid #cbcbcb;
  font-family: ${theme && theme.getFont()};
  font-size: ${theme && theme.getFontSize(1.15, 'px')};
  border-radius: 8px;
  text-align: left;
  resize: none;
  padding: ${theme && theme.getSpacing(1, 'px')};
  color: ${theme && theme.getColor('text')};
`;
