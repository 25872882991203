import React from 'react';
import PropTypes from 'prop-types';
import QrReader from 'react-qr-reader';

import { qrReaderStyle } from './QrCodeReader.styles';

const QrCodeReader = ({ onQrScan, onQrError, onQrReady }) => {
  return (
    <QrReader
      delay={800}
      onError={onQrError}
      onScan={onQrScan}
      onLoad={onQrReady}
      style={qrReaderStyle}
      resolution={500}
      showViewFinder={false}
    />
  );
};

QrCodeReader.defaultProps = {
  onQrReady: undefined,
};

QrCodeReader.propTypes = {
  onQrScan: PropTypes.func.isRequired,
  onQrError: PropTypes.func.isRequired,
  onQrReady: PropTypes.func,
};

export default QrCodeReader;
