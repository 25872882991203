import { css } from '@emotion/core';

export const dropdownWrapper = css`
  position: relative;
  width: 100%;
`;

export const dropdownMenu = (theme) => css`
  position: absolute;
  width: 100%;
  z-index: 1000;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  background-color: ${theme && theme.getColor('background')};
  padding: ${theme && theme.getSpacing(1, 'px')};
  border-radius: 10px;
  border: 1px solid ${theme && theme.getColor('default')};
  transform: translate3d(0px, 48px, 0px);
  float: left;
`;

export const optionCss = (theme) => css`
  font-family: ${theme && theme.getFont()};
  font-size: ${theme && theme.getFontSize(1, 'px')};
  color: ${theme && theme.getColor('text')};
  min-width: 100px;
  &:hover,
  &:focus {
    text-decoration: underline;
  }
  cursor: pointer;
  padding: ${theme && theme.getSpacing(1, 'px')};
  white-space: nowrap;
`;
