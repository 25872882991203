import styled from '@emotion/styled';

export const Wrapper = styled.div`
  width: 100%;
  display: block;
`;

export const Button = styled.button`
  font-size: ${({ theme }) => theme && theme.getFontSize(1, 'px')};
  line-height: ${({ theme }) => theme.getFontSize(1.3, 'px')};
  font-family: ${({ theme }) => theme && theme.getFont('futura')};
  color: ${({ theme }) => theme && theme.getColor('text')};
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  width: 100%;
  cursor: pointer;
  padding: 8px 0;
  text-transform: uppercase;
  outline: 0;
`;

export const Content = styled.div`
  height: ${({ isOpen }) => (isOpen ? 'auto' : '0')};
  transition: height 0.2s;
  overflow: hidden;
  padding: ${({ isOpen }) => (isOpen ? '8px 0 16px' : '0')};
`;
