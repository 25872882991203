import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { withFormik } from 'formik';

import PaymentLinkHeader from '../../components/PaymentLinkHeader';
import ScrollableView from '../../components/ScrollableView';
import PaymentLinkFooter from '../../components/PaymentLinkFooter';
import PaymentLinkForm from '../../components/PaymentLinkForm';
import ProductReviewItem from '../../components/ProductReviewItem';
import ComboReviewItem from '../../components/ComboReviewItem';
import InstallmentsModal from '../../components/InstallmentsModal';
import { adaptDocument } from '../../utils/adaptField';
import getProductImageSrc from '../../utils/getProductImageSrc';
import rebuildPaymentLinkComplementGroups from '../../utils/rebuildPaymentLinkComplementGroups';
import localizePrice from '../../utils/localizePrice';
import { screenWrapper } from '../Page.styles';
import { measureTypes } from '../../utils/constants';

import { section, title } from './PaymentLinkPage.styles';
import PaymentLinkPriceRelations from './PaymentLinkPriceRelations';
import paymentLinkFormSchema from './paymentLinkFormSchema';

const PaymentLinkScreen = ({
  transaction,
  values,
  touched,
  handleChange,
  handleBlur,
  handleSubmit,
  setFieldValue,
  isSubmitting,
  errors,
  isValid,
}) => {
  const { t } = useTranslation();
  const { point } = transaction.event_point;
  const { tenant } = transaction.event_point.point;
  const {
    transaction_items: items,
    transaction_combos: combos,
    delivery_fee: deliveryFee,
    transaction_pays: transactionPays,
  } = transaction;

  const [installmentsModalOpen, setInstallmentsModalOpen] = useState(false);

  const toggleInstallmentsModal = () =>
    setInstallmentsModalOpen((prev) => !prev);

  const handleNewInstallmentSelect = (newInstallments) => {
    setFieldValue('installments', newInstallments);
    setInstallmentsModalOpen(false);
  };

  const adaptedItems = combos
    .map((item) => ({
      ...item,
      comboProductItems: item.transaction_items.map((comboItem) => ({
        id: comboItem.product.id,
        itemName: comboItem.product_name,
        itemImage: comboItem.product?.image,
        itemPrice: comboItem.price,
        itemQuantity: comboItem.quantity,
        itemMeasure: measureTypes[comboItem.measure] || 'UNIT',
        itemDescription: comboItem.product.description,
        itemTotalPrice: comboItem.total,
        itemComment: comboItem.comment,
      })),
    }))
    .concat(
      items.map((item) => ({
        ...item,
        complementGroups: item.transaction_item_complements
          ? rebuildPaymentLinkComplementGroups(
              item.transaction_item_complements,
            )
          : [],
      })),
    );

  const renderItem = (item) => {
    const {
      id,
      product_name: name,
      price,
      quantity,
      measure,
      product: { image },
      complementGroups,
      comboProductItems,
    } = item;
    const itemTotal = price * quantity;

    let unit = 'x';
    if (measure === 2) {
      unit = 'KG';
    } else if (measure === 3) {
      unit = 'L';
    }

    const productImgSrc = getProductImageSrc(image);

    const productQuantityDescription =
      !measure || measure === 1 ? `${quantity}` : `${quantity * 100} ${unit}`;

    if (comboProductItems && comboProductItems.length > 0) {
      return (
        <ComboReviewItem
          key={`combo-item-${id}`}
          comboImgSrc={productImgSrc}
          comboName={name}
          quantity={quantity}
          quantityDescription={productQuantityDescription}
          comboPrice={`R$ ${localizePrice(itemTotal)}`}
          comboProductItems={comboProductItems}
          editable={false}
        />
      );
    }

    return (
      <ProductReviewItem
        key={`item-${id}`}
        productImgSrc={productImgSrc}
        productName={name}
        productPrice={`R$ ${localizePrice(itemTotal)}`}
        quantityDescription={productQuantityDescription}
        complementGroups={complementGroups}
        editable={false}
      />
    );
  };

  return (
    <>
      <InstallmentsModal
        visible={installmentsModalOpen}
        onClose={toggleInstallmentsModal}
        paymentValue={transaction.total}
        initialInstallment={values.installments}
        onInstallmentSelect={handleNewInstallmentSelect}
      />
      <div css={screenWrapper}>
        <PaymentLinkHeader storeName={`${tenant.label} - ${point.name}`} />
        <ScrollableView hasHeader hasFooter hasBottomTabs={false}>
          <div css={section}>
            <div css={title}>{t('paymentLink:myOrder')}</div>
            {adaptedItems.length === 0 && (
              <span>{t('paymentLink:noProducts')}</span>
            )}
            {adaptedItems.map(renderItem)}
          </div>
          <PaymentLinkPriceRelations
            totalPrice={
              deliveryFee ? transaction.total - deliveryFee : transaction.total
            }
            finalPrice={transaction.total}
            deliveryFee={deliveryFee}
            transactionPays={transactionPays}
          />
          <div css={section}>
            <div css={title}>{t('paymentLink:customerData')}</div>
            <PaymentLinkForm
              values={values}
              touched={touched}
              errors={errors}
              handleBlur={handleBlur}
              handleChange={handleChange}
              setFieldValue={setFieldValue}
              onInstallmentsChange={toggleInstallmentsModal}
              finalPrice={transaction.total}
            />
          </div>
        </ScrollableView>
        <PaymentLinkFooter
          buttonText={t('paymentLink:executePayment')}
          onButtonClick={handleSubmit}
          disabled={!isValid || isSubmitting}
          isLoading={isSubmitting}
        />
      </div>
    </>
  );
};

PaymentLinkScreen.propTypes = {
  transaction: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  errors: PropTypes.object.isRequired,
  isValid: PropTypes.bool.isRequired,
};

const formikEnhance = withFormik({
  mapPropsToValues: ({ initialValues }) => ({
    document: initialValues.document
      ? adaptDocument(initialValues.document)
      : '',
    hasDocument: !!initialValues.document,
    email: initialValues.email || '',
    creditCardNumber: initialValues.creditCardNumber || '',
    creditCardHolderName: initialValues.creditCardHolderName || '',
    creditCardExpiration: initialValues.creditCardExpiration || '',
    creditCardCvv: initialValues.creditCardCvv || '',
    payLinkType: initialValues.payLinkType || 'CREDIT_CARD',
    installments: initialValues.installments || 1,
  }),
  validationSchema: paymentLinkFormSchema,
  handleSubmit: (values, { props, dirty, ...formikBag }) => {
    const { onFormSubmit } = props;
    onFormSubmit(values, formikBag);
  },
  enableReinitialize: true,
});

const enhancedForm = formikEnhance(PaymentLinkScreen);

enhancedForm.propTypes = {
  initialValues: PropTypes.shape({
    document: PropTypes.string,
    email: PropTypes.string,
    creditCardNumber: PropTypes.string,
    creditCardHolderName: PropTypes.string,
    creditCardExpiration: PropTypes.string,
    creditCardCvv: PropTypes.string,
  }).isRequired,
  onFormSubmit: PropTypes.func.isRequired,
};

export default enhancedForm;
