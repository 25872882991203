import HomePage from '../pages/HomePage';
import RequiredDataPage from '../pages/RequiredDataPage';
import OptionalDataPage from '../pages/OptionalDataPage';
import DataSentPage from '../pages/DataSentPage';
import RegisterErrorPage from '../pages/RegisterErrorPage';
import OrderCartPage from '../pages/OrderCartPage';
import OrderReviewPage from '../pages/OrderReviewPage';
import Login from '../pages/LoginPage';
import ForgotPasswordPage from '../pages/ForgotPasswordPage';
import ContextPage from '../pages/ContextPage';
import RedirectPage from '../pages/RedirectPage';
import OrderPaymentStatusPage from '../pages/OrderPaymentStatusPage';
import RecoverPasswordPage from '../pages/RecoverPasswordPage';
import UserPasswordPage from '../pages/UserPasswordPage';
import UserBasicDataPage from '../pages/UserBasicDataPage';
import ProductContextPage from '../pages/ProductContextPage';
import OrderHistoryPage from '../pages/OrderHistoryPage';
import LoyaltyPage from '../pages/LoyaltyPage';
import AppTourPage from '../pages/AppTourPage';
import CardsPage from '../pages/CardsPage';
import UserAddressListPage from '../pages/UserAddressListPage';
import withTag from '../hocs/withTag';

/**
 * Only logged users can access these routes
 *
 * - Major precedence when user IS LOGGED:
 *    Overwrites the same paths of the public routes
 * - No match when user IS NOT LOGGED:
 *    Render LoginPage component by default
 */

export const privateRoutes = [
  {
    path: '/pedido',
    component: withTag(OrderReviewPage),
    redirectTo: '/carrinho',
  },
  {
    path: '/pedidos/:transactionId',
    component: withTag(OrderPaymentStatusPage),
    redirectTo: '/carrinho',
  },
  {
    path: '/alterar-senha',
    component: UserPasswordPage,
    redirectTo: '/login',
  },
  {
    path: '/minha-conta',
    component: UserBasicDataPage,
    redirectTo: '/login',
  },
  {
    path: '/historico',
    component: OrderHistoryPage,
    redirectTo: '/login',
  },
  {
    path: '/fidelidade',
    component: LoyaltyPage,
    redirectTo: '/login',
  },
  {
    path: '/cartoes',
    component: CardsPage,
    redirectTo: '/login',
  },
  {
    path: '/enderecos',
    component: UserAddressListPage,
    redirectTo: '/login',
  },
  {
    path: '/comanda',
    component: withTag(OrderReviewPage),
    redirectTo: '/carrinho',
  },
];

/**
 * Only not logged users can access these routes
 *
 * - Precedence over same paths of private routes when user IS NOT LOGGED:
 *    Overwrites the login rendering
 * - No match when user IS LOGGED
 *    Redirect to default private route
 */
export const notLoggedRoutes = [
  {
    path: '/login',
    component: Login,
  },
  {
    path: '/registro',
    component: RequiredDataPage,
  },
  {
    path: '/registro/endereco',
    component: OptionalDataPage,
  },
  {
    path: '/registro/concluido',
    component: DataSentPage,
  },
  {
    path: '/recuperar-senha',
    component: ForgotPasswordPage,
  },
  {
    path: '/nova-senha/:token',
    component: RecoverPasswordPage,
  },
  {
    path: '/comanda',
    component: withTag(OrderReviewPage),
  },
  {
    path: '/pedidos/:orderId',
    component: withTag(OrderPaymentStatusPage),
  },
];

/**
 * Both logged and not logged users can access these routes
 *
 * - Minor precedence when user IS LOGGED
 *    Is overwritten by the private route with same paths
 * - Major precedence when user IS NOT LOGGED
 *    Overwrites the same paths of the private and not logged routes
 */
export const getPublicRoutes = (qrCodeOnly, menuOnly, tutorialEnabled) => {
  if (qrCodeOnly) {
    return [
      {
        path: '/',
        component: RedirectPage,
      },
      {
        path: '/carrinho',
        component: OrderCartPage,
      },
      {
        path: '/o/:uuid',
        component: ContextPage,
      },
    ];
  }

  if (menuOnly) {
    return [
      {
        path: '/',
        component: RedirectPage,
      },
      {
        path: '/carrinho',
        component: OrderCartPage,
      },
      {
        path: '/m/:sku',
        component: ProductContextPage,
      },
    ];
  }

  let routes = [
    {
      path: '/',
      component: RedirectPage,
    },
    {
      path: '/c/:customerId',
      component: HomePage,
    },
    {
      path: '/carrinho',
      component: OrderCartPage,
    },
    {
      path: '/registro/erro',
      component: RegisterErrorPage,
    },
    {
      path: '/o/:uuid',
      component: ContextPage,
    },
    {
      path: '/m/:sku',
      component: ProductContextPage,
    },
  ];

  if (tutorialEnabled) {
    routes = routes.concat({
      path: '/app-wizard',
      component: AppTourPage,
    });
  }

  return routes;
};
