import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Button from '@bit/eyemobile.core.button';

import Input from '../../components/FormikInput';
import Header from '../../components/Header';
import ScrollableView from '../../components/ScrollableView';
import FormikRowRadioButton from '../../components/FormikRowRadioButton';

import {
  wrapperCss,
  fieldPadding,
  ErrorAnimation,
  forgotPasswordFormCss,
  RecoverError,
} from './ForgotPasswordPage.styles';
import { cpfMask } from '../../utils/masks';

const ForgotPasswordPageScreen = ({
  values,
  handleSubmit,
  setFieldValue,
  setFieldTouched,
  isSubmitting,
  isValid,
  errorMessage,
  handleOnPrev,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Header onPrev={handleOnPrev} title={t('navigation:forgotMyPassword')} />
      <ScrollableView hasHeader>
        <div css={wrapperCss}>
          <form onSubmit={handleSubmit} css={forgotPasswordFormCss}>
            <span>{t('recovery:inputDocumentForRecovery')}</span>
            <div css={fieldPadding}>
              <Input
                placeholder={t('forms:document')}
                animateLabel
                hideIcons={false}
                name="document"
                mask={cpfMask}
                inputMode="numeric"
                pattern="[0-9]*"
              />
            </div>
            <div css={fieldPadding}>
              <span>{t('recovery:chooseRecoveryType')}</span>
            </div>
            <div css={fieldPadding}>
              <FormikRowRadioButton
                name="email"
                values={values}
                fieldKey="recoveryType"
                label={t('recovery:sendEmail')}
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
              />
              <FormikRowRadioButton
                name="phone"
                values={values}
                fieldKey="recoveryType"
                label={t('recovery:sendSms')}
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
              />
            </div>
          </form>
          <ErrorAnimation hasError={!!errorMessage}>
            <RecoverError>
              <p>{errorMessage}</p>
            </RecoverError>
          </ErrorAnimation>
          <Button
            color="primary"
            type="submit"
            onClick={handleSubmit}
            isLoading={isSubmitting}
            disabled={isSubmitting || !isValid}
          >
            {t('navigation:send')}
          </Button>
        </div>
      </ScrollableView>
    </>
  );
};

ForgotPasswordPageScreen.propTypes = {
  values: PropTypes.object.isRequired,
  handleOnPrev: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  isValid: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
};

export default ForgotPasswordPageScreen;
