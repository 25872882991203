// This optional code is used to register a service worker.
// register() is not called by default.

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on subsequent visits to a page, after all the
// existing tabs open on the page have been closed, since previously cached
// resources are updated in the background.

// To learn more about the benefits of this model and instructions on how to
// opt-in, read https://bit.ly/CRA-PWA

export function register(config) {
  if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
    // The URL constructor is available in all browsers that support SW.
    const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);
    if (publicUrl.origin !== window.location.origin) {
      // Our service worker won't work if PUBLIC_URL is on a different origin
      // from what our page is served on. This might happen if a CDN is used to
      // serve assets; see https://github.com/facebook/create-react-app/issues/2374
      return;
    }

    console.log('Registering service worker'); // eslint-disable-line

    const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;

    registerValidSW(swUrl, config);
  }
}

function onNewServiceWorker(registration, callback) {
  if (registration.waiting) {
    // SW is waiting to activate. Can occur if multiple clients open and
    // one of the clients is refreshed.
    return callback();
  }

  function listenInstalledStateChange() {
    registration.installing.addEventListener('statechange', function (event) {
      if (event.target.state === 'installed') {
        // A new service worker is available, inform the user
        callback();
      }
    });
  }

  if (registration.installing) {
    return listenInstalledStateChange();
  }

  // We are currently controlled so a new SW may be found...
  // Add a listener in case a new SW is found,
  registration.addEventListener('updatefound', listenInstalledStateChange);
}

function registerValidSW(swUrl, config) {
  const domain = config && config.domain;
  var refreshing;

  // When the user asks to refresh the UI, we'll need to reload the window
  navigator.serviceWorker.addEventListener(
    'controllerchange',
    function (event) {
      if (refreshing) return; // prevent infinite refresh loop when you use "Update on Reload"
      refreshing = true;
      console.log('Controller loaded');
      window.location.reload();
    },
  );

  navigator.serviceWorker
    .register(swUrl, { scope: domain ? `/${domain}` : '/' })
    .then((registration) => {
      // Track updates to the Service Worker.
      if (!navigator.serviceWorker.controller) {
        // The window client isn't currently controlled so it's a new service
        // worker that will activate immediately
        return;
      }

      registration.update();

      onNewServiceWorker(registration, function () {
        if (!registration.waiting) {
          // Just to ensure registration.waiting is available before
          // calling postMessage()
          return;
        }

        console.log('SW: Skip waiting');

        registration.waiting.postMessage({ type: 'SKIP_WAITING' });
      });
    })
    .catch((error) => {
      console.error('Error during service worker registration:', error);
    });
}

export async function unregister() {
  if ('serviceWorker' in navigator) {
    await navigator.serviceWorker.ready
      .then((registration) => {
        registration.unregister();
      })
      .catch((error) => {
        console.error(error.message);
      });
  }
}
