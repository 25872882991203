import { createMuiTheme } from '@material-ui/core/styles';

export const Avenir = {
  light: 'Avenir-Light, sans-serif',
  roman: 'Avenir-Roman, sans-serif',
  medium: 'Avenir-Medium, sans-serif',
  heavy: 'Avenir-Heavy, sans-serif',
  black: 'Avenir-Black, sans-serif',
};

const Main = createMuiTheme({
  fonts: {
    Avenir,
  },
  palette: {
    text: {
      primary: '#515258',
      secondary: '#96979A',
    },
  },
  typography: {
    h1: {
      fontFamily: Avenir.black,
      fontSize: 36,
      lineHeight: 'normal',
      letterSpacing: 0,
      color: '#515258',
    },
    h2: {
      fontFamily: Avenir.black,
      fontSize: 32,
      lineHeight: 'normal',
      letterSpacing: 0,
      color: '#515258',
    },
    h3: {
      fontFamily: Avenir.black,
      fontSize: 20,
      lineHeight: 'normal',
      letterSpacing: 0,
      color: '#515258',
    },
    h4: {
      fontFamily: Avenir.heavy,
      fontSize: 20,
      lineHeight: 'normal',
      letterSpacing: 0,
      color: '#515258',
    },
    h5: {
      fontFamily: Avenir.heavy,
      fontSize: 18,
      lineHeight: 'normal',
      letterSpacing: 0,
      color: '#515258',
    },
    h6: {
      fontFamily: Avenir.heavy,
      fontSize: 16,
      lineHeight: 'normal',
      letterSpacing: 0,
      color: '#515258',
    },
    subtitle1: {
      fontFamily: Avenir.heavy,
      fontSize: 18,
      lineHeight: 'normal',
      letterSpacing: 0,
      color: '#515258',
    },
    subtitle2: {
      fontFamily: Avenir.heavy,
      fontSize: 18,
      lineHeight: 'normal',
      letterSpacing: 0,
      color: '#96979A',
    },
    body1: {
      fontFamily: Avenir.medium,
      fontSize: 16,
      lineHeight: 'normal',
      letterSpacing: 0,
      color: '#515258',
    },
    body2: {
      fontFamily: Avenir.medium,
      fontSize: 16,
      lineHeight: 'normal',
      letterSpacing: 0,
      color: '#96979A',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 960,
      lg: 1280,
      xl: 1584,
      xxl: 1920,
    },
  },
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          h1: 'h1',
          h2: 'h2',
          h3: 'h3',
          h4: 'h4',
          h5: 'h5',
          h6: 'h6',
          h7: 'p',
          subtitle1: 'h6',
          subtitle2: 'h6',
          subtitle3: 'h6',
          subtitle4: 'h6',
          body1: 'p',
          body2: 'p',
        },
      },
      variants: [
        {
          props: { variant: 'h7' },
          style: {
            fontFamily: Avenir.heavy,
            fontSize: 14,
            lineHeight: 'normal',
            letterSpacing: 0,
            color: '#515258',
          },
        },
        {
          props: { variant: 'subtitle3' },
          style: {
            fontFamily: Avenir.heavy,
            fontSize: 14,
            lineHeight: 'normal',
            letterSpacing: 0,
            color: '#96979A',
          },
        },
        {
          props: { variant: 'subtitle4' },
          style: {
            fontFamily: Avenir.heavy,
            fontSize: 12,
            lineHeight: 'normal',
            letterSpacing: 0,
            color: '#96979A',
          },
        },
      ],
    },
  },
});

export default Main;
