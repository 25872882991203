import React from 'react';
import PropTypes from 'prop-types';
import { withFormik } from 'formik';
import Button from '@bit/eyemobile.core.button';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { ButtonPadding } from '../../components/Form/Form.styles';
import Input from '../../components/FormikInput';
import {
  modalTitle,
  discountInputGroup,
  giftCardInput,
} from './OrderReviewPage.styles';

const giftCardSchema = Yup.object().shape({
  voucherCode: Yup.string().required('formValidation:fieldRequired'),
});

const OrderReviewGiftCardForm = ({
  onClose,
  isSubmitting,
  isValid,
  handleSubmit,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <p css={modalTitle}>{t('orderReview:insertGiftCard')}</p>
      <div css={discountInputGroup}>
        <Input
          name="voucherCode"
          inputStyle={giftCardInput}
          hideIcons
          hideErrors
          guide={false}
        />
      </div>
      <ButtonPadding hasMultiButtons>
        <Button onClick={onClose} color="error" disabled={isSubmitting}>
          {t('formValidation:cancel')}
        </Button>
        <Button
          isLoading={isSubmitting}
          onClick={handleSubmit}
          color="primary"
          disabled={!isValid || isSubmitting}
        >
          {t('forms:confirm')}
        </Button>
      </ButtonPadding>
    </>
  );
};

OrderReviewGiftCardForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  isValid: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default withFormik({
  validationSchema: giftCardSchema,
  mapPropsToValues: () => ({
    voucherCode: '',
  }),
  validateOnMount: true,
  handleSubmit: (values, { props, dirty, ...formikBag }) => {
    const { onConfirm } = props;
    onConfirm(values, formikBag);
  },
})(OrderReviewGiftCardForm);
