const validCreditCardFlags = {
  visa: 'visa',
  mastercard: 'mastercard',
  'american-express': 'amex',
  elo: 'elo',
  hipercard: 'hipercard',
  'diners-club': 'diners',
  discover: 'discover',
};

export default validCreditCardFlags;
