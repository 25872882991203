import { css } from '@emotion/core';
import styled from '@emotion/styled';

export const CheckboxWrapper = styled.label`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  ${({ disabled }) =>
    disabled && 'cursor: default; pointer-events: none; opacity: 0.7;'}
`;

export const checkboxLabel = (theme) => css`
  width: 100%;
  color: ${theme && theme.getColor('primary')};
  font-size: ${theme && theme.getFontSize(1, 'px')};
  font-family: ${theme && theme.getFont()};
  text-align: left;
`;

export const checkboxInput = () => css`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`;

export const Checkmark = styled.span`
  position: relative;
  outline: none;
  border: none;
  cursor: pointer;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  background-color: transparent;
  border: 1px solid ${({ theme }) => theme && theme.getColor('primary')};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  margin-right: ${({ theme }) => theme && theme.getSpacing(1, 'px')};

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 14px;
    width: 14px;
    border-radius: 50%;
    background-color: ${({ theme, checked }) =>
      checked ? theme && theme.getColor('primary') : 'transparent'};
    flex-shrink: 0;
    transition: background-color 0.2s ease;
  }
`;
