import * as Yup from 'yup';
import getUsedPercentValue from '../../utils/getUsedPercentValue';
import { paymentTypes, shippingTypes } from '../../utils/constants';

const isCreditCardNotNeeded = (
  loyaltyCashback,
  voucher,
  totalPrice,
  isPostPaid,
) => {
  if (loyaltyCashback) {
    const usedCashback = getUsedPercentValue(
      totalPrice,
      loyaltyCashback.maxCashbackPercentage,
      loyaltyCashback.availableCashback,
    );

    if (usedCashback === totalPrice) {
      return true;
    }
  }

  if (voucher && voucher.total >= totalPrice) {
    return true;
  }

  if (isPostPaid) {
    return true;
  }

  return false;
};

const orderReviewFormSchema = (props) =>
  Yup.object().shape({
    shippingType: Yup.string()
      .required('formValidation:fieldRequired')
      .test(
        'invalid-shipping-type',
        'formValidation:invalidShippingType',
        function invalidShippingType(value) {
          const { selectedStore } = props;
          const { tagEnabled, takeoutEnabled, deliveryEnabled } = selectedStore;

          if (
            (!tagEnabled && value === shippingTypes.TAG) ||
            (!deliveryEnabled && value === shippingTypes.ADDRESS) ||
            (!takeoutEnabled && value === shippingTypes.TAKEOUT)
          ) {
            return false;
          }

          return true;
        },
      ),
    creditCardOption: Yup.string().test(
      'must-use-credit-card',
      'formValidation:fieldRequired',
      function mustUseCreditCard(value) {
        const {
          loyaltyCashback,
          totalPrice,
          paymentOnDeliveryOnly,
          isPostPaid,
        } = props;
        const { paymentType, voucher } = this.parent;

        if (
          paymentOnDeliveryOnly &&
          value !== paymentTypes.CREDIT_CARD_ONLINE
        ) {
          return true;
        }

        if (paymentType !== paymentTypes.CREDIT_CARD_ONLINE) {
          return true;
        }

        if (
          isCreditCardNotNeeded(
            loyaltyCashback,
            voucher,
            totalPrice,
            isPostPaid,
          )
        ) {
          return true;
        }

        return !!value;
      },
    ),
    useCashback: Yup.bool(),
    paymentType: Yup.string(),
    installments: Yup.number(),
    comment: Yup.string().max(1024, 'formValidation:commentTooLong'),
    name: props.requiredFields?.includes('name')
      ? Yup.string().required('formValidation:fieldRequired')
      : Yup.string(),
    phone: props.requiredFields?.includes('phone')
      ? Yup.string().required('formValidation:fieldRequired')
      : Yup.string(),
  });

export default orderReviewFormSchema;
