import { css } from '@emotion/core';
import facepaint from 'facepaint';

const mq = facepaint(['@media screen and (min-width: 375px)']);

export const itemsContainer = (theme) => css`
  width: 100%;
  padding: ${theme.getSpacing(1, 'px')} ${theme.getSpacing(2, 'px')};
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  min-height: 140px;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }
  &::-webkit-scrollbar:vertical {
    width: 8px;
  }
  &::-webkit-scrollbar:horizontal {
    height: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    border: 2px solid #ffffff;
  }
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #ffffff;
  }
`;

export const specialItem = (theme) => css`
  flex: 0;
  height: 125px;
  width: 220px;
  border-radius: 14px;
  object-fit: cover;

  margin-left: ${theme.getSpacing(1, 'px')};
  margin-right: ${theme.getSpacing(1, 'px')};
`;

export const itemContent = (theme) => css`
  width: 220px;
  height: 100%;
  padding: ${theme.getSpacing(2, 'px')};
  overflow: hidden;
  display: flex;
  justify-content: center;
`;

export const offersText = (theme) => css`
  font-family: ${theme && theme.getFont('futura')};
  color: ${theme && theme.getColor('text')};
  margin-left: ${theme.getSpacing(2, 'px')};
  text-transform: uppercase;

  ${mq({
    fontSize: [theme.getFontSize(0.9, 'px'), theme.getFontSize(1.1, 'px')],
  })}
`;

export const specialText = (theme) => css`
  font-size: ${theme && theme.getFontSize(1, 'px')};
  line-height: ${theme && theme.getFontSize(1.2, 'px')};
  font-family: ${theme && theme.getFont('futura')};
  color: ${theme && theme.getColor('text')};
  width: 100%;
  text-align: center;
  white-space: normal;
`;

export const specialLink = (theme) => css`
  display: block;
  width: 220px;
  height: 125px;
  margin-left: ${theme.getSpacing(1, 'px')};
  margin-right: ${theme.getSpacing(1, 'px')};
`;

export const infoTextCss = (theme) => css`
  white-space: pre-wrap;
  font-size: ${theme.getFontSize(1.2, 'px')};
  line-height: ${theme.getFontSize(1.7, 'px')};
  font-family: ${theme.getFont()};
  color: ${theme.getColor('text')};
  overflow-y: scroll;
`;
