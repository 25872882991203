import { css } from '@emotion/core';
import styled from '@emotion/styled';

export const wrapperCss = (theme) => css`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: ${theme && theme.getSpacing(4, 'px')};
  padding-right: ${theme && theme.getSpacing(4, 'px')};
  padding-top: ${theme && theme.getSpacing(6, 'px')};
  padding-bottom: ${theme && theme.getSpacing(6, 'px')};
`;

export const fieldPadding = (theme) => css`
  width: 100%;
  padding-top: ${theme && theme.getSpacing(2, 'px')};
  padding-bottom: ${theme && theme.getSpacing(2, 'px')};
`;

export const ErrorAnimation = styled.div`
  margin-bottom: ${({ theme, hasError }) =>
    !hasError ? 0 : theme && theme.getSpacing(2, 'px')};
  height: ${({ hasError }) => (hasError ? '75px' : 0)};
  transition: ${({ hasError }) =>
    hasError ? 'height 500ms' : 'height 500ms 500ms'};
  z-index: ${({ hasError }) => (hasError ? 1 : -1)};
  overflow: hidden;

  p {
    opacity: ${({ hasError }) => (hasError ? 1 : 0)};
    transition: ${({ hasError }) =>
      hasError ? 'opacity 500ms 500ms' : 'opacity 500ms'};
  }
`;

export const RecoverError = styled.div`
  color: ${({ theme }) => theme && theme.getColor('text')};
  text-align: center;
  background-color: ${({ theme }) => theme && theme.getColor('error')};
  border-radius: 4px;
  padding: ${({ theme }) => theme && theme.getSpacing(2, 'px')};

  p {
    &:not(:last-child) {
      margin-bottom: ${({ theme }) => theme && theme.getSpacing(1, 'px')};
    }
  }

  a {
    text-decoration: underline;
  }
`;

export const forgotPasswordFormCss = (theme) => css`
  width: 250;
  margin-bottom: ${theme && theme.getSpacing(2, 'px')};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const buttonWrapper = (theme) => css`
  margin: ${theme && theme.getSpacing(1, 'px')};
`;
