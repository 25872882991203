import { css } from '@emotion/core';

export const contentWrapper = (theme) => css`
  padding-top: ${theme && theme.getSpacing(4, 'px')};
  padding-bottom: ${theme && theme.getSpacing(4, 'px')};
  padding-left: ${theme && theme.getSpacing(2, 'px')};
  padding-right: ${theme && theme.getSpacing(2, 'px')};
`;

export const textStyle = (theme) => css`
  text-align: center;
  margin-bottom: ${theme.getSpacing(4, 'px')};
  font-family: ${theme && theme.getFont('futura')};
  color: ${theme && theme.getColor('text')};
`;
