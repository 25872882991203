const rebuildPaymentLinkComplementGroups = (transactionComplements) => {
  const groups = Object.values(
    transactionComplements.reduce((initObj, transactionItemComplement) => {
      const obj = { ...initObj };
      if (
        obj[transactionItemComplement.product_complement_group?.id] ===
        undefined
      ) {
        obj[transactionItemComplement.product_complement_group_id] = {
          id: transactionItemComplement.product_complement_group_id,
          name: transactionItemComplement.product_complement_group_name,
          complementItems: [
            {
              id: transactionItemComplement.product_complement_id,
              name: transactionItemComplement.product_complement_name,
              price: transactionItemComplement.price,
              quantity: transactionItemComplement.quantity,
            },
          ],
        };
        return obj;
      }
      obj[
        transactionItemComplement.product_complement_group_id
      ].complementItems.push({
        id: transactionItemComplement.product_complement_id,
        name: transactionItemComplement.product_complement_name,
        price: transactionItemComplement.price,
        quantity: transactionItemComplement.quantity,
      });
      return obj;
    }, {}),
  );

  return groups;
};

export default rebuildPaymentLinkComplementGroups;
