import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation, Trans } from 'react-i18next';
import Button from '@bit/eyemobile.core.button';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import GoogleLogin from 'react-google-login';
import { Link } from 'react-router-dom';
import { useTheme } from 'emotion-theming';
import ReactCountryFlag from 'react-country-flag';

import Input from '../../components/FormikInput';
import Logo from '../../components/Logo/Logo';
import ScrollableView from '../../components/ScrollableView';
import { cpfMask } from '../../utils/masks';
import useVisualViewport from '../../utils/useVisualViewport';
import { languageFlagMap } from '../../utils/constants';
import { ReactComponent as GoogleIcon } from '../../assets/google.svg';
import { ReactComponent as FacebookIcon } from '../../assets/facebook.svg';

import {
  backgroundCss,
  wrapperCss,
  fieldPadding,
  LoginError,
  ErrorAnimation,
  ForgotPassword,
  FlagButton,
} from './LoginPage.styles';

const mapStateToProps = ({ tenantEyepass: { socialLoginDisabled } }) => ({
  socialLoginDisabled,
});

const LoginPageScreen = ({
  onFacebookLogin,
  onGoogleLogin,
  onRegistration,
  onToggleLanguageModal,
  handleSubmit,
  isSubmitting,
  isValid,
  hasError,
  domain,
  socialLoginDisabled,
}) => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const { maxHeight } = useVisualViewport();
  const aboveHeightThreshold = maxHeight === '100vh' || maxHeight > 350;

  const labelBackgroundColor =
    theme && theme.getColor('loginBackground')
      ? 'loginBackground'
      : 'foreground';

  const currentLanguage = i18n.language
    ? i18n.language.split('-CUSTOM')[0]
    : null;

  return (
    <div css={backgroundCss}>
      <ScrollableView>
        <FlagButton role="presentation" onClick={onToggleLanguageModal}>
          <ReactCountryFlag
            svg
            countryCode={languageFlagMap[currentLanguage]}
            style={{ height: '24px', width: '24px' }}
          />
        </FlagButton>
        <div css={wrapperCss}>
          {aboveHeightThreshold && <Logo />}
          <form
            onSubmit={handleSubmit}
            style={{ width: 250, marginBottom: theme.getSpacing(2, 'px') }}
            id="login-form"
          >
            <div css={fieldPadding}>
              <Input
                placeholder={t('forms:document')}
                animateLabel
                hideIcons={false}
                name="username"
                mask={cpfMask}
                inputMode="numeric"
                pattern="[0-9\-\.]*"
                color="loginInput"
                labelColor="loginInput"
                borderColor="loginInput"
                labelBackgroundColor={labelBackgroundColor}
                inputStyle={{ fontSize: '16px' }}
                variant="bordered"
              />
            </div>
            <div css={fieldPadding} style={{ paddingBottom: 0 }}>
              <Input
                placeholder={t('forms:password')}
                animateLabel
                hideIcons={false}
                name="password"
                type="password"
                color="loginInput"
                labelColor="loginInput"
                borderColor="loginInput"
                labelBackgroundColor={labelBackgroundColor}
                inputStyle={{ fontSize: '16px' }}
                variant="bordered"
              />
            </div>
          </form>
          <ErrorAnimation hasError={hasError}>
            <LoginError>
              <p>{t('errors:loginFailurePartOne')}</p>
              <p>
                <Trans key="errors:loginFailurePartTwo">
                  Esqueceu sua senha?{' '}
                  <Link to={`/${domain}/recuperar-senha`}>Recupere-a aqui</Link>
                </Trans>
              </p>
            </LoginError>
          </ErrorAnimation>
          <Button
            styles={{
              root: (ds) => ({
                fontSize: 12,
                width: 200,
                marginBottom: ds.getSpacing(2, 'px'),
              }),
            }}
            type="submit"
            isLoading={isSubmitting}
            disabled={isSubmitting || !isValid}
            color="loginButton"
            textColor="loginButtonText"
            form="login-form"
          >
            {t('navigation:login')}
          </Button>
          <div id="social-buttons-wrapper">
            <FacebookLogin
              appId="1124018501274187"
              autoLoad={false}
              fields="name,email,picture"
              scope="public_profile,email"
              callback={onFacebookLogin}
              disableMobileRedirect
              render={(renderProps) => (
                <Button
                  startIcon={<FacebookIcon />}
                  styles={{
                    root: (ds) => ({
                      fontSize: 12,
                      width: 200,
                      marginBottom: ds.getSpacing(2, 'px'),
                      display: socialLoginDisabled ? 'none' : undefined,
                    }),
                  }}
                  isLoading={renderProps.isProcessing}
                  onClick={renderProps.onClick}
                  color="loginButton"
                  textColor="loginButtonText"
                >
                  {t('navigation:loginWithFacebook')}
                </Button>
              )}
            />
            <GoogleLogin
              clientId="1058205609436-njtop3heqocn2enp3j4kicn3hdskdnel.apps.googleusercontent.com"
              render={(renderProps) => (
                <Button
                  startIcon={<GoogleIcon />}
                  styles={{
                    root: (ds) => ({
                      fontSize: 12,
                      width: 200,
                      marginBottom: ds.getSpacing(2, 'px'),
                      display: socialLoginDisabled ? 'none' : undefined,
                    }),
                  }}
                  onClick={renderProps.onClick}
                  isLoading={renderProps.isProcessing}
                  color="loginButton"
                  textColor="loginButtonText"
                >
                  {t('navigation:loginWithGoogle')}
                </Button>
              )}
              buttonText="Login"
              onSuccess={onGoogleLogin}
              onFailure={onGoogleLogin}
              cookiePolicy="single_host_origin"
            />
          </div>
          <Button
            variant="outlined"
            onClick={onRegistration}
            color="signupButton"
            styles={{
              root: (ds) => ({
                fontSize: 12,
                width: 200,
              }),
            }}
          >
            {t('navigation:iDontHaveAnAccount')}
          </Button>
          <ForgotPassword to={`/${domain}/recuperar-senha`}>
            {t('navigation:forgotMyPassword')}
          </ForgotPassword>
        </div>
      </ScrollableView>
    </div>
  );
};

LoginPageScreen.defaultProps = {
  onFacebookLogin: null,
  onGoogleLogin: null,
  onRegistration: null,
  domain: null,
};

LoginPageScreen.propTypes = {
  onFacebookLogin: PropTypes.func,
  onGoogleLogin: PropTypes.func,
  onRegistration: PropTypes.func,
  onToggleLanguageModal: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  isValid: PropTypes.bool.isRequired,
  hasError: PropTypes.bool.isRequired,
  domain: PropTypes.string,
  socialLoginDisabled: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(LoginPageScreen);
