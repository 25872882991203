import { css } from '@emotion/core';
import styled from '@emotion/styled';
import facepaint from 'facepaint';

const mq = facepaint(['@media screen and (min-width: 375px)']);

const getFontSize = ({ theme }) =>
  mq({
    fontSize: [theme.getFontSize(0.9, 'px'), theme.getFontSize(1.1, 'px')],
  });

export const groupContainer = (theme) => css`
  width: 100%;
  padding-top: ${theme && theme.getSpacing(4, 'px')};
  padding-bottom: ${theme && theme.getSpacing(1, 'px')};
`;

export const GroupTitleWrapper = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  padding-left: ${({ theme }) => theme && theme.getSpacing(2, 'px')};
  position: relative;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme && theme.getSpacing(2, 'px')};
  margin-top: ${({ theme }) => theme && theme.getSpacing(2, 'px')};
`;

export const GroupTitle = styled.span`
  ${getFontSize};
  text-align: left;
  color: ${({ theme }) => theme && theme.getColor('text')};
  font-family: ${({ theme }) => theme && theme.getColor('futura')};
  font-weight: bold;
  text-transform: uppercase;
`;
