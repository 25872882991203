import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import localizePrice from '../../utils/localizePrice';

import {
  tabWrapper,
  tabTitle,
  tabTitleWrapper,
  priceRelationWrapper,
  priceRelationRow,
  PriceRelationText,
  Divisor,
} from './OrderReviewPage.styles';

const OrderReviewPriceRelations = ({
  totalPrice,
  finalPrice,
  deliveryFee,
  useCashback,
  discounts,
  voucher,
  priceToBePaid,
  isPostPaid,
  currentTag,
}) => {
  const { t } = useTranslation();

  const cashbackNotFull = false;

  const voucherValue = useMemo(
    () =>
      discounts.voucher >= finalPrice - discounts.cashback &&
      !(voucher && voucher.oneTimeUsage)
        ? finalPrice - discounts.cashback
        : discounts.voucher,
    [discounts],
  );

  return (
    <div css={tabWrapper}>
      <div css={tabTitleWrapper}>
        <span css={tabTitle}>{t('orderReview:prices')}</span>
      </div>
      <div css={priceRelationWrapper}>
        <div css={priceRelationRow}>
          <PriceRelationText>{t('orderHistory:subtotal')}</PriceRelationText>
          <PriceRelationText>{`R$ ${localizePrice(
            totalPrice,
          )}`}</PriceRelationText>
        </div>
        {!isPostPaid && (
          <div css={priceRelationRow}>
            <PriceRelationText>
              {t('orderHistory:deliveryFee')}
            </PriceRelationText>
            <PriceRelationText>
              {deliveryFee
                ? `R$ ${localizePrice(deliveryFee)}`
                : t('orderReview:forFree')}
            </PriceRelationText>
          </div>
        )}
        {useCashback && (
          <>
            <div css={priceRelationRow}>
              <PriceRelationText>
                {t('orderReview:cashbackPay')}
              </PriceRelationText>
              <PriceRelationText color="error">
                {`- R$ ${localizePrice(discounts.cashback)}`}
                {cashbackNotFull && ' *'}
              </PriceRelationText>
            </div>
          </>
        )}
        {voucher && (
          <>
            <div css={priceRelationRow}>
              <PriceRelationText>{t('orderReview:giftCard')}</PriceRelationText>
              <PriceRelationText color="error">
                {`- R$ ${localizePrice(voucherValue)}`}
              </PriceRelationText>
            </div>
          </>
        )}
        <Divisor />
        <div css={priceRelationRow}>
          <PriceRelationText big>{t('orderHistory:total')}</PriceRelationText>
          <PriceRelationText big>{`R$ ${localizePrice(
            priceToBePaid,
          )}`}</PriceRelationText>
        </div>
        {isPostPaid && (
          <div css={priceRelationRow}>
            <PriceRelationText color="error">
              {`${t('orderReview:postPaidWarning')} ${currentTag}`}
            </PriceRelationText>
          </div>
        )}
        {useCashback && cashbackNotFull && (
          <div css={priceRelationRow} style={{ opacity: 0.8 }}>
            <PriceRelationText color="error">
              {'* '}
              {t('orderReview:cashbackWarning')}
            </PriceRelationText>
          </div>
        )}
      </div>
    </div>
  );
};

OrderReviewPriceRelations.defaultProps = {
  voucher: undefined,
  deliveryFee: 0,
  useCashback: false,
};

OrderReviewPriceRelations.propTypes = {
  totalPrice: PropTypes.number.isRequired,
  finalPrice: PropTypes.number.isRequired,
  useCashback: PropTypes.bool,
  deliveryFee: PropTypes.number,
  discounts: PropTypes.object.isRequired,
  voucher: PropTypes.shape({
    total: PropTypes.number,
    oneTimeUsage: PropTypes.bool,
  }),
  priceToBePaid: PropTypes.number.isRequired,
  isPostPaid: PropTypes.number.isRequired,
  currentTag: PropTypes.string.isRequired,
};

export default OrderReviewPriceRelations;
