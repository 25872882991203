import { css } from '@emotion/core';
import styled from '@emotion/styled';

export const outerBoxCss = (theme) => css`
  width: 100%;
`;

export const historyGroupTitle = (theme) => css`
  font-family: ${theme && theme.getFont('futura')};
  font-size: ${theme && theme.getFontSize(1, 'px')};
  color: ${theme && theme.getColor('text')};
  margin-top: ${theme && theme.getSpacing(2, 'px')};
  margin-bottom: ${theme && theme.getSpacing(1, 'px')};
`;

export const innerBoxCss = (theme) => css`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: ${theme && theme.getSpacing(1, 'px')};
`;

export const deliveryAddressWapper = (theme) => css`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: ${theme && theme.getSpacing(2, 'px')};
  padding-top: ${theme && theme.getSpacing(2, 'px')};
`;

export const DescriptionText = styled.span`
  font-family: ${({ theme, fontFamily }) =>
    theme && theme.getFont(fontFamily || 'default')};
  font-size: ${({ theme }) => theme && theme.getFontSize(1, 'px')};
  color: ${({ theme }) => theme && theme.getColor('text')};
  margin-top: ${({ theme }) => theme && theme.getSpacing(1, 'px')};
  margin-bottom: ${({ theme }) => theme && theme.getSpacing(1, 'px')};

  ${({ bold }) => bold && 'font-weight: bold;'}
`;

export const fieldText = (theme) => css`
  font-family: ${theme && theme.getFont('futura')};
  font-size: ${theme && theme.getFontSize(1.1, 'px')};
  color: ${theme && theme.getColor('text')};
  margin-top: ${theme && theme.getSpacing(1, 'px')};
  margin-bottom: ${theme && theme.getSpacing(1, 'px')};
  font-weight: bold;
  text-transform: uppercase;
`;

export const descriptionRow = css`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`;

export const addressWrapper = {
  display: 'flex',
  flexDirection: 'column',
};

export const divisorCss = (theme) => css`
  margin-top: ${theme && theme.getSpacing(2, 'px')};
  margin-bottom: ${theme && theme.getSpacing(2, 'px')};
  width: 100%;
  height: 2px;
  border-radius: 1px;
  color: default;
`;

export const statusTextWrapper = (theme) => css`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: ${theme && theme.getSpacing(1, 'px')};
`;

export const StatusText = styled.span`
  border-radius: 15px;
  padding-top: ${({ theme }) => theme && theme.getSpacing(1, 'px')};
  padding-bottom: ${({ theme }) => theme && theme.getSpacing(1, 'px')};
  padding-left: ${({ theme }) => theme && theme.getSpacing(3, 'px')};
  padding-right: ${({ theme }) => theme && theme.getSpacing(3, 'px')};
  font-family: ${({ theme }) => theme && theme.getFont('futura')};
  font-size: ${({ theme }) => theme && theme.getFontSize(1, 'px')};
  color: ${({ theme }) => theme.getColor('text')};
`;

export const blockButtonWrapper = (theme) => css`
  padding: ${theme && theme.getSpacing(1, 'px')};
  margin: auto;
`;

export const paymentWrapper = css`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const contentWrapper = (theme) => css`
  padding-left: ${theme && theme.getSpacing(1, 'px')};
  padding-right: ${theme && theme.getSpacing(1, 'px')};
`;

export const paymentCol = css`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const storeAddressCol = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const storeAddressText = css`
  font-family: ${({ theme }) => theme && theme.getFont()};
  font-size: ${({ theme }) => theme && theme.getFontSize(1, 'px')};
  color: ${({ theme }) => theme && theme.getColor('text')};
`;

export const selectedFiltersList = (theme) => css`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-left: ${theme.getSpacing(1, 'px')};
  padding-right: ${theme.getSpacing(1, 'px')};
`;

export const trackingLink = (theme) => css`
  font-family: ${theme.getFont()};
  font-size: ${theme.getFontSize(1, 'px')};
  color: ${theme.getColor('text')};
  font-weight: bold;
  margin-top: 4px;
  background-color: ${theme.getColor('lightButton')};
  padding: ${theme.getSpacing(2, 'px')};
  border-radius: ${theme.getSpacing(1, 'px')};
  text-transform: uppercase;
  border-radius: 20px;
`;
