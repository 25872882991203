import styled from '@emotion/styled';

export const FooterContainer = styled.div`
  width: 100%;
  height: 80px;
  padding-left: ${({ theme }) => theme && theme.getSpacing(4, 'px')};
  padding-right: ${({ theme }) => theme && theme.getSpacing(4, 'px')};
  background-color: ${({ theme }) => theme && theme.getColor('foreground')};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const LeftText = styled.span`
  color: ${({ theme }) => theme && theme.getColor('uiText')};
  font-size: ${({ theme }) => theme && theme.getFontSize(1.2, 'px')};
  font-weight: bold;
  text-align: left;
`;
