import styled from '@emotion/styled';
import facepaint from 'facepaint';

const mq = facepaint(['@media screen and (min-width: 375px)']);

export const ButtonWrapper = styled.div`
  width: 100%;
  min-height: 52px;
  padding: 5px 20px 3px 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 20px;
  cursor: pointer;
  background-color: #f5f5f5;
  ${({ disabled }) =>
    disabled && 'cursor: default; pointer-events: none; opacity: 0.7;'}
  overflow: hidden;
`;

export const ButtonText = styled.span`
  ${({ theme }) =>
    mq({
      fontSize: [theme.getFontSize(0.9, 'px'), theme.getFontSize(1, 'px')],
      lineHeight: [theme.getFontSize(1.3, 'px'), theme.getFontSize(1.5, 'px')],
    })}
  font-family: ${({ theme }) => theme && theme.getFont('futura')};
  color: ${({ theme }) => theme && theme.getColor('text')};
  margin: 4px 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
