import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import GradeIcon from '@material-ui/icons/Grade';
import PersonRoundedIcon from '@material-ui/icons/PersonRounded';
import LocalMallRoundedIcon from '@material-ui/icons/LocalMallRounded';
import HomeRoundedIcon from '@material-ui/icons/HomeRounded';

import {
  BottomNavigationList,
  BottomNavigationItem,
} from './BottomNavigator.styles';

const navigationDisabledRoutes = [
  '/app-wizard',
  '/registro',
  '/registro/endereco',
  '/registro/concluido',
  '/recuperar-senha',
  '/nova-senha/',
  '/m/',
  '/o/',
  '/p/',
  '/registro/erro',
];

const iconStyle = { marginBottom: '7px', height: '24px', width: '24px' };

const mapStateToProps = ({ auth, tenantEyepass: { domain } }) => ({
  isLogged: auth.isLogged,
  domain,
});

const BottomNavigator = ({ isLogged, domain }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const [pathname, setPathname] = useState(history.location?.pathname);

  useEffect(() => {
    return history.listen((location) => {
      setPathname(location.pathname);
    });
  }, [history]);

  const navigateTo = (path) => {
    history.push(path);
  };

  const onPageChange = (event, newPage) => {
    navigateTo(newPage);
  };

  const getPathWithDomain = (path) => `/${domain}${path}`;

  if (navigationDisabledRoutes.some((route) => pathname.includes(route))) {
    return null;
  }

  if (!domain || history.location?.pathname.split('/')[1] !== domain) {
    return true;
  }

  return (
    <BottomNavigationList
      onChange={onPageChange}
      showLabels
      style={{ position: 'fixed', bottom: 0 }}
    >
      <BottomNavigationItem
        label={t('navigation:start')}
        value={getPathWithDomain('/carrinho')}
        icon={<HomeRoundedIcon style={iconStyle} />}
        active={pathname === getPathWithDomain('/carrinho')}
      />
      <BottomNavigationItem
        label={t('navigation:loyalties')}
        value={getPathWithDomain('/fidelidade')}
        icon={<GradeIcon style={iconStyle} />}
        active={pathname === getPathWithDomain('/fidelidade')}
      />
      <BottomNavigationItem
        label={t('navigation:orders')}
        value={getPathWithDomain('/historico')}
        icon={<LocalMallRoundedIcon style={iconStyle} />}
        active={pathname === getPathWithDomain('/historico')}
      />
      {isLogged ? (
        <BottomNavigationItem
          label={t('navigation:userProfile')}
          value={getPathWithDomain('/minha-conta')}
          icon={<PersonRoundedIcon style={iconStyle} />}
          active={pathname === getPathWithDomain('/minha-conta')}
        />
      ) : (
        <BottomNavigationItem
          label={t('navigation:login')}
          value={getPathWithDomain('/login')}
          icon={<PersonRoundedIcon style={iconStyle} />}
          active={pathname === getPathWithDomain('/login')}
        />
      )}
    </BottomNavigationList>
  );
};

BottomNavigator.defaultProps = {
  domain: null,
};

BottomNavigator.propTypes = {
  isLogged: PropTypes.bool.isRequired,
  domain: PropTypes.string,
};

const enhance = compose(connect(mapStateToProps));

export default enhance(BottomNavigator);
