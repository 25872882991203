import { css } from '@emotion/core';

export const productList = (theme) => css`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: ${theme.getSpacing(2, 'px')};
`;

export const productMenuHeader = (theme) => css`
  width: 100%;
  padding-left: ${theme.getSpacing(2, 'px')};
  padding-right: ${theme.getSpacing(2, 'px')};
  margin-bottom: ${theme.getSpacing(4, 'px')};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const menuTitle = (theme) => css`
  font-family: ${theme && theme.getFont('futura')};
  font-size: ${theme && theme.getFontSize(1, 'px')};
  color: ${theme && theme.getColor('text')};
  text-transform: uppercase;
`;

export const clearButton = css`
  padding: 2px;
  cursor: pointer;
  width: 50px;
`;

export const clearButtonText = (theme) => css`
  font-family: ${theme && theme.getFont('futura')};
  font-size: ${theme && theme.getFontSize(0.8, 'px')};
  color: ${theme && theme.getColor('primary')};
`;

export const bottomOptions = (theme) => css`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: ${theme.getSpacing(2, 'px')};
  padding-right: ${theme.getSpacing(2, 'px')};
  padding-top: ${theme.getSpacing(2, 'px')};
`;

export const priceText = (theme) => css`
  font-family: ${theme && theme.getFont('futura')};
  font-size: ${theme && theme.getFontSize(1.2, 'px')};
  color: ${theme && theme.getColor('text')};
`;

export const contentScroll = (theme) => css`
  width: 100%;
  flex: 1;
  display: block;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;

  padding-left: ${theme && theme.getSpacing(1, 'px')};
  padding-right: ${theme && theme.getSpacing(1, 'px')};

  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }
  &::-webkit-scrollbar:vertical {
    width: 8px;
  }
  &::-webkit-scrollbar:horizontal {
    height: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    border: 2px solid #ffffff;
  }
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #ffffff;
  }
`;

export const addItensButton = (theme) => css`
  width: 100%;
  padding: ${theme.getSpacing(2, 'px')};
  margin-top: ${theme.getSpacing(2, 'px')};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const addItensButtonText = (theme) => css`
  color: ${theme.getColor('primary')};
  font-family: ${theme.getFont('futura')};
  font-size: ${theme.getFontSize(1, 'px')};
`;

export const noProductText = (theme) => css`
  font-size: ${theme && theme.getFontSize()};
  font-family: ${theme && theme.getFont()};
  color: ${theme && theme.getColor('text')};
  width: 100%;
  text-align: center;
  margin-top: ${theme && theme.getSpacing(3, 'px')};
`;
