import styled from '@emotion/styled';
import { css } from '@emotion/core';
import facepaint from 'facepaint';

const mq = facepaint(['@media screen and (min-width: 375px)']);

const headerAndFooterOnly = mq({
  height: ['calc(100% - 145px)', 'calc(100% - 160px)'],
});

const headerAndFooterBps = mq({
  height: ['calc(100% - 145px - 50px)', 'calc(100% - 160px - 50px)'],
});

const headerOrFooterBps = mq({
  height: ['calc(100% - 65px - 50px)', 'calc(100% - 70px - 50px)'],
});

const adjustHeight = ({ hasHeader, hasFooter, hasBottomTabs }) => {
  if (hasHeader && hasFooter && !hasBottomTabs) {
    return headerAndFooterOnly;
  }

  if (hasHeader && hasFooter) {
    return headerAndFooterBps;
  }

  if (hasHeader || hasFooter) {
    return headerOrFooterBps;
  }

  return 'height: 100%';
};

const scrollBarStyling = ({ hideScroll }) => {
  const hiddenScroll = css`
    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE and Edge */
    -ms-overflow-style: none;
  `;

  const styledScroll = css`
    &::-webkit-scrollbar {
      -webkit-appearance: none;
    }
    &::-webkit-scrollbar:vertical {
      width: 8px;
    }
    &::-webkit-scrollbar:horizontal {
      height: 8px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.25);
      border-radius: 10px;
      border: 2px solid #ffffff;
    }
    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: #ffffff;
    }
  `;

  return hideScroll ? hiddenScroll : styledScroll;
};

const ScrollDiv = styled.div`
  flex: 1;
  display: ${({ flex }) => (flex ? 'flex' : 'block')};
  overflow-y: auto;
  flex-direction: column;
  margin: 0 auto;
  position: relative;
  ${adjustHeight};

  ${scrollBarStyling}
`;

export default ScrollDiv;
