import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

import { mq } from '../../utils/commonMqs';

export const backgroundCss = (theme) => css`
  width: 100%;
  height: 100%;
  background-color: ${(theme && theme.getColor('loginBackground')) ||
  theme.getColor('foreground')};
`;

export const wrapperCss = (theme) => css`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: ${theme && theme.getSpacing(4, 'px')};
  padding-right: ${theme && theme.getSpacing(4, 'px')};
  ${mq({
    paddingTop: [
      theme.getSpacing(2, 'px'),
      theme.getSpacing(3, 'px'),
      theme.getSpacing(6, 'px'),
    ],
  })}
  padding-bottom: ${theme && theme.getSpacing(12, 'px')};
`;

export const flavourTextCss = (theme) => css`
  font-family: ${theme && theme.getFont('heading')};
  color: ${theme && theme.getColor('text')};
  text-align: center;
  margin: ${theme && theme.getSpacing(2, 'px')};
  font-size: ${theme && theme.getFontSize(1, 'px')};
`;

export const buttonWrapperCss = (theme) => css`
  padding-top: ${theme && theme.getSpacing(2, 'px')};
  padding-bottom: ${theme && theme.getSpacing(2, 'px')};
`;

export const fieldPadding = (theme) => css`
  width: 100%;
  ${mq({
    paddingTop: [
      theme.getSpacing(0.5, 'px'),
      theme.getSpacing(1, 'px'),
      theme.getSpacing(2, 'px'),
    ],
    paddingBottom: [
      theme.getSpacing(0.5, 'px'),
      theme.getSpacing(1, 'px'),
      theme.getSpacing(2, 'px'),
    ],
  })}
`;

export const ErrorAnimation = styled.div`
  margin-bottom: ${({ theme }) => theme.getSpacing(2, 'px')};
  display: ${({ hasError }) => (hasError ? 'block' : 'none')};
`;

export const LoginError = styled.div`
  color: #ffffff;
  text-align: center;
  background-color: ${({ theme }) => theme && theme.getColor('error')};
  border-radius: 4px;
  padding: ${({ theme }) => theme && theme.getSpacing(2, 'px')};

  p {
    &:not(:last-child) {
      margin-bottom: ${({ theme }) => theme && theme.getSpacing(1, 'px')};
    }
  }

  a {
    text-decoration: underline;
  }
`;

export const ForgotPassword = styled(Link)`
  color: ${({ theme }) => theme && theme.getColor('signupButton')};
  text-align: center;
  text-decoration: underline;
  margin-top: ${({ theme }) => theme && theme.getSpacing(3, 'px')};
  cursor: pointer;
`;

export const FlagButton = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 2px;
  cursor: pointer;
`;
