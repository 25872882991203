import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  ItemContainer,
  InnerBox,
  StatusText,
  infoCol,
  topRowStyle,
  DescriptionTextCss,
  priceTextCss,
} from './HistoryItem.styles';

const statusColorsMap = {
  REGISTERED: 'info',
  PREPARING: 'default',
  READY: 'success',
  DELIVERED: 'success',
  WAITING_PAYMENT: 'info',
  REJECTED: 'error',
  CANCELED: 'error',
  PAYMENT_CANCELED: 'error',
  CANCELLED_BY_OPERATOR: 'error',
  COLLECTED: 'success',
};

const HistoryItem = ({
  orderId,
  storeName,
  priceText,
  timeText,
  orderStatus,
  onSeeDetails,
  deliveryStatus,
}) => {
  const { t } = useTranslation();

  const adaptedOrderStatus =
    deliveryStatus === 'PACKAGE_DELIVERED' && orderStatus === 'READY'
      ? 'DELIVERED'
      : orderStatus;

  const statusText = t(`orderHistory:${adaptedOrderStatus}`);

  const statusColor = statusColorsMap[adaptedOrderStatus];

  const renderDeliveryStatus = () => {
    const deliveryStatusText = t(`orderHistory:${deliveryStatus}`);

    return (
      <div css={topRowStyle}>
        <DescriptionTextCss>{deliveryStatusText}</DescriptionTextCss>
      </div>
    );
  };

  return (
    <ItemContainer>
      <InnerBox
        onClick={onSeeDetails}
        onKeyDown={onSeeDetails}
        type="button"
        aria-label={`order-${orderId}`}
        role="presentation"
      >
        <div css={infoCol} style={{ marginRight: '10px' }}>
          <div css={topRowStyle}>
            <DescriptionTextCss fontFamily="futura">
              {`${t('orderHistory:order')} #${orderId} - ${timeText}`}
            </DescriptionTextCss>
          </div>
          <div css={topRowStyle}>
            <DescriptionTextCss>{storeName}</DescriptionTextCss>
          </div>
          {deliveryStatus && renderDeliveryStatus()}
          <span css={priceTextCss}>{priceText}</span>
        </div>
        <div css={infoCol} style={{ alignItems: 'flex-end' }}>
          <StatusText statusColor={statusColor}>{statusText}</StatusText>
        </div>
      </InnerBox>
    </ItemContainer>
  );
};

HistoryItem.propTypes = {
  orderId: PropTypes.string.isRequired,
  storeName: PropTypes.string.isRequired,
  priceText: PropTypes.string.isRequired,
  timeText: PropTypes.string.isRequired,
  orderStatus: PropTypes.string.isRequired,
  onSeeDetails: PropTypes.func.isRequired,
  deliveryStatus: PropTypes.string.isRequired,
};

export default HistoryItem;
