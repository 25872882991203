import { css } from '@emotion/core';

export const confirmationWrapper = css`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const warningText = (theme) => css`
  width: 100%;
  text-align: center;
  color: ${theme && theme.getColor('text')};
  font-family: ${theme && theme.getFont()};
  font-size: ${theme && theme.getFontSize(1, 'px')};
`;

export const buttonsRow = (theme) => css`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: ${theme && theme.getSpacing(2, 'px')};
`;
