import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Modal from '@bit/eyemobile.core.modal';
import CircleButton from '@bit/eyemobile.core.circle-button';

import { ReactComponent as KeyboardArrowDown } from '../../assets/keyboard_arrow_down.svg';
import UserAddressForm from '../../components/UserAddressForm';
import withModal from '../../hocs/withModal';
import {
  productContent,
  productInnerWrapper,
  productOverlay,
  container,
  menuHeader,
  menuTitle,
  contentScroll,
} from '../../components/ProductAdjustModal/ProductAdjustModal.styles';

const AddressAddModal = ({ visible, onClose, onFinish }) => {
  const { t } = useTranslation();

  return (
    <Modal
      visible={visible}
      styles={{
        innerWrapper: productInnerWrapper,
        content: productContent,
        overlay: productOverlay,
      }}
      keepMounted
    >
      <div css={container}>
        <div css={menuHeader}>
          <div style={{ width: '30px' }} />
          <span css={menuTitle}>{t('addressesListPage:addAddress')}</span>
          <CircleButton
            icon={<KeyboardArrowDown />}
            size="30px"
            onClick={onClose}
            backgroundColor="primary"
            color="background"
            padding="3px"
          />
        </div>
        <div css={contentScroll}>
          <UserAddressForm onFormSubmit={onFinish} />
        </div>
      </div>
    </Modal>
  );
};

AddressAddModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onFinish: PropTypes.func.isRequired,
};

export default withModal((props) => ({
  visible: props.visible,
}))(AddressAddModal);
