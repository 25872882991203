import React from 'react';
import PropTypes from 'prop-types';

import LoadingScreen from '../LoadingScreen';
import CacheBusterOverlay from './CacheBusterOverlay';
import CacheBuster from './CacheBuster';

const CacheBusterWrapper = ({ children }) => {
  return (
    <CacheBuster>
      {({ loading, refreshCacheAndReload, isLatestVersion }) => {
        if (loading) {
          return <LoadingScreen />;
        }

        return (
          <>
            {!isLatestVersion && (
              <CacheBusterOverlay
                reloadApp={refreshCacheAndReload}
                isLatestVersion={isLatestVersion}
              />
            )}
            {children}
          </>
        );
      }}
    </CacheBuster>
  );
};

CacheBusterWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
    PropTypes.array,
  ]).isRequired,
};

export default CacheBusterWrapper;
