import { css } from '@emotion/core';
import styled from '@emotion/styled';

export const RadioButton = styled.div`
  padding: ${({ theme }) => theme && theme.getSpacing(1, 'px')};
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  outline: none;
  user-select: none;
  color: ${({ theme }) => theme && theme.getColor('default')};
  font-size: ${({ theme }) => theme && theme.getFontSize(1, 'px')};

  ${({ disabled }) => disabled && 'opacity: 0.5; pointer-events: none;'}
`;

export const radioButtonLabel = (theme) => css`
  width: 100%;
  color: inherit;
  font-size: inherit;
  font-family: ${theme && theme.getFont()};
  text-align: left;
  cursor: inherit;
`;

const getMargin = ({ ltor, theme }) => {
  const spacing = theme && theme.getSpacing(1, 'px');
  return ltor ? `margin-right: ${spacing}` : `margin-left: ${spacing}`;
};

export const RadioButtonInput = styled.div`
  border: none;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: transparent;
  border: 1px solid ${({ theme }) => theme && theme.getColor('primary')};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  font-size: 16px;
  box-shadow: ${({ theme, checked }) =>
    checked && `inset 0px 0px 3px ${theme && theme.getColor('primary')}`};
  ${getMargin};
`;

export const radioButtonCheck = (theme) => css`
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background-color: ${theme && theme.getColor('primary')};
  flex-shrink: 0;
`;
