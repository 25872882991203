import { css } from '@emotion/core';
import styled from '@emotion/styled';

export const languageSelectWrapper = (theme) => css`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: ${theme && theme.getSpacing(1.5, 'px')};
  padding-right: ${theme && theme.getSpacing(1.5, 'px')};
  padding-bottom: ${theme && theme.getSpacing(1, 'px')};
  height: 100%;
  overflow: hidden;
`;

export const contentScroll = css`
  width: 100%;
  display: block;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }
  &::-webkit-scrollbar:vertical {
    width: 8px;
  }
  &::-webkit-scrollbar:horizontal {
    height: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    border: 2px solid #ffffff;
  }
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #ffffff;
  }
`;

export const LanguageItem = styled.div`
  border-radius: 17px;
  background-color: ${({ theme, active }) =>
    active ? theme.getColor('primary') : '#f5f5f5'};
  width: 100%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: ${({ theme }) => theme && theme.getSpacing(2, 'px')};
  padding-right: ${({ theme }) => theme && theme.getSpacing(2, 'px')};
  padding-top: ${({ theme }) => theme && theme.getSpacing(2, 'px')};
  padding-bottom: ${({ theme }) => theme && theme.getSpacing(2, 'px')};

  &:not(:last-of-type) {
    margin-bottom: ${({ theme }) => theme && theme.getSpacing(1, 'px')};
  }
`;

export const LanguageItemText = styled.span`
  color: ${({ theme, active }) =>
    active ? theme.getColor('background') : theme.getColor('text')};
  font-family: ${({ theme }) => theme.getFont('futura')};
  font-size: ${({ theme }) => theme.getFontSize(1, 'px')};
`;

export const languageListTitle = (theme) => css`
  color: ${theme.getColor('text')};
  font-family: ${theme.getFont('futura')};
  font-size: ${theme.getFontSize(1.2, 'px')};
  margin-bottom: ${theme.getSpacing(2, 'px')};
`;
