import { css } from '@emotion/core';
import facepaint from 'facepaint';

const mq = facepaint(['@media screen and (min-width: 375px)']);

export const headerContainerCss = (theme) => css`
  width: 100%;
  height: 50px;
  background-color: ${theme && theme.getColor('background')};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #655b7c40;
  ${mq({
    paddingLeft: [theme.getSpacing(2, 'px'), theme.getSpacing(4, 'px')],
    paddingRight: [theme.getSpacing(2, 'px'), theme.getSpacing(4, 'px')],
  })}
`;

export const headerTextCss = (theme) => css`
  font-family: ${theme && theme.getFont('futura')};
  font-size: ${theme && theme.getFontSize(1, 'px')};
  color: ${theme && theme.getColor('text')};
  text-transform: uppercase;
`;

export const headerCornerButtonDivCss = (theme) => css`
  padding-top: ${theme && theme.getSpacing(1, 'px')};
  padding-bottom: ${theme && theme.getSpacing(1, 'px')};
  height: 100%;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${theme && theme.getColor('text')};
`;
