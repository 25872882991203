import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useTranslation } from 'react-i18next';

import SessionStorageManager from '../../utils/SessionStorageManager';
import dayjs from '../../utils/dayjs';
import gateway from '../../utils/gateway';
import http from '../../utils/http';
import { getToken } from '../../utils/firebase';
import showToast from '../../utils/showToast';
import { Creators as AuthCreators } from '../../store/modules/auth';
import { Creators as UserCreators } from '../../store/modules/user';
import * as productOrderActions from '../../store/modules/productOrder';
import Analytics from '../../utils/Analytics';
import {
  analyticsEventActions,
  analyticsEventCategory,
} from '../../utils/constants';
import LanguagesModalsContainer from '../../components/LanguageModalsContainer';
import PdfViewerModal from '../../components/PdfViewerModal';
import TOSPdf from '../../assets/tos.pdf';

import UserDeleteConfirmationModal from './UserDeleteConfirmationModal';
import UserDeletePasswordModal from './UserDeletePasswordModal';
import UserBasicDataScreen from './UserBasicDataScreen';
import ContactUsModal from './ContactUsModal';
import UserDataScreen from './UserDataScreen';

const mapStateToProps = ({
  user,
  tenantEyepass: { domain, tenantId, tosUrl },
}) => ({
  user,
  domain,
  tenantId,
  tosUrl,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      logout: AuthCreators.logout,
      setUser: UserCreators.setUser,
      clearProductOrder: productOrderActions.clearProductOrder,
    },
    dispatch,
  );

const UserBasicDataContainer = ({
  history,
  user,
  logout,
  domain,
  tenantId,
  tosUrl,
  setUser,
  clearProductOrder,
}) => {
  const { t } = useTranslation();
  const [deleteConfirmationModal, setDeleteConfirmationModal] = useState(false);
  const [deletePasswordModal, setDeletePasswordModal] = useState(false);
  const [showUserData, setShowUserData] = useState(false);
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);
  const [languageModalOpen, setLanguageModalOpen] = useState(false);
  const [tosModalOpen, setTosModalOpen] = useState(false);
  const [tenantContact, setTenantContact] = useState(null);

  const userData = {
    fullName: user.name,
    phone: user.phone,
    document: user.document,
    email: user.email,
    birthday: user.birthday
      ? dayjs(user.birthday).utc().format('DD/MM/YYYY')
      : '',
    pushNotificationEnabled: !user.push_notification_disabled,
  };

  const toggleContactModal = () =>
    setIsContactModalOpen((prevValue) => !prevValue);

  const fetchTenantContact = async () => {
    try {
      const response = await http
        .get(`/tenants/${tenantId}/contact`)
        .then(({ data }) => data);

      setTenantContact(response.data.contact);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchTenantContact();
  }, []);

  const deleteUserData = async (values, formikBag) => {
    try {
      formikBag.setSubmitting(true);
      await gateway.delete('/account/delete', {
        data: {
          password: values.password,
        },
      });

      Analytics.event({
        category: analyticsEventCategory.CUSTOMER,
        action: analyticsEventActions.DELETE_USER_DATA,
        label: 'Remoção de dados do usuário',
      });
      logout();
      history.replace(`/${domain}/carrinho`);
    } catch (error) {
      const responseMessage = error?.response?.data?.data?.message;

      showToast(responseMessage || error.message, 'error');
    } finally {
      formikBag.setSubmitting(false);
    }
  };

  const updateUserData = async (values, formikBag) => {
    try {
      formikBag.setSubmitting(true);
      await gateway.put('/profile', {
        fullName: values.fullName,
        birthday: values.birthday,
        email: values.email,
        phone: values.phone,
      });

      showToast(t('success:profileUpdate'), 'success');
      const profile = await gateway.get('/profile').then(({ data }) => data);

      setUser(profile.customer);
      setShowUserData(false);
    } catch (err) {
      console.error(err);
    } finally {
      formikBag.setSubmitting(false);
    }
  };

  const clearCustomerNotificationToken = async () => {
    try {
      const token = await getToken();

      if (token) {
        await gateway.post(`/customer-notification?token=${token}`);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleLogout = () => {
    Analytics.event({
      category: analyticsEventCategory.CUSTOMER,
      action: analyticsEventActions.LOGOUT,
      label: 'Logout',
    });
    clearCustomerNotificationToken();
    SessionStorageManager.clear();
    clearProductOrder();
    logout();
  };

  const handleReceiveNotificationChange = async (enabled) => {
    try {
      await gateway.put('/customer-notification/disabled', {
        disabled: !enabled,
      });

      const profile = await gateway.get('/profile').then(({ data }) => data);

      setUser(profile.customer);
    } catch (err) {
      console.error(err);
      showToast(err?.response?.data?.message || err.message, 'error');
    }
  };

  const toggleShowUserData = () => setShowUserData((prev) => !prev);

  const toggleTosModal = () => setTosModalOpen((prev) => !prev);

  const openDeleteConfirmationModal = () => setDeleteConfirmationModal(true);

  const openLanguageModal = () => setLanguageModalOpen(true);

  const closeModals = () => {
    setDeleteConfirmationModal(false);
    setDeletePasswordModal(false);
    setLanguageModalOpen(false);
    setTosModalOpen(false);
  };

  const handleDeleteConfirmation = () => {
    setDeleteConfirmationModal(false);
    setDeletePasswordModal(true);
  };

  if (showUserData) {
    return (
      <>
        <UserDeleteConfirmationModal
          visible={deleteConfirmationModal}
          onCloseModal={closeModals}
          onConfirm={handleDeleteConfirmation}
        />
        <UserDeletePasswordModal
          visible={deletePasswordModal}
          onCloseModal={closeModals}
          onSubmit={deleteUserData}
        />
        <UserDataScreen
          userData={userData}
          initialValues={userData}
          onBackClick={toggleShowUserData}
          onUserDataDelete={openDeleteConfirmationModal}
          onReceiveNotificationChange={handleReceiveNotificationChange}
          onFormSubmit={updateUserData}
        />
      </>
    );
  }

  return (
    <>
      <LanguagesModalsContainer
        visible={languageModalOpen}
        onCloseModal={closeModals}
      />
      <UserBasicDataScreen
        userData={userData}
        onUserDataDelete={openDeleteConfirmationModal}
        onLogout={handleLogout}
        onShowUserData={toggleShowUserData}
        tenantTerms={tosUrl || TOSPdf}
        tenantContact={tenantContact}
        onOpenContactModal={toggleContactModal}
        onOpenLanguagesModal={openLanguageModal}
        onOpenTosModal={toggleTosModal}
        domain={domain}
      />
      <ContactUsModal
        visible={isContactModalOpen}
        tenantContact={tenantContact}
        onClose={toggleContactModal}
      />
      <PdfViewerModal visible={tosModalOpen} onCloseModal={closeModals} />
    </>
  );
};

UserBasicDataContainer.defaultProps = {
  domain: null,
  tosUrl: null,
};

UserBasicDataContainer.propTypes = {
  logout: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  domain: PropTypes.string,
  tenantId: PropTypes.number.isRequired,
  tosUrl: PropTypes.string,
  setUser: PropTypes.func.isRequired,
  clearProductOrder: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserBasicDataContainer);
