const validateProducts = (prevProduct, newProduct) => {
  if (
    prevProduct.id === newProduct.id &&
    prevProduct.productId === newProduct.productId &&
    prevProduct.price === newProduct.price
  ) {
    return (
      !newProduct.rigidStockEnabled ||
      newProduct.stockBalance === undefined ||
      newProduct.stockBalance >= prevProduct.amount
    );
  }

  return false;
};

const validateProductInMenu = (product, productMenus) => {
  if (product.isHistoryProduct) {
    return true;
  }

  return productMenus.some(({ products }) =>
    products.some((newProduct) => validateProducts(product, newProduct)),
  );
};

const adaptOrderToNewStore = (previousProducts, newProductMenus) => {
  const filteredProducts = previousProducts.filter((product) =>
    validateProductInMenu(product, newProductMenus),
  );

  return filteredProducts;
};

export default adaptOrderToNewStore;
