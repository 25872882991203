import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import CircleButton from '@bit/eyemobile.core.circle-button';
import Button from '@bit/eyemobile.core.button';

import { ReactComponent as KeyboardArrowDown } from '../../assets/keyboard_arrow_down.svg';
import CreditCardItemSelect from '../CreditCardItemSelect';

import {
  container,
  menuHeader,
  menuTitle,
  contentScroll,
} from './CreditCardList.styles';

const CreditCardList = ({
  onClose,
  onAdd,
  creditCardList,
  onCreditCardSelect,
}) => {
  const { t } = useTranslation();

  const renderCreditCard = (creditCard) => {
    const { id, number, brand } = creditCard;

    return (
      <CreditCardItemSelect
        key={`cc-${id}`}
        cardNumber={number}
        brand={brand && brand.toLowerCase()}
        onSelect={() => onCreditCardSelect(creditCard)}
      />
    );
  };

  return (
    <div css={container}>
      <div css={menuHeader}>
        <div style={{ width: '30px' }} />
        <span css={menuTitle}>{t('orderReview:creditCards')}</span>
        <CircleButton
          icon={<KeyboardArrowDown />}
          size="30px"
          onClick={onClose}
          backgroundColor="primary"
          color="background"
          padding="3px"
        />
      </div>
      <div css={contentScroll}>{creditCardList.map(renderCreditCard)}</div>
      <div style={{ width: '100%', marginTop: '10px', padding: '12px' }}>
        <Button onClick={onAdd} color="primary" block>
          {t('orderReview:add')}
        </Button>
      </div>
    </div>
  );
};

CreditCardList.defaultProps = {
  onAdd: null,
};

CreditCardList.propTypes = {
  onClose: PropTypes.func.isRequired,
  onAdd: PropTypes.func,
  creditCardList: PropTypes.array.isRequired,
  onCreditCardSelect: PropTypes.func.isRequired,
};

export default CreditCardList;
