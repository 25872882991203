import { imagesUrl } from './constants';

const fullResPath = `${imagesUrl}/products`;

const getProductImageSrc = (imageKey, res = 96) => {
  if (!imageKey) {
    return undefined;
  }

  if (!res) {
    return `${fullResPath}/${imageKey}`;
  }

  return `${fullResPath}/${res}x${res}/${imageKey}`;
};

export default getProductImageSrc;
