import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';

import Analytics from '../../utils/Analytics';
import {
  analyticsEventActions,
  analyticsEventCategory,
  paymentProviders,
} from '../../utils/constants';
import EcommerceService from '../../services/ecommerce';
import clean from '../../utils/clean';
import gateway from '../../utils/gateway';
import mapStore from '../../utils/mapStore';
import showToast from '../../utils/showToast';
import hashCreditCard from '../../utils/hashCreditCard';
import fetchGeolocation from '../../utils/fetchGeolocation';
import { Creators as UserCreators } from '../../store/modules/user';

import AddCreditCardModal from './AddCreditCardModal';
import CardsPageScreen from './CardsPageScreen';

const mapStateToProps = ({
  productOrder: {
    productOrder: { store },
  },
  tenantEyepass: { domain, providerId },
}) => ({
  selectedStore: store,
  domain,
  providerId,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setUser: UserCreators.setUser,
    },
    dispatch,
  );

const CardsPageContainer = ({
  history,
  selectedStore,
  setUser,
  domain,
  providerId,
}) => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [customerCards, setCustomerCards] = useState([]);
  const [addCardModalOpen, setAddCardModalOpen] = useState(false);

  const handlePrev = () => history.push(`/${domain}/minha-conta`);

  const toggleModalOpen = () => setAddCardModalOpen((prev) => !prev);

  const loadCards = async () => {
    try {
      setIsLoading(true);

      const response = await gateway.get('/cards').then(({ data }) => data);

      setCustomerCards(response);
    } catch (err) {
      console.error(err);
      showToast(err?.response?.data?.message || err.message, 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const reloadUser = async () => {
    try {
      const profile = await gateway.get('/profile').then(({ data }) => data);

      setUser(profile.customer);
      loadCards();
    } catch (err) {
      showToast(err?.response?.data?.message || err.message, 'error');
      setIsLoading(false);
    }
  };

  const handleSetMainCard = async (cardId) => {
    try {
      setIsLoading(true);
      await gateway.put(`/cards/${cardId}/main`);
      showToast(t('cardsPage:mainCardSetSuccess'), 'success');
      reloadUser();
    } catch (err) {
      console.error(err);
      setIsLoading(false);
      showToast(err?.response?.data?.message || err.message, 'error');
    }
  };

  const handleRemoveCard = async (cardId) => {
    try {
      setIsLoading(true);
      await gateway.delete(`/cards/${cardId}`);
      Analytics.event({
        category: analyticsEventCategory.CUSTOMER,
        action: analyticsEventActions.REMOVE_CREDIT_CARD,
        label: 'Cartão removido',
      });
      showToast(t('cardsPage:cardRemoveSuccess'), 'success');
      reloadUser();
    } catch (err) {
      console.error(err);
      setIsLoading(false);
      showToast(err?.response?.data?.message || err.message, 'error');
    }
  };

  useEffect(() => {
    loadCards();
  }, []);

  const fetchEventPointId = async () => {
    let position = null;

    try {
      position = await fetchGeolocation();
    } catch (err) {
      showToast(t('warnings:geoLocationDisabled'), 'warning');
    }

    try {
      let pointParams = {};
      if (position?.coords) {
        pointParams = {
          ...pointParams,
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        };
      }

      const response = await gateway
        .get(EcommerceService.getEventPoints(), { params: clean(pointParams) })
        .then(({ data }) => data);

      if (response.event_points) {
        const storeList = response.event_points
          .filter(({ open }) => !!open)
          .map(mapStore);

        if (storeList.length > 0) {
          return storeList[0].id;
        }
      }
    } catch (e) {
      console.error(e);
      showToast(t('errors:fetchPointsFailure'), 'error');
    }

    return null;
  };

  const getPayload = async (cardData, eventPointId) => {
    const {
      creditCardCvv,
      creditCardExpiration,
      creditCardHolderName,
      creditCardNumber,
      isMain,
    } = cardData;

    let payload;

    if (providerId === paymentProviders.GETNET) {
      payload = {
        number: creditCardNumber,
        cvv: creditCardCvv,
        expiration_date: creditCardExpiration,
        holder_name: creditCardHolderName,
        event_point_id: eventPointId,
      };
    } else {
      const creditCardHash = await hashCreditCard(
        eventPointId,
        creditCardNumber,
        creditCardHolderName,
        creditCardExpiration,
        creditCardCvv,
      );
      payload = {
        card_hash: creditCardHash,
        main: isMain || false,
        event_point_id: eventPointId,
      };
    }
    return { ...payload, providerId };
  };

  const handleAddCreditCard = async (cardData, formikBag) => {
    try {
      formikBag.setSubmitting(true);

      let eventPointId;

      if (selectedStore?.id) {
        eventPointId = selectedStore.id;
      } else {
        eventPointId = await fetchEventPointId();
      }

      if (eventPointId) {
        const payload = await getPayload(cardData, eventPointId);
        await gateway.post('/cards', payload);

        Analytics.event({
          category: analyticsEventCategory.CUSTOMER,
          action: analyticsEventActions.ADD_CREDIT_CARD,
          label: 'Cartão adicionado',
        });
        showToast(t('cardsPage:cardAddSuccess'), 'success');

        formikBag.setSubmitting(false);
        toggleModalOpen();
        reloadUser();
      }
    } catch (err) {
      showToast(err?.response?.data?.message || err.message, 'error');
      formikBag.setSubmitting(false);
    }
  };

  return (
    <>
      <AddCreditCardModal
        visible={addCardModalOpen}
        onClose={toggleModalOpen}
        onFinish={handleAddCreditCard}
      />
      <CardsPageScreen
        onPrev={handlePrev}
        isLoading={isLoading}
        customerCards={customerCards}
        onSetMainCard={handleSetMainCard}
        onRemoveCard={handleRemoveCard}
        onAddCard={toggleModalOpen}
      />
    </>
  );
};

CardsPageContainer.defaultProps = {
  domain: null,
  providerId: null,
};

CardsPageContainer.propTypes = {
  history: PropTypes.array.isRequired,
  selectedStore: PropTypes.object.isRequired,
  setUser: PropTypes.func.isRequired,
  domain: PropTypes.string,
  providerId: PropTypes.number,
};

const enhance = compose(connect(mapStateToProps, mapDispatchToProps));

export default enhance(CardsPageContainer);
