const reduceComplementItem = (itemAcc, complementItem) => {
  const { quantity, price } = complementItem;
  return itemAcc + quantity * price;
};

const sumComplementTotal = (complementGroups) =>
  complementGroups.reduce((acc, complementGroup) => {
    const { complementItems } = complementGroup;
    return acc + complementItems.reduce(reduceComplementItem, 0);
  }, 0);

export default sumComplementTotal;
