import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import localizePrice from '../../utils/localizePrice';

import {
  tabWrapper,
  tabTitle,
  tabTitleWrapper,
  priceRelationWrapper,
  priceRelationRow,
  PriceRelationText,
  Divisor,
} from '../OrderReviewPage/OrderReviewPage.styles';

const PaymentLinkPriceRelations = ({
  totalPrice,
  finalPrice,
  deliveryFee,
  transactionPays,
}) => {
  const { t } = useTranslation();
  const [payValue, setPayValue] = useState(0);
  const [giftCardValue, setGiftCardValue] = useState(0);

  const getPayValue = () => {
    const newGiftCardValue = transactionPays.reduce((acc, transactionPay) => {
      if (transactionPay.gift_card_id) {
        return acc + transactionPay.total;
      }

      return acc;
    }, 0);
    setGiftCardValue(newGiftCardValue);
    setPayValue(finalPrice - newGiftCardValue);
  };

  useEffect(() => {
    getPayValue();
  }, [finalPrice]);

  return (
    <div css={tabWrapper}>
      <div css={tabTitleWrapper}>
        <span css={tabTitle}>{t('orderReview:prices')}</span>
      </div>
      <div css={priceRelationWrapper}>
        <div css={priceRelationRow}>
          <PriceRelationText>{t('orderHistory:subtotal')}</PriceRelationText>
          <PriceRelationText>{`R$ ${localizePrice(
            totalPrice,
          )}`}</PriceRelationText>
        </div>
        <div css={priceRelationRow}>
          <PriceRelationText>{t('orderHistory:deliveryFee')}</PriceRelationText>
          <PriceRelationText>
            {deliveryFee
              ? `R$ ${localizePrice(deliveryFee)}`
              : t('orderReview:forFree')}
          </PriceRelationText>
        </div>
        {giftCardValue > 0 && (
          <>
            <div css={priceRelationRow}>
              <PriceRelationText>{t('orderReview:giftCard')}</PriceRelationText>
              <PriceRelationText color="error">
                {`- R$ ${localizePrice(giftCardValue)}`}
              </PriceRelationText>
            </div>
          </>
        )}
        <Divisor />
        <div css={priceRelationRow}>
          <PriceRelationText big>{t('orderHistory:total')}</PriceRelationText>
          <PriceRelationText big>{`R$ ${localizePrice(
            payValue,
          )}`}</PriceRelationText>
        </div>
      </div>
    </div>
  );
};

PaymentLinkPriceRelations.defaultProps = {
  deliveryFee: 0,
};

PaymentLinkPriceRelations.propTypes = {
  totalPrice: PropTypes.number.isRequired,
  finalPrice: PropTypes.number.isRequired,
  deliveryFee: PropTypes.number,
  transactionPays: PropTypes.array.isRequired,
};

export default PaymentLinkPriceRelations;
