import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Loading from '@bit/eyemobile.core.loading';
import Button from '@bit/eyemobile.core.button';

import Header from '../../components/Header';
import ScrollableView from '../../components/ScrollableView';
import CustomerAddressItem from '../../components/CustomerAddressItem';
import { screenWrapper, screenInner } from '../Page.styles';
import { addButtonWrapper } from './UserAddressList.styles';

const UserAddressListScreen = ({
  addressList,
  isLoading,
  onPrev,
  onAddAddress,
  onRemoveAddress,
  onSetMainAddress,
  onAddressEdit,
}) => {
  const { t } = useTranslation();

  const renderAddressItem = (addressItem) => {
    const {
      id,
      label,
      address,
      number,
      address2,
      district,
      state,
      city,
      main,
    } = addressItem;

    const addressText = address ? `${address}, ${number}` : '';
    const cityState = city ? `${city}, ${state}` : '-';

    return (
      <CustomerAddressItem
        key={id}
        label={label}
        main={main}
        address={addressText}
        cityState={cityState}
        district={district}
        complement={address2}
        onRemove={() => onRemoveAddress(addressItem)}
        onTurnMain={() => onSetMainAddress(addressItem)}
        onEdit={() => onAddressEdit(addressItem)}
      />
    );
  };

  return (
    <div css={screenWrapper}>
      <Header onPrev={onPrev} title={t('addressesListPage:addresses')} />
      <ScrollableView
        id="cart"
        hideScroll
        hasHeader
        hasFooter
        divCss={screenInner}
      >
        {isLoading ? (
          <Loading
            show
            center
            style={{ height: 'auto', flexGrow: 0, marginTop: 24 }}
          />
        ) : (
          addressList.map(renderAddressItem)
        )}
        {!isLoading && addressList.length === 0 && (
          <span>{t('addressesListPage:noRegisteredAddresses')}</span>
        )}
      </ScrollableView>
      <div css={addButtonWrapper}>
        <Button block onClick={onAddAddress} disabled={isLoading}>
          {t('addressesListPage:add')}
        </Button>
      </div>
    </div>
  );
};

UserAddressListScreen.propTypes = {
  onPrev: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  addressList: PropTypes.array.isRequired,
  onAddAddress: PropTypes.func.isRequired,
  onRemoveAddress: PropTypes.func.isRequired,
  onSetMainAddress: PropTypes.func.isRequired,
  onAddressEdit: PropTypes.func.isRequired,
};

export default UserAddressListScreen;
