const mapHighlight = (pointHighlight) => {
  const { id, highlight } = pointHighlight;

  const {
    id: highlightId,
    action,
    image,
    name,
    url,
    status,
    info_text: infoText,
  } = highlight;

  return {
    id,
    highlightId,
    action,
    image,
    name,
    url,
    status,
    infoText,
  };
};

const mapStore = (eventPoint) => {
  const { id, point, open: isOpen } = eventPoint;

  const {
    name,
    label,
    address,
    city,
    state,
    extra,
    independent_settlement: independentSettlement,
    phone,
    tenant,
    schedule_enabled: scheduleEnabled,
    schedule_max_days: scheduleMaxDays,
    schedule_next_slot_offset: scheduleNextSlotOffset,
    schedule_slot_max_orders: scheduleSlotMaxOrders,
    schedule_slot_period: scheduleSlotPeriod,
    district,
    total_to_free_delivery: totalToFreeDelivery,
    status,
    can_tag: tagEnabled,
    can_takeout: takeoutEnabled,
    can_deliver: deliveryEnabled,
    point_highlights: pointHighlights,
    ignore_rigid_stock: ignoreRigidStock,
  } = point;

  const addressNumber = point.address_number;
  const eyepass = point.point_eyepass;

  const distance = (extra && (extra.routeDistance || extra.distance)) || 0;
  const duration = (extra && extra.duration) || 0;
  const shippingPrice = (extra && extra.shipping_price) || null;
  const storePhone = independentSettlement ? phone : tenant && tenant.phone;
  const isBeyondMaxDistance = !shippingPrice;
  const deliveryPrice = (shippingPrice && shippingPrice?.value) || 0;
  const schedule = scheduleEnabled
    ? {
        scheduleMaxDays,
        scheduleNextSlotOffset,
        scheduleSlotMaxOrders,
        scheduleSlotPeriod,
      }
    : null;

  return {
    id,
    name,
    label,
    address,
    addressNumber,
    city,
    state,
    distance,
    duration,
    deliveryPrice,
    isOpen,
    deliveryEnabled,
    tagEnabled,
    takeoutEnabled,
    phone: storePhone,
    schedule,
    district,
    totalToFreeDelivery,
    isBeyondMaxDistance,
    status,
    ignoreRigidStock,
    isMenuOnly: eyepass ? eyepass.menu_only : false,
    highlights: pointHighlights
      ? pointHighlights
          .map(mapHighlight)
          .filter(({ status: highlightStatus }) => highlightStatus !== 0)
      : [],
  };
};

export default mapStore;
