import { css } from '@emotion/core';

export const qrReaderWrapper = css`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const qrReaderStyle = {
  width: '100%',
  margin: 5,
};

export const buttonWrapper = (theme) => css`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: ${theme && theme.getSpacing(2, 'px')};
`;

export const qrScanWarningText = (theme) => css`
  font-family: ${theme && theme.getFont()};
  font-size: ${theme && theme.getFontSize(1, 'px')};
  color: ${theme && theme.getColor('text')};
  margin-bottom: ${theme && theme.getSpacing(2, 'px')};
`;
