import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Modal from '@bit/eyemobile.core.modal';
import CircleButton from '@bit/eyemobile.core.circle-button';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';

import Analytics from '../../utils/Analytics';
import {
  analyticsEventActions,
  analyticsEventCategory,
  paymentProviders,
} from '../../utils/constants';
import showToast from '../../utils/showToast';
import reloadUser from '../../utils/reloadUser';
import gateway from '../../utils/gateway';
import { ReactComponent as KeyboardArrowDown } from '../../assets/keyboard_arrow_down.svg';
import withModal from '../../hocs/withModal';
import {
  productContent,
  productInnerWrapper,
  productOverlay,
} from '../ProductAdjustModal/ProductAdjustModal.styles';
import {
  menuHeader,
  menuTitle,
  contentScroll,
  container,
} from '../CreditCardList/CreditCardList.styles';
import CreditCardForm from '../CreditCardForm';
import CreditCardList from '../CreditCardList';
import hashCreditCard from '../../utils/hashCreditCard';
import { Creators as UserCreators } from '../../store/modules/user';

const mapStateToProps = ({
  productOrder: {
    productOrder: { store },
  },
  tenantEyepass: { providerId },
}) => ({
  selectedStore: store,
  providerId,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setUser: UserCreators.setUser,
    },
    dispatch,
  );

const CreditCardModal = ({
  visible,
  onClose,
  creditCardList,
  onCreditCardConfirm,
  setUser,
  selectedStore,
  providerId,
}) => {
  const { t } = useTranslation();
  const [creditCardFormOpen, setCreditCardFormOpen] = useState(false);

  const toggleCreditCardForm = () => {
    setCreditCardFormOpen((prev) => !prev);
  };

  const handleCreditCardSelect = (card) => {
    onCreditCardConfirm(card);
  };

  const getPayload = async (cardData, eventPointId) => {
    const {
      creditCardCvv,
      creditCardExpiration,
      creditCardHolderName,
      creditCardNumber,
      isMain,
    } = cardData;

    let payload;

    if (providerId === paymentProviders.GETNET) {
      payload = {
        number: creditCardNumber,
        cvv: creditCardCvv,
        expiration_date: creditCardExpiration,
        holder_name: creditCardHolderName,
        event_point_id: eventPointId,
      };
    } else {
      const creditCardHash = await hashCreditCard(
        eventPointId,
        creditCardNumber,
        creditCardHolderName,
        creditCardExpiration,
        creditCardCvv,
      );
      payload = {
        card_hash: creditCardHash,
        main: isMain || false,
        event_point_id: eventPointId,
      };
    }
    return { ...payload, providerId };
  };

  const handleAddCreditCard = async (cardData, formikBag) => {
    try {
      formikBag.setSubmitting(true);

      const eventPointId = selectedStore.id;

      if (eventPointId) {
        const payload = await getPayload(cardData, eventPointId);
        await gateway.post('/cards', payload);

        Analytics.event({
          category: analyticsEventCategory.CUSTOMER,
          action: analyticsEventActions.ADD_CREDIT_CARD,
          label: 'Cartão de crédito adicionado',
        });
        showToast(t('cardsPage:cardAddSuccess'), 'success');

        formikBag.setSubmitting(false);
        reloadUser(setUser);
        toggleCreditCardForm();
      }
    } catch (err) {
      showToast(err?.response?.data?.message || err.message, 'error');
      formikBag.setSubmitting(false);
    }
  };

  return (
    <Modal
      visible={visible}
      styles={{
        innerWrapper: productInnerWrapper,
        content: productContent,
        overlay: productOverlay,
      }}
      keepMounted
    >
      {creditCardFormOpen ? (
        <div css={container}>
          <div css={menuHeader}>
            <div style={{ width: '30px' }} />
            <span css={menuTitle}>{t('orderReview:addCreditCard')}</span>
            <CircleButton
              icon={<KeyboardArrowDown />}
              size="30px"
              onClick={onClose}
              backgroundColor="primary"
              color="background"
              padding="3px"
            />
          </div>
          <div css={contentScroll}>
            <CreditCardForm
              onFormSubmit={handleAddCreditCard}
              onCancel={toggleCreditCardForm}
            />
          </div>
        </div>
      ) : (
        <CreditCardList
          onClose={onClose}
          onAdd={toggleCreditCardForm}
          creditCardList={creditCardList}
          onCreditCardSelect={handleCreditCardSelect}
        />
      )}
    </Modal>
  );
};

CreditCardModal.defaultProps = {
  creditCardList: [],
  providerId: null,
};

CreditCardModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  creditCardList: PropTypes.array,
  onCreditCardConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  setUser: PropTypes.func.isRequired,
  selectedStore: PropTypes.object.isRequired,
  providerId: PropTypes.number,
};

const enhance = compose(
  withModal((props) => ({
    visible: props.visible,
  })),
  connect(mapStateToProps, mapDispatchToProps),
);

export default enhance(CreditCardModal);
