import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import ScrollableView from '../../components/ScrollableView';
import Header from '../../components/Header';
import RecoverPasswordForm from '../../components/RecoverPasswordForm';
import { formWrapper } from './RecoverPasswordPage.styles';

const RecoverPasswordScreen = ({ onDataSubmit }) => {
  const { t } = useTranslation();

  const handleFormSubmit = (values, formikBag) => {
    onDataSubmit(values, formikBag);
  };

  return (
    <>
      <Header title={t('navigation:passwordRecover')} />
      <ScrollableView hasHeader>
        <div css={formWrapper}>
          <RecoverPasswordForm onFormSubmit={handleFormSubmit} />
        </div>
      </ScrollableView>
    </>
  );
};

RecoverPasswordScreen.propTypes = {
  onDataSubmit: PropTypes.func.isRequired,
};

export default RecoverPasswordScreen;
