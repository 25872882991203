import { css } from '@emotion/core';
import facepaint from 'facepaint';

const mq = facepaint(['@media screen and (min-width: 375px)']);
const fineMq = facepaint([
  '@media screen and (min-width: 375px)',
  '@media screen and (min-width: 480px)',
]);

const wrapperBps = (theme) =>
  mq({
    paddingLeft: [theme.getSpacing(1, 'px'), theme.getSpacing(1.5, 'px')],
    paddingRight: [theme.getSpacing(1, 'px'), theme.getSpacing(1.5, 'px')],
  });

export const noProductText = (theme) => css`
  font-size: ${theme && theme.getFontSize()};
  font-family: ${theme && theme.getFont()};
  color: ${theme && theme.getColor('text')};
  width: 100%;
  text-align: center;
  margin-top: ${theme && theme.getSpacing(3, 'px')};
`;

export const orderDeliveryWrapper = (theme) => css`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${theme && theme.getSpacing(1, 'px')};
  margin-bottom: ${theme && theme.getSpacing(1, 'px')};
  ${wrapperBps(theme)};
`;

export const orderDeliveryItem = (theme) => css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: ${theme && theme.getSpacing(1, 'px')};
  overflow: hidden;
`;

export const subHeaderWrapper = (theme) => css`
  width: 100%;
  padding-top: ${theme && theme.getSpacing(1, 'px')};
  padding-bottom: ${theme && theme.getSpacing(1, 'px')};
  padding-left: ${theme && theme.getSpacing(2, 'px')};
  padding-right: ${theme && theme.getSpacing(2, 'px')};
  background-color: ${theme && theme.getColor('foreground')};
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const promotionalRow = (theme) => css`
  padding: ${theme && theme.getSpacing(1, 'px')};
  background-color: ${theme && theme.getColor('sideMenuButton')};
  color: ${theme && theme.getColor('sideMenuText')};
  text-align: center;
`;

export const optionLabel = (theme) => css`
  ${fineMq({
    fontSize: [
      theme.getFontSize(0.8, 'px'),
      theme.getFontSize(0.9, 'px'),
      theme.getFontSize(1, 'px'),
    ],
  })}
  font-size: ${theme.getFontSize(0.8, 'px')};
  font-family: ${theme && theme.getFont('futura')};
  color: ${theme && theme.getColor('text')};
  margin-bottom: 12px;
`;

export const addressModalStyles = {
  innerWrapper: {
    top: 0,
    transform: 'translateX(-50%)',
    maxWidth: 480,
    height: '100%',
    width: '100%',
  },
  content: {
    borderRadius: 0,
    boxShadow: 'none',
    minHeight: '100%',
    padding: 0,
    paddingBottom: 24,
    paddingTop: 24,
  },
  overlay: {
    padding: 0,
    top: 0,
    height: '100vh',
    position: 'fixed',
  },
};

export const tabTitle = (theme) => css`
  text-align: left;
  font-size: ${theme && theme.getFontSize(1, 'px')};
  font-family: ${theme && theme.getFont()};
  color: ${theme && theme.getColor('text')};
  font-weight: bold;
  text-transform: uppercase;
`;

export const addressModalWrapper = (theme) => css`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: ${theme && theme.getSpacing(4, 'px')};
  padding-right: ${theme && theme.getSpacing(4, 'px')};
`;

export const selectedFiltersList = (theme) => css`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-left: ${theme.getSpacing(1, 'px')};
  padding-right: ${theme.getSpacing(1, 'px')};
`;

export const selectedFilterItem = (theme) => css`
  padding-left: ${theme.getSpacing(1.5, 'px')};
  padding-right: ${theme.getSpacing(1.5, 'px')};
  padding-top: ${theme.getSpacing(0.5, 'px')};
  padding-bottom: ${theme.getSpacing(0.5, 'px')};
  cursor: pointer;
  background-color: ${theme.getColor('default')};
  border-radius: 20px;
  margin: ${theme.getSpacing(0.5, 'px')};
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const selectedFilterItemName = (theme) => css`
  font-family: ${theme.getFont('futura')};
  color: ${theme.getColor('background')};
  font-size: ${theme.getFontSize(1, 'px')};
`;

export const searchBar = (theme) => css`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: ${theme.getSpacing(1, 'px')};
  padding-right: ${theme.getSpacing(1, 'px')};
  margin-top: 60px;
`;

export const openFiltersButton = (theme) => css`
  padding: ${theme.getSpacing(1, 'px')};
  cursor: pointer;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
`;

export const openFiltersButtonText = (theme) => css`
  font-family: ${theme.getFont('futura')};
  color: ${theme.getColor('primary')};
  font-size: ${theme.getFontSize(1, 'px')};
`;

export const extraDescriptions = (theme) => css`
  font-family: ${theme.getFont('futura')};
  color: ${theme.getColor('default')};
  font-size: ${theme.getFontSize(0.85, 'px')};
  margin-left: ${theme.getSpacing(2, 'px')};
  margin-right: ${theme.getSpacing(2, 'px')};
  margin-bottom: ${theme.getSpacing(0.5, 'px')};
`;
