import * as Yup from 'yup';
import { cpf as cpfValidator, cnpj as cnpjValidator } from 'cpf-cnpj-validator';
import cardValidator from 'card-validator';

import dayjs from './dayjs';
import { stripMask } from './adaptField';
import validCreditCardFlags from './validCreditCardFlags';

export const documentSchema = Yup.string()
  .matches(
    /^[0-9]{3}\.[0-9]{3}\.[0-9]{3}-[0-9]{2}$/,
    'formValidation:invalidDocument',
  )
  .required('formValidation:fieldRequired')
  .test(
    'validateCpf',
    'formValidation:invalidDocument',
    function validateCpf(value) {
      if (!value) return false;
      return cpfValidator.isValid(value);
    },
  );

export const cpfOrCnpjSchema = Yup.string()
  .test(
    'validateCpfOrCnpj',
    'formValidation:invalidCpfOrCnpj',
    function validateCpfOrCnpj(value) {
      if (!value) return false;
      const normalizedValue = stripMask(value);
      return normalizedValue.length > 11
        ? cnpjValidator.isValid(value)
        : cpfValidator.isValid(value);
    },
  )
  .required('formValidation:fieldRequired');

export const creditCardNumberSchema = Yup.string()
  .test(
    'validate-card-number',
    'formValidation:invalidCreditCard',
    function validateCardNumber(value) {
      const validation = cardValidator.number(value);

      const hasValidFlag =
        !!validation.card &&
        validCreditCardFlags.some(
          (validFlag) =>
            validFlag === validation.card.niceType ||
            validFlag === validation.card.type,
        );

      return hasValidFlag && validation.isValid;
    },
  )
  .required('formValidation:fieldRequired');

export const creditCardExpirationDateSchema = Yup.string()
  .test(
    'validate-expiration-date',
    'formValidation:invalidExpirationDate',
    function validateExpirationDate(value) {
      return dayjs(value, 'MM/YYYY').format('MM/YYYY') === value;
    },
  )
  .required('formValidation:fieldRequired');

export const creditCardCvvSchema = Yup.string()
  .test(
    'validate-cvv',
    'formValidation:invalidCvv',
    function validateCvv(value) {
      const { creditCardNumber } = this.parent;
      const cardNumberValidation = cardValidator.number(creditCardNumber);

      const validation = cardValidator.cvv(
        value,
        cardNumberValidation &&
          cardNumberValidation.card &&
          cardNumberValidation.card.code.size,
      );

      return validation.isValid;
    },
  )
  .required('formValidation:fieldRequired');
