import { css } from '@emotion/core';

export const pixKeyTextArea = (theme) => css`
  width: 100%;
  outline: none;
  border: 1px solid #9a9a9a;
  background-color: #f5f5f5;
  font-family: ${theme && theme.getFont('futura')};
  border-radius: 8px;
  text-align: left;
  resize: none;
  padding: ${theme && theme.getSpacing(1, 'px')};
  margin-top: ${theme && theme.getSpacing(1, 'px')};
  font-size: ${theme.getFontSize(1, 'px')};
  line-height: ${theme.getFontSize(1.3, 'px')};
  color: ${theme && theme.getColor('text')};
`;

export const copyTextPrompt = (theme) => css`
  font-family: ${theme.getFont()};
  font-size: ${theme.getFontSize(1, 'px')};
  line-height: ${theme.getFontSize(1.3, 'px')};
  color: ${theme.getColor('default')};
`;

export const waitForConfirmation = (theme) => css`
  font-family: ${theme.getFont()};
  font-size: ${theme.getFontSize(1, 'px')};
  line-height: ${theme.getFontSize(1.3, 'px')};
  color: ${theme.getColor('default')};
  text-align: center;
`;

export const itemSpacing = (theme) => css`
  padding-top: ${theme.getSpacing(1.5, 'px')};
  padding-bottom: ${theme.getSpacing(1.5, 'px')};
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const upperInfo = (theme) => css`
  display: flex;
  flex-direction: column;
  background-color: #f5f5f5;
  padding: ${theme.getSpacing(2, 'px')};
  border-radius: 14px;
`;

export const upperInfoLabel = (theme) => css`
  font-family: ${theme.getFont('futura')};
  font-size: ${theme.getFontSize(1, 'px')};
  line-height: ${theme.getFontSize(1.3, 'px')};
  color: ${theme.getColor('text')};
  margin-bottom: ${theme.getSpacing(1, 'px')};
`;

export const upperInfoValue = (theme) => css`
  font-family: ${theme.getFont()};
  font-size: ${theme.getFontSize(1, 'px')};
  color: ${theme.getColor('text')};
  margin-bottom: ${theme.getSpacing(1, 'px')};
`;
