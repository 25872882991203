import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import CircleButton from '@bit/eyemobile.core.circle-button';

import { smallMeasureTypesMap } from '../../utils/constants';
import getProductImageSrc from '../../utils/getProductImageSrc';
import { ReactComponent as KeyboardArrowDown } from '../../assets/keyboard_arrow_down.svg';
import Carousel from '../Carousel';
import CarouselImage from '../ProductAdjustMenu/CarouselImage';
import ProductAdjustFooter from '../ProductAdjustMenu/ProductAdjustFooter';
import localizePrice from '../../utils/localizePrice';
import {
  productAdjustWrapper,
  contentScroll,
  productContent,
  productInfo,
  ProductInfoText,
  descriptionText,
  productPriceText,
  productAdjustInput,
} from '../ProductAdjustMenu/ProductAdjustMenu.styles';
import ComplementGroup from '../ComplementGroup';

import {
  comboItemCss,
  comboItemImageCss,
  comboItemQuantityCss,
  comboItemNameCss,
} from './ComboAdjustMenu.styles';

const mapStateToProps = ({
  productOrder: {
    productOrder: { store },
  },
}) => ({
  menuOnly: Boolean(store) && store.isMenuOnly,
});

const ComboAdjustMenu = ({
  comboName,
  comboImages,
  priceText,
  comboProductItems,
  finalComboPriceText,
  description,
  amount,
  onCancel,
  onAddToCart,
  onRemove,
  disabled,
  complementGroups,
  onComplementIncrement,
  onComplementDecrement,
  onAdd,
  onSubtract,
  observationText,
  onObservationChange,
  menuOnly,
}) => {
  const { t } = useTranslation();

  const hasMultiImages = comboImages.length > 1;
  const shouldRemove = onRemove && amount === 0;

  const renderImages = useMemo(() => {
    return (
      !!comboImages &&
      comboImages.map((imageKey) => (
        <div key={imageKey}>
          <CarouselImage productName={comboName} imageKey={imageKey} />
        </div>
      ))
    );
  }, [comboImages]);

  const handleConfirmation = () => {
    if (shouldRemove) {
      onRemove();
    } else {
      onAddToCart();
    }
  };

  const renderComplementGroup = (complementGroup) => {
    return (
      <ComplementGroup
        key={`complement-group-${complementGroup.id}`}
        name={complementGroup.name}
        min={complementGroup.min}
        max={complementGroup.max}
        description={complementGroup.description}
        complementItems={complementGroup.complementItems}
        onIncrement={onComplementIncrement}
        onDecrement={onComplementDecrement}
      />
    );
  };

  const renderComboItem = (comboItem) => {
    const { id, itemName, itemImage, itemQuantity, itemMeasure, itemPrice } =
      comboItem;

    const measurePriceText =
      itemMeasure !== 'UNIT'
        ? localizePrice(itemPrice / 10)
        : localizePrice(itemPrice);
    const comboItemPriceText =
      itemMeasure !== 'UNIT'
        ? `R$${measurePriceText}/${smallMeasureTypesMap[itemMeasure]}`
        : `R$${measurePriceText}/un`;

    const adaptedComboItemQuantity =
      itemMeasure !== 'UNIT'
        ? `${itemQuantity * 100}${smallMeasureTypesMap[itemMeasure]} `
        : `${itemQuantity}X `;
    return (
      <div key={`combo-item-${id}`} css={comboItemCss}>
        <img
          src={getProductImageSrc(itemImage)}
          alt={`combo-item-${itemName}`}
          css={comboItemImageCss}
        />
        <div style={{ flex: 1 }}>
          <span css={comboItemNameCss}>
            <span css={comboItemQuantityCss}>{adaptedComboItemQuantity}</span>
            {itemName}
          </span>
        </div>
        <span
          css={comboItemQuantityCss}
          style={{ whiteSpace: 'nowrap', marginLeft: '4px' }}
        >
          {comboItemPriceText}
        </span>
      </div>
    );
  };

  const renderContent = useMemo(
    () => (
      <>
        {complementGroups.map(renderComplementGroup)}
        {!menuOnly && (
          <textarea
            css={productAdjustInput}
            value={observationText}
            onChange={onObservationChange}
            placeholder={t('productOrder:observationPlaceholder')}
          />
        )}
      </>
    ),
    [
      menuOnly,
      observationText,
      onObservationChange,
      complementGroups,
      onComplementIncrement,
      onComplementDecrement,
    ],
  );

  const renderComboItems = useMemo(() => {
    return !!comboProductItems && comboProductItems.map(renderComboItem);
  }, [comboProductItems]);

  return (
    <div css={productAdjustWrapper}>
      <div
        style={{
          width: '100%',
          paddingRight: '15px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
        }}
      >
        <CircleButton
          icon={<KeyboardArrowDown />}
          size="30px"
          onClick={onCancel}
          backgroundColor="primary"
          color="background"
          padding="3px"
        />
      </div>
      <div css={contentScroll}>
        <Carousel
          showArrows={false}
          showStatus={false}
          showIndicators={hasMultiImages}
          infiniteLoop
          showThumbs={false}
          swipeable={hasMultiImages}
          stopOnHover
          emulateTouch={hasMultiImages}
        >
          {renderImages}
        </Carousel>
        <div css={productContent}>
          <div css={productInfo}>
            <ProductInfoText isTitle>{comboName}</ProductInfoText>
            {description && <p css={descriptionText}>{description}</p>}
            <span css={productPriceText}>{priceText}</span>
          </div>
          <span css={productPriceText}>{t('productOrder:comboItems')}</span>
          {renderComboItems}
          {renderContent}
        </div>
      </div>
      <ProductAdjustFooter
        shouldRemove={shouldRemove}
        disabled={disabled}
        onConfirm={handleConfirmation}
        onCancel={onCancel}
        priceText={finalComboPriceText}
        amountText={`${amount}`}
        isMenuOnly={menuOnly}
        onSubtract={onSubtract}
        onAdd={onAdd}
      />
    </div>
  );
};

ComboAdjustMenu.defaultProps = {
  comboImages: [],
  onRemove: null,
  complementGroups: [],
  disabled: false,
  description: '',
};

ComboAdjustMenu.propTypes = {
  comboName: PropTypes.string.isRequired,
  comboImages: PropTypes.array,
  priceText: PropTypes.string.isRequired,
  comboProductItems: PropTypes.array.isRequired,
  finalComboPriceText: PropTypes.string.isRequired,
  description: PropTypes.string,
  amount: PropTypes.number.isRequired,
  onAddToCart: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  complementGroups: PropTypes.array,
  onComplementIncrement: PropTypes.func.isRequired,
  onComplementDecrement: PropTypes.func.isRequired,
  onRemove: PropTypes.func,
  onCancel: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  onSubtract: PropTypes.func.isRequired,
  observationText: PropTypes.string.isRequired,
  onObservationChange: PropTypes.func.isRequired,
  menuOnly: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(ComboAdjustMenu);
