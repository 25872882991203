import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Button from '@bit/eyemobile.core.button';
import Lottie from 'react-lottie';
import { useInterval } from '../../utils/useInterval';
import localizePrice from '../../utils/localizePrice';
import ScrollableView from '../../components/ScrollableView';
import SmallIconButton from '../../components/SmallIconButton';
import Header from '../../components/Header';
import showToast from '../../utils/showToast';
import { screenWrapper, screenInner } from '../Page.styles';
import pixLogo from '../../assets/pix-logo.svg';
import * as loadingDots from '../../assets/loading-dots.json';

import {
  itemSpacing,
  pixKeyTextArea,
  copyTextPrompt,
  waitForConfirmation,
  upperInfo,
  upperInfoLabel,
  upperInfoValue,
} from './OrderPix.styles';

const OrderPixScreen = ({
  pixKey,
  pixExpirationDate,
  transactionValue,
  onRefresh,
  onPrev,
  onFollowOrder,
  transactionStatus,
}) => {
  const { t } = useTranslation();
  const [fullQrCode, setFullQrCode] = useState(false);
  const [showAnimation, setShowAnimation] = useState(false);

  const toggleFullQrCode = () => setFullQrCode((prev) => !prev);

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(pixKey);
    setShowAnimation(true);
    showToast(t('orderPayment:copiedToClipboard'), 'success');
  };

  useInterval(() => {
    if (onRefresh) {
      onRefresh();
    }
  }, 5000);

  useEffect(() => {
    if (onFollowOrder && transactionStatus) {
      let timeout;
      if (transactionStatus === 'completed') {
        timeout = setTimeout(onFollowOrder, 5000);
      }

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [transactionStatus]);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingDots.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <div css={screenWrapper}>
      <Header title={t('orderPayment:pixPayment')} onPrev={onPrev} />
      <ScrollableView hasHeader divCss={screenInner}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginBottom: '12px',
          }}
        >
          <div css={upperInfo}>
            <span css={upperInfoLabel}>
              {t('orderPayment:pixExpirationDate')}
            </span>
            <span css={upperInfoValue}>{pixExpirationDate}</span>
          </div>
          <div css={upperInfo}>
            <span css={upperInfoLabel}>
              {t('orderPayment:pixTransactionValue')}
            </span>
            <span css={upperInfoValue}>{`R$ ${localizePrice(
              transactionValue,
            )}`}</span>
          </div>
        </div>
        <span css={copyTextPrompt}>{t('orderPayment:copyPix')}</span>
        <div css={itemSpacing} style={{ paddingTop: 0, paddingBottom: 0 }}>
          <img src={pixLogo} alt="pix" style={{ width: '100px' }} />
        </div>
        <div css={itemSpacing}>
          <Button color="primary" onClick={handleCopyToClipboard}>
            {t('orderPayment:copy')}
          </Button>
        </div>
        <div css={itemSpacing}>
          {fullQrCode ? (
            <input css={pixKeyTextArea} value={pixKey} readOnly />
          ) : (
            <SmallIconButton
              onClick={toggleFullQrCode}
              label={t('orderPayment:toggleFullPixKey')}
            />
          )}
        </div>
        <span css={waitForConfirmation}>
          {t('orderPayment:waitForConfirmation')}
        </span>
        {showAnimation && (
          <Lottie options={defaultOptions} height={150} width={150} />
        )}
      </ScrollableView>
    </div>
  );
};

OrderPixScreen.defaultProps = {
  pixKey: null,
  onPrev: null,
  onRefresh: null,
  onFollowOrder: null,
  transactionStatus: null,
};

OrderPixScreen.propTypes = {
  pixKey: PropTypes.string,
  onPrev: PropTypes.func,
  onRefresh: PropTypes.func,
  pixExpirationDate: PropTypes.string.isRequired,
  transactionValue: PropTypes.number.isRequired,
  onFollowOrder: PropTypes.func,
  transactionStatus: PropTypes.string,
};

export default OrderPixScreen;
