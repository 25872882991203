import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Modal from '@bit/eyemobile.core.modal';
import Button from '@bit/eyemobile.core.button';
import Loading from '@bit/eyemobile.core.loading';

import Analytics from '../../utils/Analytics';
import EcommerceService from '../../services/ecommerce';
import ContextService from '../../services/context';
import gateway from '../../utils/gateway';
import withModal from '../../hocs/withModal';
import {
  analyticsEventActions,
  analyticsEventCategory,
} from '../../utils/constants';
import showCustomToast from '../../utils/showCustomToast';
import mapStore from '../../utils/mapStore';
import mapProductGroups from '../../utils/mapProductGroups';
import QrCodeReader from '../../components/QrCodeReader';
import StoreSelectConfirmation from '../../components/StoreSelectConfirmation';

import {
  qrReaderWrapper,
  buttonWrapper,
  qrScanWarningText,
} from './QrCodeReaderModal.styles';

const errorCatchToastId = 'ERROR_CATCH_TOAST_ID';
const wrongTenantToastId = 'WRONG_TENANT_TOAST_ID';
const productNotFoundToastId = 'PRODUCT_NOT_FOUND_TOAST_ID';
const invalidQrCodeToastId = 'INVALID_QR_CODE_TOAST_ID';

const mapStateToProps = ({ tenantEyepass: { tenantId, domain } }) => ({
  tenantId,
  domain,
});

const QrCodeReaderModal = ({
  visible,
  onCloseModal,
  onNewProductScanned,
  onNewContext,
  storeId,
  isLogged,
  hasProducts,
  tenantId,
  domain,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [storeChangeConfirmationMode, setStoreChangeConfirmationMode] =
    useState(false);
  const [newContextData, setNewContextData] = useState(null);
  const [hasError, setHasError] = useState(false);

  const { t } = useTranslation();

  const handleFetchProduct = async (sku) => {
    try {
      setIsLoading(true);

      const response = await gateway
        .get(EcommerceService.product(storeId, sku))
        .then(({ data }) => data);

      if (
        response.menu_product_groups &&
        response.menu_product_groups.length > 0
      ) {
        const mappedProductMenus =
          response.menu_product_groups.map(mapProductGroups);

        Analytics.event({
          category: analyticsEventCategory.ORDER,
          action: analyticsEventActions.PRODUCT_SCANNED,
        });

        onNewProductScanned(mappedProductMenus[0].products[0]);
      } else {
        showCustomToast(t('errors:productNotFound'), 'error', {
          toastId: productNotFoundToastId,
        });
      }
      setIsLoading(false);
    } catch (error) {
      showCustomToast(error.message, 'error', {
        toastId: errorCatchToastId,
      });
      setIsLoading(false);
    }
  };

  const handleEventPoint = async (eventPointId, contextData) => {
    try {
      setIsLoading(true);
      const response = await gateway
        .get(
          EcommerceService.getEventPoint(
            eventPointId,
            isLogged ? null : tenantId,
          ),
        )
        .then(({ data }) => data);

      const { event_point: eventPoint } = response;

      const store = (eventPoint && mapStore(eventPoint)) || null;

      setIsLoading(false);

      onNewContext(contextData, store);
    } catch (e) {
      showCustomToast(e.message, 'error', {
        toastId: errorCatchToastId,
      });
      setIsLoading(false);
    }
  };

  const handleNewContext = (contextData) => {
    const { eventPointId } = contextData.data;

    if (eventPointId && eventPointId !== storeId) {
      if (hasProducts) {
        setNewContextData(contextData);
        setStoreChangeConfirmationMode(true);
      } else {
        handleEventPoint(eventPointId, contextData);
      }
    } else {
      onNewContext(contextData);
    }
  };

  const handleFetchContext = async (uuid) => {
    try {
      setIsLoading(true);
      const response = await gateway
        .get(ContextService.link(uuid))
        .then(({ data }) => data);

      if (response.context.data.tenantId === String(tenantId)) {
        handleNewContext(response.context);
      } else {
        showCustomToast(t('context:wrongTenantId'), 'error', {
          toastId: wrongTenantToastId,
        });
      }
      setIsLoading(false);
    } catch (e) {
      showCustomToast(e.message, 'error', {
        toastId: errorCatchToastId,
      });
      setIsLoading(false);
    }
  };

  const handleQrScan = (newQrCode) => {
    if (!isLoading && newQrCode) {
      try {
        const contextMatch = /\/([a-z])\//.exec(newQrCode);

        if (!contextMatch) {
          throw new Error('URL is not a context URL');
        }

        const context = contextMatch[1];
        const contextContent = newQrCode.split(`/${context}/`);
        const contextCode = contextContent[1];
        const eyepassSplit = newQrCode.split('eyepass.com/');
        const qrCodeHostname = (eyepassSplit[0].split('//')[1] || '').replace(
          '.',
          '',
        );
        const qrCodeDomain = eyepassSplit[1].split('/')[0];

        if (qrCodeHostname !== domain && qrCodeDomain !== domain) {
          throw new Error('QrCode meant for another application');
        }

        const contextHandlers = {
          o: handleFetchContext,
          m: handleFetchProduct,
        };

        if (!contextHandlers[context]) {
          throw new Error('Context not recognized');
        }

        contextHandlers[context].call(null, contextCode);
      } catch (e) {
        console.error(e);
        showCustomToast(t('context:invalidQrCode'), 'error', {
          toastId: invalidQrCodeToastId,
        });
      }
    }
  };

  const handleQrReady = () => {
    setIsReady(true);
  };

  const handleQrError = (error) => {
    console.error(error);
    setHasError(true);
  };

  const handleQrCancel = () => {
    if (!isLoading) {
      onCloseModal();
    }
  };

  const handleStoreChangeConfirmation = () => {
    setStoreChangeConfirmationMode(false);
    const { eventPointId } = newContextData.data;

    handleEventPoint(eventPointId, newContextData);
  };

  const renderQrCode = () => {
    if (hasError) {
      return (
        <>
          <p style={{ fontWeight: 'bold' }}>{t('errors:cantEnableCamera')}</p>
          <p style={{ fontSize: 14, marginTop: 16, textAlign: 'center' }}>
            {t('info:concedeCameraPermission')}
          </p>
        </>
      );
    }

    return (
      <>
        <span css={qrScanWarningText}>{t('productOrder:bringCodeCloser')}</span>
        <div style={{ position: 'relative', width: '100%' }}>
          {!isReady && (
            <Loading
              show
              center
              style={{ position: 'absolute', top: 0, left: 0 }}
            />
          )}
          <QrCodeReader
            onQrScan={handleQrScan}
            onQrError={handleQrError}
            onQrReady={handleQrReady}
          />
        </div>
      </>
    );
  };

  const renderContent = () => {
    if (storeChangeConfirmationMode) {
      return (
        <StoreSelectConfirmation
          onCancel={onCloseModal}
          onConfirm={handleStoreChangeConfirmation}
        />
      );
    }

    return (
      <div css={qrReaderWrapper}>
        {renderQrCode()}
        <div css={buttonWrapper}>
          <Button
            color="error"
            onClick={handleQrCancel}
            disabled={isLoading}
            isLoading={isLoading}
          >
            {t('formValidation:cancel')}
          </Button>
        </div>
      </div>
    );
  };

  return <Modal visible={visible}>{renderContent()}</Modal>;
};

QrCodeReaderModal.defaultProps = {
  visible: false,
  isLogged: false,
  hasProducts: false,
};

QrCodeReaderModal.propTypes = {
  visible: PropTypes.bool,
  onCloseModal: PropTypes.func.isRequired,
  onNewProductScanned: PropTypes.func.isRequired,
  onNewContext: PropTypes.func.isRequired,
  storeId: PropTypes.string.isRequired,
  isLogged: PropTypes.bool,
  hasProducts: PropTypes.bool,
  tenantId: PropTypes.number.isRequired,
  domain: PropTypes.string.isRequired,
};

const enhance = compose(
  withModal((props) => ({
    visible: props.visible,
  })),
  connect(mapStateToProps),
);

export default enhance(QrCodeReaderModal);
