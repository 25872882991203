import React from 'react';
import PropTypes from 'prop-types';
import { withFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import Button from '@bit/eyemobile.core.button';

import Input from '../FormikInput';
import { mainForm, fieldPadding, ButtonPadding } from '../Form/Form.styles';

const getInitialValues = () => ({
  password: '',
  passwordConfirmation: '',
});

const recoverFormSchema = Yup.object().shape({
  password: Yup.string()
    .test(
      'password-has-six-chars',
      'formValidation:invalidPassword',
      function validatePasswordLength(value) {
        return value && value.length >= 6;
      },
    )
    .test(
      'password-has-one-numeral',
      'formValidation:invalidPassword',
      function validatePasswordHasNumeral(value) {
        return value && /\d/.test(value);
      },
    )
    .required('formValidation:fieldRequired'),
  passwordConfirmation: Yup.string()
    .test(
      'password-match',
      'formValidation:passwordsDontMatch',
      function validatePasswords(value) {
        const { password } = this.parent;

        return password === value;
      },
    )
    .required('formValidation:fieldRequired'),
});

const passwordInputStyle = {
  letterSpacing: 4,
};

const RecoverPasswordForm = ({ handleSubmit, isSubmitting, isValid }) => {
  const { t } = useTranslation();

  return (
    <form onSubmit={handleSubmit} css={mainForm}>
      <div css={fieldPadding}>
        <Input
          name="password"
          placeholder={t('forms:password')}
          animateLabel
          type="password"
          hideIcons={false}
          inputStyle={passwordInputStyle}
        />
      </div>
      <div css={fieldPadding}>
        <Input
          name="passwordConfirmation"
          placeholder={t('forms:passwordConfirmation')}
          animateLabel
          type="password"
          hideIcons={false}
          inputStyle={passwordInputStyle}
        />
      </div>
      <ButtonPadding>
        <Button
          isLoading={isSubmitting}
          disabled={!isValid}
          onClick={handleSubmit}
          color="primary"
        >
          {t('formValidation:confirm')}
        </Button>
      </ButtonPadding>
    </form>
  );
};

RecoverPasswordForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  isValid: PropTypes.bool.isRequired,
};

const formikEnhance = withFormik({
  mapPropsToValues: getInitialValues,
  validationSchema: recoverFormSchema,
  handleSubmit: (values, { props, dirty, ...formikBag }) => {
    const { onFormSubmit } = props;
    onFormSubmit(values, formikBag);
  },
  enableReinitialize: true,
});

const enhancedForm = formikEnhance(RecoverPasswordForm);

enhancedForm.propTypes = {
  onFormSubmit: PropTypes.func.isRequired,
};

export default enhancedForm;
