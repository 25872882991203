import React from 'react';
import PropTypes from 'prop-types';
import Loading from '@bit/eyemobile.core.loading';
import Modal from '@bit/eyemobile.core.modal';
import { useTheme } from 'emotion-theming';

import withModal from '../../hocs/withModal';
import { productContent } from '../ProductAdjustModal/ProductAdjustModal.styles';

const LoadingModal = ({ visible }) => {
  const theme = useTheme();
  const loadingColor = theme && theme.getColor('uiText');

  return (
    <Modal
      visible={visible}
      styles={{
        content: { ...productContent, backgroundColor: '#00000000' },
        inner: { backgroundColor: '#00000000' },
      }}
      keepMounted
    >
      <Loading
        loadingColor={loadingColor}
        show
        center
        style={{
          height: 'auto',
          flexGrow: 0,
        }}
      />
    </Modal>
  );
};

LoadingModal.propTypes = {
  visible: PropTypes.bool.isRequired,
};

export default withModal((props) => ({
  visible: props.visible,
}))(LoadingModal);
