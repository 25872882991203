import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useTranslation } from 'react-i18next';

import LoadingScreen from '../../components/LoadingScreen';
import RegisterErrorScreen from '../../components/Error';
import * as registerFormActions from '../../store/modules/registerForm';
import http from '../../utils/http';
import showToast from '../../utils/showToast';
import SessionStorageManager from '../../utils/SessionStorageManager';
import HomePageScreen from './HomePageScreen';
import CustomerService from '../../services/customer';

const greetingsText = 'faça aqui seu cadastro!';

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setRegisterForm: registerFormActions.setRegisterForm,
      clearRegisterForm: registerFormActions.clearRegisterForm,
    },
    dispatch,
  );

const mapStateToProps = ({ tenantEyepass: { domain } }) => ({
  domain,
});

const HomePageContainer = ({
  history,
  setRegisterForm,
  clearRegisterForm,
  match,
  domain,
}) => {
  const [userName, setUserName] = useState('');
  const [isCheckingToken, setIsCheckingToken] = useState(true);
  const [error, setError] = useState(false);
  const { t } = useTranslation();
  const urlToken = match.params.customerId;

  const handleRegistrationStart = () => {
    history.push(`/${domain}/registro`);
  };

  const handleCustomerForm = (customer) => {
    if (customer) {
      const { address } = customer;

      setUserName(customer.fullName);
      setRegisterForm({
        fullName: customer.fullName,
        email: customer.email,
        phone: customer.phone,
        birthdate: customer.birthdate,
        document: customer.document,
        addressZipcode: address ? address.zipcode : '',
        addressStreet: address ? address.address : '',
        addressNumber: address ? address.number : '',
        addressComplement: address ? address.complement : '',
        addressDistrict: address ? address.district : '',
        addressCity: address ? address.city : '',
        addressState: address ? address.state : '',
      });
    } else {
      clearRegisterForm();
    }
  };

  const handleGetCustomer = async (userToken) => {
    try {
      const response = await http
        .get(CustomerService.validate(userToken))
        .then(({ data }) => data);

      if (response.status === 'fail') {
        showToast(t('registrationError:userAlreadyRegistered'), 'warning');
        history.push('/registro/concluido');
      } else {
        const { customer } = response.data;
        handleCustomerForm(customer);
        setIsCheckingToken(false);
      }
    } catch (e) {
      SessionStorageManager.removeRegisterToken();
      if (e.response && e.response.status === 403) {
        showToast(e.response.statusText, 'error');
        setError(e.response.statusText);
        setIsCheckingToken(false);
      } else {
        setError(e.message);
        setIsCheckingToken(false);
        showToast(e.message, 'error');
      }
    }
  };

  useEffect(() => {
    const localToken = SessionStorageManager.getRegisterToken();

    if (urlToken) {
      SessionStorageManager.setRegisterToken(urlToken);
      handleGetCustomer(urlToken);
    }
    if (!urlToken && localToken) {
      handleGetCustomer(localToken);
    }
    if (!urlToken && (!localToken || localToken === 'null')) {
      setIsCheckingToken(false);
      setError(t('registrationError:registrationNotPossible'));
    }
  }, []);

  if (isCheckingToken) {
    return <LoadingScreen />;
  }

  if (error) {
    return <RegisterErrorScreen errorMessage={error} />;
  }

  return (
    <HomePageScreen
      flavourText={greetingsText}
      userName={userName}
      onRegistration={handleRegistrationStart}
    />
  );
};

HomePageContainer.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  setRegisterForm: PropTypes.func.isRequired,
  clearRegisterForm: PropTypes.func.isRequired,
  domain: PropTypes.string.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(HomePageContainer);
