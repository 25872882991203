import { css } from '@emotion/core';

export const menuWrapper = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const menuTitleWrapper = (theme) => css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-left: ${theme && theme.getSpacing(2, 'px')};
  padding-right: ${theme && theme.getSpacing(2, 'px')};
  padding-bottom: ${theme && theme.getSpacing(1, 'px')};
`;

export const menuTitle = (theme) => css`
  font-size: ${theme && theme.getFontSize(1.2, 'px')};
  font-family: ${theme && theme.getFont('heading')};
  color: ${theme && theme.getColor('text')};
  text-align: center;
  font-weight: bold;
`;

export const scheduleMenuLabel = (theme) => css`
  width: 100%;
  font-size: ${theme && theme.getFontSize(1, 'px')};
  font-family: ${theme && theme.getFont('heading')};
  color: ${theme && theme.getColor('text')};
`;

export const bottomButtonsRow = (theme) => css`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: ${theme && theme.getSpacing(3, 'px')};
`;

export const contentScroll = (theme) => css`
  width: 100%;
  display: block;
  overflow-y: auto;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }
  &::-webkit-scrollbar:vertical {
    width: 8px;
  }
  &::-webkit-scrollbar:horizontal {
    height: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    border: 2px solid #ffffff;
  }
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #ffffff;
  }
`;

export const slotCheckboxWrapper = (theme) => css`
  width: 100%;
  padding: ${theme && `0px ${theme.getSpacing(3, 'px')}`};
  &:nth-of-type(even) {
    background-color: rgba(0, 0, 0, 0.15);
  }
`;

export const radioLabelCss = (theme) => css`
  font-size: ${theme && theme.getFontSize(1, 'px')};
  font-family: ${theme && theme.getFont()};
  color: ${theme && theme.getColor('text')};
`;

export const dropdownWrapper = (theme) => css`
  padding-top: ${theme && theme.getSpacing(1, 'px')};
  padding-bottom: ${theme && theme.getSpacing(1, 'px')};
  padding-left: ${theme && theme.getSpacing(3, 'px')};
  padding-right: ${theme && theme.getSpacing(3, 'px')};
  width: 100%;
`;
