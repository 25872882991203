import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ErrorMessage } from 'formik';
import Button from '@bit/eyemobile.core.button';
import { connect } from 'react-redux';

import FormikRadioBox from '../../components/FormikRadioBox';
import timeTextFromMinutes from '../../utils/timeTextFromMinutes';
import addressValidation from '../../utils/addressValidation';
import { shippingTypes } from '../../utils/constants';
import localizePrice from '../../utils/localizePrice';

import {
  tabWrapper,
  tabTitle,
  tabTitleWrapper,
  shippingTypeErrorWrapper,
  shippingTypeErrorText,
  radioBoxTitle,
  radioBoxDescription,
  radioBoxPrice,
  radioBoxContent,
  radioBoxButton,
} from './OrderReviewPage.styles';

const mapStateToProps = ({
  productOrder: {
    productOrder: { expected },
  },
  tenantEyepass: { qrCodeOnly, deliveryDisabled },
  user,
}) => ({
  qrCodeOnly,
  deliveryDisabled,
  user,
  expectedDistance: expected.distance,
  expectedDuration: expected.duration,
});

const OrderReviewShipping = ({
  isTagOnlyDelivery,
  currentAddress,
  deliveryPrice,
  currentTag,
  isTagLocked,
  onTagChange,
  onAddressChange,
  selectedStore,
  isFreeDelivery,
  qrCodeOnly,
  deliveryDisabled,
  user,
  expectedDuration,
  allowAnonymous,
}) => {
  const { t } = useTranslation();
  const { deliveryEnabled, tagEnabled, takeoutEnabled, name } = selectedStore;

  const [invalidAddress, setInvalidAddress] = useState(false);

  const validateAddress = async () => {
    try {
      if (!allowAnonymous) {
        await addressValidation(selectedStore.id, currentAddress, t);
      }
      setInvalidAddress(false);
    } catch (err) {
      console.error(err);
      setInvalidAddress(true);
    }
  };

  useEffect(() => {
    validateAddress();
  }, [currentAddress]);

  const handleAddressChange = () => {
    onAddressChange();
  };

  const renderContent = () => {
    if (isTagOnlyDelivery || qrCodeOnly) {
      const adaptedTag =
        currentTag && currentTag.startsWith('null')
          ? `${t('productOrder:receipt')} ${currentTag.split('null')[1]}`
          : currentTag;

      return (
        <FormikRadioBox
          name="shippingType"
          value={shippingTypes.TAG}
          components={{
            Label: (
              <>
                <div css={radioBoxContent}>
                  <span css={radioBoxTitle}>{t('orderReview:deliverTo')}</span>
                  <span css={radioBoxDescription}>{adaptedTag}</span>
                  {!isTagLocked && (
                    <Button
                      onClick={onTagChange}
                      color="primary"
                      styles={radioBoxButton}
                    >
                      {t('orderReview:alterTag')}
                    </Button>
                  )}
                </div>
                <div css={radioBoxPrice}>+ R$ {localizePrice(0)}</div>
              </>
            ),
          }}
          disabled={!currentTag || !tagEnabled}
        />
      );
    }

    if (currentAddress) {
      const hasAddress = !!currentAddress.street;
      const storeHasAddress = !!selectedStore.address;

      const addressText = hasAddress
        ? `${currentAddress.street}, ${currentAddress.number}`
        : t('orderReview:addressNotRegistered');

      const addressCityText = hasAddress
        ? `${currentAddress.district}, ${currentAddress.city}, ${currentAddress.state}`
        : '';

      const storeAddressText = storeHasAddress
        ? `${selectedStore.address}, ${selectedStore.addressNumber}`
        : t('orderReview:addressNotRegistered');

      const storeAddressCityText = storeHasAddress
        ? `${selectedStore.district}, ${selectedStore.city}, ${selectedStore.state}`
        : '';

      const addressDeliveryPrice =
        deliveryPrice > 0
          ? `+ R$ ${localizePrice(deliveryPrice)}`
          : t('productOrder:freeDelivery');

      const durationText =
        expectedDuration > 0 ? timeTextFromMinutes(expectedDuration) : '';

      if (takeoutEnabled || deliveryEnabled) {
        return (
          <>
            {takeoutEnabled && (
              <FormikRadioBox
                name="shippingType"
                value={shippingTypes.TAKEOUT}
                components={{
                  Label: (
                    <>
                      <div css={radioBoxContent}>
                        <span css={radioBoxTitle}>{`${t(
                          'orderReview:pickupAt',
                        )} ${name}`}</span>
                        {hasAddress ? (
                          <>
                            <span css={radioBoxDescription}>
                              {storeAddressText}
                              <br />
                              {storeAddressCityText}
                            </span>
                          </>
                        ) : (
                          <span css={radioBoxDescription}>
                            {t('orderReview:noRegisteredAddress')}
                          </span>
                        )}
                      </div>
                      <div css={radioBoxPrice}>
                        {t('productOrder:freeDelivery')}
                      </div>
                    </>
                  ),
                }}
                style={{ marginBottom: 24 }}
              />
            )}
            {!deliveryDisabled && !qrCodeOnly && deliveryEnabled && (
              <>
                <FormikRadioBox
                  name="shippingType"
                  value={shippingTypes.ADDRESS}
                  components={{
                    Label: (
                      <>
                        <div css={radioBoxContent}>
                          <span css={radioBoxTitle}>
                            {t('orderReview:houseDelivery')}
                            {invalidAddress && '*'}
                          </span>
                          <span
                            css={radioBoxDescription}
                            style={{ fontWeight: 'bold' }}
                          >
                            {currentAddress.label}
                          </span>
                          {hasAddress ? (
                            <>
                              <span css={radioBoxDescription}>
                                {addressText}
                                <br />
                                {addressCityText}
                              </span>
                            </>
                          ) : (
                            <span css={radioBoxDescription}>
                              {t('orderReview:noRegisteredAddress')}
                            </span>
                          )}
                          {invalidAddress && (
                            <span
                              css={radioBoxTitle}
                              style={{ marginTop: '5px' }}
                            >
                              {t('errors:invalidAddress')}
                            </span>
                          )}
                          <Button
                            onClick={handleAddressChange}
                            color="primary"
                            styles={radioBoxButton}
                          >
                            {hasAddress
                              ? t('orderReview:alterAddress')
                              : t('orderReview:registerNewAddress')}
                          </Button>
                        </div>
                        <div css={radioBoxPrice}>
                          <span
                            style={{
                              textDecoration: isFreeDelivery && 'line-through',
                            }}
                          >
                            {addressDeliveryPrice}
                          </span>
                          {isFreeDelivery && (
                            <span style={{ color: 'green' }}>
                              {t('productOrder:freeDelivery')}
                            </span>
                          )}
                          <span>{durationText}</span>
                        </div>
                      </>
                    ),
                  }}
                  disabled={!hasAddress || invalidAddress}
                />
              </>
            )}
          </>
        );
      }
    }

    return (
      <div css={radioBoxContent}>
        <span css={radioBoxTitle}>{t('orderReview:noShippingType')}</span>
      </div>
    );
  };

  return (
    <div css={tabWrapper}>
      <div css={tabTitleWrapper}>
        <span css={tabTitle}>{t('orderReview:delivery')}</span>
      </div>
      <ErrorMessage name="shippingType">
        {(msg) => (
          <div css={shippingTypeErrorWrapper}>
            <span css={shippingTypeErrorText}>{t(msg)}</span>
          </div>
        )}
      </ErrorMessage>
      {renderContent()}
    </div>
  );
};

OrderReviewShipping.defaultProps = {
  isTagOnlyDelivery: false,
  currentAddress: undefined,
  deliveryPrice: 0,
  currentTag: '',
  isTagLocked: false,
  onTagChange: undefined,
  onAddressChange: undefined,
  selectedStore: undefined,
  isFreeDelivery: false,
};

OrderReviewShipping.propTypes = {
  isTagOnlyDelivery: PropTypes.bool,
  currentAddress: PropTypes.shape({
    label: PropTypes.string,
    street: PropTypes.string,
    number: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    district: PropTypes.string,
  }),
  deliveryPrice: PropTypes.number,
  currentTag: PropTypes.string,
  isTagLocked: PropTypes.bool,
  onTagChange: PropTypes.func,
  onAddressChange: PropTypes.func,
  selectedStore: PropTypes.object,
  isFreeDelivery: PropTypes.bool,
  qrCodeOnly: PropTypes.bool.isRequired,
  deliveryDisabled: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  expectedDuration: PropTypes.number.isRequired,
  allowAnonymous: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(OrderReviewShipping);
