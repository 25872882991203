import { createReducer, createAction } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { nanoid } from 'nanoid';
import { shippingTypes } from '../../utils/constants';

export const Types = {
  SET_ORDER: 'productOrder/SET_ORDER',
  ADD_PRODUCT_TO_ORDER: 'productOrder/ADD_PRODUCT_TO_ORDER',
  ALTER_PRODUCT_IN_ORDER: 'productOrder/ALTER_PRODUCT_IN_ORDER',
  REMOVE_PRODUCT_IN_ORDER: 'productOrder/REMOVE_PRODUCT_IN_ORDER',
  ADD_PROM_PRODUCT_IN_ORDER: 'productOrder/ADD_PROM_PRODUCT_IN_ORDER',
  ALTER_PROM_PRODUCT_IN_ORDER: 'productOrder/ALTER_PROM_PRODUCT_IN_ORDER',
  REMOVE_PROM_PRODUCT_IN_ORDER: 'productOrder/REMOVE_PROM_PRODUCT_IN_ORDER',
  CLEAR_ORDER: 'productOrder/CLEAR_ORDER',
  SET_STORE: 'productOrder/SET_STORE',
  SET_STORE_OPTIONS: 'productOrder/SET_STORE_OPTIONS',
  CLEAR_STORE: 'productOrder/CLEAR_STORE',
  SET_SHIPPING_TYPE: 'productOrder/SET_SHIPPING_TYPE',
  CLEAR_SHIPPING_TYPE: 'productOrder/CLEAR_SHIPPING_TYPE',
  SET_DELIVERY_ADDRESS: 'productOrder/SET_DELIVERY_ADDRESS',
  CLEAR_DELIVERY_ADDRESS: 'productOrder/CLEAR_DELIVERY_ADDRESS',
  SET_EXPECTED_DISTANCE_DURATION: 'productOrder/SET_EXPECTED_DISTANCE_DURATION',
  CLEAR_EXPECTED_DISTANCE_DURATION:
    'productOrder/CLEAR_EXPECTED_DISTANCE_DURATION',
  CLEAR_PRODUCT_ORDER: 'productOrder/CLEAR_PRODUCT_ORDER',
};

export const setOrder = createAction(
  Types.SET_ORDER,
  function prepare({ products }) {
    return {
      payload: {
        products: products
          ? products.map((product) => ({
              ...product,
              productOrderId: nanoid(),
            }))
          : [],
      },
    };
  },
);
export const addProductToOrder = createAction(
  Types.ADD_PRODUCT_TO_ORDER,
  function prepare({ product }) {
    return {
      payload: {
        product: {
          ...product,
          source: 'menu',
          productOrderId: nanoid(),
        },
      },
    };
  },
);
export const alterProductInOrder = createAction(Types.ALTER_PRODUCT_IN_ORDER);
export const removeProductInOrder = createAction(Types.REMOVE_PRODUCT_IN_ORDER);
export const addPromotionalProductToOrder = createAction(
  Types.ADD_PROM_PRODUCT_IN_ORDER,
  function prepare({ promotionalProduct }) {
    return {
      payload: {
        promotionalProduct: {
          ...promotionalProduct,
          productOrderId: nanoid(),
        },
      },
    };
  },
);
export const alterPromotionalProductToOrder = createAction(
  Types.ALTER_PROM_PRODUCT_IN_ORDER,
);
export const removePromotionalProductInOrder = createAction(
  Types.REMOVE_PROM_PRODUCT_IN_ORDER,
);
export const clearOrder = createAction(Types.CLEAR_ORDER);
export const setStore = createAction(Types.SET_STORE);
export const clearStore = createAction(Types.CLEAR_STORE);
export const setShippingType = createAction(Types.SET_SHIPPING_TYPE);
export const clearShippingType = createAction(Types.CLEAR_SHIPPING_TYPE);
export const setDeliveryAddress = createAction(Types.SET_DELIVERY_ADDRESS);
export const clearDeliveryAddress = createAction(Types.CLEAR_DELIVERY_ADDRESS);
export const setExpectedDistanceDuration = createAction(
  Types.SET_EXPECTED_DISTANCE_DURATION,
);
export const clearExpectedDistanceDuration = createAction(
  Types.CLEAR_EXPECTED_DISTANCE_DURATION,
);
export const clearProductOrder = createAction(Types.CLEAR_PRODUCT_ORDER);

const initialState = {
  products: [],
  promotionalProducts: [],
  store: null,
  isEventPointLocked: false,
  shippingType: shippingTypes.ADDRESS,
  deliveryAddress: null,
  expected: {
    duration: 0,
    distance: 0,
  },
};

const mapNewProductToOrder = (products, payloadProduct) =>
  products.map((product) => {
    if (product.productOrderId === payloadProduct.productOrderId) {
      return payloadProduct;
    }

    return product;
  });

const filterProductsInOrder = (products, productOrderId) =>
  products.filter((product) => product.productOrderId !== productOrderId);

const productOrder = createReducer(initialState, {
  [setOrder]: (state, action) => ({
    ...state,
    products: action.payload.products,
  }),
  [addProductToOrder]: (state, action) => ({
    ...state,
    products: action.payload.product
      ? state.products.concat(action.payload.product)
      : state.products,
  }),
  [alterProductInOrder]: (state, action) => ({
    ...state,
    products: action.payload.product
      ? mapNewProductToOrder(state.products, action.payload.product)
      : state.products,
  }),
  [removeProductInOrder]: (state, action) => ({
    ...state,
    products: action.payload.productOrderId
      ? filterProductsInOrder(state.products, action.payload.productOrderId)
      : state.products,
  }),
  [addPromotionalProductToOrder]: (state, action) => ({
    ...state,
    promotionalProducts: action.payload.promotionalProduct
      ? state.promotionalProducts.concat(action.payload.promotionalProduct)
      : state.promotionalProducts,
  }),
  [alterPromotionalProductToOrder]: (state, action) => ({
    ...state,
    promotionalProducts: action.payload.promotionalProduct
      ? mapNewProductToOrder(
          state.promotionalProducts,
          action.payload.promotionalProduct,
        )
      : state.promotionalProducts,
  }),
  [removePromotionalProductInOrder]: (state, action) => ({
    ...state,
    promotionalProducts: action.payload.productOrderId
      ? filterProductsInOrder(
          state.promotionalProducts,
          action.payload.productOrderId,
        )
      : state.products,
  }),
  [clearOrder]: (state, action) => ({
    ...state,
    products: [],
    promotionalProducts: [],
  }),
  [setStore]: (state, action) => ({
    ...state,
    store: action.payload.store,
    isEventPointLocked: action.payload.isEventPointLocked,
  }),
  [clearStore]: (state, action) => ({
    ...state,
    store: null,
  }),
  [setShippingType]: (state, action) => ({
    ...state,
    shippingType: action.payload.shippingType,
  }),
  [clearShippingType]: (state, action) => ({
    ...state,
    shippingType: shippingTypes.ADDRESS,
  }),
  [setDeliveryAddress]: (state, action) => ({
    ...state,
    deliveryAddress: action.payload.deliveryAddress,
  }),
  [clearDeliveryAddress]: (state, action) => ({
    ...state,
    deliveryAddress: null,
  }),
  [setExpectedDistanceDuration]: (state, action) => ({
    ...state,
    expected: {
      duration: action.payload.expected.duration,
      distance: action.payload.expected.distance,
    },
  }),
  [clearExpectedDistanceDuration]: (state, action) => ({
    ...state,
    expected: {
      duration: 0,
      distance: 0,
    },
  }),
  [clearProductOrder]: (state, action) => initialState,
});

export const reducers = combineReducers({
  productOrder,
});
