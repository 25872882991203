import styled from '@emotion/styled';
import facepaint from 'facepaint';

const mq = facepaint(['@media screen and (min-width: 375px)']);

const getTitleFontSize = ({ theme }) =>
  mq({
    fontSize: [theme.getFontSize(1, 'px'), theme.getFontSize(1.15, 'px')],
  });

const getFontSize = ({ theme }) =>
  mq({
    fontSize: [theme.getFontSize(0.85, 'px'), theme.getFontSize(1, 'px')],
  });

export const ItemContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-left: ${({ theme }) => theme && theme.getSpacing(2, 'px')};
  padding-right: ${({ theme }) => theme && theme.getSpacing(2, 'px')};
  padding-top: ${({ theme }) => theme && theme.getSpacing(2, 'px')};
  padding-bottom: ${({ theme }) => theme && theme.getSpacing(2, 'px')};
  margin-bottom: ${({ theme }) => theme && theme.getSpacing(3, 'px')};
  border-radius: 17px;
  background-color: #f5f5f5;
`;

export const ProductImage = styled.img`
  height: 70px;
  width: 70px;
  display: block;
  border-radius: 35px;
  object-fit: contain;
  margin-bottom: ${({ theme }) => theme && theme.getSpacing(1, 'px')};
`;

export const ProductCostBadge = styled.span`
  min-width: 16px;
  padding-left: 8px;
  padding-right: 8px;
  min-height: 24px;
  font-size: 12px;
  line-height: 1;
  display: block;
  border-radius: 50%;
  position: absolute;
  top: 0px;
  right: -4px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.getColor('uiText')};
  background-color: ${({ theme }) => theme.getColor('foreground')};
`;

export const ItemDescriptionWrapper = styled.div`
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  padding-left: ${({ theme }) => theme && theme.getSpacing(1, 'px')};
  padding-right: ${({ theme }) => theme && theme.getSpacing(1, 'px')};
  margin-bottom: ${({ theme }) => theme && theme.getSpacing(1, 'px')};
`;

export const ProductItemTitle = styled.span`
  font-family: ${({ theme }) => theme && theme.getFont('futura')};
  ${getTitleFontSize};
  color: ${({ theme }) => theme && theme.getColor('text')};
  text-align: left;
  margin-bottom: ${({ theme }) => theme && theme.getSpacing(0.5, 'px')};
`;

export const ProductItemText = styled.span`
  ${getFontSize};
  color: ${({ theme }) => theme && theme.getColor('text')};
  text-align: left;
  margin-bottom: ${({ theme }) => theme && theme.getSpacing(0.5, 'px')};
`;

export const ProductItemLink = styled.a`
  ${getFontSize};
  color: ${({ theme }) => theme && theme.getColor('text')};
  text-align: left;
  margin-bottom: ${({ theme }) => theme && theme.getSpacing(0.5, 'px')};
`;

export const ProgressText = styled.span`
  margin-top: ${({ theme }) => theme && theme.getSpacing(1, 'px')};
  font-family: ${({ theme }) => theme && theme.getFont('futura')};
  font-size: ${({ theme }) => theme.getFontSize(0.85, 'px')};
  color: ${({ theme }) => theme && theme.getColor('success')};
  text-align: left;
  margin-bottom: 4px;
`;

export const LoyaltyDescription = styled.p`
  font-family: ${({ theme }) => theme && theme.getFont()};
  font-size: ${({ theme }) => theme.getFontSize(0.85, 'px')};
  font-weight: bold;
  margin-bottom: ${({ theme }) => theme && theme.getSpacing(1, 'px')};
`;

export const Point = styled.span`
  display: block;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  border: 2px solid ${({ theme }) => theme.getColor('success')};
  margin-bottom: 4px;
`;

export const Progress = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: ${({ theme }) => theme.getSpacing(1, 'px')};

  span:not(:last-of-type) {
    margin-right: 12px;
  }
`;
