import { css } from '@emotion/core';

export const scrollMessagesWrapper = css`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const loaderWrapper = (theme) => css`
  padding: ${theme && theme.getSpacing(2, 'px')};
`;

export const endListText = (theme) => css`
  font-family: ${theme && theme.getFont()};
  font-size: ${theme && theme.getFontSize(1, 'px')};
  color: ${theme && theme.getColor('text')};
  font-weight: bold;
`;
