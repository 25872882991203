import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import rEmail from './emailRegex';

const initialGiftCard = {
  name: '',
  email: '',
  message: '',
};

const useComplementGroups = (initialState = initialGiftCard) => {
  const { t } = useTranslation();

  const [giftCard, setGiftCard] = useState(initialState);
  const [errors, setErrors] = useState({
    email: undefined,
  });
  const [touched, setTouched] = useState({
    name: false,
    email: false,
    message: false,
  });

  const setField = (key, value) => {
    setTouched((prevState) => ({ ...prevState, [key]: true }));
    setGiftCard((prevState) => ({ ...prevState, [key]: value }));
  };

  const setName = (e) => setField('name', e.target.value);

  const setEmail = (e) => setField('email', e.target.value);

  const setMessage = (e) => setField('message', e.target.value);

  const validate = () => {
    if (!rEmail.test(giftCard.email)) {
      setErrors({ email: t('formValidation:invalidEmail') });
    } else {
      setErrors({ email: undefined });
    }
  };

  useEffect(() => {
    if (touched.email) {
      validate();
    }
  }, [giftCard, touched]);

  return {
    giftCard,
    errors,
    touched,
    helpers: { setName, setEmail, setMessage },
  };
};

export default useComplementGroups;
