import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import ecommerceService from '../../services/ecommerce';
import LoadingScreen from '../../components/LoadingScreen';
import mapOrderHistory from '../../utils/mapOrderHistory';
import gateway from '../../utils/gateway';
import { useInterval } from '../../utils/useInterval';
import showToast from '../../utils/showToast';

import OrderHistoryDetailScreen from './OrderHistoryDetailScreen';

const refresherStatus = [
  'WAITING_PAYMENT',
  'REGISTERED',
  'PREPARING',
  'READY',
  'COLLECTED',
];

const OrderHistoryDetailContainer = ({
  order,
  onReturn,
  onRedoOrder,
  onCancelOrder,
  outerLoading,
}) => {
  const { t } = useTranslation();

  const [currentOrder, setCurrentOrder] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const onRefresh = async () => {
    try {
      const response = await gateway
        .get(ecommerceService.historyItem(order.id))
        .then(({ data }) => data);

      if (response.transaction) {
        setCurrentOrder(mapOrderHistory(response.transaction));
        setIsLoading(false);
      } else {
        throw new Error(t('errors:orderNotFound'));
      }
    } catch (e) {
      showToast(e.message, 'error');
      onReturn();
    }
  };

  const handleCancelOrder = () => onCancelOrder(currentOrder);

  useEffect(() => {
    onRefresh();
  }, []);

  useInterval(() => {
    const canRefresh = refresherStatus.some(
      (status) => currentOrder && currentOrder.status === status,
    );

    if (canRefresh && !isLoading && !outerLoading) {
      onRefresh();
    }
  }, 5000);

  const handleReturn = () => onReturn();

  const handleOrderRedo = () => {
    const transactions = currentOrder.transactionProducts.concat(
      currentOrder.transactionCombos,
    );
    onRedoOrder(currentOrder.store, transactions);
  };

  if (isLoading || outerLoading) {
    return <LoadingScreen />;
  }

  return (
    <OrderHistoryDetailScreen
      order={currentOrder}
      onReturn={handleReturn}
      onRedoOrder={handleOrderRedo}
      onCancelOrder={handleCancelOrder}
    />
  );
};

OrderHistoryDetailContainer.propTypes = {
  order: PropTypes.object.isRequired,
  outerLoading: PropTypes.bool.isRequired,
  onReturn: PropTypes.func.isRequired,
  onRedoOrder: PropTypes.func.isRequired,
  onCancelOrder: PropTypes.func.isRequired,
};

export default OrderHistoryDetailContainer;
