import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import ContextManager from '../utils/ContextManager';
import { Creators as tagActionCreators } from '../store/modules/tag';
import TenantEyepassLoader from '../components/TenantEyepassLoader';

const mapStateToProps = ({ tag }) => ({
  tag,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setTag: tagActionCreators.setTag,
      removeTag: tagActionCreators.removeTag,
    },
    dispatch,
  );

const withContext = (WrappedComponent) => {
  const TagHOC = ({ tag, setTag, ...rest }) => {
    const [isLoading, setIsLoading] = useState(true);

    const preloadTag = () => {
      if (!tag.tag) {
        const tagInfo = ContextManager.getTag();

        if (tagInfo) {
          setTag(tagInfo);
        }
      }

      setIsLoading(false);
    };

    useEffect(() => {
      preloadTag();
    }, []);

    if (isLoading) {
      return <TenantEyepassLoader />;
    }

    return <WrappedComponent tag={tag} setTag={setTag} {...rest} />;
  };

  TagHOC.propTypes = {
    tag: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
    setTag: PropTypes.func.isRequired,
  };

  return connect(mapStateToProps, mapDispatchToProps)(TagHOC);
};

export default withContext;
