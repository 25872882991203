import { css } from '@emotion/core';
import styled from '@emotion/styled';

export const filterList = (theme) => css`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: ${theme.getSpacing(2, 'px')};
`;

export const filtersTitle = (theme) => css`
  font-family: ${theme && theme.getFont('futura')};
  font-size: ${theme && theme.getFontSize(1, 'px')};
  color: ${theme && theme.getColor('text')};
  text-transform: uppercase;
  margin-bottom: ${theme.getFontSize(1, 'px')};
`;

export const contentScroll = (theme) => css`
  width: 100%;
  flex: 1;
  display: block;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;

  padding-left: ${theme && theme.getSpacing(1, 'px')};
  padding-right: ${theme && theme.getSpacing(1, 'px')};

  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }
  &::-webkit-scrollbar:vertical {
    width: 8px;
  }
  &::-webkit-scrollbar:horizontal {
    height: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    border: 2px solid #ffffff;
  }
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #ffffff;
  }
`;

export const FilterItem = styled.div`
  border-radius: 17px;
  background-color: ${({ theme, active }) =>
    active ? theme.getColor('primary') : '#f5f5f5'};
  width: 100%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: ${({ theme }) => theme && theme.getSpacing(2, 'px')};
  padding-right: ${({ theme }) => theme && theme.getSpacing(2, 'px')};
  padding-top: ${({ theme }) => theme && theme.getSpacing(2, 'px')};
  padding-bottom: ${({ theme }) => theme && theme.getSpacing(2, 'px')};
  user-select: none;
  -webkit-tap-highlight-color: transparent;

  &:not(:last-of-type) {
    margin-bottom: ${({ theme }) => theme && theme.getSpacing(1, 'px')};
  }
`;

export const FilterItemText = styled.span`
  color: ${({ theme, active }) =>
    active ? theme.getColor('background') : theme.getColor('text')};
  font-family: ${({ theme }) => theme.getFont('futura')};
  font-size: ${({ theme }) => theme.getFontSize(1, 'px')};
`;

export const filtersHeader = (theme) => css`
  width: 100%;
  height: 40px;
  padding-left: ${theme.getSpacing(2, 'px')};
  padding-right: ${theme.getSpacing(2, 'px')};
  margin-bottom: ${theme.getSpacing(3, 'px')};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const footer = (theme) => css`
  width: 100%;
  padding-top: ${theme.getSpacing(1, 'px')};
  padding-left: ${theme.getSpacing(2, 'px')};
  padding-right: ${theme.getSpacing(2, 'px')};
  padding-bottom: ${theme.getSpacing(1, 'px')};
`;
