import { css } from '@emotion/core';
import styled from '@emotion/styled';

export const CardContainer = styled.div`
  width: 100%;
  background-color: #f5f5f5;
  border-radius: 17px;
  border: 1px solid
    ${({ theme, selected }) =>
      selected ? theme.getColor('primary') : 'transparent'};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => theme.getSpacing(1, 'px')};

  &:not(:first-of-type) {
    margin-top: ${({ theme }) => theme.getSpacing(2, 'px')};
  }
`;

export const cardDigits = (theme) => css`
  margin-left: ${theme.getSpacing(1, 'px')};
  color: ${theme.getColor('text')};
  font-size: ${theme.getFontSize(1, 'px')};
  font-family: ${theme.getFont('futura')};
`;

export const cardMainTag = (theme) => css`
  padding: ${theme.getSpacing(1, 'px')} ${theme.getSpacing(1.5, 'px')};
  color: ${theme.getColor('text')};
  background-color: #c9c9c9;
  border-radius: 20px;
  font-size: ${theme.getFontSize(1, 'px')};
  font-family: ${theme.getFont('futura')};
`;

export const cardSection = css`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
