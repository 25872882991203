const extractProductMenuGroups = (menuProductGroups) => {
  return menuProductGroups.reduce((acc, menuProductGroup) => {
    const { id: menuProductGroupId, color } = menuProductGroup;
    const productGroup = menuProductGroup.product_group;

    const { id: productGroupId, name } = productGroup;

    return acc.concat({
      menuProductGroupId,
      productGroupId,
      color,
      name,
    });
  }, []);
};

export default extractProductMenuGroups;
