import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import {
  analyticsEventActions,
  analyticsEventCategory,
} from '../../utils/constants';
import Analytics from '../../utils/Analytics';
import http from '../../utils/http';
import { stripMask } from '../../utils/adaptField';
import ForgotPasswordPageScreen from './ForgotPasswordPageScreen';
import showToast from '../../utils/showToast';

const forgotPasswordFormSchema = Yup.object().shape({
  document: Yup.string()
    .matches(
      /^[0-9]{3}\.[0-9]{3}\.[0-9]{3}-[0-9]{2}$/,
      'formValidation:invalidDocument',
    )
    .required('formValidation:fieldRequired'),
  recoveryType: Yup.string().required('formValidation:fieldRequired'),
});

const formInitialValues = {
  document: '',
  recoveryType: 'email',
};

const mapStateToProps = ({ tenantEyepass: { domain, tenantId } }) => ({
  domain,
  tenantId,
});

const ForgotPasswordPageContainer = ({ history, domain, tenantId }) => {
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState('');

  const handleDataSubmit = async (values, { setSubmitting }) => {
    try {
      setErrorMessage('');
      setSubmitting(true);
      const response = await http
        .post('/account/recovery', {
          document: stripMask(values.document),
          tenantId,
          recoveryType: values.recoveryType,
        })
        .then(({ data }) => data);

      Analytics.event({
        category: analyticsEventCategory.CUSTOMER,
        action: analyticsEventActions.NEW_PASSWORD_REQUEST,
        label: 'Pedido de nova senha',
      });
      const { email, phone } = response.data;

      showToast(
        email
          ? `${t('formSubmission:passwordAlterRequest')} ${email}`
          : `${t('formSubmission:passwordAlterRequestSms')} ${phone}`,
        'success',
      );
      history.push(`/${domain}/login`);
      setSubmitting(false);
    } catch (error) {
      if (error.response.status === 422) {
        const { data } = error.response.data;
        if (/not found/.test(data.message)) {
          showToast(t('errors:passwordNotFound'), 'error');
        } else if (/not registered/.test(data.message)) {
          showToast(t('errors:cpfNotFound'), 'error');
        } else {
          showToast(data.message, 'error');
        }
      } else if (error.response.data.message) {
        setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage(t('errors:requestError'));
      }

      setSubmitting(false);
    }
  };

  const handleOnPrev = () => {
    history.push(`/${domain}/login`);
  };

  return (
    <Formik
      validationSchema={forgotPasswordFormSchema}
      onSubmit={handleDataSubmit}
      initialValues={formInitialValues}
    >
      {({
        values,
        errors,
        touched,
        handleSubmit,
        setFieldValue,
        setFieldTouched,
        isSubmitting,
        isValid,
      }) => (
        <ForgotPasswordPageScreen
          values={values}
          errors={errors}
          touched={touched}
          handleSubmit={handleSubmit}
          isSubmitting={isSubmitting}
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
          isValid={isValid}
          errorMessage={errorMessage}
          handleOnPrev={handleOnPrev}
        />
      )}
    </Formik>
  );
};

ForgotPasswordPageContainer.propTypes = {
  history: PropTypes.object.isRequired,
  domain: PropTypes.string.isRequired,
  tenantId: PropTypes.number.isRequired,
};

export default connect(mapStateToProps)(ForgotPasswordPageContainer);
