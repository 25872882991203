import { createReducer } from '@reduxjs/toolkit';

// Constants

const Types = {
  SET_TENANT_EYEPASS: 'user/SET_TENANT_EYEPASS',
  CLEAR_TENANT_EYEPASS: 'user/CLEAR_TENANT_EYEPASS',
};

// Reducers

const initialTenantEyepass = {
  tenantId: null,
  domain: null,
  title: null,
  shortName: null,
  description: null,
  theme: {},
  customLocale: {},
  logo: null,
  tosUrl: null,
  tutorialEnabled: false,
  minCartValue: 5,
  deliveryDisabled: false,
  socialLoginDisabled: false,
  qrReaderDisabled: false,
  qrCodeOnly: false,
  paymentOnDeliveryEnabled: false,
  paymentOnDeliveryOnly: false,
  integrations: null,
  maxInstallments: 1,
  minInstallmentValue: 0,
  manifest: null,
  providerId: null,
};

const tenantEyepassReducer = createReducer(initialTenantEyepass, {
  [Types.SET_TENANT_EYEPASS]: (state, action) => {
    return action.payload;
  },
  [Types.CLEAR_TENANT_EYEPASS]: () => {
    return initialTenantEyepass;
  },
});

export const reducers = tenantEyepassReducer;

// Action creators

export const Creators = {
  setTenantEyepass: (payload) => {
    return {
      type: Types.SET_TENANT_EYEPASS,
      payload,
    };
  },
  clearTenantEyepass: () => {
    return {
      type: Types.CLEAR_TENANT_EYEPASS,
    };
  },
};
