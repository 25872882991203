class Analytics {
  static pageview = (title, path = null) => {
    if (window.gtag) {
      window.gtag('event', 'page_view', {
        page_path:
          path || `${window.location.pathname}${window.location.search}`,
        page_title: title,
        send_to: 'G-4TL9M024LP',
      });
    }
  };

  static modalview = (title, modal) => {
    if (window.gtag) {
      window.gtag('event', 'page_view', {
        page_path: modal,
        page_title: title,
        send_to: 'G-4TL9M024LP',
      });
    }
  };

  static setTenant = (tenantId) => {
    if (window.gtag) {
      window.gtag('set', {
        tenantId,
      });
    }
  };

  static setCustomer = (customerId) => {
    if (window.gtag) {
      window.gtag('set', {
        customerId,
      });
    }
  };

  static event = (event) => {
    if (window.gtag) {
      window.gtag('event', event.action, {
        event_category: event.category,
        event_label: event.label || undefined,
        value: event.value || undefined,
      });
    }
    // AppsFlyer
    if (window.AF) {
      window.AF('pba', 'event', {
        eventType: 'EVENT',
        eventName: event.action,
        eventValue: {
          ...(event.label ? { label: event.label } : {}),
          ...(event.value ? { value: event.value } : {}),
          ...(event.category ? { category: event.category } : {}),
        },
      });
    }
  };

  static exception = (description, fatal = false) => {
    if (window.gtag) {
      window.gtag('event', 'exception', {
        description,
        fatal,
      });
    }
  };
}

export default Analytics;
