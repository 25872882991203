import { useState } from 'react';

const mapModalsToState = (modalKeys) => {
  return Array.isArray(modalKeys)
    ? modalKeys.reduce((curr, acc) => {
        return {
          ...curr,
          [acc]: false,
        };
      }, {})
    : [];
};

const useModalController = (modalKeys) => {
  const [modals, setModals] = useState(mapModalsToState(modalKeys));

  const toggleModal = (modalKey) =>
    setModals((prevModals) => ({
      ...prevModals,
      [modalKey]: !prevModals[modalKey],
    }));

  const openModal = (modalKey) =>
    setModals((prevModals) => ({
      ...prevModals,
      [modalKey]: true,
    }));

  const closeModals = () => setModals(mapModalsToState(modalKeys));

  return [modals, { toggleModal, openModal, closeModals }];
};

export default useModalController;
