import React from 'react';
import PropTypes from 'prop-types';
import {
  CheckboxWrapper,
  checkboxLabel,
  checkboxInput,
  Checkmark,
} from './Checkbox.styles';

const Checkbox = ({ name, checked, onChange, label, disabled }) => {
  return (
    <CheckboxWrapper htmlFor={`${name}-checkbox`} disabled={disabled}>
      <input
        id={`${name}-checkbox`}
        css={checkboxInput}
        type="checkbox"
        onChange={onChange}
        checked={checked}
      />
      <Checkmark checked={checked} />
      <span css={checkboxLabel}>{label}</span>
    </CheckboxWrapper>
  );
};

Checkbox.defaultProps = {
  label: '',
  disabled: false,
};

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.func,
  ]),
};

export default Checkbox;
