import SessionStorageManager from './SessionStorageManager';

const getDeliveryAddress = (customerAddresses, user) => {
  const sessionAddress = SessionStorageManager.getOrderAddress();

  if (sessionAddress && JSON.parse(sessionAddress)) {
    return JSON.parse(sessionAddress);
  }

  if (customerAddresses.length > 0) {
    const mainAddress =
      customerAddresses.find(({ main }) => main) || customerAddresses[0];

    if (!mainAddress) {
      return null;
    }

    return {
      id: mainAddress.id,
      label: mainAddress.label,
      zipcode: mainAddress.zipcode,
      street: mainAddress.address,
      number: mainAddress.number,
      complement: mainAddress.address2,
      district: mainAddress.district,
      city: mainAddress.city,
      state: mainAddress.state,
      latitude: mainAddress.latitude,
      longitude: mainAddress.longitude,
    };
  }

  if (!user.address) {
    return null;
  }

  return {
    label: '',
    zipcode: user.zipcode,
    street: user.address,
    number: user.number,
    complement: user.address2,
    district: user.district,
    city: user.city,
    state: user.state,
  };
};

export default getDeliveryAddress;
