import { css } from '@emotion/core';

import { mq } from '../../utils/commonMqs';

export const formWrapper = (theme) => css`
  padding-top: ${theme && theme.getSpacing(4, 'px')};
  padding-bottom: ${theme && theme.getSpacing(4, 'px')};
  ${mq({
    paddingLeft: [
      theme.getSpacing(2, 'px'),
      theme.getSpacing(4, 'px'),
      theme.getSpacing(8, 'px'),
    ],
    paddingRight: [
      theme.getSpacing(2, 'px'),
      theme.getSpacing(4, 'px'),
      theme.getSpacing(8, 'px'),
    ],
  })}
`;
