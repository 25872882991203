import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useUIDSeed } from 'react-uid';

import Header from '../../components/Header';
import ScrollableView from '../../components/ScrollableView';
import LoyaltyItem from '../../components/LoyaltyItem';
import CashbackItem from '../../components/CashbackItem';

import { contentWrapper, textStyle } from './LoyaltyPage.styles';
import { screenWrapper, screenInner } from '../Page.styles';

const LoyaltyPageScreen = ({ onNavigate, loyalties, hasError }) => {
  const seed = useUIDSeed();
  const { t } = useTranslation();

  const mapRedeems = (balance, name, description) => (redeem, index) => {
    const { product, reward_cost: cost, id } = redeem;

    return (
      <LoyaltyItem
        id={seed(id, index)}
        key={seed(id, index)}
        name={name}
        product={product}
        balance={balance}
        cost={cost}
        description={description}
      />
    );
  };

  const mapLoyalty = (loyalty) => {
    const {
      extra: { balance },
      loyalty_redeems: redeemRules,
      name,
      cashback,
      cashback_percentage: cashbackPercentage,
      id,
      balances,
      description,
    } = loyalty;

    if (cashback) {
      return (
        <div key={`loyalty-${id}`}>
          <CashbackItem
            id={id}
            name={name}
            percentage={cashbackPercentage}
            balance={balance}
            balances={balances.map((item, index) => ({ ...item, id: index }))}
          />
        </div>
      );
    }

    return (
      <div key={`loyalty-${id}`}>
        {redeemRules.map(mapRedeems(balance, name, description))}
      </div>
    );
  };

  const renderLoyalties = () => {
    if (hasError) {
      return <p css={textStyle}>{t('loyalty:cantFetchLoyalties')}</p>;
    }

    if (loyalties.length === 0) {
      return <p css={textStyle}>{t('loyalty:noLoyaltiesFound')}</p>;
    }

    return (
      <>
        <p css={textStyle}>{t('info:loyaltyRedeem')}</p>
        {loyalties.map(mapLoyalty)}
      </>
    );
  };

  return (
    <div css={screenWrapper}>
      <Header title={t('navigation:myLoyalties')} onPrev={onNavigate} />
      <ScrollableView divCss={screenInner} hideScroll hasHeader>
        <div css={contentWrapper}>{renderLoyalties()}</div>
      </ScrollableView>
    </div>
  );
};

LoyaltyPageScreen.defaultProps = {
  hasError: false,
};

LoyaltyPageScreen.propTypes = {
  onNavigate: PropTypes.func.isRequired,
  loyalties: PropTypes.array.isRequired,
  hasError: PropTypes.bool,
};

export default LoyaltyPageScreen;
