import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import EcommerceService from '../../services/ecommerce';
import * as productOrderActions from '../../store/modules/productOrder';
import mapProductGroups from '../../utils/mapProductGroups';
import gateway from '../../utils/gateway';
import StoreSelectModal from '../OrderCartPage/StoreSelectModal';
import showToast from '../../utils/showToast';
import ProductAdjustModal from '../../components/ProductAdjustModal';

import ProductContextScreen from './ProductContextScreen';
import ContextStoreSelectModal from './ContextStoreSelectModal';

const mapStateToProps = ({
  auth,
  productOrder: {
    productOrder: { store, products },
  },
  tenantEyepass: { domain },
}) => ({
  isLogged: auth.isLogged,
  store,
  savedProducts: products,
  domain,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      addProductToOrder: productOrderActions.addProductToOrder,
      clearOrder: productOrderActions.clearOrder,
      setStore: productOrderActions.setStore,
    },
    dispatch,
  );

const ProductContextContainer = ({
  history,
  match,
  isLogged,
  store,
  savedProducts,
  addProductToOrder,
  clearOrder,
  setStore,
  domain,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [firstStoreSelectModalOpen, setFirstStoreSelectModalOpen] =
    useState(false);
  const [productAdjustModalOpen, setProductAdjustModalOpen] = useState(false);
  const [scannedProduct, setScannedProduct] = useState(null);
  const [storeSelectModalOpen, setStoreSelectModalOpen] = useState(false);
  const { t } = useTranslation();

  const { sku } = match.params;

  const ignoreRigidStock = Boolean(store?.ignoreRigidStock);

  const handleFetchProduct = async (storeId) => {
    try {
      setIsLoading(true);

      const response = await gateway
        .get(EcommerceService.product(storeId, sku))
        .then(({ data }) => data);

      if (
        response.menu_product_groups &&
        response.menu_product_groups.length > 0
      ) {
        const mappedProductMenus =
          response.menu_product_groups.map(mapProductGroups);

        setScannedProduct(mappedProductMenus[0].products[0]);
        setProductAdjustModalOpen(true);
      } else {
        showToast(t('errors:productNotFound'), 'error');
        history.push(`/${domain}/carrinho`);
      }

      setIsLoading(false);
    } catch (error) {
      setHasError(true);
      setIsLoading(false);
    }
  };

  const handleContext = () => {
    if (store) {
      handleFetchProduct(store && store.id);
    } else {
      setFirstStoreSelectModalOpen(true);
    }
  };

  useEffect(() => {
    if (sku) {
      handleContext();
    } else {
      history.push(`/${domain}/carrinho`);
    }
  }, []);

  const handleStoreChange = (newStore) => {
    if ((newStore && newStore.id) !== (store && store.id)) {
      setStore({ store: newStore });
      clearOrder();
    }

    setFirstStoreSelectModalOpen(false);
    setStoreSelectModalOpen(false);
    handleFetchProduct(newStore && newStore.id);
  };

  const handleFirstStoreDeny = () => {
    setFirstStoreSelectModalOpen(false);
    setStoreSelectModalOpen(true);
  };

  const handleCloseStoreSelectModal = () => {
    setStoreSelectModalOpen(false);
    showToast(t('context:couldNotAddProduct'));
    history.push(`/${domain}/carrinho`);
  };

  const handleCloseAdjustMenuModal = () => {
    history.push(`/${domain}/carrinho`);
  };

  const handleAddProductToCart = (newProduct) => {
    addProductToOrder({ product: newProduct });
    history.push(`/${domain}/carrinho`);
  };

  const hasProducts = savedProducts && savedProducts.length > 0;

  return (
    <>
      <ProductAdjustModal
        visible={productAdjustModalOpen}
        product={scannedProduct}
        onCloseModal={handleCloseAdjustMenuModal}
        onAddToCart={handleAddProductToCart}
        ignoreRigidStock={ignoreRigidStock}
      />
      <ContextStoreSelectModal
        visible={firstStoreSelectModalOpen}
        isLogged={isLogged}
        onStoreSelect={handleStoreChange}
        onDeny={handleFirstStoreDeny}
      />
      <StoreSelectModal
        visible={storeSelectModalOpen}
        currentStore={store}
        onStoreChange={handleStoreChange}
        onCloseModal={handleCloseStoreSelectModal}
        hasProducts={hasProducts}
      />
      <ProductContextScreen
        isLoading={isLoading}
        hasError={hasError}
        hasStoreSelected={!!store}
      />
    </>
  );
};

ProductContextContainer.defaultProps = {
  domain: null,
};

ProductContextContainer.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  isLogged: PropTypes.bool.isRequired,
  store: PropTypes.object.isRequired,
  savedProducts: PropTypes.array.isRequired,
  addProductToOrder: PropTypes.func.isRequired,
  clearOrder: PropTypes.func.isRequired,
  setStore: PropTypes.func.isRequired,
  domain: PropTypes.string,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProductContextContainer);
