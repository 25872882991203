import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'emotion-theming';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import ds from './utils/ds';
import outerTheme from './theme/theme.json';
import AppContainer from './components/AppContainer';
import ToastContainer from './components/ToastContainer';
import CacheBusterWrapper from './components/CacheBusterWrapper';

import App from './App';

const mapStateToProps = ({
  tenantEyepass: { theme: tenantEyepassTheme },
  language: { language },
}) => ({
  tenantEyepassTheme,
  language,
});

const AppTenant = ({ tenantEyepassTheme, language }) => {
  const { i18n } = useTranslation();

  useEffect(() => {
    if (tenantEyepassTheme.colors) {
      const fullTheme = { ...outerTheme, ...tenantEyepassTheme };

      ds.updateValues(fullTheme);
    }
  }, [tenantEyepassTheme]);

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);

  return (
    <ThemeProvider theme={ds}>
      <ToastContainer />
      <AppContainer>
        <CacheBusterWrapper>
          <App />
        </CacheBusterWrapper>
      </AppContainer>
    </ThemeProvider>
  );
};

AppTenant.propTypes = {
  tenantEyepassTheme: PropTypes.object.isRequired,
  language: PropTypes.string.isRequired,
};

export default connect(mapStateToProps)(AppTenant);
