import { css } from '@emotion/core';

export const comboProductCss = (theme) => css`
  width: 100%;
  padding-left: ${theme.getSpacing(1, 'px')};
  padding-bottom: ${theme.getSpacing(0.5, 'px')};
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const comboProductImageCss = (theme) => css`
  height: 38px;
  width: 38px;
  border-radius: 19px;
  margin-right: ${theme.getSpacing(0.5, 'px')};
`;

export const arrowWrapper = (theme) => css`
  position: absolute;
  bottom: 0;
  left: 0;
  height: 24px;
  width: 24px;
  padding-left: 6px;
  padding-right: 6px;
  background-color: ${theme.getColor('primary')};
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
