import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Analytics from '../../utils/Analytics';
import {
  analyticsEventActions,
  analyticsEventCategory,
} from '../../utils/constants';
import * as productOrderActions from '../../store/modules/productOrder';
import { Creators as tagActionCreators } from '../../store/modules/tag';
import gateway from '../../utils/gateway';
import { TransactionError } from '../../utils/errors';
import { Creators as UserCreators } from '../../store/modules/user';
import ecommerceService from '../../services/ecommerce';
import SessionStorageManager from '../../utils/SessionStorageManager';
import showToast from '../../utils/showToast';
import dayjs from '../../utils/dayjs';

import OrderPaymentStatusRefresher from './OrderPaymentStatusRefresher';
import OrderPaymentStatusScreen from './OrderPaymentStatusScreen';
import OrderPixScreen from './OrderPixScreen';
import ContextManager from '../../utils/ContextManager';

const mapStateToProps = ({
  tag: { isPostPaid },
  user,
  tenantEyepass: { domain },
}) => ({
  user,
  domain,
  isPostPaid,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setUser: UserCreators.setUser,
      clearOrder: productOrderActions.clearOrder,
      removeTag: tagActionCreators.removeTag,
    },
    dispatch,
  );

const OrderPaymentStatusContainer = ({
  history,
  match,
  setUser,
  removeTag,
  clearOrder,
  domain,
  isPostPaid,
}) => {
  const { t } = useTranslation();
  const { transactionId, orderId } = match.params;
  const [isUserRefreshing, setIsUserRefreshing] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [transaction, setTransaction] = useState(null);
  const [transactionStatus, setTransactionStatus] = useState('pending');
  const [pixQRcode, setPixQRcode] = useState(null);

  const deliveryType = SessionStorageManager.getDeliveryType();

  const fetchOrderPaymentStatus = async () => {
    try {
      const response = await gateway
        .get(ecommerceService.transaction(transactionId))
        .then(({ data }) => data);

      if (!response.transaction) {
        throw new TransactionError(t('paymentLink:invalidLink'));
      }

      setTransaction(response.transaction);
      if (response.extraData.pix_qrcode) {
        SessionStorageManager.clear();
        ContextManager.removeTag();
        removeTag();
        clearOrder();
        setPixQRcode({
          pixQrCode: response.extraData.pix_qrcode,
          expirationDate: response.extraData.pix_expiration_date,
        });
      }
    } catch (err) {
      if (err?.response?.data?.message) {
        setErrorMessage(err.response.data.message);
      } else {
        setErrorMessage(t('errors:failedToLoad'));
      }
    }
  };

  const fetchOrderAndNavigate = async () => {
    Analytics.event({
      category: analyticsEventCategory.ORDER,
      action: analyticsEventActions.TRACKING_ORDER,
    });

    if (isPostPaid) {
      history.push({
        pathname: `/${domain}/carrinho`,
      });
    } else {
      history.push({
        pathname: `/${domain}/historico`,
        search: `?orderId=${transaction.id}`,
      });
    }
  };

  const handlePrevPress = () => history.push(`/${domain}/historico`);

  const isTransactionRejected = () => {
    const { transaction_pays: transactionPays } = transaction;

    if (isPostPaid) return false;

    if (transactionPays.length > 0) {
      const { tef_transaction: tefTransaction } = transactionPays[0];

      const parsedTefTransaction = tefTransaction
        ? JSON.parse(tefTransaction)
        : undefined;

      if (parsedTefTransaction && parsedTefTransaction.status === 4) {
        return true;
      }
    }

    return false;
  };

  const getTransactionStatus = () => {
    if (transaction) {
      const { cancelled, completed } = transaction;

      if (isTransactionRejected()) {
        setTransactionStatus('rejected');
      } else if (cancelled) {
        setTransactionStatus('cancelled');
      } else if (completed) {
        setTransactionStatus('completed');
      }
    }
  };

  const handleUserRefresh = async () => {
    try {
      setIsUserRefreshing(true);

      const response = await gateway.get('/profile').then(({ data }) => data);

      setUser(response.customer);
    } catch (error) {
      showToast(error.message, 'error');
    } finally {
      setIsUserRefreshing(false);
    }
  };

  const handleNewOrder = () => {
    SessionStorageManager.clear();
    history.push(`/${domain}/carrinho`);
  };

  const handleReviewOrder = () => {
    history.push(`/${domain}/pedido`);
  };

  useEffect(() => {
    if (transactionId && !isPostPaid) {
      fetchOrderPaymentStatus();
    } else if (orderId || isPostPaid) {
      setTransaction({
        cancelled: false,
        completed: true,
      });
    } else {
      SessionStorageManager.clear();
      history.replace(`/${domain}/carrinho`);
    }
  }, []);

  useEffect(() => {
    if (transactionStatus === 'completed') {
      if (isPostPaid) {
        clearOrder();
      } else {
        handleUserRefresh();
        SessionStorageManager.clear();
        ContextManager.removeTag();
        removeTag();
        clearOrder();
      }
    }
  }, [transaction, transactionStatus]);

  useEffect(() => {
    getTransactionStatus();
  }, [transaction]);

  if (pixQRcode) {
    const pixExpirationDate = dayjs(pixQRcode.expirationDate).format(
      'DD/MM, HH:mm',
    );

    return (
      <OrderPixScreen
        pixKey={pixQRcode.pixQrCode}
        onPrev={handlePrevPress}
        transactionValue={transaction.total}
        pixExpirationDate={pixExpirationDate}
        onRefresh={fetchOrderPaymentStatus}
        onFollowOrder={fetchOrderAndNavigate}
        transactionStatus={transactionStatus}
      />
    );
  }

  if (isUserRefreshing) {
    return <OrderPaymentStatusRefresher />;
  }

  if (!transaction && !errorMessage) {
    return <OrderPaymentStatusRefresher onRefresh={fetchOrderPaymentStatus} />;
  }

  return (
    <OrderPaymentStatusScreen
      errorMessage={errorMessage}
      transactionStatus={transactionStatus}
      onRefresh={fetchOrderPaymentStatus}
      onNewOrder={handleNewOrder}
      onFollowOrder={fetchOrderAndNavigate}
      onReviewOrder={handleReviewOrder}
      deliveryType={deliveryType}
    />
  );
};

OrderPaymentStatusContainer.defaultProps = {
  domain: null,
};

OrderPaymentStatusContainer.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  setUser: PropTypes.func.isRequired,
  removeTag: PropTypes.func.isRequired,
  clearOrder: PropTypes.func.isRequired,
  domain: PropTypes.string,
  isPostPaid: PropTypes.bool.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OrderPaymentStatusContainer);
