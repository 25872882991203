import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { useTranslation } from 'react-i18next';

import { hinterWrapperCss } from './PasswordHinter.styles';

const withField = (WrappedComponent) => {
  const FieldHOC = (props) => {
    return (
      <Field>
        {({ form, field }) => (
          <WrappedComponent {...props} form={form} field={field} />
        )}
      </Field>
    );
  };

  return FieldHOC;
};

const PasswordHinter = ({ form }) => {
  const { t } = useTranslation();
  const { values } = form;
  const { password } = values;

  const hasSixDigits = useMemo(() => password.length >= 6, [password]);
  const hasNumeral = useMemo(() => /\d/.test(password), [password]);

  return (
    <ul css={hinterWrapperCss}>
      {!hasSixDigits && (
        <li>{t('formValidation:passwordMostHaveSixDigits')}</li>
      )}
      {!hasNumeral && <li>{t('formValidation:passwordMostHaveOneNumeral')}</li>}
    </ul>
  );
};

PasswordHinter.propTypes = {
  form: PropTypes.object.isRequired,
};

export default withField(PasswordHinter);
