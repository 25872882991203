/**
 * TODO: Decouple this component:
 * This file is a React Router 4 wrapper. It has a impure component
 * because it's  highly coupled to route configs and Login and NotFoundPage Components.
 * Can it will be an library in a not far distant future?
 *
 * TODO: Write some case tests for logged and not logged case
 */

import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Switch, Redirect, useLocation } from 'react-router-dom';

import Route from './AppRoute';
import { privateRoutes, getPublicRoutes, notLoggedRoutes } from './routePaths';
import PaymentLinkPage from '../pages/PaymentLinkPage';
import NotFoundPage from '../pages/404Page';

const mapStateToProps = ({
  auth,
  tenantEyepass: { qrCodeOnly, tutorialEnabled },
  productOrder: {
    productOrder: { store },
  },
}) => ({
  isLogged: auth.isLogged,
  qrCodeOnly,
  menuOnly: Boolean(store) && store.isMenuOnly,
  tutorialEnabled,
});

const Routes = ({
  isLogged,
  domain,
  qrCodeOnly,
  menuOnly,
  tutorialEnabled,
}) => {
  const location = useLocation();
  const publicOrPrivateRoutes = [
    ...getPublicRoutes(qrCodeOnly, menuOnly, tutorialEnabled),
    ...privateRoutes,
  ];
  const defaultRoute = publicOrPrivateRoutes.find((route) => route.default) || {
    path: '/',
  };

  const setRoute = (route) => {
    return (
      <Route
        key={route.path}
        path={`/${domain}${route.path}`}
        component={route.component}
        exact
      />
    );
  };

  /**
   * This preserves the path for redirect to wished page after login
   * @param {*} route
   */
  const setPrivateRoute = (route) => {
    return setRoute({
      ...route,
      template: isLogged ? route.template : undefined,
      component: isLogged ? route.component : undefined,
    });
  };

  const setRedirect = (overrideRedirect) => (route) => {
    const { path, redirectTo } = route;

    const to = `/${domain}${
      overrideRedirect || redirectTo || defaultRoute.path
    }`;

    return <Redirect key={path} from={`/${domain}${path}`} to={to} />;
  };

  const routes = useMemo(() => {
    const allRoutes = [
      privateRoutes.map(isLogged ? setPrivateRoute : setRedirect()),
      notLoggedRoutes.map(
        isLogged
          ? setRedirect(location.state && location.state.redirectTo)
          : setRoute,
      ),
      getPublicRoutes(qrCodeOnly, menuOnly, tutorialEnabled).map(setRoute),
    ];

    /**
     * Two precedence rules:
     * 1 - User is Logged: Private Routes > Public Routes
     * 2 - User is Not Logged: Public Routes > Not Logged Routes > Private Routes
     *
     * These two rules allow overwrites on direction of user status, easing the route configuration.
     */

    return isLogged ? allRoutes : allRoutes.reverse();
  }, [isLogged]);

  return (
    <Switch>
      <Route path={`/${domain}/p/:uuid`} exact component={PaymentLinkPage} />
      {routes}
      <Route component={NotFoundPage} />
    </Switch>
  );
};

Routes.defaultProps = {
  domain: null,
};

Routes.propTypes = {
  isLogged: PropTypes.bool.isRequired,
  domain: PropTypes.string,
  qrCodeOnly: PropTypes.bool.isRequired,
  menuOnly: PropTypes.bool.isRequired,
  tutorialEnabled: PropTypes.bool.isRequired,
};

const enhance = compose(connect(mapStateToProps));

export default enhance(Routes);
