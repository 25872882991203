import React from 'react';
import PropTypes from 'prop-types';
import RowRadioButton from '../RowRadioButton';

const FormikRowRadioButton = ({
  name,
  values,
  fieldKey,
  label,
  setFieldValue,
  setFieldTouched,
  shouldValidate,
  disabled,
  onClick,
  ltor,
}) => {
  const handleChange = () => {
    setFieldTouched(fieldKey, true);
    setFieldValue(
      fieldKey,
      values[fieldKey] === name ? '' : name,
      shouldValidate,
    );
  };

  return (
    <RowRadioButton
      name={name}
      checked={values && values[fieldKey] === name}
      onChange={handleChange}
      label={label}
      disabled={disabled}
      onClick={onClick}
      ltor={ltor}
    />
  );
};

FormikRowRadioButton.defaultProps = {
  shouldValidate: true,
  disabled: false,
  onClick: undefined,
  ltor: false,
};

FormikRowRadioButton.propTypes = {
  name: PropTypes.string.isRequired,
  values: PropTypes.object.isRequired,
  fieldKey: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  shouldValidate: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  ltor: PropTypes.bool,
};

export default FormikRowRadioButton;
