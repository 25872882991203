import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'emotion-theming';

import shoppingBasket from '../../assets/shopping_basket.svg';
import getProductImageSrc from '../../utils/getProductImageSrc';

import PointProgress from './PointProgress';
import {
  ItemContainer,
  ProductImage,
  ItemDescriptionWrapper,
  ProductItemTitle,
  ProductItemLink,
  ProductItemText,
  ProgressText,
} from './LoyaltyItem.styles';

const LoyaltyItem = ({
  name,
  product,
  onClick,
  balance,
  cost,
  id,
  description,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const descUrlMatches = description && description.match(/\bhttps?:\/\/\S+/gi);
  const descUrl = !!descUrlMatches && descUrlMatches[0];

  return (
    <ItemContainer onClick={onClick} role="presentation" onKeyDown={onClick}>
      <ProductImage
        src={product.image ? getProductImageSrc(product.image) : shoppingBasket}
        alt={`img-${product.name}`}
        resizemode="contain"
      />
      <ItemDescriptionWrapper>
        <ProductItemTitle>{name}</ProductItemTitle>
        {descUrl ? (
          <ProductItemLink href={descUrl} rel="noreferrer" target="_blank">
            {description.split(descUrl)[0]}
            <span
              style={{
                textDecoration: 'underline',
                color: theme.getColor('info'),
              }}
            >
              {descUrl}
            </span>
            {description.split(descUrl)[1]}
          </ProductItemLink>
        ) : (
          <ProductItemText>{description}</ProductItemText>
        )}
        <PointProgress progress={balance} total={cost} id={id} />
        <ProgressText>
          {t('loyalty:progress', { fraction: balance, total: cost })}
        </ProgressText>
      </ItemDescriptionWrapper>
    </ItemContainer>
  );
};

LoyaltyItem.defaultProps = {
  name: '',
  onClick: undefined,
  balance: 0,
  cost: 0,
  description: '',
};

LoyaltyItem.propTypes = {
  name: PropTypes.string,
  product: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  balance: PropTypes.number,
  cost: PropTypes.number,
  id: PropTypes.string.isRequired,
  description: PropTypes.string,
};

export default LoyaltyItem;
