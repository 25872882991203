import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { logo } from '../../assets';
import { StyledLogo } from './Logo.styles';

const mapStateToProps = ({ tenantEyepass }) => ({
  tenantEyepass,
});

const Logo = ({ tenantEyepass }) => {
  const [fallbackImg, setFallbackImg] = useState(null);

  const onImgError = () => setFallbackImg(logo);

  return (
    <StyledLogo
      src={fallbackImg || tenantEyepass.logo || logo}
      onError={onImgError}
      alt="Logo"
    />
  );
};

Logo.propTypes = {
  tenantEyepass: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(Logo);
