import React from 'react';
import PropTypes from 'prop-types';
import Button from '@bit/eyemobile.core.button';
import { useTranslation } from 'react-i18next';

import localizePrice from '../../utils/localizePrice';

import { footerContainer, priceText } from './OrderReviewCartFooter.styles';

const OrderReviewCartFooter = ({
  total,
  totalDiscount,
  onButtonClick,
  disabled,
  isPostPaid,
}) => {
  const { t } = useTranslation();

  return (
    <div css={footerContainer}>
      <span css={priceText}>
        {`R$ ${localizePrice(total - totalDiscount)}`}
      </span>

      <Button
        onClick={onButtonClick}
        disabled={disabled}
        color="success"
        textColor="background"
      >
        {isPostPaid ? t('orderReview:confirmCheckout') : t('orderReview:pay')}
      </Button>
    </div>
  );
};

OrderReviewCartFooter.defaultProps = {
  disabled: false,
  totalDiscount: 0,
};

OrderReviewCartFooter.propTypes = {
  total: PropTypes.number.isRequired,
  totalDiscount: PropTypes.number,
  onButtonClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  isPostPaid: PropTypes.bool.isRequired,
};

export default OrderReviewCartFooter;
