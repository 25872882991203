import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import Analytics from '../../utils/Analytics';
import {
  analyticsEventActions,
  analyticsEventCategory,
} from '../../utils/constants';
import http from '../../utils/http';
import showToast from '../../utils/showToast';
import LoadingScreen from '../../components/LoadingScreen';

import RecoverPasswordScreen from './RecoverPasswordScreen';

const mapStateToProps = ({ tenantEyepass: { domain } }) => ({
  domain,
});

const RecoverPasswordContainer = ({ history, match, domain }) => {
  const { token } = match.params;
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const handleDataSubmit = async (values, formikBag) => {
    try {
      const payload = {
        password: values.password,
        token,
      };

      await http.put('/account/recovery/password', payload);
      Analytics.event({
        category: analyticsEventCategory.CUSTOMER,
        action: analyticsEventActions.NEW_PASSWORD_SET,
        label: 'Nova senha associada',
      });
      showToast(t('formSubmission:passwordAlterSuccess'), 'success');
      formikBag.setSubmitting(false);
      history.replace(`/${domain}/login`);
    } catch (error) {
      formikBag.setSubmitting(false);
      if (error.response.status === 422) {
        showToast(t('errors:newPasswordMatchesOld'), 'error');
      } else {
        showToast(error?.response?.data?.message || error.message, 'error');
      }
    }
  };

  const handleTokenValidation = async () => {
    try {
      setIsLoading(true);

      await http.get(`/account/tokens/${token}`);

      setIsLoading(false);
    } catch (error) {
      showToast(error?.response?.data?.data?.message || error.message, 'error');
      history.replace(`/${domain}/login`);
    }
  };

  useEffect(() => {
    if (token) {
      handleTokenValidation();
    } else {
      history.replace(`/${domain}/login`);
    }
  }, []);

  if (isLoading) {
    return <LoadingScreen />;
  }

  return <RecoverPasswordScreen onDataSubmit={handleDataSubmit} />;
};

RecoverPasswordContainer.defaultProps = {
  domain: null,
};

RecoverPasswordContainer.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  domain: PropTypes.string,
};

export default connect(mapStateToProps)(RecoverPasswordContainer);
