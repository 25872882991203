import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import ScrollableView from '../../components/ScrollableView';
import Header from '../../components/Header';
import PasswordForm from '../../components/PasswordForm';
import { formWrapper } from './UserPasswordPage.styles';

const UserPasswordScreen = ({ onDataSubmit, onNavigate }) => {
  const { t } = useTranslation();

  const handleFormSubmit = (values, formikBag) => {
    onDataSubmit(values, formikBag);
  };

  return (
    <>
      <Header title={t('navigation:changePassword')} onPrev={onNavigate} />
      <ScrollableView hasHeader>
        <div css={formWrapper}>
          <PasswordForm onFormSubmit={handleFormSubmit} />
        </div>
      </ScrollableView>
    </>
  );
};

UserPasswordScreen.propTypes = {
  onNavigate: PropTypes.func.isRequired,
  onDataSubmit: PropTypes.func.isRequired,
};

export default UserPasswordScreen;
