import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';

import Analytics from '../../utils/Analytics';
import {
  analyticsEventActions,
  analyticsEventCategory,
} from '../../utils/constants';
import * as productOrderActions from '../../store/modules/productOrder';
import { Creators as UserCreators } from '../../store/modules/user';
import gateway from '../../utils/gateway';
import showToast from '../../utils/showToast';
import SessionStorageManager from '../../utils/SessionStorageManager';
import ecommerceService from '../../services/ecommerce';
import mapStore from '../../utils/mapStore';

import UserAddressListScreen from './UserAddressListScreen';
import AddressAddModal from './AddressAddModal';
import AddressEditModal from './AddressEditModal';

const mapStateToProps = ({
  user,
  productOrder: {
    productOrder: { deliveryAddress, shippingType },
  },
  tenantEyepass: { domain },
}) => ({
  user,
  deliveryAddress,
  domain,
  shippingType,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setUser: UserCreators.setUser,
      setDeliveryAddress: productOrderActions.setDeliveryAddress,
      setStore: productOrderActions.setStore,
      setExpectedDistanceDuration:
        productOrderActions.setExpectedDistanceDuration,
      setShippingType: productOrderActions.setShippingType,
    },
    dispatch,
  );

const UserAddressListContainer = ({
  setUser,
  deliveryAddress,
  setDeliveryAddress,
  domain,
  setStore,
  setExpectedDistanceDuration,
  setShippingType,
  shippingType,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const [addressList, setAddressList] = useState([]);
  const [addressAddModalOpen, setAddressAddModalOpen] = useState(false);
  const [addressEdit, setAddressEdit] = useState(null);

  const toggleAddressAddModal = () => setAddressAddModalOpen((prev) => !prev);

  const openAddressEditModal = (address) => setAddressEdit(address);

  const closeAddressEditModal = () => setAddressEdit(null);

  const handlePrev = () => history.push(`/${domain}/minha-conta`);

  const loadAddresses = async () => {
    try {
      setIsLoading(true);
      const response = await gateway
        .get(ecommerceService.getAddresses())
        .then(({ data }) => data);

      setAddressList(response);

      if (deliveryAddress) {
        setDeliveryAddress({ deliveryAddress: null });
      }
    } catch (err) {
      console.error(err);
      showToast(err?.response?.data?.message || err.message, 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const reloadUser = async () => {
    try {
      const profile = await gateway.get('/profile').then(({ data }) => data);

      setUser(profile.customer);
      loadAddresses();
    } catch (err) {
      showToast(err?.response?.data?.message || err.message, 'error');
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadAddresses();
  }, []);

  const handleSetMainAddress = async (addressItem) => {
    try {
      setIsLoading(true);

      const payload = {
        receiverName: addressItem.receiver_name,
        label: addressItem.label,
        zipcode: addressItem.zipcode,
        address: addressItem.address,
        address2: addressItem.address2,
        number: addressItem.number,
        district: addressItem.district,
        state: addressItem.state,
        city: addressItem.city,
        main: true,
      };

      const newAddress = {
        id: addressItem.id,
        label: addressItem.label,
        zipcode: addressItem.zipcode,
        street: addressItem.address,
        number: addressItem.number,
        complement: addressItem.address2,
        district: addressItem.district,
        city: addressItem.city,
        state: addressItem.state,
        receiverName: addressItem.receiverName,
        latitude: addressItem.latitude,
        longitude: addressItem.longitude,
        distance: addressItem.extra_distance,
        duration: addressItem.extra_duration,
        deliveryPrice: addressItem?.extra_shipping_price?.value,
      };
      SessionStorageManager.setOrderAddress(newAddress);

      const pointParams = {
        latitude: newAddress.latitude,
        longitude: newAddress.longitude,
        shippingType,
      };

      const response = await gateway
        .get(ecommerceService.getEventPoints(), { params: pointParams })
        .then(({ data }) => data);

      let initialStore = null;

      if (response.event_points) {
        const storeList = response.event_points
          .filter(({ open }) => !!open)
          .map(mapStore);

        if (storeList.length > 0) {
          initialStore = storeList && storeList[0];
          setStore({ store: initialStore });
          setShippingType({ shippingType });
          setExpectedDistanceDuration({
            expected: {
              duration: storeList[0].duration,
              distance: storeList[0].distance,
            },
          });
          SessionStorageManager.removeOrderDeliverySchedule();
        }
      }

      await gateway.put(`/profile/address/${addressItem.id}`, payload);

      reloadUser();
    } catch (err) {
      console.error(err);
      showToast(err?.response?.data?.message || err.message, 'error');
      setIsLoading(false);
    }
  };

  const handleRemoveAddress = async (addressItem) => {
    try {
      setIsLoading(true);

      await gateway.delete(`/profile/address/${addressItem.id}`);

      Analytics.event({
        category: analyticsEventCategory.CUSTOMER,
        action: analyticsEventActions.REMOVE_ADDRESS,
        label: 'Remoção de endereço de entrega',
      });
      showToast(t('addressesListPage:addressRemoveSuccess'), 'success');

      reloadUser();
    } catch (err) {
      console.error(err);
      showToast(err?.response?.data?.message || err.message, 'error');
      setIsLoading(false);
    }
  };

  const onEditAddress = async (addressData, formikBag, addressId) => {
    try {
      formikBag.setSubmitting(true);

      const {
        addressLabel,
        addressZipcode,
        addressStreet,
        addressNumber,
        addressComplement,
        addressDistrict,
        addressCity,
        addressState,
        addressReceiverName,
        main,
      } = addressData;

      const payload = {
        label: addressLabel,
        zipcode: addressZipcode,
        address: addressStreet,
        address2: addressComplement,
        number: addressNumber,
        district: addressDistrict,
        state: addressState,
        city: addressCity,
        receiverName: addressReceiverName,
        main,
      };

      await gateway.put(`/profile/address/${addressId}`, payload);

      showToast(t('addressesListPage:addressEditSuccess'), 'success');

      closeAddressEditModal();
      reloadUser();
    } catch (err) {
      console.error(err);
      showToast(err?.response?.data?.message || err.message, 'error');
    } finally {
      formikBag.setSubmitting(false);
    }
  };

  const onAddAddress = async (addressData, formikBag) => {
    try {
      formikBag.setSubmitting(true);

      const {
        addressLabel,
        addressZipcode,
        addressStreet,
        addressNumber,
        addressComplement,
        addressDistrict,
        addressCity,
        addressState,
        addressReceiverName,
        main,
      } = addressData;

      const payload = {
        label: addressLabel,
        zipcode: addressZipcode,
        address: addressStreet,
        address2: addressComplement,
        number: addressNumber,
        district: addressDistrict,
        state: addressState,
        city: addressCity,
        receiverName: addressReceiverName,
        main,
      };

      await gateway.post('/profile/address', payload);
      Analytics.event({
        category: analyticsEventCategory.CUSTOMER,
        action: analyticsEventActions.ADD_ADDRESS,
        label: 'Adição de endereço',
      });

      showToast(t('addressesListPage:addressAddSuccess'), 'success');

      toggleAddressAddModal();
      reloadUser();
    } catch (err) {
      console.error(err);
      showToast(err?.response?.data?.message || err.message, 'error');
      formikBag.setSubmitting(false);
    }
  };

  return (
    <>
      <AddressAddModal
        visible={addressAddModalOpen}
        onClose={toggleAddressAddModal}
        onFinish={onAddAddress}
      />
      <AddressEditModal
        visible={!!addressEdit}
        onClose={closeAddressEditModal}
        onFinish={onEditAddress}
        addressItem={addressEdit}
      />
      <UserAddressListScreen
        onPrev={handlePrev}
        isLoading={isLoading}
        addressList={addressList}
        onSetMainAddress={handleSetMainAddress}
        onRemoveAddress={handleRemoveAddress}
        onAddressEdit={openAddressEditModal}
        onAddAddress={toggleAddressAddModal}
      />
    </>
  );
};

UserAddressListContainer.defaultProps = {
  deliveryAddress: null,
  domain: null,
  shippingType: null,
};

UserAddressListContainer.propTypes = {
  setUser: PropTypes.func.isRequired,
  deliveryAddress: PropTypes.object,
  setDeliveryAddress: PropTypes.func.isRequired,
  domain: PropTypes.string,
  setStore: PropTypes.func.isRequired,
  setExpectedDistanceDuration: PropTypes.func.isRequired,
  setShippingType: PropTypes.func.isRequired,
  shippingType: PropTypes.string,
};

const enhance = compose(connect(mapStateToProps, mapDispatchToProps));

export default enhance(UserAddressListContainer);
