import { css } from '@emotion/core';

export const itemContainer = css`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const itemWrapperCss = (theme) => css`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: ${theme && theme.getSpacing(1, 'px')};
  padding-bottom: ${theme && theme.getSpacing(1, 'px')};
  padding-left: ${theme && theme.getSpacing(2, 'px')};
`;

export const complementWrapperCss = (theme) => css`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-left: ${theme && theme.getSpacing(0.5, 'px')};
`;

export const descriptionText = (theme) => css`
  font-family: ${theme && theme.getFont('futura')};
  font-size: ${theme && theme.getFontSize(1, 'px')};
  color: ${theme && theme.getColor('text')};
  margin-top: ${theme && theme.getSpacing(0.5, 'px')};
  margin-bottom: ${theme && theme.getSpacing(0.5, 'px')};
`;

export const productImageCss = css`
  height: 70px;
  width: 70px;
  border-radius: 50%;
  object-fit: contain;
`;
