import styled from '@emotion/styled';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import facepaint from 'facepaint';

const mq = facepaint([
  '@media screen and (min-width: 320px)',
  '@media screen and (min-width: 480px)',
  '@media screen and (min-width: 720px)',
]);

export const BottomNavigationList = styled(BottomNavigation)`
  height: 70px;
  position: absolute;
  bottom: 0px;
  ${mq({
    width: ['320px', '100%', '480px', '100%'],
  })}
  background-color: ${({ theme }) => theme && theme.getColor('foreground')};
  z-index: 1000;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  padding-left: 16px;
  padding-right: 16px;
  overflow-x: auto;
`;

export const BottomNavigationItem = styled(BottomNavigationAction)`
  color: ${({ theme }) => theme.getColor('background')};
  font-size: 12px;
  border-bottom: 4px solid
    ${({ theme, active }) =>
      active ? theme.getColor('background') : 'transparent'};
`;
