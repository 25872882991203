import facepaint from 'facepaint';
import { css } from '@emotion/core';

const mq = facepaint([
  '@media screen and (min-width: 320px)',
  '@media screen and (min-width: 480px)',
  '@media screen and (min-width: 720px)',
]);

export const centerContainerCss = css`
  display: flex;
  flex: 1;
  justify-content: center;
  background-color: #ffffff;
  height: 100vh;
  overflow-y: hidden;
`;

export const wrapperContainer = (theme) => css`
  background-color: ${theme && theme.getColor('background')};
  position: relative;
  ${mq({
    width: ['320px', '100%', '480px', '100%'],
  })}
`;
