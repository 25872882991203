import { getServiceWorkerToken } from './utils/firebase';
// This optional code is used to register a service worker.
// register() is not called by default.

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on subsequent visits to a page, after all the
// existing tabs open on the page have been closed, since previously cached
// resources are updated in the background.

// To learn more about the benefits of this model and instructions on how to
// opt-in, read https://bit.ly/CRA-PWA

export const register = (config) => {
  if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
    // The URL constructor is available in all browsers that support SW.
    const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);
    if (publicUrl.origin !== window.location.origin) {
      // Our service worker won't work if PUBLIC_URL is on a different origin
      // from what our page is served on. This might happen if a CDN is used to
      // serve assets; see https://github.com/facebook/create-react-app/issues/2374
      return;
    }

    console.log('Registering firebase service worker'); // eslint-disable-line

    const swUrl = `${process.env.PUBLIC_URL}/firebase-messaging-sw.js`;

    registerValidSW(swUrl, config);
  }
};

const registerValidSW = (swUrl, config) => {
  const domain = config && config.domain;
  navigator.serviceWorker
    .register(swUrl, { scope: domain ? `/${domain}` : '/' })
    .then((registration) => {
      getServiceWorkerToken(registration);
      // Track updates to the Service Worker.
      if (!navigator.serviceWorker.controller) {
        // The window client isn't currently controlled so it's a new service
        // worker that will activate immediately
        return;
      }

      registration.update();
    })
    .catch((error) => {
      console.error('Error during service worker registration:', error);
    });
};

export const unregister = async () => {
  if ('serviceWorker' in navigator) {
    await navigator.serviceWorker.ready
      .then((registration) => {
        registration.unregister();
      })
      .catch((error) => {
        console.error(error.message);
      });
  }
};
