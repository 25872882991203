import { createReducer } from '@reduxjs/toolkit';

// Constants

const Types = {
  SET_LANGUAGE: 'user/SET_LANGUAGE',
};

// Reducers

const initialLanguage = { language: 'pt-CUSTOM' };

const userReducer = createReducer(initialLanguage, {
  [Types.SET_LANGUAGE]: (state, action) => {
    return action.payload;
  },
});

export const reducers = userReducer;

// Action creators

export const Creators = {
  setLanguage: (payload) => {
    return {
      type: Types.SET_LANGUAGE,
      payload,
    };
  },
};
