import axios from 'axios';

const { REACT_APP_SERVER_ENV, REACT_APP_SERVER_PORT, REACT_APP_SERVER_HOST } =
  process.env;

const port = REACT_APP_SERVER_PORT || 3003;
const host = REACT_APP_SERVER_HOST || 'localhost';

const backendHost = {
  production: 'https://customers.eyemobile.com.br/api/v1',
  staging: 'https://staging-customers.eyemobile.com.br/api/v1',
  development: ` http://${host}:${port}/api/v1`,
};

const baseURL = REACT_APP_SERVER_ENV
  ? backendHost[REACT_APP_SERVER_ENV]
  : backendHost.development;

export const customerUrl = '/customers';

export const basicAuth = {
  username: 'eyemobile',
  password: 'customer.service@22',
};

const instance = axios.create({
  baseURL,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
  },
  defaults: {
    timeout: 60000,
  },
  auth: basicAuth,
});

export default instance;
