import styled from '@emotion/styled';
import { css } from '@emotion/core';
import facepaint from 'facepaint';

const mobileMq = facepaint(['@media screen and (min-width: 375px)']);

export const ItemContainer = styled.div`
  width: 100%;
  padding-left: ${({ theme }) => theme && theme.getSpacing(2, 'px')};
  padding-right: ${({ theme }) => theme && theme.getSpacing(2, 'px')};
  padding-top: ${({ theme }) => theme && theme.getSpacing(1, 'px')};
  padding-bottom: ${({ theme }) => theme && theme.getSpacing(1, 'px')};
  border-radius: 17px;
  background-color: #f5f5f5;

  &:not(:first-of-type) {
    margin-top: ${({ theme }) => theme && theme.getSpacing(2, 'px')};
  }
`;

export const InnerBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: ${({ theme }) => theme && theme.getSpacing(1, 'px')};
  padding-right: ${({ theme }) => theme && theme.getSpacing(1, 'px')};
  padding-top: ${({ theme }) => theme && theme.getSpacing(1, 'px')};
  padding-bottom: ${({ theme }) => theme && theme.getSpacing(1, 'px')};
  cursor: pointer;
`;

export const DescriptionTextCss = styled.span`
  font-family: ${({ theme, fontFamily }) =>
    theme && theme.getFont(fontFamily || 'default')};
  font-size: ${({ theme }) => theme && theme.getFontSize(1, 'px')};
  color: ${({ theme, color }) => theme && theme.getColor(color || 'text')};
  ${({ bold }) => bold && 'font-weight: bold;'}
`;

export const priceTextCss = (theme) => css`
  font-family: ${theme.getFont('futura')};
  font-size: ${theme.getFontSize(1, 'px')};
  color: ${theme.getColor('placeholder')};
  white-space: nowrap;
  margin-top: ${theme.getFontSize(0.5, 'px')};
  margin-bottom: ${theme.getFontSize(1, 'px')};
`;

const statusTextBps = ({ theme }) =>
  mobileMq({
    fontSize: [theme.getFontSize(0.8, 'px'), theme.getFontSize(1, 'px')],
    paddingRight: [theme.getSpacing(1, 'px'), theme.getSpacing(3, 'px')],
    paddingLeft: [theme.getSpacing(1, 'px'), theme.getSpacing(3, 'px')],
  });

export const StatusText = styled.span`
  background-color: ${({ theme, statusColor }) =>
    theme && theme.getColor(statusColor || 'default')};
  border-radius: 15px;
  padding-top: ${({ theme }) => theme && theme.getSpacing(1, 'px')};
  padding-bottom: ${({ theme }) => theme && theme.getSpacing(1, 'px')};
  font-family: ${({ theme }) => theme && theme.getFont('futura')};
  ${statusTextBps}
  color: ${({ theme }) => theme && theme.getColor('lightText')};
  text-align: center;
`;

export const topRowStyle = (theme) => css`
  width: 100%;
  padding-top: ${theme && theme.getSpacing(1, 'px')};
  padding-bottom: ${theme && theme.getSpacing(1, 'px')};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const infoCol = (theme) => css`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const arrowImg = (theme) => css`
  width: 20px;
  height: 20px;
  object-fit: contain;
  display: block;
  margin-left: 10px;
  color: ${theme && theme.getColor('primary')};
`;
