import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Input from '@bit/eyemobile.core.input';
import CircleButton from '@bit/eyemobile.core.circle-button';
import Loading from '@bit/eyemobile.core.loading';

import { ReactComponent as KeyboardArrowDown } from '../../assets/keyboard_arrow_down.svg';
import { ReactComponent as Search } from '../../assets/search.svg';
import timeTextFromMinutes from '../../utils/timeTextFromMinutes';
import { shippingTypes } from '../../utils/constants';
import ScrollableView from '../ScrollableView';
import AddressItem from '../AddressItem';

import {
  storeSelectWrapper,
  contentWrapper,
  noStoretext,
} from './StoreSelect.styles';

const StoreSelect = ({
  onClose,
  storeList,
  shippingType,
  deliveryAddress,
  filterText,
  onFilterTextChange,
  onStoreChange,
  isLoading,
}) => {
  const { t } = useTranslation();

  const handleStoreChange = (newStore) => () => {
    onStoreChange(newStore);
  };

  const renderStoreLocation = (storeData) => {
    const {
      id,
      name,
      isOpen,
      city,
      state,
      address,
      addressNumber,
      duration,
      distance,
      deliveryPrice,
      isBeyondMaxDistance,
    } = storeData;

    const distanceText =
      !isBeyondMaxDistance && distance > 0 ? `${Math.round(distance)} Km` : '';
    const durationText =
      duration > 0 && shippingType === shippingTypes.ADDRESS
        ? timeTextFromMinutes(duration)
        : '';
    const storeAddress = address
      ? `${address}, ${addressNumber}`
      : t('productOrder:storeNoAddress');

    const storeDeliveryPrice =
      shippingType === shippingTypes.ADDRESS ? deliveryPrice : null;

    return (
      <AddressItem
        key={`store-${id}`}
        distanceText={distanceText}
        durationText={durationText}
        storeName={name}
        isDisabled={!isOpen}
        storeAddress={storeAddress}
        addressCityState={city ? `${city}, ${state}` : '-'}
        storeDeliveryPrice={storeDeliveryPrice}
        onClick={handleStoreChange(storeData)}
      />
    );
  };

  const renderContent = useMemo(() => {
    if (isLoading) {
      return <Loading show center style={{ height: 'auto', flexGrow: 0 }} />;
    }

    if (storeList.length === 0) {
      return <span css={noStoretext}>{t('productOrder:noStoreFound')}</span>;
    }

    return (
      <ScrollableView flex={false}>
        {storeList.map(renderStoreLocation)}
      </ScrollableView>
    );
  }, [isLoading, storeList]);

  return (
    <div css={storeSelectWrapper}>
      <div
        style={{
          width: '100%',
          paddingRight: '8px',
          paddingBottom: '5px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
        }}
      >
        <CircleButton
          icon={<KeyboardArrowDown />}
          size="30px"
          onClick={onClose}
          backgroundColor="primary"
          color="background"
          padding="3px"
        />
      </div>
      <Input
        value={filterText}
        placeholder="Pesquisar loja"
        name="creditCardHolderName"
        onChange={onFilterTextChange}
        variant="bordered"
        inputIcon={<Search />}
        inputStyle={{ borderColor: '#b4b4b4' }}
      />
      <div css={contentWrapper}>{renderContent}</div>
    </div>
  );
};

StoreSelect.defaultProps = {
  filterText: '',
  isLoading: false,
  deliveryAddress: null,
};

StoreSelect.propTypes = {
  onClose: PropTypes.func.isRequired,
  storeList: PropTypes.array.isRequired,
  shippingType: PropTypes.string.isRequired,
  deliveryAddress: PropTypes.object,
  filterText: PropTypes.string,
  onFilterTextChange: PropTypes.func.isRequired,
  onStoreChange: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

export default StoreSelect;
