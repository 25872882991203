import * as Yup from 'yup';
import cardValidator from 'card-validator';

import dayjs from '../../utils/dayjs';
import { cpfOrCnpjSchema } from '../../utils/schemas';
import validCreditCardFlags from '../../utils/validCreditCardFlags';

const paymentLinkFormSchema = Yup.object().shape({
  document: cpfOrCnpjSchema,
  email: Yup.string()
    .email('formValidation:invalidEmail')
    .required('formValidation:fieldRequired'),
  creditCardNumber: Yup.string().when('payLinkType', {
    is: 'CREDIT_CARD',
    then: Yup.string()
      .required('formValidation:fieldRequired')
      .test(
        'validate-card-number',
        'formValidation:invalidCreditCard',
        function validateCardNumber(value) {
          if (!value) return false;
          const validation = cardValidator.number(value.replace(' ', ''));
          const hasValidFlag =
            !!validation.card &&
            validCreditCardFlags.some(
              (validFlag) =>
                validFlag === validation.card.niceType ||
                validFlag === validation.card.type,
            );

          return hasValidFlag && validation.isValid;
        },
      ),
    otherwise: Yup.string(),
  }),

  creditCardHolderName: Yup.string().when('payLinkType', {
    is: 'CREDIT_CARD',
    then: Yup.string().required('formValidation:fieldRequired'),
    otherwise: Yup.string(),
  }),
  creditCardExpiration: Yup.string().when('payLinkType', {
    is: 'CREDIT_CARD',
    then: Yup.string()
      .required('formValidation:fieldRequired')
      .test(
        'validate-ccexpiration',
        'formValidation:invalidExpirationDate',
        function validateExpirationDate(value) {
          if (!value) return false;
          return dayjs(value, 'MM/YYYY').format('MM/YYYY') === value;
        },
      ),
    otherwise: Yup.string(),
  }),
  creditCardCvv: Yup.string().when('payLinkType', {
    is: 'CREDIT_CARD',
    then: Yup.string()
      .required('formValidation:fieldRequired')
      .test(
        'validate-cvv',
        'formValidation:invalidCvv',
        function validateCvv(value) {
          if (!value) return false;
          const { creditCardNumber } = this.parent;
          const cardNumberValidation = cardValidator.number(creditCardNumber);

          const validation = cardValidator.cvv(
            value,
            cardNumberValidation &&
              cardNumberValidation.card &&
              cardNumberValidation.card.code.size,
          );

          return validation.isValid;
        },
      ),
    otherwise: Yup.string(),
  }),
  installments: Yup.number(),
  payLinkType: Yup.string(),
});

export default paymentLinkFormSchema;
