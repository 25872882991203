import { css } from '@emotion/core';

export const storeSelectWrapper = (theme) => css`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: ${theme && theme.getSpacing(1.5, 'px')};
  padding-right: ${theme && theme.getSpacing(1.5, 'px')};
  padding-bottom: ${theme && theme.getSpacing(1, 'px')};
  height: 100%;
  overflow: hidden;
`;

export const contentWrapper = (theme) => css`
  width: 100%;
  padding-top: ${theme && theme.getSpacing(1, 'px')};
  padding-bottom: ${theme && theme.getSpacing(1, 'px')};
  display: block;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }
  &::-webkit-scrollbar:vertical {
    width: 8px;
  }
  &::-webkit-scrollbar:horizontal {
    height: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    border: 2px solid #ffffff;
  }
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #ffffff;
  }
`;

export const noStoretext = (theme) => css`
  font-size: ${theme && theme.getFontSize(1, 'px')};
  font-family: ${theme && theme.getFont()};
  color: ${theme && theme.getColor('text')};
  font-weight: bold;
  width: 100%;
  text-align: center;
`;
