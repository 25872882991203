import { css } from '@emotion/core';
import styled from '@emotion/styled';
import facepaint from 'facepaint';

const mq = facepaint(['@media screen and (min-width: 375px)']);

export const HeaderContainer = styled.div`
  width: 100%;
  height: 50px;
  padding-top: ${({ theme }) => theme && theme.getSpacing(1, 'px')};
  padding-bottom: ${({ theme }) => theme && theme.getSpacing(1, 'px')};
  padding-left: ${({ theme }) => theme && theme.getSpacing(2, 'px')};
  padding-right: ${({ theme }) => theme && theme.getSpacing(2, 'px')};
  background-color: ${({ theme }) => theme && theme.getColor('background')};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #655b7c40;
`;

export const headerNameWrapper = (theme) => css`
  width: 100%;
  padding: ${theme && theme.getSpacing(1, 'px')};
`;

export const HeaderName = styled.span`
  text-align: left;
  font-size: ${({ theme }) => theme && theme.getFontSize(1, 'px')};
  font-family: ${({ theme }) => theme && theme.getFont('heading')};
  color: ${({ theme }) => theme && theme.getColor('uiText')};
  font-weight: bold;
  width: 100%;
`;

export const userAddress = (theme) => css`
  ${mq({
    fontSize: [theme.getFontSize(0.9, 'px'), theme.getFontSize(1, 'px')],
  })}
  line-height: ${theme.getFontSize(1.3, 'px')};
  font-family: ${theme && theme.getFont('futura')};
  color: ${theme && theme.getColor('text')};
  margin-right: ${theme && theme.getSpacing(1, 'px')};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
`;

export const qrCodeButtonWrapper = (theme) => css`
  margin-left: ${theme && theme.getSpacing(2, 'px')};
`;

export const qrCodeScanWrapper = (theme) => css`
  padding-left: ${theme && theme.getSpacing(1, 'px')};
`;

export const circleButtonStyle = {
  userSelect: 'none',
  '-webkit-tap-highlight-color': 'transparent',
};
