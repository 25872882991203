import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import Modal from '@bit/eyemobile.core.modal';
import Slide from '@material-ui/core/Slide';

import {
  analyticsEventActions,
  analyticsEventCategory,
} from '../../utils/constants';
import Analytics from '../../utils/Analytics';
import withModal from '../../hocs/withModal';
import * as productOrderActions from '../../store/modules/productOrder';
import getTotalPriceFromProductMenus from '../../utils/getTotalPriceFromProductMenus';
import {
  productContent,
  productInnerWrapper,
  productOverlay,
} from '../../components/ProductAdjustModal/ProductAdjustModal.styles';
import OrderCartListMenu from '../../components/OrderCartListMenu';

const mapStateToProps = ({
  productOrder: {
    productOrder: { products, promotionalProducts },
  },
  tenantEyepass: { minCartValue },
}) => ({
  products,
  promotionalProducts,
  minCartValue,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      clearOrder: productOrderActions.clearOrder,
      removeProductInOrder: productOrderActions.removeProductInOrder,
      removePromotionalProductInOrder:
        productOrderActions.removePromotionalProductInOrder,
    },
    dispatch,
  );

const OrderCartListModal = ({
  visible,
  onCloseModal,
  products,
  promotionalProducts,
  clearOrder,
  onEditProduct,
  removeProductInOrder,
  removePromotionalProductInOrder,
  onFinishOrder,
  minCartValue,
  disableOrder,
}) => {
  const totalPrice = useMemo(
    () => getTotalPriceFromProductMenus(products.concat(promotionalProducts)),
    [products, promotionalProducts],
  );

  const finishOrderDisabled =
    !products.concat(promotionalProducts) ||
    products.concat(promotionalProducts).length === 0 ||
    totalPrice < minCartValue ||
    disableOrder;

  const handleAddProduct = () => onCloseModal();

  const handleRemoveProduct = (product) => {
    Analytics.event({
      category: analyticsEventCategory.ORDER,
      action: analyticsEventActions.REMOVE_PRODUCT,
      label: product.name,
    });
    if (product.source !== 'menu') {
      removePromotionalProductInOrder({
        productOrderId: product.productOrderId,
      });
    } else {
      removeProductInOrder({ productOrderId: product.productOrderId });
    }
  };

  const handleEditProduct = (product) => {
    onEditProduct(product);
  };

  return (
    <Slide direction="up" in={visible}>
      <Modal
        visible={visible}
        styles={{
          innerWrapper: productInnerWrapper,
          content: productContent,
          overlay: productOverlay,
        }}
        keepMounted
      >
        <OrderCartListMenu
          products={promotionalProducts.concat(products)}
          onAddProduct={handleAddProduct}
          onRemoveProduct={handleRemoveProduct}
          onEditProduct={handleEditProduct}
          onClearCart={clearOrder}
          onClose={onCloseModal}
          totalPrice={totalPrice}
          onFinishOrder={onFinishOrder}
          finishOrderDisabled={finishOrderDisabled}
        />
      </Modal>
    </Slide>
  );
};

OrderCartListModal.defaultProps = {
  visible: false,
  disableOrder: true,
};

OrderCartListModal.propTypes = {
  visible: PropTypes.bool,
  onCloseModal: PropTypes.func.isRequired,
  products: PropTypes.array.isRequired,
  promotionalProducts: PropTypes.array.isRequired,
  clearOrder: PropTypes.func.isRequired,
  onEditProduct: PropTypes.func.isRequired,
  removeProductInOrder: PropTypes.func.isRequired,
  removePromotionalProductInOrder: PropTypes.func.isRequired,
  onFinishOrder: PropTypes.func.isRequired,
  minCartValue: PropTypes.number.isRequired,
  disableOrder: PropTypes.bool,
};

const enhance = compose(
  withModal((props) => ({
    visible: props.visible,
  })),
  connect(mapStateToProps, mapDispatchToProps),
);

export default enhance(OrderCartListModal);
