import { css } from '@emotion/core';
import facepaint from 'facepaint';

const mq = facepaint([
  '@media screen and (min-width: 375px)',
  '@media screen and (min-width: 720px)',
]);

export const screenWrapper = css`
  width: 100%;
  height: 100%;
`;

const innerBps = (theme) =>
  mq({
    paddingTop: [
      theme.getSpacing(1, 'px'),
      theme.getSpacing(2, 'px'),
      theme.getSpacing(4, 'px'),
    ],
    paddingBottom: [
      theme.getSpacing(1, 'px'),
      theme.getSpacing(2, 'px'),
      theme.getSpacing(4, 'px'),
    ],
    paddingLeft: [
      theme.getSpacing(2, 'px'),
      theme.getSpacing(4, 'px'),
      theme.getSpacing(8, 'px'),
    ],
    paddingRight: [
      theme.getSpacing(2, 'px'),
      theme.getSpacing(4, 'px'),
      theme.getSpacing(8, 'px'),
    ],
  });

export const screenInner = (theme) => css`
  ${innerBps(theme)};
`;
