import React from 'react';
import PropTypes from 'prop-types';

import { CornerButton, iconCss } from './HeaderCornerButton.styles';

const HeaderCornerButton = ({ icon, disabled, onClick, style }) => {
  const styledIcon = React.cloneElement(icon, { style: iconCss });
  return (
    <CornerButton
      style={style}
      onClick={onClick}
      type="button"
      disabled={disabled}
    >
      {styledIcon}
    </CornerButton>
  );
};

HeaderCornerButton.defaultProps = {
  disabled: false,
  style: undefined,
};

HeaderCornerButton.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.func, PropTypes.node, PropTypes.element])
    .isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  style: PropTypes.object,
};

export default HeaderCornerButton;
