import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import ContextManager from '../utils/ContextManager';
import { Creators as tagActionCreators } from '../store/modules/tag';

const mapStateToProps = ({ tag }) => ({
  tag: tag.tag,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setTag: tagActionCreators.setTag,
    },
    dispatch,
  );

const withTag = (WrappedComponent) => {
  const TagHOC = ({ tag, setTag, ...rest }) => {
    const preloadTag = () => {
      if (!tag) {
        const tagInfo = ContextManager.getTag();

        if (tagInfo) {
          setTag(tagInfo);
        }
      }
    };

    useEffect(() => {
      preloadTag();
    }, []);

    return <WrappedComponent tag={tag} {...rest} />;
  };

  TagHOC.propTypes = {
    tag: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
    setTag: PropTypes.func.isRequired,
  };

  return connect(mapStateToProps, mapDispatchToProps)(TagHOC);
};

export default withTag;
