import React from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Error from '../components/Error';

const mapStateToProps = ({ tenantEyepass: { domain } }) => ({
  domain,
});

const NotFoundPage = ({ history, domain }) => {
  const { t } = useTranslation();

  const handleReturn = () => {
    history.push(`/${domain}/carrinho`);
  };

  return <Error onReturn={handleReturn} errorMessage={t('errors:notFound')} />;
};

NotFoundPage.defaultProps = {
  domain: null,
};

NotFoundPage.propTypes = {
  history: PropTypes.object.isRequired,
  domain: PropTypes.string,
};

export default connect(mapStateToProps)(NotFoundPage);
