import React from 'react';
import PropTypes from 'prop-types';
import { withFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import Button from '@bit/eyemobile.core.button';

import Input from '../FormikInput';
import {
  mainForm,
  fieldPadding,
  formAlignment,
  ButtonPadding,
} from '../Form/Form.styles';

const getInitialValues = () => ({
  currentPassword: '',
  newPassword: '',
  newPasswordConfirmation: '',
});

const requiredFormSchema = Yup.object().shape({
  currentPassword: Yup.string().required('formValidation:fieldRequired'),
  newPassword: Yup.string()
    .test(
      'password-has-six-chars',
      'formValidation:invalidPassword',
      function validatePasswordLength(value) {
        return value && value.length >= 6;
      },
    )
    .test(
      'password-has-one-numeral',
      'formValidation:invalidPassword',
      function validatePasswordHasNumeral(value) {
        return value && /\d/.test(value);
      },
    )
    .required('formValidation:fieldRequired'),
  newPasswordConfirmation: Yup.string()
    .test(
      'password-match',
      'formValidation:passwordsDontMatch',
      function validatePasswords(value) {
        const { newPassword } = this.parent;

        return newPassword === value;
      },
    )
    .required('formValidation:fieldRequired'),
});

const passwordInputStyle = {
  letterSpacing: 4,
};

const RequiredDataForm = ({ handleSubmit, isSubmitting, isValid }) => {
  const { t } = useTranslation();

  return (
    <form onSubmit={handleSubmit} css={mainForm}>
      <div css={formAlignment}>
        <div css={fieldPadding}>
          <Input
            name="currentPassword"
            placeholder={t('forms:currentPassword')}
            animateLabel
            type="password"
            hideIcons={false}
            inputStyle={passwordInputStyle}
            variant="bordered"
          />
        </div>
        <div css={fieldPadding}>
          <Input
            name="newPassword"
            placeholder={t('forms:password')}
            animateLabel
            type="password"
            hideIcons={false}
            inputStyle={passwordInputStyle}
            variant="bordered"
          />
        </div>
        <div css={fieldPadding}>
          <Input
            name="newPasswordConfirmation"
            placeholder={t('forms:passwordConfirmation')}
            animateLabel
            type="password"
            hideIcons={false}
            inputStyle={passwordInputStyle}
            variant="bordered"
          />
        </div>
        <ButtonPadding>
          <Button
            isLoading={isSubmitting}
            disabled={!isValid}
            onClick={handleSubmit}
            color="primary"
          >
            {t('formValidation:confirm')}
          </Button>
        </ButtonPadding>
      </div>
    </form>
  );
};

RequiredDataForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  isValid: PropTypes.bool.isRequired,
};

const formikEnhance = withFormik({
  mapPropsToValues: getInitialValues,
  validationSchema: requiredFormSchema,
  handleSubmit: (values, { props, dirty, ...formikBag }) => {
    const { onFormSubmit } = props;
    onFormSubmit(values, formikBag);
  },
  enableReinitialize: true,
});

const enhancedForm = formikEnhance(RequiredDataForm);

enhancedForm.propTypes = {
  onFormSubmit: PropTypes.func.isRequired,
};

export default enhancedForm;
