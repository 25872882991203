import styled from '@emotion/styled';
import { css } from '@emotion/core';

export const ItemContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-left: ${({ theme }) => theme && theme.getSpacing(2, 'px')};
  padding-right: ${({ theme }) => theme && theme.getSpacing(2, 'px')};
  padding-top: ${({ theme }) => theme && theme.getSpacing(0.5, 'px')};
  padding-bottom: ${({ theme }) => theme && theme.getSpacing(2, 'px')};
`;

export const ProductImage = styled.img`
  height: 50px;
  width: 50px;
  border-radius: 25px;
  object-fit: contain;
  border: 2px solid ${({ theme }) => theme && theme.getColor('primary')};
`;

export const ItemDescriptionWrapper = styled.div`
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  padding-left: ${({ theme }) => theme && theme.getSpacing(1, 'px')};
  padding-right: ${({ theme }) => theme && theme.getSpacing(1, 'px')};
`;

export const ComplementWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: ${({ theme }) => theme && theme.getSpacing(0.5, 'px')};
`;

export const ProductItemText = styled.span`
  font-family: ${({ theme }) => theme && theme.getFont('futura')};
  font-size: ${({ theme }) => theme && theme.getFontSize(1, 'px')};
  color: ${({ theme }) => theme && theme.getColor('text')};
  text-align: left;
`;

export const ProductExtraProductText = styled.span`
  font-family: ${({ theme }) => theme && theme.getFont()};
  font-size: ${({ theme }) => theme && theme.getFontSize(1, 'px')};
  color: ${({ theme }) => theme && theme.getColor('text')};
  text-align: left;
  font-style: italic;
  margin-bottom: ${({ theme }) => theme && theme.getSpacing(0.25, 'px')};
`;

export const ProductItemValueText = styled.span`
  font-family: ${({ theme }) => theme.getFont('futura')};
  font-size: ${({ theme }) => theme.getFontSize(1, 'px')};
  color: ${({ theme }) => theme.getColor('placeholder')};
  text-align: left;
`;

export const ProductButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const RemoveButtonWrapper = styled.div`
  margin-left: ${({ theme }) => theme && theme.getSpacing(1, 'px')};
`;

export const quantityText = (theme) => css`
  color: ${theme && theme.getColor('background')};
  font-family: ${theme && theme.getFont('futura')};
  font-size: ${theme && theme.getFontSize(0.6, 'px')};
`;

export const quantityWrapper = (theme) => css`
  position: absolute;
  top: 0;
  right: 0;
  height: 20px;
  min-width: 20px;
  padding-left: 6px;
  padding-right: 6px;
  background-color: ${theme && theme.getColor('primary')};
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
