import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Fade from '@material-ui/core/Fade';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Button from '@bit/eyemobile.core.button';
import Input from '@bit/eyemobile.core.input';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import CheckCircle from '@material-ui/icons/CheckCircle';

import gateway from '../../utils/gateway';

import {
  producerImage,
  fieldLabel,
  fieldValue,
  fieldTitle,
  batchInput,
} from './RhizomProductTrack.styles';

const RhizomProductTrack = ({ productId }) => {
  const { t } = useTranslation();
  const [batchNumber, setBatchNumber] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showTrackingData, setShowTrackingData] = useState(false);
  const [productTrackingData, setProductTrackingData] = useState(null);
  const [productTrackingFailed, setProductTrackingFailed] = useState(false);

  const handleBatchNumberChange = (e) => {
    if (e.target.value.length === 6) {
      fetchTracking(e.target.value);
    }
    if (e.target.value.length <= 6) {
      setBatchNumber(e.target.value);
    }
  };

  const fetchTracking = async (value) => {
    setIsLoading(true);
    setProductTrackingFailed(false);
    setProductTrackingData(null);

    try {
      const response = await gateway
        .get(`/menus/${productId}/tracking`, {
          params: {
            batch: value,
          },
        })
        .then(({ data }) => data);

      setProductTrackingData(response.tracking);
    } catch (err) {
      console.error(err);
      setProductTrackingFailed(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box width="100%">
      <Divider />
      <Box p={2}>
        <Grid container direction="column" spacing={4}>
          <Grid item>
            <Box display="flex" alignItems="center">
              <Typography css={fieldValue}>
                {t('productRhizom:batchNumberInput')}
              </Typography>
              <Box ml={2} width={140} css={batchInput}>
                <Input
                  value={batchNumber}
                  variant="bordered"
                  borderColor="foreground"
                  inputRightIcon={
                    batchNumber.length === 6 ? (
                      <CheckCircle color="inherit" />
                    ) : null
                  }
                  disabled={isLoading}
                  onChange={handleBatchNumberChange}
                />
              </Box>
            </Box>
          </Grid>
          {isLoading && (
            <Grid item>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                <Typography css={fieldValue}>
                  {t('productRhizom:loading')}
                </Typography>
              </Box>
            </Grid>
          )}
          {!isLoading && productTrackingFailed && (
            <Grid item>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                <Typography css={fieldValue}>
                  {t('productRhizom:trackingNotAvailable')}
                </Typography>
              </Box>
            </Grid>
          )}
          {!isLoading && !productTrackingFailed && productTrackingData && (
            <Grid item>
              <Grid container direction="column" spacing={4}>
                <Grid item>
                  <Box display="flex" alignItems="center">
                    <Box mr={2}>
                      <Avatar css={producerImage} />
                    </Box>
                    <Box display="flex" flexDirection="column">
                      <Typography css={fieldTitle}>
                        {productTrackingData.mainProducer.producerName}
                      </Typography>
                      <Typography css={fieldValue}>
                        {t('productRhizom:cocoaProducer')}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item>
                  <Button
                    color="foreground"
                    endIcon={showTrackingData ? <ExpandLess /> : <ExpandMore />}
                    block
                    onClick={() => setShowTrackingData((prev) => !prev)}
                  >
                    {t('productRhizom:moreInfo')}
                  </Button>
                </Grid>
                {showTrackingData && (
                  <Grid item>
                    <Fade in>
                      <Grid container direction="column" spacing={2}>
                        <Grid item container direction="row" spacing={1}>
                          <Grid item xs={4}>
                            <Typography css={fieldLabel}>
                              {t('productRhizom:producerName')}
                            </Typography>
                          </Grid>
                          <Grid item xs={8}>
                            <Typography css={fieldValue}>
                              {productTrackingData.mainProducer.producerName}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid item container direction="row" spacing={1}>
                          <Grid item xs={4}>
                            <Typography css={fieldLabel}>
                              {t('productRhizom:producerFarm')}
                            </Typography>
                          </Grid>
                          <Grid item xs={8}>
                            <Typography css={fieldValue}>
                              {productTrackingData.mainProducer.farmName}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid item container direction="row" spacing={1}>
                          <Grid item xs={4}>
                            <Typography css={fieldLabel}>
                              {t('productRhizom:producerCity')}
                            </Typography>
                          </Grid>
                          <Grid item xs={8}>
                            <Typography css={fieldValue}>
                              {productTrackingData.mainProducer.city} /{' '}
                              {productTrackingData.mainProducer.state}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid item container direction="row" spacing={1}>
                          <Grid item xs={4}>
                            <Typography css={fieldLabel}>
                              {t('productRhizom:preservedForest')}
                            </Typography>
                          </Grid>
                          <Grid item xs={8}>
                            <Typography css={fieldValue}>
                              {productTrackingData.mainProducer.preservedForest}{' '}
                              {t('productRhizom:hectars')}
                            </Typography>
                          </Grid>
                        </Grid>
                        {!!productTrackingData.mainProducer.certifications && (
                          <Grid item container direction="row" spacing={1}>
                            <Grid item xs={4}>
                              <Typography css={fieldLabel}>
                                {t('productRhizom:certifications')}
                              </Typography>
                            </Grid>
                            <Grid item xs={8}>
                              <Typography css={fieldValue}>
                                {
                                  productTrackingData.mainProducer
                                    .certifications
                                }
                              </Typography>
                            </Grid>
                          </Grid>
                        )}
                        <Grid item>
                          <Typography css={fieldLabel}>
                            {t('productRhizom:awardRate', {
                              rate: productTrackingData.mainProducer.awardRate,
                            })}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Fade>
                  </Grid>
                )}
              </Grid>
            </Grid>
          )}
        </Grid>
      </Box>
    </Box>
  );
};

RhizomProductTrack.propTypes = {
  productId: PropTypes.string.isRequired,
};

export default RhizomProductTrack;
