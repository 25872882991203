const mapCashbackLoyalty = (loyalty) => {
  const {
    id,
    name,
    extra,
    max_cashback_percentage: maxCashbackPercentage,
  } = loyalty;

  const availableCashback = extra.balance;

  return {
    id,
    name,
    availableCashback,
    maxCashbackPercentage: maxCashbackPercentage || 100,
  };
};

export default mapCashbackLoyalty;
