import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import packageJson from '../../../package.json';

const isDevEnv = process.env.NODE_ENV === 'development';

const semverMatch = (versionA, versionB) => {
  if (!versionA) return false;

  return versionA === versionB;
};

const CacheBuster = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [isLatestVersion, setIsLatestVersion] = useState(true);

  const refreshCacheAndReload = async () => {
    if (caches) {
      // Service worker cache should be cleared with caches.delete()

      const cacheKeys = await caches.keys();

      cacheKeys.forEach((names) => {
        names.forEach((name) => {
          caches.delete(name);
        });
      });
    }

    // Delete browser cache and hard reload
    window.location.reload(true);
  };

  const fetchMeta = async () => {
    try {
      setLoading(true);
      const response = await fetch(`/meta.json?${new Date().getTime()}`, {
        cache: 'no-cache',
      });

      const metaJson = await response.json().catch(console.error);

      const latestVersion = metaJson?.version;
      const currentVersion = packageJson.version;

      const shouldForceRefresh = !semverMatch(latestVersion, currentVersion);

      if (shouldForceRefresh) {
        setIsLatestVersion(false);
      } else {
        setIsLatestVersion(true);
      }

      setLoading(false);
    } catch (e) {
      console.error(e);
      setIsLatestVersion(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isDevEnv) {
      setIsLatestVersion(true);
      setLoading(false);
    } else {
      fetchMeta();
    }
  }, []);

  return children({
    loading,
    isLatestVersion,
    refreshCacheAndReload,
  });
};

CacheBuster.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
    PropTypes.array,
  ]).isRequired,
};

export default CacheBuster;
