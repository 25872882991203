const fetchGeolocation = async () =>
  new Promise((resolve, reject) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          resolve(position);
        },
        (error) => {
          reject(error);
        },
      );
    } else {
      reject(new Error('Geolocalization not available'));
    }
  });

export default fetchGeolocation;
