import dayjs from './dayjs';

const onlyUnique = (value, index, self) => self.indexOf(value) === index;

const formatToDate = (time) => dayjs(time).format('DD-MM-YYYY');

const mapGroupedOrders = (day, orderList) => {
  return {
    day,
    orders: orderList.filter(({ time }) => formatToDate(time) === day),
  };
};

const groupOrderHistoryByDay = (orderList) => {
  const filteredOrderList = orderList.filter(
    ({ status }) => status !== 'WAITING_PAYMENT',
  );

  const days = filteredOrderList
    .map(({ time }) => formatToDate(time))
    .filter(onlyUnique);

  return days.map((day) => mapGroupedOrders(day, filteredOrderList));
};

export default groupOrderHistoryByDay;
