import React from 'react';
import PropTypes from 'prop-types';
import PaymentIcon from 'react-payment-icons';

import pixLogo from '../../assets/pix-logo.svg';
import hipercardLogo from '../../assets/hipercard-logo.png';
import creditCardIcon from '../../assets/credit_card.svg';

import { FlagImg } from './CreditCardFlag.styles';

const CreditCardFlag = ({ brand, size }) => {
  if (brand === 'pix') {
    return (
      <FlagImg
        src={pixLogo}
        alt="flag"
        size={size}
        height={`${size}`}
        width={`${size}`}
      />
    );
  }

  if (brand === 'hipercard') {
    return (
      <FlagImg
        src={hipercardLogo}
        alt="flag"
        size={size}
        height={`${size}`}
        width={`${size}`}
      />
    );
  }

  if (brand) {
    return (
      <PaymentIcon
        id={brand}
        icon={brand}
        style={{ width: size, height: size }}
      />
    );
  }

  return (
    <FlagImg
      src={creditCardIcon}
      alt="flag"
      size={size}
      height={`${size}`}
      width={`${size}`}
    />
  );
};

CreditCardFlag.defaultProps = {
  size: 30,
};

CreditCardFlag.propTypes = {
  brand: PropTypes.string.isRequired,
  size: PropTypes.number,
};

export default CreditCardFlag;
