import { css } from '@emotion/core';

export const wrapper = (theme) => css`
  width: 100%;
  height: 100%;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  flex-direction: column;
  background-color: #ffffff;
`;
