import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'emotion-theming';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import localizePrice from '../../utils/localizePrice';
import { smallMeasureTypesMap } from '../../utils/constants';
import getProductImageSrc from '../../utils/getProductImageSrc';
import PopMenu from '../PopMenu';
import ProductImage from '../ProductImage';
import {
  ItemContainer,
  ItemDescriptionWrapper,
  ProductItemText,
  ProductItemValueText,
  quantityWrapper,
  quantityText,
} from '../ProductReviewItem/ProductReviewItem.styles';

import {
  comboItemProductCss,
  comboItemProductImageCss,
  comboItemDescription,
} from './ComboReviewItem.styles';

const ComboReviewItem = ({
  comboImgSrc,
  comboName,
  comboPrice,
  quantityDescription,
  comboProductItems,
  quantity,
  onRemove,
  onEdit,
  editable,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [anchorMenu, setAnchorMenu] = useState(null);
  const open = !!anchorMenu;

  const renderComboItems = useMemo(
    () =>
      comboProductItems.map((comboItem) => {
        const {
          id,
          itemName,
          itemImage,
          itemQuantity,
          itemMeasure,
          itemPrice,
        } = comboItem;

        const measurePriceText =
          itemMeasure !== 'UNIT'
            ? localizePrice(itemPrice / 10)
            : localizePrice(itemPrice);
        const comboItemPriceText =
          itemMeasure !== 'UNIT'
            ? `R$${measurePriceText}/${smallMeasureTypesMap[itemMeasure]}`
            : `R$${measurePriceText}/un`;

        const adaptedComboItemQuantity =
          itemMeasure !== 'UNIT'
            ? `${itemQuantity * 100}${smallMeasureTypesMap[itemMeasure]} `
            : `${itemQuantity}X `;

        return (
          <div id={`combo-item-${id}`} css={comboItemProductCss}>
            <img
              src={getProductImageSrc(itemImage)}
              alt={`combo-item-${itemName}`}
              css={comboItemProductImageCss}
            />
            <div style={{ flex: 1 }}>
              <span css={comboItemDescription}>
                <span style={{ fontWeight: 'bold' }}>
                  {adaptedComboItemQuantity}
                </span>
                {itemName}
              </span>
            </div>
            <span
              css={comboItemDescription}
              style={{
                fontWeight: 'bold',
                whiteSpace: 'nowrap',
                marginLeft: '4px',
              }}
            >
              {comboItemPriceText}
            </span>
          </div>
        );
      }),
    [comboProductItems],
  );

  const handleClose = () => {
    setAnchorMenu(null);
  };

  const handleRemoveItem = () => {
    handleClose();
    onRemove();
  };

  const handleEditItem = () => {
    handleClose();
    onEdit();
  };

  const handleClick = (event) => {
    setAnchorMenu(event.currentTarget);
  };

  return (
    <>
      <ItemContainer>
        <div style={{ position: 'relative' }}>
          <ProductImage
            imgSrc={comboImgSrc}
            alt={`combo-img-${comboName}`}
            size="50px"
          />
          <div css={quantityWrapper}>
            <span css={quantityText}>{quantityDescription}</span>
          </div>
        </div>
        <ItemDescriptionWrapper>
          <ProductItemText>{comboName}</ProductItemText>
          <ProductItemText style={{ marginTop: '3px', marginLeft: '3px' }}>
            {t('productOrder:comboItems')}
          </ProductItemText>
          <ItemDescriptionWrapper style={{ paddingTop: '5px' }}>
            {renderComboItems}
          </ItemDescriptionWrapper>
          <ProductItemValueText>{comboPrice}</ProductItemValueText>
        </ItemDescriptionWrapper>
        {editable && (
          <>
            <IconButton
              aria-label="more"
              aria-controls="long-menu"
              aria-haspopup="true"
              onClick={handleClick}
            >
              <MoreVertIcon />
            </IconButton>
            <PopMenu
              id="long-menu"
              anchorEl={anchorMenu}
              open={open}
              onClose={handleClose}
            >
              <MenuItem
                key="edit"
                onClick={handleEditItem}
                style={{
                  color: theme.getColor('background'),
                }}
              >
                {t('orderReview:edit')}
              </MenuItem>
              <MenuItem
                key="remove"
                onClick={handleRemoveItem}
                style={{
                  color: theme.getColor('background'),
                }}
              >
                {t('orderReview:remove')}
              </MenuItem>
            </PopMenu>
          </>
        )}
      </ItemContainer>
    </>
  );
};

ComboReviewItem.defaultProps = {
  comboImgSrc: null,
  quantityDescription: null,
  comboProductItems: [],
  editable: true,
};

ComboReviewItem.propTypes = {
  comboImgSrc: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  comboName: PropTypes.string.isRequired,
  comboPrice: PropTypes.string.isRequired,
  quantityDescription: PropTypes.string,
  quantity: PropTypes.number.isRequired,
  comboProductItems: PropTypes.array,
  onRemove: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  editable: PropTypes.bool,
};

export default ComboReviewItem;
