import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import { useTranslation } from 'react-i18next';

import shoppingBasket from '../../assets/shopping_basket.svg';
import {
  itemContainer,
  itemWrapperCss,
  descriptionText,
  productImageCss,
} from '../HistoryProductItem/HistoryProductItem.styles';
import getProductImageSrc from '../../utils/getProductImageSrc';
import { smallMeasureTypesMap } from '../../utils/constants';
import { ReactComponent as ArrowDown } from '../../assets/arrow_down.svg';
import localizePrice from '../../utils/localizePrice';

import { arrowWrapper } from './HistoryComboItem.styles';
import HistoryComboProduct from './HistoryComboProduct';

const HistoryComboItem = ({
  comboImgSrc,
  comboName,
  comboQuantity,
  comboQuantityText,
  comboItems,
  totalPrice,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [fallbackImg, setFallbackImg] = useState(null);

  const toggleOpen = () => setOpen((prev) => !prev);

  const renderComboItem = (comboItem) => {
    const { id, itemName, itemImage, itemQuantity, itemMeasure, itemPrice } =
      comboItem;
    const measurePriceText =
      itemMeasure !== 'UNIT'
        ? localizePrice(itemPrice / 10)
        : localizePrice(itemPrice);
    const comboItemPriceText =
      itemMeasure !== 'UNIT'
        ? `R$${measurePriceText}/${smallMeasureTypesMap[itemMeasure]}`
        : `R$${measurePriceText}/un`;

    const adaptedComboItemQuantity =
      itemMeasure !== 'UNIT'
        ? `${itemQuantity * 100}${smallMeasureTypesMap[itemMeasure]} `
        : `${itemQuantity}X `;

    return (
      <HistoryComboProduct
        key={`combo-item-${id}`}
        comboImgSrc={getProductImageSrc(itemImage)}
        comboItemQuantity={adaptedComboItemQuantity}
        comboItemName={itemName}
        comboItemPrice={comboItemPriceText}
      />
    );
  };

  const renderComboItems = useMemo(() => {
    return (
      !!open && (
        <>
          <span css={descriptionText}>
            {t('orderHistory:thisComboContains')}
          </span>
          {comboItems.map(renderComboItem)}
        </>
      )
    );
  }, [comboItems, open]);

  const onImgError = () => setFallbackImg(shoppingBasket);

  return (
    <>
      <div
        css={itemContainer}
        style={{ cursor: 'pointer' }}
        onClick={toggleOpen}
        onKeyDown={toggleOpen}
        role="presentation"
      >
        <div style={{ position: 'relative' }}>
          <img
            src={fallbackImg || comboImgSrc || shoppingBasket}
            alt={`img-${comboName}`}
            onError={onImgError}
            resizemode="contain"
            css={productImageCss}
          />
          <div css={arrowWrapper}>
            <ArrowDown
              style={{
                transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
                color: theme.getColor('background'),
              }}
            />
          </div>
        </div>
        <div css={itemWrapperCss}>
          <span css={descriptionText}>{comboName}</span>
          {!!comboQuantityText && (
            <span css={descriptionText}>{comboQuantityText}</span>
          )}
          <span css={descriptionText} style={{ fontWeight: 'bold' }}>
            {totalPrice}
          </span>
        </div>
      </div>
      {renderComboItems}
    </>
  );
};

HistoryComboItem.defaultProps = {
  comboQuantityText: null,
  comboImgSrc: null,
  comboItems: [],
};

HistoryComboItem.propTypes = {
  comboImgSrc: PropTypes.string,
  comboName: PropTypes.string.isRequired,
  comboQuantity: PropTypes.number.isRequired,
  comboQuantityText: PropTypes.string,
  totalPrice: PropTypes.string.isRequired,
  comboItems: PropTypes.array,
};

export default HistoryComboItem;
