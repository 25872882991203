import * as Yup from 'yup';
import {
  creditCardNumberSchema,
  creditCardExpirationDateSchema,
  creditCardCvvSchema,
} from '../../utils/schemas';

const creditCardFormSchema = Yup.object().shape({
  creditCardNumber: creditCardNumberSchema,
  creditCardHolderName: Yup.string().required('formValidation:fieldRequired'),
  creditCardExpiration: creditCardExpirationDateSchema,
  creditCardCvv: creditCardCvvSchema,
  creditCardTokenize: Yup.bool(),
});

export default creditCardFormSchema;
