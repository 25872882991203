import { css } from '@emotion/core';

export const carouselCss = (theme) => css`
  .carousel {
    .slide {
      background: transparent;
    }
    .slider-wrapper {
      padding-bottom: 32px;
    }
    .control-dots {
      .dot {
        opacity: 1;
        box-shadow: none;
        outline: none;
        border: 1px solid ${theme && theme.getColor('primary')};
        transition: transform 200ms, background-color 200ms;
        background-color: white;

        &.selected {
          transform: scale(1.4);
          background-color: ${theme && theme.getColor('primary')};
        }
      }
    }
  }
`;
