import { css } from '@emotion/core';

export const backgroundCss = (theme) => css`
  width: 100%;
  height: 100%;
  background-color: ${theme && theme.getColor('foreground')};
`;

export const wrapperCss = (theme) => css`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: ${theme && theme.getSpacing(4, 'px')};
  padding-right: ${theme && theme.getSpacing(4, 'px')};
  padding-top: ${theme && theme.getSpacing(6, 'px')};
  padding-bottom: ${theme && theme.getSpacing(6, 'px')};
`;

export const errorTextCss = (theme) => css`
  margin: ${theme && theme.getSpacing(2, 'px')};
  color: ${theme && theme.getColor('uiText')};
  font-size: ${theme && theme.getFontSize(1, 'px')};
  font-family: ${theme && theme.getFont()};
  text-align: center;
`;

export const errorImgCss = (theme) => css`
  width: 120px;
  height: 120px;
  object-fit: contain;
  margin: ${theme && theme.getSpacing(2, 'px')};
`;
