import mapStore from './mapStore';
import {
  measureTypes,
  orderStatusTypes,
  deliveryTypes,
  packageDeliveryStatus,
} from './constants';
import getProductImageSrc from './getProductImageSrc';
import sumComplementTotal from './sumComplementTotal';

const rebuildComplementGroups = (transactionComplement) => {
  const groups = Object.values(
    transactionComplement.reduce((initObj, transactionItemComplement) => {
      const obj = { ...initObj };
      if (
        obj[
          transactionItemComplement?.complement_group?.id ||
            transactionItemComplement?.product_complement_group?.id
        ] === undefined
      ) {
        const {
          product_complement_group: pComplementGroup,
          complement_group: complementGroup,
        } = transactionItemComplement;
        const usedComplementGroup = pComplementGroup || complementGroup;
        const available =
          usedComplementGroup &&
          (usedComplementGroup.available_for_sale ||
            usedComplementGroup.status);

        obj[usedComplementGroup?.id] = {
          id: usedComplementGroup?.id,
          name: transactionItemComplement.product_complement_group_name,
          min: usedComplementGroup?.min,
          max: usedComplementGroup?.max,
          available,
          complementItems: [
            {
              id: transactionItemComplement.product_complement_id,
              name:
                transactionItemComplement?.complement?.name ||
                transactionItemComplement?.complement?.product?.name ||
                transactionItemComplement.product_complement_name,
              price: transactionItemComplement.price,
              quantity: transactionItemComplement.quantity,
            },
          ],
        };
        return obj;
      }
      obj[
        transactionItemComplement?.complement_group?.id ||
          transactionItemComplement?.product_complement_group?.id
      ].complementItems.push({
        id: transactionItemComplement.product_complement_id,
        name:
          transactionItemComplement?.complement?.name ||
          transactionItemComplement?.complement?.product?.name ||
          transactionItemComplement.product_complement_name,
        price: transactionItemComplement.price,
        quantity: transactionItemComplement.quantity,
      });
      return obj;
    }, {}),
  );

  return groups;
};

const mapComboProductItem = (comboItem, productQuantity) => ({
  id: comboItem.product.id,
  itemName: comboItem.product_name,
  itemImage: comboItem.product?.image,
  itemPrice: comboItem.price,
  itemQuantity: Math.round(comboItem.quantity / productQuantity),
  itemMeasure: measureTypes[comboItem.measure] || 'UNIT',
  itemDescription: comboItem.product.description,
  itemTotalPrice: comboItem.total,
  itemComment: comboItem.comment,
});

const mapTransactionItems = (transacationItem) => {
  const {
    id,
    product_name: name,
    quantity,
    measure,
    total,
    product,
    price,
    transaction_item_complements: transactionComplements,
  } = transacationItem;

  const image = getProductImageSrc(product?.image);
  const productId = product?.id;
  const productImages = (product && product.product_images) || [];
  const mappedMeasure = measureTypes[measure] || 'UNIT';
  const complementGroups = rebuildComplementGroups(
    transactionComplements,
    quantity,
  );

  const complementsTotal = sumComplementTotal(complementGroups);

  return {
    id,
    productId,
    price,
    name,
    image,
    mainImageKey: product?.image,
    productImages,
    rigidStockEnabled: false,
    stockBalance: undefined,
    sku: undefined,
    measure: mappedMeasure,
    totalPrice: total,
    takeoutEnabled: false,
    amount: mappedMeasure === 'UNIT' ? quantity : quantity * 10,
    isHistoryProduct: true,
    comboProductItems: [],
    complementGroups,
    complementsTotal,
  };
};

const mapTransactionCombo = (transactionCombo) => {
  const {
    id,
    product_name: name,
    quantity,
    total,
    product,
    price,
    transaction_items: transactionItems,
  } = transactionCombo;

  const image = getProductImageSrc(product?.image);
  const productId = product?.id;
  const productImages = (product && product.product_images) || [];
  const comboProductItems = transactionItems.map((item) =>
    mapComboProductItem(item, quantity),
  );

  return {
    id,
    productId,
    price,
    name,
    image,
    mainImageKey: product?.image,
    productImages,
    description: product?.description,
    rigidStockEnabled: false,
    stockBalance: undefined,
    sku: undefined,
    measure: 'UNIT',
    totalPrice: total,
    takeoutEnabled: false,
    amount: quantity,
    isHistoryProduct: true,
    complementGroups: [],
    complementsTotal: 0,
    comboProductItems,
  };
};

const adaptOrderAddress = (order) => {
  const {
    address,
    address2,
    city,
    district,
    state,
    number: addressNumber,
  } = order;

  return {
    addressStreetNumber: `${address}, ${addressNumber}`,
    city,
    state,
    district,
    complement: address2,
  };
};

const adaptPayment = (transactionPay) => {
  const {
    id,
    tef_brand: tefBrand,
    tef_installments: tefInstallments,
    status,
    provider_last_digits: lastDigits,
    tef,
    total,
    pay_type_name: payTypeName,
    pay_type_id: payTypeId,
  } = transactionPay;

  return {
    id,
    cardFlag: tefBrand,
    installments: tefInstallments,
    status,
    number: lastDigits || '****',
    tef,
    subtotal: total,
    payTypeName,
    payTypeId,
  };
};

const adaptDeliveryPackage = (deliveryPackage) => {
  if (!deliveryPackage) {
    return null;
  }

  const {
    id,
    status: deliveryPackageStatus,
    provider_status: statusMessage,
    tracking_url: trackingUrl,
  } = deliveryPackage;

  const status = packageDeliveryStatus[deliveryPackageStatus];

  return {
    id,
    status,
    statusMessage,
    trackingUrl,
  };
};

const mapOrderHistory = (transaction) => {
  const {
    id,
    time,
    comment,
    event_point: eventPoint,
    subtotal,
    delivery_fee: deliveryFee,
    total,
    discount,
    transaction_items: transactionItems,
    transaction_combos: transactionCombos,
    order,
    transaction_pays: transactionPays,
  } = transaction;

  const {
    order_items: orderItems,
    delivery_type: deliveryType,
    delivery_package: deliveryPackage,
    schedule_starts_at: scheduleStartsAt,
    schedule_ends_at: scheduleEndsAt,
    delivery_detail: deliveryDetail,
    id: orderId,
  } = order;

  const orderStatusCode = orderItems[0].status;

  const status = orderStatusTypes[orderStatusCode];

  const mappedDeliveryType = deliveryTypes[deliveryType];

  const schedule = scheduleStartsAt
    ? {
        start: scheduleStartsAt,
        end: scheduleEndsAt,
      }
    : null;

  return {
    id,
    comment,
    priceTotal: total,
    priceSubtotal: subtotal,
    discount,
    deliveryFee,
    time,
    orderId,
    store: mapStore(eventPoint),
    transactionProducts: transactionItems
      ? transactionItems.map(mapTransactionItems)
      : [],
    transactionCombos: transactionCombos
      ? transactionCombos.map(mapTransactionCombo)
      : [],
    deliveryType: mappedDeliveryType,
    status,
    deliveryAddress:
      mappedDeliveryType === 'ADDRESS' ? adaptOrderAddress(order) : null,
    payments: transactionPays ? transactionPays.map(adaptPayment) : [],
    deliveryPackage: adaptDeliveryPackage(deliveryPackage),
    schedule,
    eta: order.eta,
    deliveryDetail,
  };
};

export default mapOrderHistory;
