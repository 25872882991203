import React from 'react';
import PropTypes from 'prop-types';

import localizePrice from '../../utils/localizePrice';
import {
  FooterContainer,
  LeftWrapper,
  RightWrapper,
  MiddleWrapper,
  MiddleText,
  countCircle,
  countText,
  PriceText,
} from './CartFooter.styles';
import Loading from '../Loading';

const CartFooter = ({
  total,
  onButtonClick,
  disabled,
  isLoading,
  middleText,
  cartCount,
  visible,
  fullLoad,
}) => {
  const handleClick = () => {
    if (!disabled && !isLoading) {
      onButtonClick();
    }
  };

  if (!visible) {
    return true;
  }

  return (
    <FooterContainer
      role="button"
      disabled={disabled || isLoading}
      onClick={handleClick}
      onKeyDown={handleClick}
    >
      {fullLoad ? (
        <MiddleWrapper style={{ width: '100%' }}>
          <Loading isSmall />
        </MiddleWrapper>
      ) : (
        <>
          <LeftWrapper>
            <div css={countCircle}>
              <span css={countText}>{cartCount}</span>
            </div>
          </LeftWrapper>
          <MiddleWrapper>
            <MiddleText>{middleText}</MiddleText>
          </MiddleWrapper>
          <RightWrapper>
            <PriceText>{`R$ ${localizePrice(total)}`}</PriceText>
          </RightWrapper>
        </>
      )}
    </FooterContainer>
  );
};

CartFooter.defaultProps = {
  disabled: false,
  isLoading: false,
  visible: true,
  fullLoad: false,
};

CartFooter.propTypes = {
  total: PropTypes.number.isRequired,
  onButtonClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  middleText: PropTypes.string.isRequired,
  cartCount: PropTypes.number.isRequired,
  visible: PropTypes.bool,
  fullLoad: PropTypes.bool,
};

export default CartFooter;
