import firebase from 'firebase/app';
import 'firebase/messaging';

const firebaseConfig = {
  apiKey: 'AIzaSyBO_wOAp73B7E9i2ENyWTqW0tb9ibunQTM',
  authDomain: 'pwa-ecommerce-279820.firebaseapp.com',
  projectId: 'pwa-ecommerce-279820',
  storageBucket: 'pwa-ecommerce-279820.appspot.com',
  messagingSenderId: '1058205609436',
  appId: '1:1058205609436:web:46290abba7a0190e4ab319',
};

let messaging = null;

export const initializeApp = () => {
  if (firebase.messaging.isSupported()) {
    firebase.initializeApp(firebaseConfig);
    messaging = firebase.messaging();
  }
};

export const getToken = async () => {
  if (firebase.messaging.isSupported()) {
    try {
      const token = await messaging.getToken();

      if (token) {
        console.log('Registration token available.', token); // eslint-disable-line
        return token;
      }

      console.log('No registration token available.'); // eslint-disable-line
      return null;
    } catch (err) {
      console.error(err);
      return null;
    }
  }
};

export const getServiceWorkerToken = async (serviceWorkerRegistration) => {
  if (firebase.messaging.isSupported()) {
    try {
      const token = await messaging.getToken({
        vapidKey:
          'BNGZv5JVwQ_tn6WfUtP9GLfKSQe9AA7mm_OrrjSe7VfE1VxViwX5i6F8tcGzoA7ywI6SGvsM8Z4muz--_2wAJeE',
        serviceWorkerRegistration,
      });

      if (token) {
        console.log('SW registration token available.', token); // eslint-disable-line
      } else {
        console.log('No SW registration token available.'); // eslint-disable-line
      }
    } catch (err) {
      console.error(err);
    }
  }
};

export const isPushNotificationSupported = () => {
  return firebase.messaging.isSupported();
};

export const setOnBackgroundMessageListener = (handler) => {
  if (firebase.messaging.isSupported()) {
    messaging.onBackgroundMessage((nextOrObserver, error, completed) => {
      // eslint-disable-next-line
      console.log(
        'Received background message ',
        nextOrObserver,
        error,
        completed,
      );

      const notificationTitle = nextOrObserver.notification.title;
      const notificationOptions = {
        body: nextOrObserver.notification.body,
      };

      messaging.registration.showNotification(
        notificationTitle,
        notificationOptions,
      );

      handler(nextOrObserver, error, completed);
    });
  }
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    messaging.onMessage((payload) => {
      resolve(payload);
    });
  });
