import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';

import { Point, Progress } from './LoyaltyItem.styles';

const PointProgress = ({ progress, total, separators, id }) => {
  const theme = useTheme();

  const renderStep = (item, index) => (
    <Point
      key={`${id}-step-${index}`}
      style={{
        backgroundColor: item ? theme.getColor('success') : 'transparent',
      }}
      width={100 / total}
    />
  );

  const normalizeProgress = progress > total ? total : progress;
  const steps = new Array(total).fill(true).fill(false, normalizeProgress);

  return <Progress separators={separators}>{steps.map(renderStep)}</Progress>;
};

PointProgress.defaultProps = {
  progress: 0,
  total: 1,
  separators: true,
};

PointProgress.propTypes = {
  progress: PropTypes.number,
  total: PropTypes.number,
  separators: PropTypes.bool,
  id: PropTypes.string.isRequired,
};

export default PointProgress;
