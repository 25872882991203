import React from 'react';
import PropTypes from 'prop-types';
import { withFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import Input from '@bit/eyemobile.core.input';
import Button from '@bit/eyemobile.core.button';

import {
  mainForm,
  formAlignment,
  fieldPadding,
  ButtonPadding,
} from '../Form/Form.styles';
import { cepMask, ufMask } from '../../utils/masks';
import fetchCepData from '../../utils/fetchCepData';
import showToast from '../../utils/showToast';

const optionalFormSchema = Yup.object()
  .shape({
    addressZipcode: Yup.string(),
    addressStreet: Yup.string(),
    addressNumber: Yup.string(),
    addressComplement: Yup.string(),
    addressDistrict: Yup.string(),
    addressCity: Yup.string(),
    addressState: Yup.string(),
  })
  .test(
    'address-filled',
    'Se um campo de endereço for preenchido, todos devem ser preenchidos',
    function verifyForm(values) {
      const {
        addressZipcode,
        addressStreet,
        addressNumber,
        addressComplement,
        addressDistrict,
        addressCity,
        addressState,
      } = values;

      const filled = Boolean(
        addressZipcode ||
          addressStreet ||
          addressNumber ||
          addressComplement ||
          addressDistrict ||
          addressCity ||
          addressState,
      );

      if (filled) {
        return Yup.object()
          .shape({
            addressState: Yup.string().required('Estado é obrigatório'),
            addressCity: Yup.string().required('Cidade é obrigatória'),
            addressDistrict: Yup.string().required('Bairro é obrigatório'),
            addressComplement: Yup.string(),
            addressNumber: Yup.string().required('Número é obrigatório'),
            addressStreet: Yup.string().required('Rua é obrigatória'),

            addressZipcode: Yup.string().required('CEP é obrigatório'),
          })
          .validate(values);
      }

      return true;
    },
  );

const OptionalDataForm = ({
  values,
  touched,
  handleChange,
  handleBlur,
  handleSubmit,
  setValues,
  setFieldError,
  isSubmitting,
  errors,
  isValid,
  onCancel,
}) => {
  const { t } = useTranslation();

  const handleZipCodeBlur = async (e) => {
    handleBlur(e);
    try {
      const addressData = await fetchCepData(values.addressZipcode);

      if (addressData) {
        const { district, city, state, street } = addressData;

        setValues({
          ...values,
          addressDistrict: district,
          addressCity: city,
          addressState: state,
          addressStreet: street,
        });
      }
    } catch (error) {
      showToast(error.message, 'error');
      setFieldError('addressZipcode', error.message);
    }
  };

  return (
    <form onSubmit={handleSubmit} css={mainForm}>
      <div css={formAlignment}>
        <div css={fieldPadding}>
          <Input
            value={values.addressZipcode}
            touched={touched.addressZipcode}
            isValid={!errors.addressZipcode}
            error={errors.addressZipcode && t(errors.addressZipcode)}
            placeholder={t('forms:zipcode')}
            animateLabel
            mask={cepMask}
            name="addressZipcode"
            onBlur={handleZipCodeBlur}
            onChange={handleChange}
            inputMode="numeric"
            pattern="[0-9]*"
            variant="bordered"
            borderColor="primary"
            labelColor="primary"
          />
        </div>
        <div css={fieldPadding}>
          <Input
            value={values.addressStreet}
            touched={touched.addressStreet}
            isValid={!errors.addressStreet}
            error={t(errors.addressStreet)}
            placeholder={t('forms:street')}
            animateLabel
            name="addressStreet"
            onBlur={handleBlur}
            onChange={handleChange}
            variant="bordered"
            borderColor="primary"
            labelColor="primary"
          />
        </div>
        <div css={fieldPadding}>
          <Input
            value={values.addressNumber}
            touched={touched.addressNumber}
            isValid={!errors.addressNumber}
            error={t(errors.addressNumber)}
            placeholder={t('forms:addressNumber')}
            animateLabel
            name="addressNumber"
            onBlur={handleBlur}
            onChange={handleChange}
            inputMode="numeric"
            pattern="[0-9]*"
            variant="bordered"
            borderColor="primary"
            labelColor="primary"
          />
        </div>
        <div css={fieldPadding}>
          <Input
            value={values.addressComplement}
            touched={touched.addressComplement}
            isValid={!errors.addressComplement}
            error={t(errors.addressComplement)}
            placeholder={t('forms:addressComplement')}
            animateLabel
            name="addressComplement"
            onBlur={handleBlur}
            onChange={handleChange}
            variant="bordered"
            borderColor="primary"
            labelColor="primary"
          />
        </div>
        <div css={fieldPadding}>
          <Input
            value={values.addressDistrict}
            touched={touched.addressDistrict}
            isValid={!errors.addressDistrict}
            error={t(errors.addressDistrict)}
            placeholder={t('forms:addressDistrict')}
            animateLabel
            name="addressDistrict"
            onBlur={handleBlur}
            onChange={handleChange}
            variant="bordered"
            borderColor="primary"
            labelColor="primary"
          />
        </div>
        <div css={fieldPadding}>
          <Input
            value={values.addressCity}
            touched={touched.addressCity}
            isValid={!errors.addressCity}
            error={t(errors.addressCity)}
            placeholder={t('forms:city')}
            animateLabel
            name="addressCity"
            onBlur={handleBlur}
            onChange={handleChange}
            variant="bordered"
            borderColor="primary"
            labelColor="primary"
          />
        </div>
        <div css={fieldPadding}>
          <Input
            value={values.addressState}
            touched={touched.addressState}
            isValid={!errors.addressState}
            error={t(errors.addressState)}
            placeholder={t('forms:state')}
            mask={ufMask}
            animateLabel
            name="addressState"
            onBlur={handleBlur}
            onChange={handleChange}
            variant="bordered"
            borderColor="primary"
            labelColor="primary"
          />
        </div>
        <ButtonPadding hasMultiButtons={!!onCancel}>
          {onCancel && (
            <Button onClick={onCancel} color="error" disabled={isSubmitting}>
              {t('formValidation:cancel')}
            </Button>
          )}
          <Button
            isLoading={isSubmitting}
            onClick={handleSubmit}
            color="primary"
            disabled={!isValid || isSubmitting}
          >
            {t('forms:confirm')}
          </Button>
        </ButtonPadding>
      </div>
    </form>
  );
};

const formikEnhance = withFormik({
  mapPropsToValues: ({ initialValues }) => ({
    addressZipcode: initialValues.addressZipcode || '',
    addressStreet: initialValues.addressStreet || '',
    addressNumber: initialValues.addressNumber || '',
    addressComplement: initialValues.addressComplement || '',
    addressDistrict: initialValues.addressDistrict || '',
    addressCity: initialValues.addressCity || '',
    addressState: initialValues.addressState || '',
  }),
  validationSchema: optionalFormSchema,
  handleSubmit: (values, { props, dirty, ...formikBag }) => {
    const { onFormSubmit } = props;
    onFormSubmit(values, formikBag);
  },
  enableReinitialize: true,
});

OptionalDataForm.defaultProps = {
  onCancel: null,
};

OptionalDataForm.propTypes = {
  values: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  setValues: PropTypes.func.isRequired,
  setFieldError: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  errors: PropTypes.object.isRequired,
  isValid: PropTypes.bool.isRequired,
  onCancel: PropTypes.func,
};

const enhancedForm = formikEnhance(OptionalDataForm);

enhancedForm.defaultProps = {
  onCancel: null,
};

enhancedForm.propTypes = {
  initialValues: PropTypes.shape({
    addressZipcode: PropTypes.string,
    addressStreet: PropTypes.string,
    addressNumber: PropTypes.string,
    addressComplement: PropTypes.string,
    addressDistrict: PropTypes.string,
    addressCity: PropTypes.string,
    addressState: PropTypes.string,
  }).isRequired,
  onFormSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
};

export default enhancedForm;
