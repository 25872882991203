import React, { useState } from 'react';
import PropTypes from 'prop-types';

import shoppingBasket from '../../assets/shopping_basket.svg';

import { descriptionText } from '../HistoryProductItem/HistoryProductItem.styles';
import {
  comboProductCss,
  comboProductImageCss,
} from './HistoryComboItem.styles';

const HistoryComboProduct = ({
  comboImgSrc,
  comboItemQuantity,
  comboItemName,
  comboItemPrice,
}) => {
  const [fallbackImg, setFallbackImg] = useState(null);

  const onImgError = () => setFallbackImg(shoppingBasket);

  return (
    <div css={comboProductCss}>
      <img
        src={fallbackImg || comboImgSrc || shoppingBasket}
        alt={`img-combo-${comboItemName}`}
        onError={onImgError}
        resizemode="contain"
        css={comboProductImageCss}
      />
      <div style={{ flex: 1 }}>
        <span css={descriptionText} style={{ fontStyle: 'italic' }}>
          <span style={{ fontWeight: 'bold' }}>{`${comboItemQuantity} `}</span>
          {comboItemName}
        </span>
      </div>
      <span
        css={descriptionText}
        style={{ fontWeight: 'bold', marginLeft: '4px', whiteSpace: 'nowrap' }}
      >
        {comboItemPrice}
      </span>
    </div>
  );
};

HistoryComboProduct.defaultProps = {
  comboImgSrc: null,
};

HistoryComboProduct.propTypes = {
  comboImgSrc: PropTypes.string,
  comboItemQuantity: PropTypes.string.isRequired,
  comboItemName: PropTypes.string.isRequired,
  comboItemPrice: PropTypes.string.isRequired,
};

export default HistoryComboProduct;
