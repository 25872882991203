import React from 'react';
import { Trans } from 'react-i18next';

import reduxStore from '../store/configureStore';
import { Creators as AuthCreators } from '../store/modules/auth';
import { Creators as UserCreators } from '../store/modules/user';
import SessionStorageManager from './SessionStorageManager';
import LocalStorageManager from './LocalStorageManager';
import showToast from './showToast';

const authPath = /\/customers/i;
const publicPaths = [/\/event_points/i];

const isPublicPath = (path) =>
  publicPaths.some((publicPath) => publicPath.test(path));

const applyInterceptors = (gateway) => {
  // Session interceptor
  gateway.interceptors.request.use((config) => {
    const newConfig = config;
    const isAuth = /\/customers\/refresh/i.test(config.url);

    if (!isAuth) {
      const token = LocalStorageManager.getToken();

      if (token) {
        newConfig.headers['x-access-token'] = token;
      }
    }

    return newConfig;
  });

  gateway.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;
      if (authPath.test(originalRequest.url)) {
        return Promise.reject(error);
      }
      if (error.response.status === 401 && !originalRequest.retried) {
        const tenantId = LocalStorageManager.getDomainTenantId();
        const refreshToken = LocalStorageManager.getRefreshToken();
        originalRequest.retried = true;
        return gateway
          .post('/customers/refresh', null, {
            headers: { 'x-refresh-token': refreshToken },
          })
          .then((res) => {
            if (res.status === 200) {
              LocalStorageManager.setToken(res.headers['x-access-token']);
              return gateway(originalRequest);
            }
          })
          .catch((e) => {
            LocalStorageManager.removeRefreshToken();
            LocalStorageManager.removeToken();
            reduxStore.store.dispatch(UserCreators.removeUser());
            SessionStorageManager.clear();
            reduxStore.store.dispatch(AuthCreators.logoutSuccessful());

            showToast(<Trans i18nKey="errors:sessionExpired" />, 'error');

            if (isPublicPath(originalRequest.url)) {
              delete originalRequest.headers['x-access-token'];
              originalRequest.params.tenant_id = tenantId;

              return gateway(originalRequest);
            }
          });
      }

      // return Error object with Promise
      return Promise.reject(error);
    },
  );
};

export default applyInterceptors;
