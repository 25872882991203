import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';

import Checkbox from '../Checkbox';

const FormikCheckbox = ({ name, label }) => {
  const [field, , helpers] = useField({ name, type: 'checkbox' });

  const { checked } = field;
  const { setValue, setTouched } = helpers;

  const handleChange = () => {
    setTouched(true);
    setValue(!checked);
  };

  return (
    <Checkbox
      name={name}
      label={label}
      checked={checked}
      onChange={handleChange}
    />
  );
};

FormikCheckbox.defaultProps = {
  label: '',
};

FormikCheckbox.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.func,
  ]),
};

export default FormikCheckbox;
