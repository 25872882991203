import React from 'react';
import PropTypes from 'prop-types';
import Button from '@bit/eyemobile.core.button';
import { FooterContainer } from './PaymentLinkFooter.styles';

const PaymentLinkFooter = ({
  buttonText,
  onButtonClick,
  disabled,
  isLoading,
}) => (
  <FooterContainer>
    <Button
      variant="reversed"
      onClick={onButtonClick}
      disabled={disabled}
      isLoading={isLoading}
    >
      {buttonText}
    </Button>
  </FooterContainer>
);

PaymentLinkFooter.defaultProps = {
  disabled: false,
  isLoading: false,
};

PaymentLinkFooter.propTypes = {
  buttonText: PropTypes.string.isRequired,
  onButtonClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
};

export default PaymentLinkFooter;
