import React, { useState } from 'react';
import PropTypes from 'prop-types';

import getProductImageSrc from '../../utils/getProductImageSrc';
import shoppingBasket from '../../assets/shopping_basket.svg';

import { productImg } from './ProductAdjustMenu.styles';

const CarouselImage = ({ imageKey, productName }) => {
  const [currentImage, setCurrentImage] = useState(
    getProductImageSrc(imageKey, 512) || shoppingBasket,
  );

  const handleImageError = () => {
    if (currentImage.includes('512x512')) {
      setCurrentImage(getProductImageSrc(imageKey, null));
    } else {
      setCurrentImage(shoppingBasket);
    }
  };

  return (
    <img
      alt={productName}
      src={currentImage}
      css={productImg}
      onError={handleImageError}
    />
  );
};

CarouselImage.propTypes = {
  imageKey: PropTypes.string.isRequired,
  productName: PropTypes.string.isRequired,
};

export default CarouselImage;
