import styled from '@emotion/styled';
import facepaint from 'facepaint';

const mq = facepaint(['@media screen and (min-width: 375px)']);

const getFontSize = ({ theme }) =>
  mq({
    fontSize: [theme.getFontSize(0.85, 'px'), theme.getFontSize(1, 'px')],
  });

export const ItemContainer = styled.div`
  cursor: pointer;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: ${({ theme }) => theme && theme.getSpacing(2, 'px')};
  padding-right: ${({ theme }) => theme && theme.getSpacing(2, 'px')};
  padding-top: ${({ theme }) => theme && theme.getSpacing(2, 'px')};
  padding-bottom: ${({ theme }) => theme && theme.getSpacing(2, 'px')};
  margin-bottom: ${({ theme }) => theme && theme.getSpacing(3, 'px')};
  border-radius: 17px;
  background-color: #f5f5f5;
`;

export const ItemDescriptionWrapper = styled.div`
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  padding-left: ${({ theme }) => theme && theme.getSpacing(1, 'px')};
  padding-right: ${({ theme }) => theme && theme.getSpacing(1, 'px')};
`;

export const ItemText = styled.span`
  font-family: ${({ theme }) => theme && theme.getFont()};
  ${getFontSize};
  color: ${({ theme }) => theme && theme.getColor('text')};
  text-align: left;
  margin-bottom: 4px;
`;

export const ItemLabel = styled.span`
  font-family: ${({ theme }) => theme && theme.getFont('futura')};
  ${getFontSize};
  color: ${({ theme }) => theme && theme.getColor('text')};
  text-align: left;
  margin-bottom: 5px;
`;

export const ItemTop = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
`;
