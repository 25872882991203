import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'emotion-theming';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Analytics from '../../utils/Analytics';
import {
  analyticsEventActions,
  analyticsEventCategory,
} from '../../utils/constants';
import * as productOrderActions from '../../store/modules/productOrder';
import getHighlightImage from '../../utils/getHighlightImage';
import ProductAdjustModal from '../ProductAdjustModal';

import InfoTextModal from './InfoTextModal';
import {
  specialItem,
  specialLink,
  offersText,
  itemsContainer,
} from './OrderSpecials.styles';

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      addPromotionalProductToOrder:
        productOrderActions.addPromotionalProductToOrder,
    },
    dispatch,
  );

const OrderSpecials = ({
  selectedStore,
  addPromotionalProductToOrder,
  onChangeAddressAttempt,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const [currentInfoText, setCurrentInfoText] = useState(null);
  const [currentProduct, setCurrentProduct] = useState(null);

  const hasHighlights =
    !!selectedStore?.highlights && selectedStore.highlights.length > 0;

  const handleCloseInfoTextModal = () => setCurrentInfoText(null);

  const handleCloseProductAdjustModal = () => setCurrentProduct(null);

  const handleAddToCart = (adjustedProduct) => {
    const { amount, measure, complementsTotal, price } = adjustedProduct;
    addPromotionalProductToOrder({
      promotionalProduct: { ...adjustedProduct, source: 'highlight' },
    });
    Analytics.event({
      category: analyticsEventCategory.ORDER,
      action: analyticsEventActions.ADD_PRODUCT,
      label: adjustedProduct.name,
      value:
        measure !== 'UNIT'
          ? (amount * price) / 10
          : (price + complementsTotal) * amount,
    });
    handleCloseProductAdjustModal();
  };

  const renderHighlight = (highlight) => {
    const { id, action, image, name, url, infoText } = highlight;

    if (action === 2) {
      const adaptedHighlightUrl =
        url.includes('http://') || url.includes('https://') ? url : `//${url}`;
      return (
        <a
          key={id}
          css={specialLink}
          href={adaptedHighlightUrl}
          rel="noreferrer"
          target="_blank"
        >
          <img css={specialItem} alt={name} src={getHighlightImage(image)} />
        </a>
      );
    }

    if (action === 4) {
      return (
        <div
          key={`info-text-${id}`}
          onClick={() => setCurrentInfoText(infoText)}
          onKeyDown={() => setCurrentInfoText(infoText)}
          role="presentation"
          style={{ cursor: 'pointer' }}
        >
          <img
            css={specialItem}
            key={id}
            alt={name}
            src={getHighlightImage(image)}
            style={{
              marginLeft: theme.getSpacing(1, 'px'),
              marginRight: theme.getSpacing(1, 'px'),
            }}
          />
        </div>
      );
    }

    if (action === 5) {
      return (
        <div
          key={`change-address-${id}`}
          onClick={onChangeAddressAttempt}
          onKeyDown={onChangeAddressAttempt}
          role="presentation"
          style={{ cursor: 'pointer' }}
        >
          <img
            css={specialItem}
            key={id}
            alt={name}
            src={getHighlightImage(image)}
            style={{
              marginLeft: theme.getSpacing(1, 'px'),
              marginRight: theme.getSpacing(1, 'px'),
            }}
          />
        </div>
      );
    }

    return (
      <img
        css={specialItem}
        key={id}
        alt={name}
        src={getHighlightImage(image)}
        style={{
          marginLeft: theme.getSpacing(1, 'px'),
          marginRight: theme.getSpacing(1, 'px'),
        }}
      />
    );
  };

  if (!selectedStore || !hasHighlights) {
    return true;
  }

  return (
    <>
      <InfoTextModal
        visible={!!currentInfoText}
        infoText={currentInfoText}
        onClose={handleCloseInfoTextModal}
      />
      <ProductAdjustModal
        visible={!!currentProduct}
        product={currentProduct}
        onCloseModal={handleCloseProductAdjustModal}
        onAddToCart={handleAddToCart}
      />
      <div style={{ width: '100%', marginTop: '5px' }}>
        <span css={offersText}>{t('promotions:offers')}</span>
        <div css={itemsContainer}>
          {hasHighlights && selectedStore.highlights.map(renderHighlight)}
        </div>
      </div>
    </>
  );
};

OrderSpecials.defaultProps = {
  selectedStore: null,
};

OrderSpecials.propTypes = {
  selectedStore: PropTypes.object,
  onChangeAddressAttempt: PropTypes.func.isRequired,
  addPromotionalProductToOrder: PropTypes.func.isRequired,
};

export default connect(null, mapDispatchToProps)(OrderSpecials);
