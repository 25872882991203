import React from 'react';
import PropTypes from 'prop-types';
import { centerContainerCss, wrapperContainer } from './AppContainer.styles';

const AppContainer = ({ children, background }) => (
  <div css={centerContainerCss} style={{ background: background || undefined }}>
    <div css={wrapperContainer} style={{ background: background || undefined }}>
      {children}
    </div>
  </div>
);

AppContainer.defaultProps = {
  background: null,
};

AppContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
    PropTypes.array,
  ]).isRequired,
  background: PropTypes.string,
};

export default AppContainer;
