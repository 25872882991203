import { useState, useCallback } from 'react';
import sumComplementTotal from './sumComplementTotal';

const mapComplementIncrement = (
  complementGroups,
  complementGroupId,
  complementItemId,
) =>
  complementGroups.map((complementGroup) => {
    if (complementGroup.id === complementGroupId) {
      if (complementGroup.max === 1) {
        return {
          ...complementGroup,
          complementItems: complementGroup.complementItems.map(
            (complementItem) => ({
              ...complementItem,
              quantity: (complementItemId === complementItem.id) * 1,
            }),
          ),
        };
      }

      const complementItemsSum = complementGroup.complementItems.reduce(
        (acc, complementItem) => acc + complementItem.quantity,
        0,
      );

      if (complementItemsSum >= complementGroup.max) {
        return complementGroup;
      }

      const complementItemIndex = complementGroup.complementItems.findIndex(
        ({ id }) => id === complementItemId,
      );

      if (complementItemIndex > -1) {
        return {
          ...complementGroup,
          complementItems: complementGroup.complementItems.map(
            (complementItem, index) => {
              if (index === complementItemIndex) {
                return {
                  ...complementItem,
                  quantity: complementItem.quantity + 1,
                };
              }

              return complementItem;
            },
          ),
        };
      }

      return complementGroup;
    }

    return complementGroup;
  });

const mapComplementDecrement = (
  complementGroups,
  complementGroupId,
  complementItemId,
) =>
  complementGroups.map((complementGroup) => {
    if (complementGroup.id === complementGroupId) {
      if (complementGroup.max === 1) {
        return {
          ...complementGroup,
          complementItems: complementGroup.complementItems.map(
            (complementItem) => ({
              ...complementItem,
              quantity: 0,
            }),
          ),
        };
      }

      const complementItemIndex = complementGroup.complementItems.findIndex(
        ({ id }) => id === complementItemId,
      );

      if (complementItemIndex > -1) {
        return {
          ...complementGroup,
          complementItems: complementGroup.complementItems.map(
            (complementItem, index) => {
              if (index === complementItemIndex) {
                return {
                  ...complementItem,
                  quantity: Math.max(complementItem.quantity - 1, 0),
                };
              }

              return complementItem;
            },
          ),
        };
      }

      return complementGroup;
    }

    return complementGroup;
  });

const useComplementGroups = (initialState) => {
  const [complementGroups, setComplementGroups] = useState(
    initialState.complementGroups || [],
  );
  const [complementsTotal, setComplementsTotal] = useState(
    initialState.complementsTotal || 0,
  );

  const incrementItem = useCallback(
    (complementGroupId, complementItemId) => {
      const mappedComplementGroups = mapComplementIncrement(
        complementGroups,
        complementGroupId,
        complementItemId,
      );
      const newComplementsTotal = sumComplementTotal(mappedComplementGroups);

      setComplementGroups(mappedComplementGroups);
      setComplementsTotal(newComplementsTotal);
    },
    [complementGroups, setComplementGroups, setComplementsTotal],
  );

  const decrementItem = useCallback(
    (complementGroupId, complementItemId) => {
      const mappedComplementGroups = mapComplementDecrement(
        complementGroups,
        complementGroupId,
        complementItemId,
      );
      const newComplementsTotal = sumComplementTotal(mappedComplementGroups);

      setComplementGroups(mappedComplementGroups);
      setComplementsTotal(newComplementsTotal);
    },
    [complementGroups, setComplementGroups, setComplementsTotal],
  );

  return [
    { complementGroups, complementsTotal },
    { incrementItem, decrementItem },
  ];
};

export default useComplementGroups;
