import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Analytics from '../../utils/Analytics';
import http from '../../utils/http';
import * as registerFormActions from '../../store/modules/registerForm';
import SessionStorageManager from '../../utils/SessionStorageManager';
import showToast from '../../utils/showToast';
import { stripMask } from '../../utils/adaptField';
import OptionalDataScreen from './OptionalDataScreen';
import CustomerService from '../../services/customer';
import clean from '../../utils/clean';
import {
  analyticsEventActions,
  analyticsEventCategory,
} from '../../utils/constants';
import PointService from '../../services/point';

const mapStateToProps = ({
  registerForm: { registerForm },
  tenantEyepass: { domain, tenantId },
}) => ({
  formData: {
    id: registerForm.id,
    fullName: registerForm.fullName,
    email: registerForm.email,
    phone: registerForm.phone,
    birthdate: registerForm.birthdate,
    document: registerForm.document,
    addressZipcode: registerForm.addressZipcode,
    addressStreet: registerForm.addressStreet,
    addressNumber: registerForm.addressNumber,
    addressComplement: registerForm.addressComplement,
    addressDistrict: registerForm.addressDistrict,
    addressCity: registerForm.addressCity,
    addressState: registerForm.addressState,
    password: registerForm.password,
    acceptedMarketing: registerForm.acceptedMarketing,
  },
  domain,
  tenantId,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setRegisterForm: registerFormActions.setRegisterForm,
      clearRegisterForm: registerFormActions.clearRegisterForm,
    },
    dispatch,
  );

const OptionalDataContainer = ({
  history,
  formData,
  clearRegisterForm,
  domain,
  tenantId,
}) => {
  const { t } = useTranslation();
  const token = SessionStorageManager.getRegisterToken();
  useEffect(() => {
    if (!formData.fullName) {
      history.push(`/${domain}/registro`);
    }
  }, []);

  const handleFormSubmitResponse = async (fullFormData, userId = null) => {
    const method = token || userId ? 'put' : 'post';

    const tokenPath = token
      ? CustomerService.update(token)
      : CustomerService.create;

    const path = userId ? CustomerService.complete(userId) : tokenPath;

    const normalizedData = token
      ? fullFormData
      : {
          ...fullFormData,
          tenantId,
        };

    return http[method](path, normalizedData).then(({ data }) => data);
  };

  const handleShouldLoginCheck = async (pointId) => {
    try {
      if (!pointId) {
        throw new Error('No point provided');
      }

      const {
        data: { point },
      } = await http.get(PointService.find(pointId)).then(({ data }) => data);

      return point.ecommerceEnabled;
    } catch (e) {
      console.warn(e);
      return true;
    }
  };

  const handleFormSubmit = async (submittedFormData, formikBag) => {
    const {
      id: userId,
      fullName,
      email,
      phone,
      birthdate,
      document,
      password,
      acceptedMarketing,
    } = formData;

    const {
      addressZipcode,
      addressStreet,
      addressNumber,
      addressComplement,
      addressDistrict,
      addressCity,
      addressState,
    } = submittedFormData;

    try {
      const fullFormData = clean({
        fullName,
        email,
        phone: stripMask(phone),
        birthdate,
        document: stripMask(document),
        marketingOptin: Number(acceptedMarketing),
        password,
        address: {
          address: addressStreet,
          number: addressNumber,
          complement: addressComplement,
          city: addressCity,
          state: addressState,
          district: addressDistrict,
          zipcode: stripMask(addressZipcode),
        },
      });

      const {
        data: { customer },
      } = await handleFormSubmitResponse(fullFormData, userId);

      const shouldLogin = await handleShouldLoginCheck(customer.pointId);

      clearRegisterForm();
      SessionStorageManager.removeRegisterToken();

      Analytics.event({
        category: analyticsEventCategory.CUSTOMER,
        action: analyticsEventActions.REGISTRATION_END,
        label: 'Término de registro',
      });
      history.push(`/${domain}/registro/concluido`, {
        hasRegistered: true,
        name: fullFormData.fullName,
        document: fullFormData.document,
        password: fullFormData.password,
        shouldLogin,
      });
    } catch (error) {
      if (error.response) {
        if (/already registered/.test(error?.response?.data?.data?.message)) {
          showToast(t('formSubmission:documentAlreadyExists'), 'error');
          history.push(`/${domain}/registro`);
        } else {
          showToast(t('formSubmission:submissionError'), 'error');
        }
      } else {
        showToast(error.message, 'error');
      }
      formikBag.setSubmitting(false);
    }
  };

  return (
    <OptionalDataScreen
      formInitialValues={formData}
      onFormSubmit={handleFormSubmit}
    />
  );
};

OptionalDataContainer.defaultProps = {
  domain: null,
};

OptionalDataContainer.propTypes = {
  history: PropTypes.object.isRequired,
  formData: PropTypes.object.isRequired,
  clearRegisterForm: PropTypes.func.isRequired,
  domain: PropTypes.string,
  tenantId: PropTypes.number.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OptionalDataContainer);
