import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import gateway from '../../utils/gateway';
import showToast from '../../utils/showToast';
import LoadingScreen from '../../components/LoadingScreen';
import { Creators as UserCreators } from '../../store/modules/user';
import UserPasswordScreen from './UserPasswordScreen';

const mapStateToProps = ({ user, tenantEyepass: { domain } }) => ({
  user,
  domain,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ setUser: UserCreators.setUser }, dispatch);

const UserPaswordContainer = ({ history, setUser, domain }) => {
  const { t } = useTranslation();
  const [isLoading, setisLoading] = useState(false);

  const handleUserRefresh = async () => {
    try {
      setisLoading(true);

      const response = await gateway.get('/profile').then(({ data }) => data);

      setUser(response.customer);
      history.push(`/${domain}/carrinho`);
    } catch (error) {
      showToast(error.message, 'error');
    }
    setisLoading(false);
  };

  const handleDataSubmit = async (values, formikBag) => {
    try {
      const payload = {
        oldPassword: values.currentPassword,
        newPassword: values.newPassword,
      };

      await gateway.put('/account/password', payload);

      showToast(t('formSubmission:passwordAlterSuccess'), 'success');
      formikBag.setSubmitting(false);
      handleUserRefresh();
    } catch (error) {
      formikBag.setSubmitting(false);
      showToast(error.message, 'error');
    }
  };

  if (isLoading) {
    return <LoadingScreen />;
  }

  const navigateToAccount = () => history.push(`/${domain}/minha-conta`);

  return (
    <UserPasswordScreen
      onDataSubmit={handleDataSubmit}
      onNavigate={navigateToAccount}
    />
  );
};

UserPaswordContainer.defaultProps = {
  domain: null,
};

UserPaswordContainer.propTypes = {
  history: PropTypes.object.isRequired,
  setUser: PropTypes.func.isRequired,
  domain: PropTypes.string,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserPaswordContainer);
