import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';
import ptbr from 'dayjs/locale/pt-br';

dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.locale(ptbr);

export default dayjs;
