import { css } from '@emotion/core';

export const section = (theme) => css`
  padding: ${theme && theme.getSpacing(2, 'px')};
`;

export const title = (theme) => css`
  margin-bottom: ${theme && theme.getSpacing(3, 'px')};
  text-align: left;
  font-size: ${theme && theme.getFontSize(1, 'px')};
  font-family: ${theme && theme.getFont()};
  color: ${theme && theme.getColor('text')};
  font-weight: bold;
  text-transform: uppercase;
`;

export const backgroundCss = (theme) => css`
  width: 100%;
  height: 100%;
  background-color: ${theme && theme.getColor('foreground')};
`;

export const wrapperCss = (theme) => css`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: ${theme && theme.getSpacing(1, 'px')};
  padding-right: ${theme && theme.getSpacing(1, 'px')};
`;

export const logoCss = (theme) => css`
  width: 180px;
  height: 180px;
  object-fit: contain;
  margin: ${theme && theme.getSpacing(2, 'px')};
`;

export const iconCss = (theme) => css`
  width: 120px;
  height: 120px;
  object-fit: contain;
  margin: ${theme && theme.getSpacing(2, 'px')};
`;

export const messageCss = (theme) => css`
  font-family: ${theme && theme.getFont('heading')};
  font-size: ${theme && theme.getFontSize(1, 'px')};
  color: ${theme && theme.getColor('text')};
  text-align: center;
`;
