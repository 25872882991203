import { createReducer } from '@reduxjs/toolkit';
import { takeLatest, put, all, call } from 'redux-saga/effects';

import { Creators as UserCreators } from './user';
import gateway from '../../utils/gateway';
import SessionStorageManager from '../../utils/SessionStorageManager';
import { clearOrder } from './productOrder';
import LocalStorageManager from '../../utils/LocalStorageManager';

// Constants

export const Types = {
  LOGIN_SUCCESSFUL: 'auth/LOGIN_SUCCESSFUL',
  LOGOUT: 'auth/LOGOUT',
  LOGOUT_SUCCESSFUL: 'auth/LOGOUT_SUCCESSFUL',
};

// Reducers

const authInitial = {
  isLogged: false,
  isProcessing: false,
};

const authReducer = createReducer(authInitial, {
  [Types.LOGIN_SUCCESSFUL]: (state) => {
    return {
      ...state,
      isProcessing: false,
      isLogged: true,
    };
  },
  [Types.LOGOUT]: (state) => {
    return { ...state, isProcessing: true };
  },
  [Types.LOGOUT_SUCCESSFUL]: (state) => {
    return {
      ...state,
      isProcessing: false,
      isLogged: false,
    };
  },
});

export const reducers = authReducer;

// Action Creators

export const Creators = {
  loginSuccessful: () => {
    return {
      type: Types.LOGIN_SUCCESSFUL,
    };
  },
  logout: () => {
    return {
      type: Types.LOGOUT,
    };
  },
  logoutSuccessful: () => {
    return {
      type: Types.LOGOUT_SUCCESSFUL,
    };
  },
};

// Services

const logout = async () => {
  await gateway.post('/customers/logout').catch(console.error);
};

// Middleware

export function* logoutSaga() {
  yield call(logout);
  yield put(UserCreators.removeUser());
  yield put(clearOrder());
  LocalStorageManager.removeToken();
  LocalStorageManager.removeRefreshToken();
  SessionStorageManager.clear();
  yield put(Creators.logoutSuccessful());
}

function* watchLogoutSaga() {
  yield takeLatest(Types.LOGOUT, logoutSaga);
}

function* authSagas() {
  yield all([watchLogoutSaga()]);
}

export const sagas = authSagas;
