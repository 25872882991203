import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import Modal from '@bit/eyemobile.core.modal';
import Slide from '@material-ui/core/Slide';
import { useTranslation } from 'react-i18next';

import useComplementGroups from '../../utils/useComplementGroups';
import withModal from '../../hocs/withModal';
import localizePrice from '../../utils/localizePrice';
import ComboAdjustMenu from '../ComboAdjustMenu';
import showCustomToast from '../../utils/showCustomToast';

import {
  productContent,
  productInnerWrapper,
  productOverlay,
} from '../ProductAdjustModal/ProductAdjustModal.styles';

const ComboAdjustModal = ({
  visible,
  productCombo,
  onCloseModal,
  onAddToCart,
  onRemove,
}) => {
  const { price, observations, amount, name, description } = productCombo;
  const comboProductItems = productCombo.comboProductItems || [];
  const images = [productCombo.mainImageKey].concat(productCombo.productImages);

  const { t } = useTranslation();
  const [observationText, setObservationText] = useState(observations || '');
  const [comboAmount, setProductAmount] = useState(
    amount || (onRemove ? 0 : 1),
  );
  const [complementGroupState, complementGroupsActions] = useComplementGroups({
    complementGroups: productCombo.complementGroups,
    complementsTotal: productCombo.complementsTotal,
  });

  const { complementGroups, complementsTotal } = complementGroupState;

  const handleAddToCart = () => {
    const cartCombo = {
      ...productCombo,
      amount: comboAmount,
      observations: observationText,
      complementGroups,
      complementsTotal,
    };

    onAddToCart(cartCombo);
  };

  const handleProductRemoval = () => {
    onRemove(productCombo);
  };

  const handleAddProduct = useCallback(() => {
    const maxComboProduct = comboProductItems.reduce(
      (acc, comboProductItem) => {
        const { itemStockBalance, itemQuantity, itemRigidStockEnabled } =
          comboProductItem;
        if (!itemRigidStockEnabled) {
          return acc;
        }
        const maxStock = itemStockBalance === undefined ? 99 : itemStockBalance;
        return Math.min(acc, Math.floor(maxStock / itemQuantity));
      },
      99,
    );
    setProductAmount((prevAmount) => {
      if (prevAmount < maxComboProduct) {
        return prevAmount + 1;
      }

      showCustomToast(t('productOrder:maxStockBalance'), 'error', {
        toastId: 'MAX_STOCK_TOAST',
      });
      return prevAmount;
    });
  }, [setProductAmount, productCombo, comboProductItems, t]);

  const handleSubtractProduct = () =>
    setProductAmount((prevAmount) =>
      Math.max(onRemove ? 0 : 1, prevAmount - 1),
    );

  const handleObservationChange = (e) => setObservationText(e.target.value);

  const priceText = `R$ ${price && localizePrice(price)}`;

  const finalProductPrice = (price + complementsTotal) * comboAmount;

  const finalComboPriceText = `R$ ${localizePrice(finalProductPrice)}`;

  return (
    <Slide direction="up" in={visible}>
      <Modal
        visible={visible}
        styles={{
          innerWrapper: productInnerWrapper,
          content: productContent,
          overlay: productOverlay,
        }}
        keepMounted
      >
        <ComboAdjustMenu
          comboImages={images || []}
          comboName={name}
          priceText={priceText}
          comboProductItems={comboProductItems}
          description={description}
          complementGroups={complementGroups}
          onComplementIncrement={complementGroupsActions.incrementItem}
          onComplementDecrement={complementGroupsActions.decrementItem}
          finalComboPriceText={finalComboPriceText}
          amount={comboAmount}
          onAddToCart={handleAddToCart}
          onRemove={handleProductRemoval}
          onCancel={onCloseModal}
          onAdd={handleAddProduct}
          onSubtract={handleSubtractProduct}
          observationText={observationText}
          onObservationChange={handleObservationChange}
        />
      </Modal>
    </Slide>
  );
};

ComboAdjustModal.defaultProps = {
  visible: false,
  onRemove: null,
};

ComboAdjustModal.propTypes = {
  visible: PropTypes.bool,
  productCombo: PropTypes.object.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  onAddToCart: PropTypes.func.isRequired,
  onRemove: PropTypes.func,
};

export default withModal((props) => ({
  visible: props.visible && props.productCombo,
}))(ComboAdjustModal);
