import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import { bindActionCreators, compose } from 'redux';
import * as Yup from 'yup';

import { getToken } from '../../utils/firebase';
import dayjs from '../../utils/dayjs';
import gateway from '../../utils/gateway';
import * as registerFormActions from '../../store/modules/registerForm';
import { Creators as AuthCreators } from '../../store/modules/auth';
import { Creators as UserCreators } from '../../store/modules/user';
import { stripMask } from '../../utils/adaptField';
import {
  analyticsEventActions,
  analyticsEventCategory,
} from '../../utils/constants';
import LocalStorageManager from '../../utils/LocalStorageManager';
import Analytics from '../../utils/Analytics';
import LanguagesModalsContainer from '../../components/LanguageModalsContainer';

import LoginPageScreen from './LoginPageScreen';

const loginFormSchema = Yup.object().shape({
  username: Yup.string()
    .matches(
      /^[0-9]{3}\.[0-9]{3}\.[0-9]{3}-[0-9]{2}$/,
      'formValidation:invalidDocument',
    )
    .required('formValidation:fieldRequired'),
  password: Yup.string().required('formValidation:fieldRequired'),
});

const mapStateToProps = ({ tenantEyepass: { domain, tenantId } }) => ({
  domain,
  tenantId,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setRegisterForm: registerFormActions.setRegisterForm,
      clearRegisterForm: registerFormActions.clearRegisterForm,
      loginSuccessful: AuthCreators.loginSuccessful,
      setUser: UserCreators.setUser,
    },
    dispatch,
  );

const LoginPageContainer = ({
  history,
  setRegisterForm,
  setUser,
  loginSuccessful,
  domain,
  tenantId,
}) => {
  const [hasError, setHasError] = useState(false);
  const [languageModalOpen, setLanguageModalOpen] = useState(false);

  const toggleLanguageModal = () => setLanguageModalOpen((prev) => !prev);

  const handleRegistrationStart = () => {
    Analytics.event({
      category: analyticsEventCategory.CUSTOMER,
      action: analyticsEventActions.REGISTRATION_START,
      label: 'Início de registro',
    });
    history.push(`/${domain}/registro`);
  };

  const handleFacebookLogin = (response) => {
    setRegisterForm({
      autoFetchData: false,
      fullName: response.name,
      email: response.email,
      birthdate: response.birthday
        ? dayjs(response.birthday, 'MM/DD/YYYY').format('DD/MM/YYYY')
        : '',
    });

    Analytics.event({
      category: analyticsEventCategory.CUSTOMER,
      action: analyticsEventActions.FACEBOOK_LOGIN,
      label: 'Login por Facebook',
    });

    handleRegistrationStart();
  };

  const handleGoogleLogin = (response) => {
    const user = response.profileObj;
    if (!response?.error) {
      setRegisterForm({
        autoFetchData: false,
        fullName: user?.name || '',
        email: user?.email || '',
      });

      Analytics.event({
        category: analyticsEventCategory.CUSTOMER,
        action: analyticsEventActions.GOOGLE_LOGIN,
        label: 'Login por Google',
      });

      handleRegistrationStart();
    }
  };

  const registerNotificationToken = async (customerInfoId) => {
    try {
      const token = await getToken();

      if (token) {
        await gateway.post('/customer-notification', { customerInfoId, token });
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleLogin = async (values, { setSubmitting }) => {
    try {
      setHasError(false);
      setSubmitting(true);
      const response = await gateway.post('/customers/login', {
        username: stripMask(values.username),
        password: values.password,
        tenant_id: `${tenantId}`,
      });

      LocalStorageManager.setToken(response.headers['x-access-token']);
      LocalStorageManager.setRefreshToken(response.headers['x-refresh-token']);
      LocalStorageManager.setDomainTenantId(tenantId);

      const profile = await gateway.get('/profile').then(({ data }) => data);

      await registerNotificationToken(profile.customer.id);

      setUser(profile.customer);
      Analytics.setCustomer(profile.customer.id);
      Analytics.event({
        category: analyticsEventCategory.CUSTOMER,
        action: analyticsEventActions.LOGIN,
        label: 'Login',
      });
      setSubmitting(false);
      loginSuccessful();
      history.push(`/${domain}`);
    } catch (e) {
      console.error(e);
      setHasError(true);
      setSubmitting(false);
    }
  };

  const handleSubmitEnter = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleLogin();
    }
  };

  return (
    <>
      <LanguagesModalsContainer
        visible={languageModalOpen}
        onCloseModal={toggleLanguageModal}
      />
      <Formik
        validationSchema={loginFormSchema}
        onSubmit={handleLogin}
        initialValues={{
          username: '',
          password: '',
        }}
      >
        {({ values, errors, touched, handleSubmit, isSubmitting, isValid }) => (
          <LoginPageScreen
            onRegistration={handleRegistrationStart}
            onFacebookLogin={handleFacebookLogin}
            onGoogleLogin={handleGoogleLogin}
            onToggleLanguageModal={toggleLanguageModal}
            values={values}
            errors={errors}
            touched={touched}
            handleSubmit={handleSubmit}
            isSubmitting={isSubmitting}
            isValid={isValid}
            hasError={hasError}
            handleSubmitEnter={handleSubmitEnter}
            domain={domain}
          />
        )}
      </Formik>
    </>
  );
};

LoginPageContainer.defaultProps = {
  domain: null,
};

LoginPageContainer.propTypes = {
  history: PropTypes.object.isRequired,
  setRegisterForm: PropTypes.func.isRequired,
  loginSuccessful: PropTypes.func.isRequired,
  setUser: PropTypes.func.isRequired,
  domain: PropTypes.string,
  tenantId: PropTypes.number.isRequired,
};

const enhance = compose(connect(mapStateToProps, mapDispatchToProps));

export default enhance(LoginPageContainer);
