import { css } from '@emotion/core';
import facepaint from 'facepaint';

const mobileMq = facepaint([
  '@media screen and (min-width: 375px)',
  '@media screen and (min-width: 720px)',
]);

export const menuHeader = (theme) => css`
  width: 100%;
  padding-left: ${theme.getSpacing(2, 'px')};
  padding-right: ${theme.getSpacing(2, 'px')};
  padding-bottom: ${theme.getSpacing(2, 'px')};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const menuTitle = (theme) => css`
  font-family: ${theme && theme.getFont('futura')};
  font-size: ${theme && theme.getFontSize(1, 'px')};
  color: ${theme && theme.getColor('text')};
  text-transform: uppercase;
`;

export const contentScroll = (theme) => css`
  width: 100%;
  display: block;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  ${mobileMq({
    paddingLeft: [
      theme.getSpacing(1, 'px'),
      theme.getSpacing(1.5, 'px'),
      theme.getSpacing(2, 'px'),
    ],
    paddingRight: [
      theme.getSpacing(1, 'px'),
      theme.getSpacing(1.5, 'px'),
      theme.getSpacing(2, 'px'),
    ],
  })}

  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }
  &::-webkit-scrollbar:vertical {
    width: 8px;
  }
  &::-webkit-scrollbar:horizontal {
    height: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    border: 2px solid #ffffff;
  }
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #ffffff;
  }
`;

export const container = (theme) => css`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: ${theme.getSpacing(2, 'px')};
`;
