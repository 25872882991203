import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ArrowDown } from '../../assets/arrow_down.svg';

import localizePrice from '../../utils/localizePrice';
import dayjs from '../../utils/dayjs';

import {
  ItemContainer,
  ItemDescriptionWrapper,
  ItemText,
  ItemLabel,
  ItemTop,
} from './CashbackItem.styles';

const CashbackItem = ({ name, percentage, balance, balances }) => {
  const { t } = useTranslation();

  const [balancesOpen, setBalancesOpen] = useState(false);

  const toggleBalancesOpen = () => setBalancesOpen((prev) => !prev);

  const renderBalances = (loyaltyTransaction) => {
    const { points, due_date: dueDate } = loyaltyTransaction;
    const dueDay = dueDate.split('T')[0];
    return (
      <div key={`cashback-${loyaltyTransaction.id}`}>
        <ItemText>
          {t('loyalty:cashbackAvailableUntil', {
            amount: localizePrice(points),
          })}
          <ItemLabel>{` ${dayjs(dueDay).format('DD/MM/YYYY')}`}</ItemLabel>
        </ItemText>
      </div>
    );
  };

  return (
    <ItemContainer role="presentation" onClick={toggleBalancesOpen}>
      <ItemDescriptionWrapper>
        <ItemTop>
          <ItemLabel style={{ marginBottom: '0px', marginTop: '5px' }}>
            {name}
          </ItemLabel>
          {!!balances && balances.length > 0 && (
            <ArrowDown
              style={{
                transform: balancesOpen ? 'rotate(180deg)' : 'rotate(0deg)',
              }}
            />
          )}
        </ItemTop>
        <ItemText style={{ marginBottom: '6px' }}>
          {t('loyalty:cashbackAvailable', { balance: localizePrice(balance) })}
        </ItemText>
        {!!balances &&
          balancesOpen &&
          balances.length > 0 &&
          balances.map(renderBalances)}
      </ItemDescriptionWrapper>
    </ItemContainer>
  );
};

CashbackItem.defaultProps = {
  balance: 0,
  percentage: 0,
  name: '',
  balances: [],
};

CashbackItem.propTypes = {
  name: PropTypes.string,
  percentage: PropTypes.number,
  balance: PropTypes.number,
  balances: PropTypes.array,
};

export default CashbackItem;
