import ecommerceService from '../services/ecommerce';

import gateway from './gateway';

const addressValidation = async (storeId, newAddress, t) => {
  try {
    const query =
      !!newAddress.latitude && !!newAddress.longitude
        ? ecommerceService.checkAddressCoordinatesValidity(
            storeId,
            newAddress.latitude,
            newAddress.longitude,
          )
        : ecommerceService.checkAddressValidity(storeId, newAddress);

    const response = await gateway.get(query).then(({ data }) => data);

    const { open } = response.event_point;

    if (!open) {
      return Promise.reject(new Error(t('errors:storeIsNotOpen')));
    }

    const { extra } = response.event_point.point;

    const hasShippingPrice = extra && extra.shipping_price;

    if (hasShippingPrice) {
      return true;
    }

    throw new Error(t('errors:addressOutsideRange'));
  } catch (error) {
    return Promise.reject(error);
  }
};

export default addressValidation;
