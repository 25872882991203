import styled from '@emotion/styled';
import { css } from '@emotion/core';
import facepaint from 'facepaint';

const mq = facepaint([
  '@media screen and (min-width: 320px)',
  '@media screen and (min-width: 480px)',
  '@media screen and (min-width: 720px)',
]);

const mobileMq = facepaint([
  '@media screen and (min-width: 375px)',
  '@media screen and (min-width: 720px)',
]);

export const wrapper = css`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const footerBps = ({ theme }) =>
  mobileMq({
    height: [60, 70, 75],
    paddingLeft: [
      theme.getSpacing(2, 'px'),
      theme.getSpacing(4, 'px'),
      theme.getSpacing(6, 'px'),
    ],
    paddingRight: [
      theme.getSpacing(2, 'px'),
      theme.getSpacing(4, 'px'),
      theme.getSpacing(6, 'px'),
    ],
  });

const getFooterBackgroundColor = ({ theme }) => {
  if (theme) {
    return theme.getColor('secondary');
  }

  return false;
};

export const FooterContainer = styled.div`
  ${footerBps};
  background-color: ${getFooterBackgroundColor};
  display: flex;
  flex-direction: row;
  align-items: center;
  position: fixed;
  bottom: 50px;
  padding-bottom: 20px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  outline: 0;
  transition: opacity 300ms;
  box-shadow: 0px -7px 5px -5px #ccc;
  cursor: pointer;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  ${({ disabled }) => disabled && 'cursor: default;'}
  ${mq({
    width: ['320px', '100%', '480px', '100%'],
  })}
`;

export const LeftWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 25%;
`;

export const MiddleWrapper = styled.div`
  ${wrapper};
  width: 50%;
  justify-content: center;
  outline: none;
`;

export const RightWrapper = styled.div`
  ${wrapper};
  width: 25%;
  justify-content: flex-end;
  outline: none;
`;

const priceTextBps = ({ theme }) =>
  mobileMq({
    fontSize: [
      theme.getFontSize(0.84, 'px'),
      theme.getFontSize(1, 'px'),
      theme.getFontSize(1.2, 'px'),
    ],
  });

const middleTextBps = ({ theme }) =>
  mobileMq({
    fontSize: [
      theme.getFontSize(0.8, 'px'),
      theme.getFontSize(1, 'px'),
      theme.getFontSize(1.2, 'px'),
    ],
    padding: [
      theme.getSpacing(1, 'px'),
      theme.getSpacing(1.25, 'px'),
      theme.getSpacing(1.5, 'px'),
    ],
  });

export const PriceText = styled.span`
  color: ${({ theme }) => theme && theme.getColor('background')};
  ${priceTextBps};
  font-family: ${({ theme }) => theme.getFont('futura')};
  margin-top: ${({ theme }) => theme.getSpacing(0.5, 'px')};
`;

export const MiddleText = styled.span`
  color: ${({ theme }) => theme && theme.getColor('background')};
  ${middleTextBps};
  font-family: ${({ theme }) => theme.getFont('futura')};
  text-transform: uppercase;
  text-align: center;
  white-space: nowrap;
  outline: none;
  ${({ disabled }) =>
    disabled && 'opacity: 0.75; cursor: default; pointer-events: none;'}
`;

export const countCircle = (theme) => css`
  background-color: ${theme.getColor('error')};
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  border-radius: 15px;
`;

export const countText = (theme) => css`
  color: ${theme.getColor('background')};
  font-size: ${theme.getFontSize(0.84, 'px')};
  font-family: ${theme.getFont('futura')};
`;
