import { css } from '@emotion/core';
import styled from '@emotion/styled';
import facepaint from 'facepaint';

const mobileMq = facepaint(['@media screen and (min-width: 375px)']);

export const productAdjustWrapper = (theme) => css`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const contentScroll = css`
  width: 100%;
  display: block;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  overflow-x: hidden;
  margin-right: 2px;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }
  &::-webkit-scrollbar:vertical {
    width: 8px;
  }
  &::-webkit-scrollbar:horizontal {
    height: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    border: 2px solid #ffffff;
  }
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #ffffff;
  }
`;

export const productImg = (theme) => css`
  height: 375px;
  object-fit: contain;
  width: 100%;
  padding-top: ${theme.getSpacing(6, 'px')};
`;

export const productInfo = (theme) => css`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-top: ${theme.getSpacing(1, 'px')};
  color: ${theme && theme.getColor('text')};
`;

export const ProductInfoText = styled.span`
  font-family: ${({ theme }) => theme && theme.getFont()};
  font-size: ${({ theme, isTitle }) =>
    theme && theme.getFontSize(isTitle ? 1.15 : 1, 'px')};
  margin-bottom: ${({ theme }) => theme && theme.getSpacing(1, 'px')};
`;

export const descriptionText = (theme) => css`
  font-family: ${theme && theme.getFont()};
  font-size: ${theme && theme.getFontSize(0.85, 'px')};
  margin-bottom: ${theme.getSpacing(1, 'px')};
  color: ${theme && theme.getColor('default')};
  line-height: 1.4;
`;

export const productPriceText = (theme) => css`
  font-family: ${theme && theme.getFont('futura')};
  font-size: ${theme && theme.getFontSize(1, 'px')};
  color: ${theme && theme.getColor('placeholder')};
  margin-bottom: ${theme && theme.getSpacing(1, 'px')};
`;

export const productContent = (theme) => css`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 ${theme.getSpacing(4, 'px')};
`;

export const amountTextCss = (theme) => css`
  font-family: ${theme && theme.getFont('futura')};
  font-size: ${theme && theme.getFontSize(1, 'px')};
  font-weight: bold;
  text-align: center;
  color: ${theme && theme.getColor('text')};
  white-space: nowrap;
  margin-right: ${theme && theme.getSpacing(0.5, 'px')};
  margin-left: ${theme && theme.getSpacing(0.5, 'px')};
  min-width: 25px;
`;

export const productAmountRow = (theme) => css`
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-bottom: ${theme && theme.getSpacing(3, 'px')};
  margin-top: ${theme && theme.getSpacing(2, 'px')};
`;

export const productAdjustInput = (theme) => css`
  width: 100%;
  outline: none;
  height: 60px;
  border: 1px solid #cbcbcb;
  font-family: ${theme && theme.getFont()};
  font-size: ${theme && theme.getFontSize(1.15, 'px')};
  border-radius: 8px;
  text-align: left;
  resize: none;
  padding: ${theme && theme.getSpacing(1, 'px')};
  margin-top: ${theme && theme.getSpacing(1, 'px')};
  color: ${theme && theme.getColor('text')};
`;

export const buttonsRow = (theme) => css`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-top: ${theme && theme.getSpacing(2, 'px')};
`;

export const optionalsWrapper = (theme) => css`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: ${theme && theme.getSpacing(1, 'px')};
  margin-bottom: ${theme && theme.getSpacing(3, 'px')};
  margin-left: -${theme.getSpacing(4, 'px')};
  margin-right: -${theme.getSpacing(4, 'px')};
`;

const footerBps = ({ theme }) =>
  mobileMq({
    height: [85, 100],
    paddingLeft: [theme.getSpacing(2, 'px'), theme.getSpacing(4, 'px')],
    paddingRight: [theme.getSpacing(2, 'px'), theme.getSpacing(4, 'px')],
    paddingBottom: [theme.getSpacing(1, 'px'), theme.getSpacing(2, 'px')],
  });

export const FooterContainer = styled.div`
  ${footerBps};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
`;

export const productAdjustHeader = css`
  position: absolute;
  top: 30px;
  left: 44px;
`;

export const footerButtonsWrapper = css`
  max-width: 150px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const radioLabelBps = (theme) =>
  mobileMq({
    fontSize: [theme.getFontSize(0.85, 'px'), theme.getFontSize(1, 'px')],
  });

export const radioLabelCss = (theme) => css`
  ${theme && radioLabelBps(theme)};
  font-family: ${theme && theme.getFont()};
  color: ${theme && theme.getColor('text')};
  user-select: none;
  -webkit-tap-highlight-color: transparent;
`;

export const fieldSpacing = (theme) => css`
  width: 100%;
  margin-bottom: ${theme && theme.getSpacing(2, 'px')};
`;

export const textareaCss = (theme) => css`
  border: 1px solid ${theme && theme.getColor('primary')};
  border-radius: 8px;
  padding: 4px 8px;
`;
