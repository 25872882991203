import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import LocalStorageManager from '../../utils/LocalStorageManager';

const mapStateToProps = ({
  auth,
  tenantEyepass: { domain, tutorialEnabled, tenantId },
}) => ({
  isLogged: auth.isLogged,
  domain,
  tutorialEnabled,
  tenantId,
});

const RedirectPageContainer = ({ history, domain, tutorialEnabled }) => {
  const isNotFirstAccess = LocalStorageManager.getIsNotFirstAccess();

  const firstAccessPath =
    isNotFirstAccess || !tutorialEnabled ? '/carrinho' : '/app-wizard';

  useEffect(() => {
    setTimeout(() => {
      history.push(`/${domain}${firstAccessPath}`);
    }, 500);
  }, []);

  return <p>Redirecionando...</p>;
};

RedirectPageContainer.defaultProps = {
  domain: null,
};

RedirectPageContainer.propTypes = {
  history: PropTypes.object.isRequired,
  domain: PropTypes.string,
  tutorialEnabled: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(RedirectPageContainer);
