import { css } from '@emotion/core';

export const hinterWrapperCss = (theme) => css`
  list-style-type: disc;
  margin-left: 18px;

  li {
    font-size: ${theme && theme.getFontSize(0.8, 'px')};
    &:not(:last-child) {
      margin-bottom: ${theme && theme.getSpacing(1, 'px')};
    }
  }
`;
