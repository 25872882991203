import styled from '@emotion/styled';
import { css } from '@emotion/core';

export const Box = styled.div`
  display: flex;
  align-items: center;
`;

export const LeftWrapper = styled.div`
  flex-shrink: 0;
  width: 48px;
`;

export const labelCss = (theme) => css`
  padding: 0;
  margin: 0;

  & > div {
    margin: 0%;
  }
`;
