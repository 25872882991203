import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import timeTextFromMinutes from '../../utils/timeTextFromMinutes';

import {
  ItemContainer,
  labelText,
  descText,
  mainTag,
} from './OrderAddressSelectItem.styles';

const OrderAddressSelectItem = ({
  selected,
  main,
  label,
  address,
  cityState,
  district,
  complement,
  onSelect,
  disabled,
  distance,
  duration,
}) => {
  const { t } = useTranslation();

  const distanceText = distance > 0 ? `${Math.round(distance)} Km` : '';
  const durationText = duration > 0 ? timeTextFromMinutes(duration) : '';

  return (
    <ItemContainer
      selected={selected}
      onClick={onSelect}
      onKeyDown={onSelect}
      disabled={disabled}
      role="presentation"
    >
      <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
        <span css={labelText}>{label}</span>
        <span css={descText}>{address}</span>
        <span css={descText}>{cityState}</span>
        <span css={descText}>{district}</span>
        <span css={descText}>{complement}</span>
        <span css={descText} style={{ fontWeight: 'bold' }}>
          {distanceText}
        </span>
        <span css={descText} style={{ fontWeight: 'bold' }}>
          {durationText}
        </span>
      </div>
      <div
        style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-end',
          height: '100%',
        }}
      >
        {main && <span css={mainTag}>{t('addressesListPage:main')}</span>}
      </div>
    </ItemContainer>
  );
};

OrderAddressSelectItem.defaultProps = {
  selected: false,
  disabled: false,
  complement: '',
  label: null,
  distance: null,
  duration: null,
};

OrderAddressSelectItem.propTypes = {
  selected: PropTypes.bool,
  main: PropTypes.bool.isRequired,
  label: PropTypes.string,
  address: PropTypes.string.isRequired,
  cityState: PropTypes.string.isRequired,
  district: PropTypes.string.isRequired,
  complement: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  distance: PropTypes.number,
  duration: PropTypes.number,
};

export default OrderAddressSelectItem;
