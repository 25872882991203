import { normalizeSync } from 'normalize-diacritics';

const filterProductName = (products, nameFilter) => {
  if (nameFilter) {
    const normalizedFilter = normalizeSync(nameFilter.toLowerCase());
    return products.filter((product) => {
      const normalizedProductName = normalizeSync(product.name.toLowerCase());
      return normalizedProductName.includes(normalizedFilter);
    });
  }

  return products;
};

const sortProducts = (orderBy) => (productA, productB) => {
  if (orderBy === 'NAME') {
    return (
      (productA.name.toUpperCase() > productB.name.toUpperCase()) -
      (productA.name.toUpperCase() < productB.name.toUpperCase())
    );
  }

  if (orderBy === 'PRICE') {
    return productA.price - productB.price;
  }

  return 0;
};

const reduceMenus = (nameFilter) => (acc, menu) => {
  const filteredProducts = filterProductName(menu.products, nameFilter);

  if (filteredProducts.length === 0) {
    return acc;
  }

  return acc.concat({
    ...menu,
    products: filteredProducts,
  });
};

const filterOrderProductMenus = (
  productMenus,
  nameFilter,
  productMenusFilters,
  orderBy = '',
) => {
  let filteredMenus = productMenus;

  if (productMenusFilters.length > 0) {
    filteredMenus = filteredMenus.filter((productMenu) =>
      productMenusFilters.some(
        (productMenusFilter) =>
          productMenusFilter.menuProductGroupId === productMenu.id,
      ),
    );
  }

  if (nameFilter) {
    filteredMenus = filteredMenus.reduce(reduceMenus(nameFilter), []);
  }

  if (orderBy) {
    filteredMenus = filteredMenus.map((menu) => ({
      ...menu,
      products: menu.products.sort(sortProducts(orderBy)),
    }));
  }

  return filteredMenus;
};

export default filterOrderProductMenus;
