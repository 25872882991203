import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Button from '@bit/eyemobile.core.button';

import {
  confirmationWrapper,
  warningText,
  buttonsRow,
} from './CommonConfirmation.styles';

const CommonConfirmation = ({
  onConfirm,
  onCancel,
  confirmationText,
  confirmText,
  cancelText,
}) => {
  const { t } = useTranslation();

  return (
    <div css={confirmationWrapper}>
      <span css={warningText}>{confirmationText}</span>
      <div css={buttonsRow}>
        <Button variant="outlined" color="error" onClick={onCancel}>
          {cancelText || t('formValidation:cancel')}
        </Button>
        <Button color="error" onClick={onConfirm}>
          {confirmText || t('formValidation:confirm')}
        </Button>
      </div>
    </div>
  );
};

CommonConfirmation.defaultProps = {
  confirmText: undefined,
  cancelText: undefined,
};

CommonConfirmation.propTypes = {
  confirmationText: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  confirmText: PropTypes.string,
  cancelText: PropTypes.string,
};

export default CommonConfirmation;
