import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Button from '@bit/eyemobile.core.button';

import ErrorImg from '../../assets/error.svg';
import ScrollableView from '../ScrollableView';
import {
  backgroundCss,
  wrapperCss,
  errorTextCss,
  errorImgCss,
} from './Error.styles';

const Error = ({ errorMessage, onReturn, buttonText }) => {
  const { t } = useTranslation();

  return (
    <div css={backgroundCss}>
      <ScrollableView>
        <div css={wrapperCss}>
          <img src={ErrorImg} alt="Error" css={errorImgCss} />
          {errorMessage && <span css={errorTextCss}>{errorMessage}</span>}
          {onReturn && (
            <Button variant="outlined" color="uiText" onClick={onReturn}>
              {buttonText || t('registrationError:return')}
            </Button>
          )}
        </div>
      </ScrollableView>
    </div>
  );
};

Error.defaultProps = {
  errorMessage: null,
  onReturn: null,
  buttonText: undefined,
};

Error.propTypes = {
  errorMessage: PropTypes.string,
  onReturn: PropTypes.func,
  buttonText: PropTypes.string,
};

export default Error;
