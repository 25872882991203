import styled from '@emotion/styled';
import { css } from '@emotion/core';
import facepaint from 'facepaint';

const mq = facepaint(['@media screen and (min-width: 375px)']);

const tabletMq = facepaint(['@media screen and (min-width: 720px)']);

const getFontSize = ({ theme }) =>
  mq({
    fontSize: [theme.getFontSize(0.85, 'px'), theme.getFontSize(1, 'px')],
    lineHeight: [theme.getFontSize(1.25, 'px'), theme.getFontSize(1.35, 'px')],
  });

export const ItemContainer = styled.div`
  width: calc(100% - 40px);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  position: relative;
  align-items: center;
  padding-left: ${({ theme }) => theme && theme.getSpacing(2, 'px')};
  padding-right: ${({ theme }) => theme && theme.getSpacing(2, 'px')};
  padding-top: ${({ theme }) => theme && theme.getSpacing(2, 'px')};
  padding-bottom: ${({ theme }) => theme && theme.getSpacing(2, 'px')};
  background-color: #f5f5f5;
  margin: 7px 20px;
  border-radius: 17px;
  cursor: pointer;
  user-select: none;
  -webkit-tap-highlight-color: transparent;

  ${({ disabled }) =>
    disabled && 'cursor: default; pointer-events: none; opacity: 0.5;'}
`;

export const ProductImage = styled.img`
  height: 70px;
  width: 70px;
  border-radius: 50%;
  object-fit: contain;
  border: 1px solid #cecece;
`;

export const ItemDescriptionWrapper = styled.div`
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  ${tabletMq({
    maxWidth: ['70%', '90%'],
  })}
  padding-left: ${({ theme }) => theme && theme.getSpacing(2, 'px')};
  padding-right: ${({ theme }) => theme && theme.getSpacing(1, 'px')};
  overflow: hidden;
`;

export const ProductItemText = styled.span`
  font-family: ${({ theme }) => theme && theme.getFont('futura')};
  ${getFontSize};
  color: ${({ theme }) => theme && theme.getColor('text')};
  text-align: left;
  margin-bottom: 4px;
`;

export const ProductText = styled.span`
  font-family: ${({ theme, fontFamily }) =>
    theme && theme.getFont(fontFamily || 'default')};
  font-size: ${({ theme, fontSize }) => theme.getFontSize(fontSize, 'px')};
  color: ${({ theme, customColor }) =>
    theme && theme.getColor(customColor || 'text')};
  text-align: left;
  margin-bottom: 4px;
  margin-top: 4px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const productDescriptionCss = (theme) => css`
  margin-top: 4px;
  margin-bottom: ${theme && theme.getSpacing(1, 'px')};
  width: 100%;
`;

export const ProductButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const RemoveButtonWrapper = styled.div`
  margin-left: ${({ theme }) => theme && theme.getSpacing(1, 'px')};
`;

export const amountTextCss = (theme) => css`
  position: absolute;
  top: 0;
  right: 0;
  font-family: ${theme.getFont('futura')};
  font-size: ${theme.getFontSize(0.7, 'px')};
  color: ${theme.getColor('buttonText')};
  background-color: ${theme.getColor('success')};
  padding: ${theme.getSpacing(0.5, 'px')};
  border-radius: 10px;
  text-align: center;
  min-width: 20px;
  min-height: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
