import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'emotion-theming';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import ProductImage from '../ProductImage';
import PopMenu from '../PopMenu';

import {
  ItemContainer,
  ItemDescriptionWrapper,
  ComplementWrapper,
  ProductItemText,
  ProductExtraProductText,
  ProductItemValueText,
  quantityWrapper,
  quantityText,
} from './ProductReviewItem.styles';

const filterComponentGroups = (componentGroups) =>
  componentGroups.reduce((acc, componentGroup) => {
    const { complementItems } = componentGroup;
    const filteredComponentItems = complementItems.filter(
      ({ quantity }) => quantity > 0,
    );

    if (filteredComponentItems.length > 0) {
      return acc.concat({
        ...componentGroup,
        complementItems: filteredComponentItems,
      });
    }

    return acc;
  }, []);

const ProductReviewItem = ({
  productImgSrc,
  productName,
  productPrice,
  quantityDescription,
  complementGroups,
  onRemove,
  onEdit,
  editable,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [anchorMenu, setAnchorMenu] = useState(null);
  const open = !!anchorMenu;
  const filteredComplementGroups = useMemo(
    () => filterComponentGroups(complementGroups),
    [complementGroups],
  );

  const handleClick = (event) => {
    setAnchorMenu(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorMenu(null);
  };

  const handleRemoveItem = () => {
    handleClose();
    onRemove();
  };

  const handleEditItem = () => {
    handleClose();
    onEdit();
  };

  const renderComplementItem = (max, complementItem) => {
    const innerQuantityText = max !== 1 ? `${complementItem.quantity}x` : '';
    const itemText = `- ${innerQuantityText} ${complementItem.name}`;

    return (
      <ProductExtraProductText key={`complement-item-${complementItem.id}`}>
        {itemText}
      </ProductExtraProductText>
    );
  };

  const renderComplementGroup = (complementGroup) => (
    <ComplementWrapper key={`complement-group-${complementGroup.id}`}>
      <ProductExtraProductText>{complementGroup.name}</ProductExtraProductText>
      <ComplementWrapper>
        {complementGroup.complementItems.map((complementItem) =>
          renderComplementItem(complementGroup.max, complementItem),
        )}
      </ComplementWrapper>
    </ComplementWrapper>
  );

  return (
    <ItemContainer>
      <div style={{ position: 'relative' }}>
        <ProductImage
          imgSrc={productImgSrc}
          alt={`img-${productName}`}
          size="50px"
        />
        <div css={quantityWrapper}>
          <span css={quantityText}>{quantityDescription}</span>
        </div>
      </div>
      <ItemDescriptionWrapper>
        <ProductItemText>{productName}</ProductItemText>
        <ItemDescriptionWrapper style={{ paddingTop: '5px' }}>
          {filteredComplementGroups.map(renderComplementGroup)}
        </ItemDescriptionWrapper>
        <ProductItemValueText>{productPrice}</ProductItemValueText>
      </ItemDescriptionWrapper>
      {editable && (
        <>
          <IconButton
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            onClick={handleClick}
          >
            <MoreVertIcon />
          </IconButton>
          <PopMenu
            id="long-menu"
            anchorEl={anchorMenu}
            open={open}
            onClose={handleClose}
          >
            {!!onEdit && (
              <MenuItem
                key="edit"
                onClick={handleEditItem}
                style={{
                  color: theme.getColor('background'),
                }}
              >
                {t('orderReview:edit')}
              </MenuItem>
            )}
            <MenuItem
              key="remove"
              onClick={handleRemoveItem}
              style={{
                color: theme.getColor('background'),
              }}
            >
              {t('orderReview:remove')}
            </MenuItem>
          </PopMenu>
        </>
      )}
    </ItemContainer>
  );
};

ProductReviewItem.defaultProps = {
  productImgSrc: null,
  quantityDescription: null,
  complementGroups: [],
  onRemove: null,
  onEdit: null,
  editable: true,
};

ProductReviewItem.propTypes = {
  productImgSrc: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  productName: PropTypes.string.isRequired,
  productPrice: PropTypes.string.isRequired,
  quantityDescription: PropTypes.string,
  complementGroups: PropTypes.array,
  onRemove: PropTypes.func,
  onEdit: PropTypes.func,
  editable: PropTypes.bool,
};

export default ProductReviewItem;
