import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import localizePrice from '../../utils/localizePrice';
import CreditCardFlag from '../../components/CreditCardFlag';

import OrderDescriptionRow from './OrderDescriptionRow';

import {
  fieldText,
  descriptionRow,
  DescriptionText,
  paymentWrapper,
  paymentCol,
  contentWrapper,
} from './OrderHistoryPage.styles';

const tefTypeMap = {
  1: 'orderHistory:CASH',
  3: 'orderHistory:DEBIT_CARD',
  4: 'orderHistory:CREDIT_CARD',
  10: 'orderHistory:PIX',
};

const OrderHistoryDetailPayment = ({ order }) => {
  const { t } = useTranslation();

  const { payments, priceTotal } = order;
  const payment = payments.find(({ tef }) => tef !== 5);
  const isOnlinePayment = payment && payment.cardFlag && payment.number;

  if (!payment) {
    return null;
  }

  return (
    <div css={paymentCol}>
      <span css={fieldText}>{t('orderHistory:payment')}</span>
      <OrderDescriptionRow label={t('orderHistory:paymentType')} bold>
        {isOnlinePayment
          ? t('orderHistory:onlinePayment')
          : t(tefTypeMap[payment.payTypeId])}
      </OrderDescriptionRow>
      {payment.installments > 1 && (
        <OrderDescriptionRow label={t('forms:installments')} bold>
          {t('forms:installmentsPay', {
            installments: payment.installments,
            installmentValue: localizePrice(priceTotal / payment.installments),
          })}
        </OrderDescriptionRow>
      )}
      {isOnlinePayment && (
        <div css={descriptionRow}>
          <DescriptionText fontFamily="futura">
            {t('orderHistory:card')}
          </DescriptionText>
          <div css={paymentWrapper}>
            <div css={contentWrapper}>
              <DescriptionText>{payment.number}</DescriptionText>
            </div>
            <CreditCardFlag
              brand={
                payment && payment.cardFlag && payment.cardFlag.toLowerCase()
              }
              size={40}
            />
          </div>
        </div>
      )}
    </div>
  );
};

OrderHistoryDetailPayment.propTypes = {
  order: PropTypes.object.isRequired,
};

export default OrderHistoryDetailPayment;
