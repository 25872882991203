import { css } from '@emotion/core';

export const helpWrapper = css`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const divisorCss = (theme) => css`
  margin-top: ${theme && theme.getSpacing(1, 'px')};
  margin-bottom: ${theme && theme.getSpacing(1, 'px')};
  width: 100%;
  height: 1px;
  color: default;
`;

export const contactText = (theme) => css`
  text-align: center;
  color: ${theme && theme.getColor('text')};
  font-family: ${theme && theme.getFont()};
  font-size: ${theme && theme.getFontSize(1, 'px')};
`;

export const contactLink = (theme) => css`
  text-align: center;
  color: ${theme && theme.getColor('primary')};
  font-family: ${theme && theme.getFont()};
  font-size: ${theme && theme.getFontSize(1, 'px')};

  &:hover,
  &:focus {
    text-decoration: underline;
  }
`;

export const buttonsRow = (theme) => css`
  width: 100%;
  margin-top: ${theme && theme.getSpacing(2, 'px')};
`;

export const contentRow = (theme) => css`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: ${theme && theme.getSpacing(1.5, 'px')};
  margin-bottom: ${theme && theme.getSpacing(1.5, 'px')};
`;
