import styled from '@emotion/styled';
import { css } from '@emotion/core';

export const WrapperDiv = styled.div`
  width: 100%;
  height: 100%;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  flex-direction: column;
  background-color: ${({ theme }) => theme && theme.getColor('loadingScreen')};
`;

export const messageCss = (theme) => css`
  font-size: ${theme && theme.getFontSize(1, 'px')};
  font-family: ${theme && theme.getFont()};
  color: ${theme && theme.getColor('uiText')};
  text-align: center;
  margin: ${theme && theme.getSpacing(2, 'px')};
`;
