import { createReducer } from '@reduxjs/toolkit';

// Constants

const Types = {
  SET_USER: 'user/SET_USER',
  REMOVE_USER: 'user/REMOVE_USER',
};

// Reducers

const initialUser = {};

const userReducer = createReducer(initialUser, {
  [Types.SET_USER]: (state, action) => {
    return action.payload;
  },
  [Types.REMOVE_USER]: () => {
    return initialUser;
  },
});

export const reducers = userReducer;

// Action creators

export const Creators = {
  setUser: (payload) => {
    return {
      type: Types.SET_USER,
      payload,
    };
  },
  removeUser: () => {
    return {
      type: Types.REMOVE_USER,
    };
  },
};
