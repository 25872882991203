import React from 'react';
import PropTypes from 'prop-types';
import Modal from '@bit/eyemobile.core.modal';

import withModal from '../../hocs/withModal';
import UserDeletePasswordForm from '../../components/UserDeletePasswordForm';

const UserDeletePasswordModal = ({ visible, onCloseModal, onSubmit }) => {
  return (
    <Modal visible={visible}>
      <UserDeletePasswordForm onCancel={onCloseModal} onFormSubmit={onSubmit} />
    </Modal>
  );
};

UserDeletePasswordModal.defaultProps = {
  visible: false,
};

UserDeletePasswordModal.propTypes = {
  visible: PropTypes.bool,
  onCloseModal: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default withModal((props) => ({
  visible: props.visible,
}))(UserDeletePasswordModal);
