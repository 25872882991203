import React from 'react';
import PropTypes from 'prop-types';
import Modal from '@bit/eyemobile.core.modal';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { useTranslation } from 'react-i18next';

import withModal from '../../hocs/withModal';
import LanguageSelector from '../LanguageSelector';
import { Creators as LanguageCreators } from '../../store/modules/language';

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setLanguage: LanguageCreators.setLanguage,
    },
    dispatch,
  );

const LanguagesModalsContainer = ({ visible, onCloseModal, setLanguage }) => {
  const { i18n } = useTranslation();

  const handleLanguageSelect = (lng) => {
    i18n.changeLanguage(`${lng}-CUSTOM`);
    setLanguage({ language: `${lng}-CUSTOM` });
    onCloseModal();
  };

  return (
    <Modal visible={visible}>
      <LanguageSelector
        languageOptions={i18n.languages.filter(
          (lng) => !lng.includes('CUSTOM'),
        )}
        selectedLanguage={i18n.language}
        onLanguageSelect={handleLanguageSelect}
        onCancel={onCloseModal}
      />
    </Modal>
  );
};

LanguagesModalsContainer.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  setLanguage: PropTypes.func.isRequired,
};

const enhance = compose(
  withModal((props) => ({
    visible: props.visible,
  })),
  connect(null, mapDispatchToProps),
);

export default enhance(LanguagesModalsContainer);
