import React from 'react';
import PropTypes from 'prop-types';

import AppTour from '../../components/AppTour';

const AppTourScreen = ({ onSkip }) => {
  return <AppTour onQuit={onSkip} />;
};

AppTourScreen.propTypes = {
  onSkip: PropTypes.func.isRequired,
};

export default AppTourScreen;
