import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import LocalStorageManager from '../../utils/LocalStorageManager';

import AppTourScreen from './AppTourScreen';

const mapStateToProps = ({ tenantEyepass: { domain, tenantId } }) => ({
  domain,
  tenantId,
});

const AppTourContainer = ({ history, domain }) => {
  const handleSkipTour = () => {
    LocalStorageManager.setIsNotFirstAccess();
    history.replace(`/${domain}/carrinho`);
  };

  return <AppTourScreen onSkip={handleSkipTour} />;
};

AppTourContainer.defaultProps = {
  domain: null,
};

AppTourContainer.propTypes = {
  domain: PropTypes.string,
  history: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(AppTourContainer);
