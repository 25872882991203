import axios from 'axios';

const { REACT_APP_SERVER_ENV, REACT_APP_SERVER_PORT, REACT_APP_SERVER_HOST } =
  process.env;

const currentEnv = REACT_APP_SERVER_ENV || 'development';
const devHost = REACT_APP_SERVER_HOST || 'localhost';
const devPort = REACT_APP_SERVER_PORT || 3002;

const hosts = {
  development: `http://${devHost}:${devPort}/api/v1`,
  staging: 'https://staging-gateway.eyemobile.com.br/api/v1',
  production: 'https://gateway.eyemobile.com.br/api/v1',
};

export const currentHost = hosts[currentEnv];

const instance = axios.create({
  baseURL: currentHost,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
  },
  timeout: 60000,
});

export default instance;
