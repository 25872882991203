import { shippingTypes } from './constants';
import getCashbackValue from './getCashbackValue';
import clean from './clean';
import showToast from './showToast';

const adaptComplementGroups = (componentGroups) =>
  componentGroups.reduce((acc, componentGroup) => {
    const { complementItems } = componentGroup;
    const filteredComplementItems = complementItems
      .filter(({ quantity }) => quantity > 0)
      .map((complementItem) => ({
        id: complementItem.id,
        amount: complementItem.quantity,
      }));

    if (filteredComplementItems.length > 0) {
      return acc.concat({
        id: componentGroup.id,
        complements: filteredComplementItems,
      });
    }

    return acc;
  }, []);

const adaptProductCombos = (comboProductItems) =>
  comboProductItems.map((comboProductItem) => ({
    id: comboProductItem.id,
    amount: comboProductItem.itemQuantity,
    price: comboProductItem.itemPrice,
  }));

const mapProducts = (product) => {
  const adaptedComplementGroups = adaptComplementGroups(
    product.complementGroups || [],
  );

  const adaptedProductCombos = adaptProductCombos(
    product.comboProductItems || [],
  );

  return {
    id: product.productId,
    productGroupId: product.productGroupId,
    amount:
      product.measure === 'UNIT'
        ? product.amount
        : (product.amount * 1000 * 0.1) / 1000,
    observations: product.observations || '',
    takeout: product.takeout || false,
    complement_groups: adaptedComplementGroups,
    product_combos: adaptedProductCombos,
    ...product.giftCardFields,
  };
};

const mapPromotionalProduct = (promotionalProduct) => {
  const productData = {
    id: promotionalProduct.productId,
    amount:
      promotionalProduct.measure === 'UNIT'
        ? promotionalProduct.amount
        : (promotionalProduct.amount * 1000 * 0.1) / 1000,
    observations: promotionalProduct.observations || '',
    price: promotionalProduct.price,
  };

  if (promotionalProduct.highlightId) {
    productData.highlightId = promotionalProduct.highlightId;
  }

  if (promotionalProduct.loyaltyId) {
    productData.loyaltyId = promotionalProduct.loyaltyId;
  }

  return productData;
};

const adaptCard = (cardId, cardHash, cardTokenize, total, installments) => {
  if (cardId) {
    return {
      type: 'CREDIT_CARD_ONLINE',
      card_id: cardId,
      total,
      installments,
    };
  }

  return {
    type: 'CREDIT_CARD_ONLINE',
    card_hash: cardHash,
    card_tokenize: cardTokenize || false,
    total,
    installments,
  };
};

const adaptOnDeliveryPayment = ({ type, change, total }) => {
  let payment = { type, total };

  if (change) {
    payment = { ...payment, change };
  }

  return payment;
};

const adaptPayments = (payment, cashback, giftCard) => {
  const { cardId, cardHash, cardTokenize, total, type, change, installments } =
    payment;

  const { useCashback, loyaltyCashback } = cashback;

  let discountedTotal = total;
  let payments = {};

  if (giftCard) {
    if (giftCard.total >= total) {
      return { gift_card: { id: giftCard.id, code: giftCard.code, total } };
    }

    discountedTotal -= giftCard.total;

    payments = {
      gift_card: {
        id: giftCard.id,
        code: giftCard.code,
        total: giftCard.total,
      },
    };
  }

  if (useCashback && loyaltyCashback) {
    const cashbackValue = getCashbackValue(
      total,
      loyaltyCashback.availableCashback,
      giftCard ? giftCard.total : 0,
      loyaltyCashback.maxCashbackPercentage,
    );

    discountedTotal -= cashbackValue;

    payments = {
      ...payments,
      cashback: {
        id: loyaltyCashback.id,
        total: cashbackValue,
      },
    };
  }

  if (discountedTotal < 3.5 && discountedTotal > 0) {
    showToast('Valor deve ser no mínimo R$ 3,50', 'error');
    throw new Error('Valor deve ser no mínimo R$ 3,50');
  }

  if (discountedTotal > 0) {
    const truncatedTotal = Number(discountedTotal.toFixed(2));

    const paymentMethod =
      type === 'CREDIT_CARD_ONLINE'
        ? adaptCard(
            cardId,
            cardHash,
            cardTokenize,
            truncatedTotal,
            installments,
          )
        : adaptOnDeliveryPayment({
            total: truncatedTotal,
            type,
            change,
          });

    payments = {
      ...payments,
      payment: paymentMethod,
    };
  }

  return { ...payments };
};

const adaptSchedule = (schedule) =>
  schedule
    ? {
        starts_at: schedule.start,
        ends_at: schedule.end,
      }
    : undefined;

const adaptShipping = (shipping, schedule) => {
  const adaptedSchedule = adaptSchedule(schedule);

  if (shipping.type === shippingTypes.TAG) {
    return {
      type: shippingTypes.TAG,
      comment: shipping.tag,
      schedule: adaptedSchedule,
    };
  }

  if (shipping.type === shippingTypes.TAKEOUT) {
    return {
      type: shippingTypes.TAKEOUT,
      address_id: '0',
      schedule: adaptedSchedule,
    };
  }

  if (shipping.address) {
    const { address } = shipping;

    if (shipping.address_id) {
      return {
        type: shippingTypes.ADDRESS,
        address_id: shipping.address_id,
        schedule: adaptedSchedule,
      };
    }

    return {
      type: shippingTypes.ADDRESS,
      address: address.street,
      number: address.number,
      zipcode: address.zipcode,
      city: address.city,
      state: address.state,
      district: address.district,
      complement: address.complement || '',
      schedule: adaptedSchedule,
    };
  }

  return {
    type: shippingTypes.ADDRESS,
    address_id: '0',
    schedule: adaptedSchedule,
  };
};

const buildProductOrder = (
  store,
  products,
  promotionalProducts,
  payment,
  cashback,
  shipping,
  schedule,
  giftCard,
  comment,
) => {
  return clean(
    {
      event_point_id: store.id,
      products: products.map(mapProducts),
      promotionalProducts: promotionalProducts.map(mapPromotionalProduct),
      shipping: adaptShipping(shipping, schedule),
      comment: comment.length > 0 ? comment : null,
      ...adaptPayments(payment, cashback, giftCard),
    },
    false,
  );
};

export default buildProductOrder;
