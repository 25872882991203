import { css } from '@emotion/core';
import styled from '@emotion/styled';

export const ItemContainer = styled.div`
  width: 100%;
  padding: ${({ theme }) =>
    `${theme.getSpacing(1, 'px')} ${theme.getSpacing(1.5, 'px')}`};
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #f5f5f5;
  border-radius: 17px;
  cursor: pointer;
  border: 1px solid
    ${({ theme, selected }) =>
      selected ? theme.getColor('primary') : 'transparent'};
  &:not(:first-of-type) {
    margin-top: ${({ theme }) => theme.getSpacing(2, 'px')};
  }

  ${({ disabled }) =>
    disabled && 'opacity: 0.75; cursor: default; pointer-events: none;'}
`;

export const mainTag = (theme) => css`
  padding: ${theme.getSpacing(1, 'px')} ${theme.getSpacing(1.5, 'px')};
  color: ${theme.getColor('text')};
  background-color: #c9c9c9;
  border-radius: 20px;
  font-size: ${theme.getFontSize(1, 'px')};
  font-family: ${theme.getFont('futura')};
`;

export const labelText = (theme) => css`
  color: ${theme.getColor('text')};
  font-size: ${theme.getFontSize(1, 'px')};
  font-family: ${theme.getFont('futura')};
`;

export const descText = (theme) => css`
  color: ${theme.getColor('text')};
  font-size: ${theme.getFontSize(1, 'px')};
  font-family: ${theme.getFont()};
  line-height: 17px;
`;
