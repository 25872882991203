import gateway from './gateway';
import showToast from './showToast';

const reloadUser = async (setUser) => {
  try {
    const profile = await gateway.get('/profile').then(({ data }) => data);

    setUser(profile.customer);
  } catch (err) {
    showToast(err?.response?.data?.message || err.message, 'error');
  }
};

export default reloadUser;
