import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import LoadingScreen from '../../components/LoadingScreen';
import ErrorScreen from '../../components/Error';

const ContextScreen = ({ isLoading, hasError }) => {
  const { t } = useTranslation();

  if (isLoading || !hasError) {
    return <LoadingScreen />;
  }

  return <ErrorScreen errorMessage={t('context:contextError')} />;
};

ContextScreen.defaultProps = {
  isLoading: true,
  hasError: false,
};

ContextScreen.propTypes = {
  isLoading: PropTypes.bool,
  hasError: PropTypes.bool,
};

export default ContextScreen;
