import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  RadioButton,
  radioButtonLabel,
  RadioButtonInput,
  radioButtonCheck,
} from './RowRadioButton.styles';

const RowRadioButton = ({
  name,
  checked,
  onChange,
  label,
  disabled,
  ltor,
  style,
  labelCss,
  onClick,
}) => {
  const handleOnClick = () => {
    if (onClick) onClick();
    onChange(!checked);
  };

  const renderLabel = () => {
    return label ? (
      <label
        key={`${name}-label`}
        htmlFor={`${name}-checkbox`}
        css={radioButtonLabel}
      >
        {label}
      </label>
    ) : null;
  };

  const renderButton = () => (
    <RadioButtonInput
      key={`${name}-input`}
      checked={checked}
      id={`${name}-checkbox`}
      ltor={ltor}
    >
      {checked && !disabled && <div css={radioButtonCheck} />}
    </RadioButtonInput>
  );

  const renderContent = useMemo(() => {
    const renders = [renderLabel(), renderButton()];

    return ltor ? renders.reverse() : renders;
  }, [ltor, checked]);

  return (
    <RadioButton
      onKeyDown={handleOnClick}
      onClick={handleOnClick}
      aria-label="radio-input"
      role="button"
      tabIndex={0}
      disabled={disabled}
      style={style}
      css={labelCss}
    >
      {renderContent}
    </RadioButton>
  );
};

RowRadioButton.defaultProps = {
  name: 'radio-button',
  label: '',
  disabled: false,
  ltor: false,
  style: undefined,
  labelCss: undefined,
  onClick: undefined,
};

RowRadioButton.propTypes = {
  name: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  ltor: PropTypes.bool,
  disabled: PropTypes.bool,
  style: PropTypes.object,
  labelCss: PropTypes.object,
  onClick: PropTypes.func,
};

export default RowRadioButton;
