import { createReducer, createAction } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { takeLatest, put, all } from 'redux-saga/effects';

export const increment = createAction('fb/example/INCREMENT');
export const decrement = createAction('fb/example/DECREMENT');
export const sagasIncrement = createAction('fb/example/SAGASINCREMENT');

const counter = createReducer(0, {
  [increment]: (state, action) => state + action.payload,
  [decrement]: (state, action) => state - action.payload,
});

export const reducers = combineReducers({
  counter,
});

// Middleware

export function* incrementCounter({ payload }) {
  yield put(increment(payload));
}

function* watchIncrement() {
  yield takeLatest(sagasIncrement, incrementCounter);
}

function* exampleSagas() {
  yield all([watchIncrement()]);
}

export const sagas = exampleSagas;
