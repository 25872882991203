import React from 'react';
import PropTypes from 'prop-types';

import {
  HeaderContainer,
  headerNameWrapper,
  HeaderName,
} from './PaymentLinkHeader.styles';

const PaymentLinkHeader = ({ storeName }) => {
  return (
    <HeaderContainer>
      <div css={headerNameWrapper}>
        <HeaderName>{storeName}</HeaderName>
      </div>
    </HeaderContainer>
  );
};

PaymentLinkHeader.defaultProps = {
  storeName: '',
};

PaymentLinkHeader.propTypes = {
  storeName: PropTypes.string,
};

export default PaymentLinkHeader;
