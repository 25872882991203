import { css } from '@emotion/core';

export const producerImage = (theme) => css`
  width: ${theme && theme.getSpacing(8, 'px')};
  height: ${theme && theme.getSpacing(8, 'px')};
`;

export const fieldLabel = (theme) => css`
  font-family: ${theme && theme.getFont()};
  color: ${theme && theme.getColor('text')};
`;

export const fieldValue = (theme) => css`
  font-family: ${theme && theme.getFont()};
  color: ${theme && theme.getColor('placeholder')};
`;

export const fieldTitle = (theme) => css`
  font-size: ${theme && theme.getFontSize(1.25, 'px')};
  font-family: ${theme && theme.getFont()};
  color: ${theme && theme.getColor('text')};
`;

export const batchInput = (theme) => css`
  color: ${theme && theme.getColor('foreground')};
`;
