import React from 'react';
import PropTypes from 'prop-types';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import { carouselCss } from './Carousel.styles';

const CustomCarousel = ({ children, ...props }) => {
  return (
    <Carousel {...props} css={carouselCss}>
      {children}
    </Carousel>
  );
};

CustomCarousel.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default CustomCarousel;
