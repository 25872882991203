import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Button from '@bit/eyemobile.core.button';
import {
  confirmationWrapper,
  warningText,
  buttonsRow,
} from './ShippingTypeConfirmation.styles';

const ShippingTypeConfirmation = ({ onConfirm, onCancel }) => {
  const { t } = useTranslation();

  return (
    <div css={confirmationWrapper}>
      <span css={warningText}>
        {t('productOrder:confirmShippingTypeChange')}
      </span>
      <div css={buttonsRow}>
        <Button variant="outlined" color="primary" onClick={onCancel}>
          {t('formValidation:cancel')}
        </Button>
        <Button color="primary" onClick={onConfirm}>
          {t('formValidation:confirm')}
        </Button>
      </div>
    </div>
  );
};

ShippingTypeConfirmation.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default ShippingTypeConfirmation;
